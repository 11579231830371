import React, { Component } from "react";
import { Consumer } from "../../context/index";
import Request from "../../utils/http";
import ButtonActions from "./buttonsActions";
import EdditModal from "./edditModal";
import Modal from "react-modal";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
//import DownloadExcel from "../../pages/inventory/downloadExcel";
//import DownloadPDF from "../../pages/inventory/downloadPDF";

import "./modal.css";
import "./tableArticles.css";

const request = new Request();
Modal.setAppElement(document.getElementById("root"));
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    //overflow: "scroll",
  },
};
class TableArticles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productos: [],
      showModal: false,
      image: "",
      producto: "",
      user: {
        id: 0,
        id_perfil: 0,
        name: "",
      },
      artEddit: {
        id: 0,
        nom: "",
      },
      edditModal: false,
      load: true,
      areas: [],
      id: "",
      text: "",
      area: -1,
      activo: -1,
      page: 0,
      rowsPerPage: 25,
    };
  }

  render() {
    return (
      <div className="content-wrapper" id="tableBodega">
        <div className="card" style={{ margin: "1rem" }}>
          <div className="card-header">
            <div
              className="card-title row card-title row row-cols-2 row-cols-md-4 row-cols-lg-5"
              style={{ width: "100%" }}
            >
              <div className="col text-center">
                <TextField
                  label="ID"
                  name="id"
                  type="number"
                  value={this.state.id}
                  onChange={(e) => this.handleChangeFilter(e)}
                />
              </div>
              <div className="col text-center">
                <TextField
                  label="Palabras Clave"
                  name="texto"
                  value={this.state.text}
                  onChange={(e) => this.handleChangeFilter(e)}
                />
              </div>
              {this.state.user.id_perfil === 1 ||
              this.state.user.id_perfil === 5 ? (
                <>
                  <div className="col text-center">
                    <FormControl>
                      <InputLabel htmlFor="type">Almacén</InputLabel>
                      <Select
                        value={this.state.area}
                        onChange={(e) => this.handleChangeFilter(e)}
                        input={<Input id="almacen" />}
                        name="almacen"
                      >
                        <MenuItem value={-1}>Todos</MenuItem>
                        {this.state.areas.map((info, key) => (
                          <MenuItem key={key} value={info.id_area}>
                            {info.descrip}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="col text-center">
                    <FormControl>
                      <InputLabel htmlFor="activo">Activo</InputLabel>
                      <Select
                        value={this.state.activo}
                        onChange={(e) => this.handleChangeFilter(e)}
                        input={<Input id="activo" />}
                        name="activo"
                      >
                        <MenuItem value={-1}>
                          <i className="fas fa-list"></i>&nbsp;Todos
                        </MenuItem>
                        <MenuItem value={1}>
                          <i className="fas fa-eye"></i>&nbsp;Activo
                        </MenuItem>
                        <MenuItem value={0}>
                          <i className="fas fa-eye-slash"></i>&nbsp;Inactivo
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </>
              ) : null}
              <div className="col text-right">
                <button
                  className="btn btn-primary btn-sm button-filter"
                  onClick={this.handleSearch.bind(this)}
                >
                  <i className="fas fa-search"></i>
                </button>
                &nbsp;
                <button
                  className="btn btn-secondary btn-sm button-filter"
                  onClick={this.handleClear.bind(this)}
                >
                  <i className="fas fa-times"></i>
                </button>
                &nbsp;
                <button
                  className="btn btn-info btn-sm bg-gradient-info button-filter"
                  onClick={(e) =>
                    this.openEddit({ id: 0, nom: "Nuevo Artículo" })
                  }
                >
                  <i className="fas fa-plus"></i>
                </button>
                {/* &nbsp;
                {this.state.productos.length !== 0 ? (
                  <>
                    <DownloadExcel excelData={this.state.productos} />
                    &nbsp;
                    <DownloadPDF pdfData={this.state.productos} />
                  </>
                ) : null} */}
              </div>
            </div>
          </div>
          <div className="card-body">
            <TableContainer>
              <Table
                aria-labelledby="tableTitle"
                size="small"
                aria-label="enhanced table"
              >
                <TableHead>
                  <TableRow>
                    {this.state.user.id_perfil === 1 ||
                    this.state.user.id_perfil === 5 ? (
                      <>
                        <TableCell align="center" className="AcctionsButton">
                          ACCIÓN
                        </TableCell>
                        <TableCell align="center">ACTIVO</TableCell>
                        <TableCell align="center">ALMACÉN</TableCell>
                      </>
                    ) : null}
                    <TableCell align="center">FOTO</TableCell>
                    <TableCell align="center">ID</TableCell>
                    <TableCell align="center">NOMBRE</TableCell>
                    <TableCell align="center">DIMENSIÓN</TableCell>
                    <TableCell align="center">MATERIAL</TableCell>
                    <TableCell align="center">EXISTENCIA</TableCell>
                    <TableCell align="center">TOTAL</TableCell>
                    <TableCell align="center">UNIDAD</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!this.state.load ? (
                    this.state.productos
                      .slice(
                        this.state.page * this.state.rowsPerPage,
                        this.state.page * this.state.rowsPerPage +
                          this.state.rowsPerPage
                      )
                      .map((row, index) => {
                        return (
                          <TableRow key={index}>
                            {this.state.user.id_perfil === 1 ||
                            this.state.user.id_perfil === 5 ? (
                              <>
                                <TableCell
                                  align="center"
                                  className="AcctionsButton"
                                >
                                  <ButtonActions
                                    id={row.id_art}
                                    nom={row.nomE}
                                    reload={this.reload.bind(this)}
                                    openModal={(e) => this.openEddit(e)}
                                    idPerfil={this.state.user.id_perfil}
                                  ></ButtonActions>
                                </TableCell>
                                <TableCell align="center">
                                  {row.activo === 0 ? (
                                    <>
                                      <i
                                        className="fas fa-eye-slash"
                                        style={{ color: "#b71c1c" }}
                                        title="Inactivo"
                                      ></i>
                                      &nbsp;Inactivo
                                    </>
                                  ) : (
                                    <>
                                      <i
                                        className="fas fa-eye"
                                        style={{ color: "#4caf50" }}
                                        title="Activo"
                                      ></i>
                                      &nbsp;Activo
                                    </>
                                  )}
                                </TableCell>
                                <TableCell align="center">{row.area}</TableCell>
                              </>
                            ) : null}
                            <TableCell align="center">
                              {this.customImg(row.img_pred)}
                            </TableCell>
                            <TableCell align="center">{row.id_art}</TableCell>
                            <TableCell align="center">
                              {row.nomE}
                              <hr />
                              <i>{row.nomI}</i>
                            </TableCell>
                            <TableCell align="center">{row.dim}</TableCell>
                            <TableCell align="center">{row.caract}</TableCell>
                            <TableCell align="center">
                              {this.customStock(row.totalStock)}
                            </TableCell>
                            <TableCell align="center">{row.total}</TableCell>
                            <TableCell align="center">{row.unidad}</TableCell>
                          </TableRow>
                        );
                      })
                  ) : (
                    <TableRow>
                      <TableCell align="center" colSpan={11}>
                        <i
                          className="fas fa-spinner fa-spin"
                          style={{ fontSize: "62px", color: "#3f51b5" }}
                        ></i>
                        <br /> Cargando Artículos...
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[25, 50, 100, 150, 200, 250]}
              component="div"
              count={this.state.productos.length}
              rowsPerPage={this.state.rowsPerPage}
              page={this.state.page}
              onChangePage={this.handleChangePage.bind(this)}
              onChangeRowsPerPage={this.handleChangeRowsPerPage.bind(this)}
              labelRowsPerPage="Artículos por página"
            />

            <Modal
              isOpen={this.state.showModal}
              style={customStyles}
              contentLabel="EditarArticulo"
            >
              <div className="card-body">
                <button
                  type="button"
                  className="close"
                  onClick={(e) => this.setState({ showModal: false })}
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
                <div
                  className="tab-content"
                  id="custom-tabs-three-tabContent"
                  style={{ display: "flex" }}
                >
                  <div>
                    <img
                      src={this.state.image}
                      alt=""
                      style={{
                        width: "auto",
                        maxWidth: 800 + "px",
                        height: "auto",
                        maxHeight: 400 + "px",
                      }}
                    />
                  </div>
                </div>
              </div>
            </Modal>

            <EdditModal
              openModal={this.state.edditModal}
              page={this.state.page}
              setOpenModal={(e) => this.openEddit(e)}
              data={this.state.artEddit}
              newId={(e) => this.newId(e)}
              reload={(e) => this.reload(e)}
              handleSearch={() => this.handleSearch()}
            />
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    this.getAlmacen();
    this.handleSearch();
    this.load();
  }
  async load() {
    const user = await this.props.context.loadUser();

    if (user) {
      this.setState({
        user: user,
      });
    }
    let productos = JSON.parse(sessionStorage.getItem("Productos"));
    if (productos === null) {
      this.getArticles();
    } else {
      this.setState({
        productos: productos,
      });
    }
  }
  async reload(data) {
    let prod = this.state.productos;
    let newp = true;
    for (let i = 0; i < prod.length; i++) {
      if (data.id_art === prod[i].id_art) {
        prod[i] = data;
        newp = false;
        this.setState({ productos: prod });
        return;
      }
    }
    if (newp) {
      prod.unshift(data);
      this.setState({ productos: prod, page: 0 });
    }
  }
  async openModal(img) {
    this.setState({ showModal: !this.state.showModal });
    this.setState({ image: img });
  }
  openEddit(data) {
    this.setState({ artEddit: data, edditModal: !this.state.edditModal });
  }
  newId(data) {
    this.setState({ artEddit: data });
  }
  async getArticles() {
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }
    let data = {
      token: token,
      band: 1,
      idPerfil: this.state.user.id_perfil,
    };

    const response = await request.post("/auth/inventory/getArticles", data);

    if (response && !response.error) {
      if (response.result && !response.empty) {
        this.setState({ productos: response.result, load: false });
      } else {
        this.setState({
          empty: true,
          loading: false,
          message: response.message,
        });
      }
    } else {
      this.setState({
        error: true,
        loading: false,
        message: response.message,
      });
    }
  }
  customStock(value) {
    //console.log("TableArticles -> customStock -> value", value);
    let stock = { cun: 0, rm: 0, cm: 0, cab: 0 };
    if (value !== null) {
      value = JSON.parse(value);

      //console.log("TableArticles -> customStock -> value", value)

      for (let i = 0; i < value.length; i++) {
        let el = value[i];

        switch (el.ubi) {
          case 1:
            stock.cun = el.cant;
            break;
          case 2:
            stock.rm = el.cant;
            break;
          case 3:
            stock.cm = el.cant;
            break;
          case 4:
            stock.cab = el.cant;
            break;
        }
      }
      //console.log("TableArticles -> customStock -> stock ", stock);
    }
    return (
      <>
        <div
          className="bg-gradient-info"
          title="Cancún"
          style={{
            padding: "0.2rem",
            fontWeight: "bold",
            borderRadius: "0.2rem",
            marginTop: "0.1rem",
          }}
        >
          CUN:&nbsp;{stock.cun}
        </div>
        <div
          className="bg-gradient-secondary"
          title="Riviera Maya"
          style={{
            padding: "0.2rem",
            fontWeight: "bold",
            borderRadius: "0.2rem",
            marginTop: "0.1rem",
          }}
        >
          RM:&nbsp;{stock.rm}
        </div>
        <div
          className="bg-gradient-dark"
          title="Costa Mujeres"
          style={{
            padding: "0.2rem",
            fontWeight: "bold",
            borderRadius: "0.2rem",
            marginTop: "0.1rem",
          }}
        >
          CM:&nbsp;{stock.cm}
        </div>
        <div
          className="bg-gradient-primary"
          title="Los Cabos"
          style={{
            padding: "0.2rem",
            fontWeight: "bold",
            borderRadius: "0.2rem",
            marginTop: "0.1rem",
          }}
        >
          CAB:&nbsp;{stock.cab}
        </div>
      </>
    );
  }
  customImg(value) {
    let img = "";
    if (value.slice(0, 4) === "http") {
      img = value;
    } else {
      img = `${request.publicURL()}/inventario/${value}`;
    }
    return (
      <div>
        <img
          src={
            value.slice(0, 4) === "http"
              ? value.replace("/imagenes/", "/imagenes/thumb/")
              : `${request.publicURL()}/inventario/${value}`
          }
          style={{ maxHeight: "80px", maxWidth: "120px" }}
          alt="Imagen Data"
          onError={(e) => {
            e.target.src = "./assets/uploads/no-image.jpg";
          }}
          onClick={(e) => {
            this.openModal(img);
          }}
        />
      </div>
    );
  }
  async getAlmacen() {
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }
    let data = {
      token: token,
    };

    const response = await request.post("/auth/inventory/getAlmacen", data);

    if (response && !response.error) {
      if (response.result && !response.empty) {
        this.setState({ areas: response.result.almacen });
      }
    }
  }
  async handleChangeFilter(e) {
    let name = e.target.name;
    switch (name) {
      case "id":
        if (e.target.value.length !== 0) {
          this.setState({ id: parseInt(e.target.value, 10) });
        } else {
          this.setState({ id: "" });
        }
        break;
      case "texto":
        if (e.target.value.length !== 0) {
          this.setState({ text: e.target.value });
        } else {
          this.setState({ text: "" });
        }
        break;
      case "almacen":
        this.setState({ area: e.target.value });
        break;
      case "activo":
        this.setState({ activo: e.target.value });
        break;
    }
  }
  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };
  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };
  async handleSearch() {
    this.setState({ load: true });
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }
    if (
      this.state.id.length === 0 &&
      this.state.text.length === 0 &&
      this.state.area === -1 &&
      this.state.activo === -1
    ) {
      this.getArticles();
      return;
    }
    let data = {
      token: token,
      band: 1,
      idPerfil: this.state.user.id_perfil,
      id: this.state.id.length !== 0 ? this.state.id : "%",
      text: this.state.text,
      area: this.state.area,
      activo: this.state.activo,
    };

    const response = await request.post(
      "/auth/inventory/getSearchArticles",
      data
    );

    if (response && !response.error) {
      if (response.result && !response.empty) {
        this.setState({ productos: response.result, load: false });
      } else {
        this.setState({
          empty: true,
          loading: false,
          message: response.message,
        });
      }
    } else {
      this.setState({
        error: true,
        loading: false,
        message: response.message,
      });
    }
  }
  async handleClear() {
    this.setState({
      id: "",
      text: "",
      area: -1,
      activo: -1,
      load: true,
    });
    this.getArticles();
  }
}

export default Consumer(TableArticles);
