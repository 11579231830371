import React, { useEffect, useRef, useState } from "react";
import QrScanner from "qr-scanner";
import "./qrScanner.scss";

const QRScanner = (props) => {
  const videoElementRef = useRef(null);
 // const [scanned, setScannedText] = useState("");

  useEffect(() => {
    const video: HTMLVideoElement = videoElementRef.current;
    const qrScanner = new QrScanner(
      video,
      (result) => {
        //console.log("decoded qr code:", result);
        //setScannedText(result.data);
        //console.log(qrScanner);
        props.changeData(result.data);
        qrScanner.stop();
        qrScanner.destroy();
      },
      {
        returnDetailedScanResult: true,
        highlightScanRegion: true,
        highlightCodeOutline: true,
      }
    );
    qrScanner.start();
    // console.log("start");

    return () => {
      console.log(qrScanner);
      qrScanner.stop();
      qrScanner.destroy();
    };
  }, []);

  /* const qrScanner = new QrScanner(videoElement, (result) =>
     console.log('decoded qr code:', result)
   );*/

  return (
    <div className="modal-body card-primary card-outline card-outline-tabs">
      <div className="card-body">
        <div className="videoWrapper">
          <video className="qrVideo" id="qrVideo" ref={videoElementRef} />
        </div>
      </div>
    </div>
  );
};

export default QRScanner;
