import React, { Component } from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import Modal from "react-modal";
import Fab from "@material-ui/core/Fab";
import EditIcon from "@material-ui/icons/Edit";
import { toast } from "react-toastify";
import Request from "../../utils/http";
const request = new Request();

Modal.setAppElement(document.getElementById("root"));

const customStyles = {
  content: {
    top: "50%",
    left: "80%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    overflow: "scroll",
  },
};

class NewProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      proveedores: [],
      unidades: [],
      categorias: [],
      subCategorias: [],
      openModal: false,
      idArt: 0,
      eddit: this.props.eddit,
      saved: 0,
      nombre: "",
      unidad: "",
      caracteristicas: "",
      categoria: 0,
      subcat: 0,
      proveedor: this.props.id_prov,
      codProv: "",
    };
  }
  obtenerToken() {
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }
    return token;
  }
  closeModal() {
    this.openModal();
  }
  reload() {
    this.props.reload();
  }
  saved(id) {
    let cont = this.state.saved + 1;
    this.setState({
      saved: cont,
      idArt: id,
    });
  }
  render() {
    return (
      <>
        {this.props.edit === false ? (
          <Tooltip title={"Agregar Producto"}>
            <Fab
              color="primary"
              size={"small"}
              aria-label="add"
              className="MuiButtonBase-root MuiIconButton-root MUIDataTableToolbar-icon-49"
              onClick={this.openModal.bind(this)}
            >
              <AddIcon className="MuiSvgIcon-root" />
            </Fab>
          </Tooltip>
        ) : (
          <button
            className="btn btn-sm btn-info bg-gradient-info"
            title="Editar"
            onClick={this.openModal.bind(this)}
          >
            <EditIcon />
          </button>
        )}

        <Modal
          isOpen={this.state.openModal}
          style={customStyles.content}
          contentLabel="EditarArticulo"
        >
          <div className="modal-header">
            <h4 className="modal-title">Nuevo Producto</h4>
            <button
              type="button"
              className="close"
              onClick={this.openModal.bind(this)}
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body card-primary card-outline card-outline-tabs">
            <div className="card-body">
              <div className="tab-content" id="custom-tabs-three-tabContent">
                <div className="white-space-16"></div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">ID:</span>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        aria-label=""
                        value={this.state.idArt}
                        readOnly
                      ></input>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">NOMBRE:</span>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        aria-label=""
                        value={this.state.nombre}
                        onChange={(e) =>
                          this.setState({ nombre: e.target.value })
                        }
                      ></input>
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-6">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">UNIDAD:</span>
                      </div>
                      <select
                        name=""
                        value={this.state.unidad}
                        onChange={(e) =>
                          this.setState({ unidad: e.target.value })
                        }
                        className="form-control"
                        id=""
                      >
                        <option value="0"></option>
                        {this.state.unidades.map((unidad, key) => (
                          <option key={key} value={unidad.id_uni}>
                            {unidad.descrip}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">CATEGORIA:</span>
                      </div>
                      <select
                        name=""
                        className="form-control"
                        id=""
                        value={this.state.categoria}
                        onChange={(e) => this.cambioCat(e)}
                      >
                        <option value="0"></option>
                        {this.state.categorias.map((categoria, key) => (
                          <option key={key} value={categoria.id_categoria}>
                            {categoria.nombre_categoria}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-6">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">SUBCATEGORIA:</span>
                      </div>
                      <select
                        name=""
                        className="form-control"
                        id=""
                        value={this.state.subcat}
                        onChange={(e) =>
                          this.setState({ subcat: e.target.value })
                        }
                      >
                        {this.state.subCategorias.map((sub, key) => (
                          <option key={key} value={sub.id_subcategoria}>
                            {sub.nombre_subcategoria}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">PROVEEDOR:</span>
                      </div>
                      <select
                        name=""
                        className="form-control"
                        id=""
                        value={this.state.proveedor}
                        onChange={(e) =>
                          this.setState({ proveedor: e.target.value })
                        }
                      >
                        {" "}
                        <option value="0"></option>
                        {this.state.proveedores.map((proveedor, key) => (
                          <option key={key} value={proveedor.id_prov}>
                            {proveedor.razon}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-12">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          CODIGO PROVEEDOR / FOLIO:
                        </span>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        aria-label=""
                        value={this.state.codProv}
                        onChange={(e) =>
                          this.setState({ codProv: e.target.value })
                        }
                      ></input>
                    </div>
                  </div>
                </div>

                <div className="white-space-16"></div>
                <div className="row mt-4"></div>
                <div className="white-space-32"></div>
                <div className="row">
                  <div className="col-12">
                    <div className="input-group mb-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          CARACTERISTICAS:
                        </span>
                      </div>
                      <textarea
                        name="descrip"
                        className="form-control"
                        value={this.state.caracteristicas}
                        onChange={(e) =>
                          this.setState({ caracteristicas: e.target.value })
                        }
                      ></textarea>
                    </div>
                  </div>
                </div>

                <div className="modal-footer justify-content-left ">
                  <button
                    type="button"
                    className="btn btn-outline-success btn-flat "
                    onClick={this.handleSubmit.bind(this)}
                  >
                    <i className="far fa-save"></i>&nbsp; Guardar
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-danger btn-flat"
                    onClick={this.openModal.bind(this)}
                  >
                    <i className="fas fa-ban"></i> Cerrar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  }

  openModal() {
    this.setState({
      openModal: !this.state.openModal,
    });

    this.getunidad();
    this.getCategoria();

    this.getProv();

    if (this.props.edit === true) {
      this.getData();
    }
  }

  async getData() {
    let token = this.obtenerToken();

    let data = {
      token: token,
      id: this.props.id,
    };

    const response = await request.post("/auth/purchases/getProductInfo", data);
    console.log("response: ", response);
    if (response && !response.error) {
      if (response.result && !response.empty) {
        //console.log(response.result.providers);
        this.setState({
          idArt: response.result[0].id_prod,
          nombre: response.result[0].nombre,
          unidad: response.result[0].unidad,
          caracteristicas: response.result[0].caracteristicas,
          categoria: response.result[0].id_categoria,
          subcat: response.result[0].subcategoria,
          proveedor: response.result[0].id_prov,
          codProv: response.result[0].cod_alt,
        });
        this.subcat(response.result[0].id_categoria);
      } else {
        console.log(response.result);
      }
    } else {
      console.log("error en la peticion");
    }
  }
  cambioCat(e) {
    this.setState({ categoria: e.target.value });
    this.subcat(e.target.value);
  }
  async getunidad() {
    let token = this.obtenerToken();

    let data = {
      token: token,
    };

    const response = await request.post("/auth/purchases/getUnity", data);
    if (response && !response.error) {
      if (response.result && !response.empty) {
        //console.log(response.result.providers);
        this.setState({
          unidades: response.result,
        });
      } else {
        console.log(response.result);
      }
    } else {
      console.log("error en la peticion");
    }
  }
  async getCategoria() {
    let token = this.obtenerToken();

    let data = {
      token: token,
    };

    const response = await request.post("/auth/purchases/getCategory", data);
    if (response && !response.error) {
      if (response.result && !response.empty) {
        this.setState({
          categorias: response.result,
        });
      } else {
        console.log(response.result);
      }
    } else {
      console.log("error en la peticion");
    }
  }
  async subcat(id) {
    let token = this.obtenerToken();

    let data = {
      token: token,
      id: id,
    };

    const response = await request.post("/auth/purchases/getSubCat", data);
    if (response && !response.error) {
      if (response.result && !response.empty) {
        this.setState({
          subCategorias: response.result,
        });
      } else {
        console.log(response.result);
      }
    } else {
      console.log("error en la peticion");
    }
  }
  async getProv() {
    let token = this.obtenerToken();

    let data = {
      token: token,
    };

    const response = await request.post("/auth/purchases/getProviders", data);
    console.log("response: ", response);

    //validar los datos que recibimos
    if (response && !response.error) {
      if (response.result && !response.empty) {
        //console.log(response.result);
        this.setState({
          proveedores: response.result,
        });
      } else {
        console.log(response.result);
      }
    } else {
      console.log("error en la peticion");
    }
  }
  async handleSubmit() {
    let token = this.obtenerToken();

    let data = {
      token: token,
      idArt: this.state.idArt,
      nombre: this.state.nombre,
      unidad: this.state.unidad,
      caracteristicas: this.state.caracteristicas,
      subcat: this.state.subcat,
      proveedor: this.state.proveedor,
      codProv: this.state.codProv,
    };
    const response = await request.post("/auth/purchases/saveProduct", data);
    if (response && !response.error) {
      if (response.result && !response.empty) {
        this.closeModal();
        this.props.reload();
        toast.success("Datos Guardados con exito!", {
          position: toast.POSITION.TOP_RIGHT,
          theme: "colored",
        });
      } else {
        toast.error(
          "UPSS!!!, algo salio mal.. intentalo de nuevo, si el problema continua contacta a sistemas.",
          {
            position: toast.POSITION.TOP_RIGHT,
            theme: "colored",
          }
        );
        console.log(response.result.message);
      }
    } else {
      console.log("error en la peticion");
    }
  }
}
export default NewProduct;
