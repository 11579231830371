import React, { Component } from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import Modal from "react-modal";
import Fab from "@material-ui/core/Fab";
import Button from "@material-ui/core/Button";
//import validator from "./validatorPurchases";
import { Consumer } from "../../context/index";
import Request from "../../utils/http";
import EditIcon from "@material-ui/icons/Edit";
import { toast } from "react-toastify";
const request = new Request();
Modal.setAppElement(document.getElementById("root"));

const customStyles = {
  content: {
    position: "absolute",
    top: "20%",
    left: "25%",
    right: "25%",
    bottom: "35%",
    border: "1px solid rgb(204, 204, 204)",
    background: "rgb(255, 255, 255)",
    overflow: "auto",
    outline: "none",
    padding: "15px",
  },
};
class NewWarehouse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
      eddit: this.props.eddit,
      id_area: 0,
      descrip: "",
      activo: 1,
    };
  }
  closeModal() {
    this.openModal();
  }
  reload() {
    this.props.reload();
  }
  componentDidMount() {
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }
    this.setState({ token: token });
    this.getUser();
  }
  async getWarehouse() {
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }
    let data = {
      token: token,
      id: this.props.idW,
    };

    const response = await request.post(
      "/auth/inventory/getWarehouseInfo",
      data
    );
    console.log(response);
    if (response && !response.error) {
      if (response.result && !response.empty) {
        this.setState({
          id_area: response.result[0].id_area,
          descrip: response.result[0].descrip,
          activo: response.result[0].activo,
        });
      } else {
        this.setState({
          empty: true,
          loading: false,
          message: response.message,
        });
      }
    } else {
      this.setState({
        error: true,
        loading: false,
        message: response.message,
      });
    }
  }
  async getUser() {
    const user = await this.props.context.loadUser();

    if (user) {
      this.setState({
        idUsuario: user.id,
        idPerfil: user.id_perfil,
        nombre: user.name,
      });
    }
  }
  openModal() {
    this.setState({
      openModal: !this.state.openModal,
    });
    if (this.props.eddit === true) {
      this.getWarehouse();
    }
  }
  handleChange(e) {
    let valores = {
      id_area: this.state.id_area,
      descrip: this.state.descrip,
      activo: this.state.activo,
    };
    // let active = docu;
    let name = e.target.name;
    if (e.target.type === "checkbox") {
      valores[name] = Number(e.target.checked);
    } else if (e.target.type === "text" || e.target.type === "textarea") {
      valores[name] = e.target.value.trim();
    } else {
      valores[name] = Number(e.target.value);
    }
    //document.getElementById(name).classList.remove("is-invalid");
    this.setState({
      id_area: valores.id_area,
      descrip: valores.descrip,
      activo: valores.activo,
    });
    console.log(valores);
  }
  async handleSubmit() {
    let data = {
      token: this.state.token,
      id_area: this.state.id_area,
      descrip: this.state.descrip,
      activo: this.state.activo,
    };

    const response = await request.post("/auth/inventory/saveWarehouse", data);
    console.log(response);
    if (response && !response.error) {
      if (response.result && !response.empty) {
        this.setState({ toast: true, id_area: response.result[0].id_area });
        this.props.reload();
        toast.success("Datos Guardados con exito!");
        this.openModal();
      } else {
        this.setState({ toast: true });
        toast.error(
          "UPSS!!!, algo salio mal.. intentalo de nuevo, si el problema continua contacta a sistemas.",
          {
            position: toast.POSITION.TOP_RIGHT,
            theme: "colored",
          }
        );
        console.log(response.result);
      }
    } else {
      console.log("error en la peticion");
    }
  }

  render() {
    return (
      <>
        {this.props.eddit === false ? (
          <Tooltip title={"Agregar Almacén"}>
            <Fab
              color="primary"
              size="small"
              aria-label="add"
              className="MuiButtonBase-root MuiIconButton-root MUIDataTableToolbar-icon-49"
              style={{ padding: "0px" }}
              onClick={this.openModal.bind(this)}
            >
              <AddIcon className="MuiSvgIcon-root" />
            </Fab>
          </Tooltip>
        ) : (
          <div>
            <button
              className="btn btn-sm btn-info bg-gradient-info"
              title="Editar"
              onClick={this.openModal.bind(this)}
            >
              <EditIcon />
            </button>
          </div>
        )}
        <Modal
          isOpen={this.state.openModal}
          style={customStyles}
          contentLabel="EditarRequisicion"
        >
          <div className="modal-header">
            <h4 className="modal-title">
              {this.props.idW !== 0
                ? "Editar Almacén " + this.props.idW
                : "Nuevo Almacén"}
            </h4>
            <button
              type="button"
              className="close"
              onClick={this.openModal.bind(this)}
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div
            className="modal-body card-primary card-outline card-outline-tabs"
            style={{ zoom: "95%", padding: "0rem" }}
          >
            <div className="card-body">
              <div className="row">
                <div className="col-md-3">
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text text-sm">ID:</span>
                    </div>
                    <input
                      type="text"
                      name="id_area"
                      id="id"
                      className="form-control"
                      aria-label=""
                      value={this.state.id_area}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text text-sm">ALMACÉN:</span>
                    </div>
                    <input
                      type="text"
                      id="almacen"
                      name="descrip"
                      className="form-control"
                      aria-label=""
                      defaultValue={this.state.descrip}
                      onChange={this.handleChange.bind(this)}
                    ></input>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-3">
                  <div
                    className="form-control custom-control custom-switch custom-switch-off-danger custom-switch-on-success"
                    style={{ textAlign: "center" }}
                  >
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="activo"
                      defaultChecked={this.state.activo}
                      name="activo"
                      onChange={this.handleChange.bind(this)}
                      style={{ width: "100%", zIndex: 99 }}
                    />
                    <label className="custom-control-label" htmlFor="activo">
                      {this.state.activo == 1 ? "Activo" : "Inactivo"}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer justify-content-left ">
            <button
              type="button"
              className="btn btn-outline-success btn-flat "
              onClick={this.handleSubmit.bind(this)}
            >
              <i className="far fa-save"></i>&nbsp; Guardar
            </button>
            <button
              type="button"
              className="btn btn-outline-danger btn-flat"
              onClick={this.openModal.bind(this)}
            >
              <i className="fas fa-ban"></i> Cerrar
            </button>
          </div>
        </Modal>
      </>
    );
  }
}
export default Consumer(NewWarehouse);
