import React from "react";
import Layout from "./layout";
import Sidebar from "../components/header/sidebar";
import Product from "../components/purchases/product";
import FilterProduct from "../components/purchases/filterProvider";

const Product_page = () => (
  <Layout
    title="Productos y servicios"
    description="Página privada de perfil"
    keywords="Reactjs, SEO Ready, Ejemplo"
  >
    <Sidebar />
    <div className="content-wrapper">
      <div className="card">
        <div className="card-body">
          <Product />
        </div>
      </div>
    </div>
  </Layout>
);

export default Product_page;
