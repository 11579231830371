import React, {useEffect, useState} from "react";
import Request from "../../utils/http";
import MUIDataTable from "mui-datatables";
import ButtonSave from "../general/buttonSave";
const request = new Request();


export default function NewFormCategoria(props){

    const [categorias, setCategorias]=useState({
        name_categoria:''
    })
    //nuestro token para todos
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token = token1.id;


    let validarUbicacion=data=>{
      let error = 1;
        if (data.name_categoria === null || data.name_categoria.length === 0 || data.name_categoria === "") {
            document.getElementById("name_categoria").classList.add("is-invalid");
            return error;
        }
        return data;
    }
     
    

    const save = async () => {
        let datos=validarUbicacion(categorias);
        if(datos===1){
          alert("favor de llenar los campos");
        }else{
          let data = {
            token: token,
            datos: datos
          }
  
          const response = await request.post("/almacen/saveCategoriaAlmacen", data);
          if (response && !response.error) {
              if (response.result.ok && !response.empty) {
                alert("Categoria guardado");
                props.recargar();
                //nos permite eliminar o vaciar formulario modal
                setCategorias({
                    categoria:"",
                });
                document.getElementById("formulario-categoria").reset();
                //fin
              } else {
                console.log(response.result.message);
              }
            } else {
              console.log("error en la peticion");
          }
        }
    }

    return(
        <div className="tab-content">
            <form id="formulario-categoria">
            <div className="row mt-2">
                <div className="col-md-6">
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Categoria:</span>
                        </div>
                        <input type="text"  
                            className="form-control text-uppercase" 
                            id="name_categoria" 
                            name="name_categoria" 
                            value={categorias.name_categoria} 
                            onChange={e => setCategorias({...categorias,name_categoria:e.target.value.toUpperCase()})} 
                         />
                    </div>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-md-12">
                    <ButtonSave  
                    save={save}
                    closeModal={props.closeModal}
                    />
                </div> 
            </div>
            </form>
        </div>
    );
}