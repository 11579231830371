import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import Request from "../../utils/http";
import { toast } from "react-toastify";
import validator from "./validator";
import moment from "moment-timezone";
import ButtonSave from "../inventory/tabs/buttonSave";
import { Consumer } from "../../context/index";
import Modal from "react-modal";
import "react-datepicker/dist/react-datepicker.css";
const request = new Request();

function OdsStep1(props) {
  const customStyles = {
    content: {
      top: "30%",
      left: "50%",
      right: "30%",
      bottom: "20%",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };
  let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
  let token;
  if (token1) {
    token = token1.id;
  }
  let data = {
    eddit: props.eddit,
    token: token,
    id_event: props.idEvents,
  };

  // hooks de estado
  const [saveCatLoad, setSaveCatLoad] = useState(false);
  const [saveHotLoad, setSaveHotLoad] = useState(false);
  const [saveAgeLoad, setSaveAgeLoad] = useState(false);
  const [block, setBlock] = useState(false);
  const [ods, setData] = useState({
    modificado: 0,
    nombreEv: "",
    VendedorId: 0,
    ConsiergeId: 0,
    status: 1,
    cfecha: 0,
    fInicio: moment().format("YYYY-MM-DD HH:mm"),
    //Hinicio: "",
    fMontaje: "",
    //hMontaje: "",
    fTermino: moment().format("YYYY-MM-DD HH:mm"),
    //hTermino: "",
    fDesmontaje: "",
    //hDesmontaje: "",
    idioma: "Español MX",
    tEvento: 0,
    subEv: 1,
    pax: 1,
    teme: { label: "Sin Tema", value: 17 },
    diseno: 1,
    sistemas: 0,
    temaEsp: "",
    comentarios: "",
    hotel: 0,
    salon: "",
    backup: "No",
    planner: "",
    agencia: { label: "No Aplica", value: 0 },
    contAge: "",
    finalContact: "",
    comentOperations: "",

    // datos de facturacion
    idCliente: "",//{ label: "", value: 0 },
    // cfdi: { label: "P01\tPor definir", value: 22 },
    fpago: 1,// { label: "Cuenta\tMaestra", value: 1 },
    //mpago: { label: "PUE\tPago en una sola exhibición", value: 1 },
    moneda: 1,
    iva: 0,
    comision: 0,
    desc: 0,
    tdesc: 0,
  });
  const [sellers, setSellers] = useState([]);
  const [user, setUser] = useState({});
  const [status, setStatus] = useState([]);
  const [types, setTypes] = useState([]);
  const [temas, setTemas] = useState([]);
  const [hoteles, setHotel] = useState([]);
  const [agency, setAgency] = useState([]);

  const [errorTipo, setErrorTipo] = useState(false);
  const [errorTema, setErrorTema] = useState(false);
  const [errorHotel, setErrorHotel] = useState(false);

  const [load, setLoad] = useState(false);
  const [showModal, setModal] = useState(false);
  const [modalHotel, setModalHotel] = useState(false);
  const [modalAgency, setModalAgency] = useState(false);
  const [clientes, setClientes] = useState([]);
  const [cfdi, setCfdi] = useState([]);
  const [formas, setFormas] = useState([]);
  const [metodos, setMetodos] = useState([]);
  const [monedas, setMonedas] = useState([]);
  const [ready, setReady] = useState(false);
  const tEventRef = useRef(null);
  const temeRef = useRef(null);
  const hotelRef = useRef(null);
  const monedaRef = useRef(null);
  const agenciaRef = useRef(null);

  // terminan hooks de estado

  useEffect(() => {
    if (props.eddit === true || props.eddit === 1) {
      getData();
    } else {
      setReady(true);
      props.setIdioma(ods.idioma);
      props.numE(1);
      // props.asign(
      //   response.result[0].id_ubicacion_h,
      //   response.result[0].nombre_h
      // );
      // props.asignIdUser(response.result[0].id_usuario);
      // props.fechaI(response.result[0].Fecha_inicio);
      // props.fechaF(response.result[0].Fecha_fin);
    }
    getUser();

    getStatus();
    getSellers();
    getTypes();
    getHotels();
    getCategory();
    getMoneda();
    getForma();
    getFact();
    getCfdi();
    getMetodo();
    getAgency();
  }, []);

  const getData = async () => {
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }
    let data = {
      eddit: props.eddit,
      token: token,
      id_event: props.idEvents,
    };
    const response = await request.post("/auth/calendar/getEventHead", data);
    console.log(response);
    if (response && !response.error) {
      if (response.result && !response.empty) {
        props.numE(response.result[0].eventos);
        props.asign(
          response.result[0].id_ubicacion_h,
          response.result[0].nombre_h
        );
        props.asignIdUser(response.result[0].id_usuario);
        props.setIdioma(response.result[0].Idioma);

        let fechaI = response.result[0].Fecha_inicio;

        var nueva = fechaI.split(" ")[0];
        var format = nueva.split("-");
        var ultima = format[2] + "-" + format[1] + "-" + format[0];
        let horaIncio = `${fechaI.split(" ")[1]}`;

        // fecha fin
        let fechaF = response.result[0].Fecha_fin;
        var nueva2 = fechaF.split(" ")[0];
        var format2 = nueva2.split("-");
        var ultima2 = format2[2] + "-" + format2[1] + "-" + format2[0];
        let horaTermino = `${fechaF.split(" ")[1]}`;
        // fecha fMontaje
        if (response.result[0].Fecha_montaje !== null) {
          let fechaM = response.result[0].Fecha_montaje;
          var nueva3 = fechaM.split(" ")[0];
          var format3 = nueva3.split("-");
          var ultima3 = format3[2] + "-" + format3[1] + "-" + format3[0];

          var fm = response.result[0].Fecha_montaje;
          var horaMontaje = `${fechaM.split(" ")[1]}`;
        } else {
          ultima3 = "";
          fm = "";
          horaMontaje = "";
        }
        if (response.result[0].Fecha_desmontaje !== null) {
          let fechaD = response.result[0].Fecha_desmontaje;
          var nueva4 = fechaD.split(" ")[0];
          var format4 = nueva4.split("-");
          var ultima4 = format4[2] + "-" + format4[1] + "-" + format4[0];

          var fdm = response.result[0].Fecha_desmontaje;
          var horaDesmontaje = `${fechaD.split(" ")[1]}`;
        } else {
          ultima4 = "";
          fdm = "";
          horaDesmontaje = "";
        }
        props.fechaI(response.result[0].Fecha_inicio);
        props.fechaF(response.result[0].Fecha_fin);
        // fecha desmontaje

        setData({
          modificado: 0,
          nombreEv: response.result[0].nombre,
          VendedorId: response.result[0].id_usuario,
          status: response.result[0].id_status,
          cfecha: response.result[0].sinfecha,
          fInicio: ultima + " " + horaIncio,
          //Hinicio: horaIncio,
          fMontaje: ultima3 + " " + horaMontaje,
          //hMontaje: fm === "00-00-0000 00:00" ? "" : horaMontaje,
          fTermino: ultima2 + " " + horaTermino,
          //hTermino: horaTermino,
          fDesmontaje: ultima4 + " " + horaDesmontaje,
          //hDesmontaje: fdm === "00-00-0000 00:00" ? "" : horaDesmontaje,
          idioma: response.result[0].Idioma,
          tEvento: {
            label: response.result[0].tipo_esp,
            value: response.result[0].id_tipo,
          },
          subEv: response.result[0].eventos,
          pax: response.result[0].Paxes,
          teme: {
            label: response.result[0].Tema,
            value: response.result[0].id_tema,
          },
          diseno: response.result[0].Renders,
          sistemas: response.result[0].sistemas,
          temaEsp: response.result[0].T_especial,
          comentarios: response.result[0].Comentarios_int,
          hotel: {
            label: response.result[0].nombre_h,
            value: response.result[0].id_hotel,
          },
          salon: response.result[0].Salon,
          backup: response.result[0].Bakup,
          planner: response.result[0].Contacto_Hotel,
          agencia: {
            label: response.result[0].empresa,
            value: response.result[0].id_empresa,
          },
          contAge: response.result[0].cAgencia,
          finalContact: response.result[0].cFinal,
          comentOperations: response.result[0].cCli,

          // datos de facturacion
          idCliente: response.result[0].clienteId,/*{
            label: response.result[0].clienteNombre,
            value: response.result[0].id_cliente,
          },
          cfdi: {
            label: response.result[0].cfdiDescripcion,
            value: response.result[0].cfdi,
          },*/
          fpago:response.result[0].formaPago,/* {
            label: response.result[0].formaDescripcion,
            value: response.result[0].formaPago,
          },*/
          /*mpago: {
            label: response.result[0].metodoNombre,
            value: response.result[0].metodoPago,
          },*/
          moneda:
            /*{
            label: response.result[0].monedaDescripcion,
            value:*/ response.result[0].moneda,
          /*},*/
          iva: response.result[0].siniva,
          comision: response.result[0].comh,
          desc: response.result[0].desc,
          tdesc: response.result[0].tdesc,
          ConsiergeId: response.result[0].id_consierge,
        });
        setReady(true);
      } else {
        console.log(response.result[0]);
      }
    } else {
    }
    // getStatus();
  };

  const getUser = async () => {
    const user = await props.context.loadUser();

    if (user) {
      setUser(user);
      setData({ ...ods, VendedorId: user.id });
    }
  };

  const changeData = (e) => {
    console.log(e.target.name);
    if (e.target.type === "checkbox") {
      setData({ ...ods, [e.target.name]: Number(e.target.checked) });
    } else {
      setData({ ...ods, [e.target.name]: e.target.value });
    }

    document.getElementById(e.target.name).classList.remove("is-invalid");
  };
  const remove = (id) => {
    if (ods.tEvento === 0) {
      setErrorTipo(true);
    } else {
      setErrorTipo(false);
    }
    document.getElementById(id).classList.remove("is-invalid");
  };

  const getTypes = async () => {
    const response = await request.post("/auth/sales/getType", data);
    let typeslist = [];
    if (response && !response.error) {
      if (response.result && !response.empty) {
        response.result.types.map((t, key) => {
          typeslist.push({ value: t.id_tipo, label: t.tipo_esp });
        });
        setTypes(typeslist);
      }
    }
  };
  const getSellers = async () => {
    const response = await request.post("/auth/sales/getSellers", data);

    if (response && !response.error) {
      if (response.result && !response.empty) {
        setSellers(response.result.vendedores);
      }
    }
  };
  const getCategory = async () => {
    let tema = [];
    const response = await request.post("/auth/sales/getCategory", data);

    if (response && !response.error) {
      if (response.result.teme && !response.empty) {
        response.result.teme.map((type, key) => {
          tema.push({ value: type.id_tema, label: type.Tema });
        });
        setTemas(tema);
      }
    }
  };
  const getHotels = async () => {
    const response = await request.post("/auth/sales/getHotel", data);
    let hotellist = [];

    if (response && !response.error) {
      if (response.result && !response.empty) {
        response.result.hotels.map((h, key) => {
          hotellist.push({ value: h.id_hotel, label: h.nombre });
        });
        setHotel(hotellist);
      }
    }
  };
  const getStatus = async () => {
    const response = await request.post("/auth/sales/getStatus", data);

    if (response && !response.error) {
      if (response.result && !response.empty) {
        setStatus(response.result.type);
      }
    }
  };

  const getFact = async () => {
    const clientessp = [];
    let data = {
      token: token,
    };
    const response = await request.post("/auth/sales/getClientes", data);

    if (response && !response.error) {
      if (response.result.clients && !response.empty) {
        response.result.clients.map((cli, key) => {
          clientessp.push({ value: cli.id_cliente, label: cli.nombre });
        });
        setClientes(clientessp);
      } else {
        console.log(response.result.message);
      }
    } else {
      console.log(response.result);
    }
  };

  const getCfdi = async () => {
    const cfdi = [];
    let data = {
      token: token,
    };
    const response = await request.post("/auth/sales/getCfdi", data);

    if (response && !response.error) {
      if (response.result.cfdi && !response.empty) {
        response.result.cfdi.map((cfdis, key) => {
          cfdi.push({ value: cfdis.id_uso_CFDI, label: cfdis.descripcion });
        });
        setCfdi(cfdi);
      } else {
        console.log(response.result.message);
      }
    } else {
      console.log(response.result);
    }
  };

  const getForma = async () => {
    const forma = [];
    let data = {
      token: token,
    };
    const response = await request.post("/auth/sales/getFormaPago", data);

    if (response && !response.error) {
      if (response.result.pago && !response.empty) {
        response.result.pago.map((pagos, key) => {
          forma.push({
            value: pagos.id_forma_pago,
            label: pagos.descripcion,
          });
        });
        setFormas(forma);
      } else {
        console.log(response.result.message);
      }
    } else {
      console.log(response.result);
    }
  };
  const getMetodo = async () => {
    const metodo = [];
    let data = {
      token: token,
    };
    const response = await request.post("/auth/sales/getMetodoPago", data);
    console.log(
      "🚀 ~ file: odsStep1.jsx ~ line 439 ~ getMetodo ~ response",
      response
    );

    if (response && !response.error) {
      if (response.result.metodo && !response.empty) {
        response.result.metodo.map((metodos, key) => {
          metodo.push({
            value: metodos.id_metodo_pago,
            label: metodos.descripcion,
          });
        });
        setMetodos(metodo);
      } else {
        console.log(response.result.message);
      }
    } else {
      console.log(response.result);
    }
  };
  const getMoneda = async () => {
    const moneda = [];

    let data = {
      token: token,
    };
    const response = await request.post("/auth/sales/getMoneda", data);
    console.log(
      "🚀 ~ file: odsStep1.jsx ~ line 463 ~ getMoneda ~ response",
      response
    );

    if (response && !response.error) {
      if (response.result.moneda && !response.empty) {
        response.result.moneda.map((monedas, key) => {
          moneda.push({
            value: monedas.id_moneda,
            label: monedas.descripcion,
          });
        });
        setMonedas(moneda);
      } else {
        console.log(response.result.message);
      }
    } else {
      console.log(response.result);
    }
  };
  const getAgency = async () => {
    const response = await request.post("/auth/sales/getAgency", data);
    console.log(response);
    let agencylist = [];
    if (response && !response.error) {
      if (response.result && !response.empty) {
        response.result.agency.map((t, key) => {
          agencylist.push({ value: t.id_empresa, label: t.nombre });
        });
        setAgency(agencylist);
      }
    }
  };

  const saveCategory = async () => {
    setSaveCatLoad(true);
    let ingles = document.getElementById("nomIng").value;
    let esp = document.getElementById("nomEsp").value;
    if (!ingles || !esp) {
      toast.error(
        <span>
          !Todos los campos para agregar una categoría son obligatorios!.
        </span>
      );
      setSaveCatLoad(false);
      return;
    }

    let datos = {
      token: data.token,
      ingles: ingles,
      esp: esp,
    };
    const response = await request.post("/auth/sales/saveCategory", datos);

    if (response && !response.error) {
      if (response.result && !response.empty) {
        setTemas([...temas, { label: esp, value: response.result[0].new_id }]);
        setData({
          ...ods,
          teme: { label: esp, value: response.result[0].new_id },
        });
        toast.success(<span>Datos de la categoría, guardados con exito.</span>);
        setSaveCatLoad(false);
        setModal(false);
      }
    }
  };
  const saveHotel = async () => {
    setSaveHotLoad(true);
    let nombre = document.getElementById("nomHotel").value;
    let abrv = document.getElementById("abrv").value;
    let ubi = document.getElementById("ubHotel").value;
    if (!nombre || !abrv || !ubi) {
      toast.error(
        <span>!Todos los campos para agregar un hotel son obligatorios!.</span>
      );
      setSaveHotLoad(false);
      return;
    }

    let datos = {
      token: data.token,
      nombre: nombre,
      abrv: abrv,
      ubi: ubi,
    };
    // let lugar = await locationDB.getByID(ubi);

    const response = await request.post("/auth/sales/saveHotel", datos);

    if (response && !response.error) {
      if (response.result && !response.empty) {
        setHotel([
          ...hoteles,
          { label: nombre, value: response.result[0].new_id },
        ]);
        setData({
          ...ods,
          hotel: { label: nombre, value: response.result[0].new_id },
        });
        toast.success(<span>Datos del hotel, guardados con exito.</span>);
        setSaveHotLoad(false);
        setModalHotel(false);
      }
    }
  };

  const saveAgency = async () => {
    setSaveAgeLoad(true);
    let nombre = document.getElementById("nomAgency").value;
    if (!nombre) {
      toast.error(
        <span>
          !Todos los campos para agregar un intermediario son obligatorios!.
        </span>
      );
      setSaveAgeLoad(false);
      return;
    }

    let datos = {
      token: data.token,
      nombre: nombre,
    };
    // let lugar = await locationDB.getByID(ubi);
    console.log("datos :>> ", datos);
    const response = await request.post("/auth/sales/saveAgency", datos);

    if (response && !response.error) {
      if (response.result && !response.empty) {
        setAgency([
          ...agency,
          { label: nombre, value: response.result[0].new_id },
        ]);
        setData({
          ...ods,
          agencia: { label: nombre, value: response.result[0].new_id },
        });
        toast.success(
          <span>Datos del intermediario, guardados con exito.</span>
        );
        setSaveAgeLoad(false);
        setModalAgency(false);
      }
    }
  };

  let id;

  const handleSubmit = async (datos) => {
    setLoad(true);
    if (props.eddit === 0) {
      id = moment().format("YYYYMMDDHHmmss");
    } else {
      id = props.idEvents;
    }
    let fechaInicio = `${datos.fInicio}`; // ${datos.Hinicio}
    let fechaFin = `${datos.fTermino}`; // ${datos.hTermino}

    let fMontaje = `${datos.fMontaje}`; // ${datos.hMontaje}
    let fDesmontaje = `${datos.fDesmontaje}`; // ${datos.hDesmontaje}

    let data = {
      token: token,
      id: id,
      nombreEv: datos.nombreEv,
      VendedorId: datos.VendedorId,
      ConsiergeId: datos.ConsiergeId,
      fechaInicio: fechaInicio.replace("T", " "),
      fechaFin: fechaFin.replace("T", " "),
      fechaMontaje: fMontaje.replace("T", " "),
      fechaDesmontaje: fDesmontaje.replace("T", " "),
      cfecha: datos.cfecha,
      tEvento: datos.tEvento.value,
      teme: datos.teme.value,
      temaEsp: datos.temaEsp,
      idioma: datos.idioma,
      subEv: datos.subEv,
      pax: datos.pax,
      diseno: datos.diseno,
      comentarios: datos.comentarios,
      status: datos.status,
      modificado: datos.modificado,
      hotel: datos.hotel.value,
      salon: datos.salon,
      backup: datos.backup,
      planner: datos.planner,
      agencia: datos.agencia.value,
      contAge: datos.contAge,
      finalContact: datos.finalContact,
      comentOperations: datos.comentOperations,
      desc: datos.desc,
      iva: datos.iva,
      tdesc: datos.tdesc,
      sistemas: datos.sistemas,
      comision: datos.comision,
      idCliente: datos.idCliente,//.value
      cfdi: 22, // datos.cfdi.value,
      fpago: datos.fpago,//.value,
      mpago: 1, //datos.mpago.value,
      moneda: datos.moneda, //.value,
    };

    const response = await request.post("/auth/sales/saveOds", data);
    console.log(response);
    if (response && !response.error) {
      if (response.result[0].p_idEve && !response.empty) {
        toast.success(<span>Datos del encabezado, guardados con exito.</span>);
        setBlock(true);
        setLoad(false);

        // props.id(Number(response.result.id[0].p_idEve));
        props.cont(data.subEv);
        props.fechaI(data.fechaInicio);
        props.fechaF(data.fechaFin);
        props.asignIdUser(Number(data.VendedorId));
        props.setIdioma(datos.idioma);
        if (props.eddit !== 0) {
          props.fechaM(data.fechaMontaje);
          props.fechaD(data.fechaDesmontaje);
        }
        updateInfo(response.result[0].p_idEve);
      } else {
        setBlock(true);
        setLoad(false);

        toast.error(
          <span>
            UPSS!!!, algo salio mal.. intentalo de nuevo, si el problema
            continua contacta a sistemas.
          </span>
        );
      }
    }
  };

  const save = () => {
    let datos = validator(ods);

    if (ods.tEvento === 0) {
      if (datos !== 1) {
        tEventRef.current.focus();
      }
      document.getElementById("tEvento").style.border = "1px solid red";
      datos = 1;
    }
    if (ods.teme === 0) {
      if (datos !== 1) {
        temeRef.current.focus();
      }
      document.getElementById("teme").style.border = "1px solid red";
      datos = 1;
    }
    if (ods.hotel === 0) {
      if (datos !== 1) {
        hotelRef.current.focus();
      }
      document.getElementById("hotel").style.border = "1px solid red";
      datos = 1;
    }
    if (ods.moneda === 0) {
      if (datos !== 1) {
        monedaRef.current.focus();
      }
      document.getElementById("moneda").style.border = "1px solid red";
      datos = 1;
    }

    if (datos === 1) {
      toast.error(
        <span>!Todos los campos marcados en rojo son obligatorios!.</span>
      );
    } else {
      handleSubmit(ods);
      return;
    }
  };
  const updateInfo = async (id) => {
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }
    let data = {
      token: token,
      id_event: id,
    };
    const response = await request.post(
      "/auth/calendar/getEventCalendar",
      data
    );

    if (response && !response.error) {
      if (response.result && !response.empty) {
        props.eddit !== 0
          ? props.updateEvent(response.result[0])
          : props.changeEvent(response.result[0]);
        props.hotelAsignar(Number(response.result[0].id_ubicacion));
      }
    }
  };
  return (
    <>
      {ready ? (
        <>
          <div className="form-step1">
            <div className="text-center">
              <h6>DATOS DEL EVENTO</h6>
            </div>
            <div className="mb-3 border border-danger">
              <i className="fas fa-info-circle text-danger"></i>&nbsp;FORMATO
              DEL NOMBRE: Hotel(abr)-Agencia-Nombre grupo o boda-Tema
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="input-group mb-2">
                  <div className="input-group-prepend">
                    <span className="input-group-text">Nombre Evento</span>
                  </div>
                  <input
                    type="text"
                    name="nombreEv"
                    className="form-control"
                    id="nombreEv"
                    value={ods.nombreEv}
                    onChange={(e) => changeData(e)}
                    required=""
                    aria-required="true"
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="input-group mb-2">
                  <div className="input-group-prepend">
                    <span className="input-group-text">Vendedor</span>
                  </div>
                  <select
                    name="VendedorId"
                    id="VendedorId"
                    value={ods.VendedorId}
                    className="form-control"
                    onChange={(e) => changeData(e)}
                    disabled={user.id_perfil == 3 ? "disabled" : ""}
                  >
                    <option value={0}></option>
                    {sellers.map((vendedor, key) => (
                      <option value={vendedor.id_usuario} key={key}>
                        {vendedor.nombre}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="input-group mb-2">
                  <div className="input-group-prepend">
                    <span className="input-group-text">Status</span>
                  </div>
                  <select
                    className="form-control"
                    id="status"
                    name="status"
                    onChange={(e) => changeData(e)}
                    value={ods.status}
                  >
                    {props.idEvents !== 0 ? (
                      status.map((status, key) => (
                        <option key={key} value={status.id_status}>
                          {status.status}
                        </option>
                      ))
                    ) : (
                      <option key={0} value={1}>
                        Cotización
                      </option>
                    )}
                  </select>
                </div>
              </div>
              <div className="col-md-2">
                <div className="input-group mb-2">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <input
                        type="checkbox"
                        name="cfecha"
                        id="cfecha"
                        defaultChecked={ods.cfecha}
                        onChange={(e) => changeData(e)}
                      />
                    </span>
                  </div>
                  <label type="text" className="form-control text-sm">
                    Cotizacion sin fecha
                  </label>
                </div>
              </div>

              <div className="col-md-6">
                <div className="input-group mb-2">
                  <div className="input-group-prepend">
                    <span className="input-group-text">Concierge</span>
                  </div>
                  <select
                    name="ConsiergeId"
                    id="ConsiergeId"
                    value={ods.ConsiergeId}
                    className="form-control"
                    onChange={(e) => changeData(e)}
                  >
                    <option value={0}>No Aplica</option>
                    {sellers.map((vendedor, key) => (
                      <option value={vendedor.id_usuario} key={key}>
                        {vendedor.nombre}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            {/* Seccion de horarios */}
            <div className="row">
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-8">
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <span className="input-group-text">Fecha inicio</span>
                      </div>
                      <input
                        type="datetime-local"
                        className="form-control"
                        value={ods.fInicio}
                        id="fInicio"
                        name="fInicio"
                        // min={moment().format("YYYY-MM-DD")}
                        onChange={(e) => changeData(e)}
                      />
                    </div>
                  </div>
                  {/* <div className="col-md-4 ">
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <span className="input-group-text">Hora</span>
                        <input
                          type="time"
                          name="Hinicio"
                          className="form-control"
                          id="Hinicio"
                          autoComplete="off"
                          value={ods.Hinicio}
                          onChange={(e) => changeData(e)}
                        />
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-8">
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <span className="input-group-text">Fecha montaje</span>
                      </div>
                      <input
                        type="datetime-local"
                        name="fMontaje"
                        value={ods.fMontaje}
                        id="fMontaje"
                        onChange={(e) => changeData(e)}
                        className="form-control"
                      />
                    </div>
                  </div>
                  {/* <div className="col-md-4 ">
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <span className="input-group-text">Hora</span>
                        <input
                          type="time"
                          name="hMontaje"
                          className="form-control"
                          id="hMontaje"
                          autoComplete="off"
                          value={ods.hMontaje}
                          onChange={(e) => changeData(e)}
                        />
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-8">
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <span className="input-group-text">Fecha termino</span>
                      </div>
                      <input
                        type="datetime-local"
                        name="fTermino"
                        id="fTermino"
                        value={ods.fTermino}
                        min={moment().format("YYYY-MM-DD")}
                        onChange={(e) => changeData(e)}
                        className="form-control"
                      />
                    </div>
                  </div>
                  {/* <div className="col-md-4 ">
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <span className="input-group-text">Hora</span>
                        <input
                          type="time"
                          name="hTermino"
                          className="form-control"
                          id="hTermino"
                          autoComplete="off"
                          value={ods.hTermino}
                          onChange={(e) => changeData(e)}
                        />
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-8">
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          Fecha desmontaje
                        </span>
                      </div>
                      <input
                        type="datetime-local"
                        value={ods.fDesmontaje}
                        id="fDesmontaje"
                        name="fDesmontaje"
                        onChange={(e) => changeData(e)}
                        className="form-control"
                      />
                    </div>
                  </div>
                  {/* <div className="col-md-4 ">
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <span className="input-group-text">Hora</span>
                        <input
                          type="time"
                          name="hDesmontaje"
                          className="form-control"
                          id="hDesmontaje"
                          autoComplete="off"
                          value={ods.hDesmontaje}
                          onChange={(e) => changeData(e)}
                        />
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="input-group mb-2">
                  <div className="input-group-prepend">
                    <span className="input-group-text">Idioma:</span>
                  </div>
                  <select
                    name="idioma"
                    id="idioma"
                    value={ods.idioma}
                    className="form-control"
                    onChange={(e) => changeData(e)}
                  >
                    <option value="English US">English US</option>
                    <option value="Español MX">Español MX</option>
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="input-group mb-2">
                  <span className="input-group-text">Tipo de evento</span>
                  <div className="form-control border-0 pr-0 pl-0 pt-0 pb-0">
                    <Select
                      className="pl-0 pr-0 is-invalid"
                      ref={tEventRef}
                      options={types}
                      name="tEvento"
                      id="tEvento"
                      value={ods.tEvento}
                      onChange={
                        ((e) => remove("tEvento"),
                        (e) => setErrorTipo(false),
                        (value) => setData({ ...ods, tEvento: value }))
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-6 ">
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <span className="input-group-text">Sub eventos</span>
                      </div>
                      <input
                        type="number"
                        className="form-control"
                        name="subEv"
                        id="subEv"
                        value={ods.subEv}
                        onChange={(e) => changeData(e)}
                        min="1"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <span className="input-group-text">Pax</span>
                      </div>
                      <input
                        type="number"
                        className="form-control"
                        value={ods.pax}
                        name="pax"
                        id="pax"
                        onChange={(e) => changeData(e)}
                        min="1"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="input-group mb-2">
                  <div className="input-group-prepend">
                    <span className="input-group-text">Categoría</span>
                  </div>
                  <div className="form-control border-0 pr-0 pl-0 pt-0 pb-0">
                    <Select
                      ref={temeRef}
                      className="pl-0 pr-0"
                      id="teme"
                      options={temas}
                      value={ods.teme}
                      onChange={
                        ((e) => remove("teme"),
                        (value) => setData({ ...ods, teme: value }))
                      }
                    />
                  </div>
                  <div className="input-group-append pl-0">
                    <button
                      type="button"
                      className="btn btn-primary input-group-text "
                      onClick={(e) => setModal(!showModal)}
                    >
                      <i className="fas fa-plus"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-6">
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <input
                            type="checkbox"
                            name="diseno"
                            id="diseno"
                            defaultChecked={ods.diseno}
                            onChange={(e) => changeData(e)}
                          />
                        </span>
                      </div>
                      <label type="text" className="form-control text-sm">
                        Requiere Diseño / Branding
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <input
                            type="checkbox"
                            name="sistemas"
                            id="sistemas"
                            defaultChecked={ods.sistemas}
                            onChange={(e) => changeData(e)}
                          />
                        </span>
                      </div>
                      <label type="text" className="form-control text-sm">
                        Requiere área de sistemas
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="input-group mb-2">
                  <div className="input-group-prepend">
                    <span className="input-group-text">Tema especial:</span>
                  </div>

                  <input
                    type="text"
                    name="temaEsp"
                    className="form-control"
                    id="temaEsp"
                    value={ods.temaEsp}
                    onChange={(e) => changeData(e)}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="input-group mb-2">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      Comentarios para <br /> Operaciones:
                    </span>
                  </div>
                  <textarea
                    name="comentarios"
                    id="comentarios"
                    value={ods.comentarios}
                    onChange={(e) => changeData(e)}
                    className="form-control"
                  ></textarea>
                </div>
              </div>
            </div>
            <hr className="mt-4 mb-3" />
            <div className="text-center">
              <h6>DATOS DEL LUGAR</h6>
            </div>

            <div className="row">
              <div className="col-md-6 ">
                <div className="input-group mb-2">
                  <div className="input-group-prepend">
                    <span className="input-group-text">Hotel:</span>
                  </div>
                  <div className="form-control border-0 pr-0 pl-0 pt-0 pb-0">
                    <Select
                      className="pl-0 pr-0"
                      ref={hotelRef}
                      options={hoteles}
                      value={ods.hotel}
                      id="hotel"
                      onChange={
                        ((e) => remove("hotel"),
                        (value) => setData({ ...ods, hotel: value }))
                      }
                    />
                  </div>
                  <div className="input-group-append pl-0">
                    <button
                      type="button"
                      className="btn btn-primary input-group-text "
                      onClick={(e) => setModalHotel(!modalHotel)}
                    >
                      <i className="fas fa-plus"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="input-group mb-2">
                  <div className="input-group-prepend">
                    <span className="input-group-text">Salón/Locación:</span>
                  </div>

                  <input
                    type="text"
                    name="salon"
                    id="salon"
                    className="form-control"
                    value={ods.salon}
                    onChange={(e) => changeData(e)}
                    required=""
                    aria-required="true"
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="input-group mb-2">
                  <div className="input-group-prepend">
                    <span className="input-group-text">Backup:</span>
                  </div>

                  <input
                    type="text"
                    name="backup"
                    id="backup"
                    className="form-control"
                    value={ods.backup}
                    onChange={(e) => changeData(e)}
                  />
                </div>
              </div>
              <br />
              <div className="col-md-6">
                <div className="input-group mb-2">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      Contacto hotel/planner:
                    </span>
                  </div>

                  <input
                    type="text"
                    name="planner"
                    id="planner"
                    className="form-control"
                    onChange={(e) => changeData(e)}
                    value={ods.planner}
                  />
                </div>
              </div>
            </div>
            <hr className="mt-4 mb-3" />
            <div className="text-center">
              <h6>DATOS DEL INTERMEDIARIO</h6>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="input-group mb-2">
                  <div className="input-group-prepend">
                    <span className="input-group-text">Agencia/Empresa</span>
                  </div>
                  <div className="form-control border-0 pr-0 pl-0 pt-0 pb-0">
                    <Select
                      ref={agenciaRef}
                      className="pl-0 pr-0"
                      id="agencia"
                      options={agency}
                      value={ods.agencia}
                      onChange={
                        ((e) => remove("agencia"),
                        (value) => setData({ ...ods, agencia: value }))
                      }
                    />
                  </div>
                  <div className="input-group-append pl-0">
                    <button
                      type="button"
                      className="btn btn-primary input-group-text "
                      onClick={(e) => setModalAgency(!modalAgency)}
                    >
                      <i className="fas fa-plus"></i>
                    </button>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="input-group mb-2">
                  <div className="input-group-prepend">
                    <span className="input-group-text">Contacto:</span>
                  </div>

                  <input
                    type="text"
                    name="contAge"
                    id="contAge"
                    className="form-control"
                    value={ods.contAge}
                    onChange={(e) => changeData(e)}
                  />
                </div>
              </div>
            </div>
            <hr className="mt-4 mb-3" />
            <div className="text-center">
              <h6>CONTACTO</h6>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="input-group mb-2">
                  <div className="input-group-prepend">
                    <span className="input-group-text">Contacto Final:</span>
                  </div>
                  <input
                    type="text"
                    name="finalContact"
                    id="finalContact"
                    className="form-control"
                    value={ods.finalContact}
                    onChange={(e) => changeData(e)}
                  />
                </div>
              </div>

              <div className="col-md-12">
                <div className="input-group mb-2">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      Comentarios para <br /> Contacto Final:
                    </span>
                  </div>
                  <textarea
                    name="comentOperations"
                    id="comentOperations"
                    className="form-control"
                    value={ods.comentOperations}
                    onChange={(e) => changeData(e)}
                  ></textarea>
                </div>
              </div>
            </div>
            <hr className="mt-4 mb-3" />
            <div className="text-center">
              <h6>DATOS DE FACTURACIÓN</h6>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="input-group mb-2">
                  <div className="input-group-prepend">
                    <span className="input-group-text">Forma de pago:</span>
                  </div>
                  <div className="form-control border-0 pr-0 pl-0 pt-0 pb-0">
                  <select
                      ref={monedaRef}
                      className="form-control pl-0 pr-0"
                      id="fpago"
                      name="fpago"
                      value={ods.fpago}
                      onChange={
                        (e) =>
                          changeData(
                            e
                          ) /*(value) => setData({ ...ods, moneda: value })*/
                      }
                    >
                      {formas.map((datos, key) => (
                        <option key={key} value={datos.value}>
                          {datos.label}
                        </option>
                      ))}
                    </select>
                    {/*<Select
                      className="pl-0 pr-0"
                      options={formas}
                      value={ods.fpago}
                      onChange={(value) => setData({ ...ods, fpago: value })}
                    />*/}
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="input-group mb-2">
                  <div className="input-group-prepend">
                    <span className="input-group-text">Comisión Hotel:</span>
                  </div>
                  <input
                    type="number"
                    className="form-control"
                    step="5"
                    min="0"
                    max="40"
                    name="comision_hotel"
                    id="comision_hotel"
                    value={ods.comision}
                    onChange={(e) =>
                      setData({ ...ods, comision: e.target.value })
                    }
                  />
                  <div className="input-group-append">
                    <span className="input-group-text">%</span>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="input-group mb-2">
                  <div className="input-group-prepend">
                    <span className="input-group-text">Descuento:</span>
                  </div>
                  <input
                    type="number"
                    name="desc"
                    id="desc"
                    className="form-control valid"
                    aria-label="Small"
                    aria-describedby="inputGroup-sizing-sm"
                    value={ods.desc}
                    min="0"
                    onChange={(e) => setData({ ...ods, desc: e.target.value })}
                  />
                  <div className="input-group-append">
                    <span className="input-group-text">
                      %&nbsp;
                      <input
                        type="radio"
                        name="t_desc"
                        className="t_desc"
                        onClick={(e) => setData({ ...ods, tdesc: 0 })}
                        //checked={ods.tdesc === 0 ? true : false}
                        defaultChecked={ods.tdesc === 0 ? true : false}
                      />
                    </span>
                    <span className="input-group-text">
                      $&nbsp;
                      <input
                        type="radio"
                        name="t_desc"
                        className="t_desc"
                        onClick={(e) => setData({ ...ods, tdesc: 1 })}
                        defaultChecked={ods.tdesc === 0 ? false : true}
                      />
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="input-group mb-2">
                  <div className="input-group-prepend">
                    <span className="input-group-text">Cliente:</span>
                  </div>
                  <div className="form-control border-0 pr-0 pl-0 pt-0 pb-0">
                    {/*<Select
                      className="pl-0 pr-0"
                      options={clientes}
                      value={ods.idCliente}
                      onChange={(value) =>
                        setData({ ...ods, idCliente: value })
                      }
                    />*/}
                    <input
                    type="text"
                    name="idCliente"
                    id="idCliente"
                    className="form-control"
                    value={ods.idCliente}
                    onChange={(e) => changeData(e)}
                  />
                  </div>
                </div>
              </div>
              {/*<div className="col-md-6">
                <div className="input-group mb-2">
                  <div className="input-group-prepend">
                    <span className="input-group-text">Uso CDFi:</span>
                  </div>
                  <div className="form-control border-0 pr-0 pl-0 pt-0 pb-0">
                    <Select
                      className="pl-0 pr-0"
                      options={cfdi}
                      value={ods.cfdi}
                      onChange={(value) => setData({ ...ods, cfdi: value })}
                    />
                  </div>
                </div>
                    </div>*/}
              {/*<div className="col-md-6">
                <div className="input-group mb-2">
                  <div className="input-group-prepend">
                    <span className="input-group-text">Metodo de pago:</span>
                  </div>
                  <div className="form-control border-0 pr-0 pl-0 pt-0 pb-0">
                    <Select
                      className="pl-0 pr-0"
                      options={metodos}
                      value={ods.mpago}
                      onChange={(value) => setData({ ...ods, mpago: value })}
                    />
                  </div>
                </div>
                    </div>*/}

              <div className="col-md-3">
                <div className="input-group mb-2">
                  <div className="input-group-prepend">
                    <span className="input-group-text">Moneda:</span>
                  </div>
                  <div className="form-control border-0 pr-0 pl-0 pt-0 pb-0">
                    <select
                      ref={monedaRef}
                      className="form-control pl-0 pr-0"
                      id="moneda"
                      name="moneda"
                      value={ods.moneda}
                      onChange={
                        (e) =>
                          changeData(
                            e
                          ) /*(value) => setData({ ...ods, moneda: value })*/
                      }
                    >
                      {monedas.map((datos, key) => (
                        <option key={key} value={datos.value}>
                          {datos.label}
                        </option>
                      ))}
                    </select>
                    {/*<Select
                      className="pl-0 pr-0"
                      ref={monedaRef}
                      id="moneda"
                      options={monedas}
                      value={ods.moneda}
                      onChange={(value) => setData({ ...ods, moneda: value })}
                    />*/}
                  </div>
                </div>
              </div>

              <div className="col-md-3">
                <div className="input-group mb-2">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <input
                        type="checkbox"
                        onChange={(e) =>
                          setData({
                            ...ods,
                            iva: e.target.checked === true ? 1 : 0,
                          })
                        }
                        defaultChecked={ods.iva}
                      />
                    </span>
                  </div>
                  <label type="text" className="form-control">
                    Sin iva
                  </label>
                </div>
              </div>
            </div>
          </div>
          <ButtonSave
            closeModal={(e) => props.closeModal(e)}
            save={save}
            load={load}
            block={block}
          />
          {
            <Modal
              isOpen={modalHotel}
              contentLabel="Agregar Hotel"
              style={customStyles}
            >
              <div className="modal-header">
                <h6>Agregar Hotel</h6>
                <button
                  type="button"
                  className="close"
                  onClick={(e) => setModalHotel(!modalHotel)}
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="white-space-24"></div>
              <div className="modal-body">
                <div className="col-md-12 mb-3">
                  <div className="input-group mb-2">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        Nombre del hotel / lugar
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      id="nomHotel"
                      // value={odsProd.locacion}
                      name="locacion"
                      // onChange={e => changeData(e)}
                    />
                  </div>
                </div>
                <div className="col-md-12 mb-3">
                  <div className="input-group mb-2">
                    <div className="input-group-prepend">
                      <span className="input-group-text">Abreviar</span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      id="abrv"
                      // value={odsProd.locacion}
                      name="locacion"
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="input-group mb-2">
                    <div className="input-group-prepend">
                      <span className="input-group-text">Ubicacion</span>
                    </div>
                    <select
                      name=""
                      className="form-control"
                      id="ubHotel"
                      defaultValue="1"
                    >
                      <option value="1">
                        Cancún - Desde Cancún hasta Puerto Morelos
                      </option>
                      <option value="2">
                        Riviera Maya - Desde Playa del Carmen hasta Chetumal
                      </option>
                      <option value="4">Los Cabos - Baja California Sur</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="white-space-24"></div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-outline-success btn-flat "
                  onClick={(e) => saveHotel()}
                  disabled={saveHotLoad}
                >
                  {saveHotLoad ? (
                    <i className="fas fa-spinner fa-spin"></i>
                  ) : (
                    <i className="far fa-save"></i>
                  )}
                  &nbsp;Guardar
                </button>

                <button
                  type="button"
                  className="btn btn-outline-danger btn-flat"
                  onClick={(e) => setModalHotel(false)}
                >
                  Cancelar
                </button>
              </div>
            </Modal>
          }

          {
            <Modal
              isOpen={showModal}
              contentLabel="Agregar Categoría"
              style={customStyles}
            >
              <div className="modal-header">
                <h6>Agregar Categoría</h6>
                <button
                  type="button"
                  className="close"
                  onClick={(e) => setModal(!showModal)}
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="white-space-24"></div>
              <div className="modal-body">
                <div className="col-md-12 mb-3">
                  <div className="input-group mb-2">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        Nombre en español
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      id="nomEsp"
                      // value={odsProd.locacion}
                      name="locacion"
                      // onChange={e => changeData(e)}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="input-group mb-2">
                    <div className="input-group-prepend">
                      <span className="input-group-text">Nombre en inglés</span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      id="nomIng"
                      // value={odsProd.locacion}
                      name="locacion"
                      // onChange={e => changeData(e)}
                    />
                  </div>
                </div>
              </div>
              <div className="white-space-24"></div>
              <div className="modal-footer justify-content-left">
                <button
                  type="button"
                  className="btn btn-outline-success btn-flat "
                  onClick={() => saveCategory()}
                  disabled={saveCatLoad}
                >
                  {saveCatLoad ? (
                    <div className="overlay">
                      <i className="fas fa-spinner fa-spin"></i>
                    </div>
                  ) : (
                    <i className="far fa-save"></i>
                  )}
                  &nbsp;Guardar
                </button>

                <button
                  type="button"
                  className="btn btn-outline-danger btn-flat"
                  onClick={(e) => setModal(false)}
                >
                  Cancelar
                </button>
              </div>
            </Modal>
          }

          {
            <Modal
              isOpen={modalAgency}
              contentLabel="Agregar Categoría"
              style={customStyles}
            >
              <div className="modal-header">
                <h6>Agregar Agencia / Empresa</h6>
                <button
                  type="button"
                  className="close"
                  onClick={(e) => setModalAgency(!modalAgency)}
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="white-space-24"></div>
              <div className="modal-body">
                <div className="col-md-12 mb-3">
                  <div className="input-group mb-2">
                    <div className="input-group-prepend">
                      <span className="input-group-text">Nombre</span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      id="nomAgency"
                      // value={odsProd.locacion}
                      name="nomAgency"
                      // onChange={e => changeData(e)}
                    />
                  </div>
                </div>
              </div>
              <div className="white-space-24"></div>
              <div className="modal-footer justify-content-left">
                <button
                  type="button"
                  className="btn btn-outline-success btn-flat "
                  onClick={() => saveAgency()}
                  disabled={saveAgeLoad}
                >
                  {saveAgeLoad ? (
                    <div className="overlay">
                      <i className="fas fa-spinner fa-spin"></i>
                    </div>
                  ) : (
                    <i className="far fa-save"></i>
                  )}
                  &nbsp;Guardar
                </button>

                <button
                  type="button"
                  className="btn btn-outline-danger btn-flat"
                  onClick={(e) => setModalAgency(false)}
                >
                  Cancelar
                </button>
              </div>
            </Modal>
          }
        </>
      ) : (
        <div className="overlay text-center" style={{ color: "#007bff" }}>
          <i className="fas fa-2x fa-spinner fa-spin"></i>
          <br />
          Cargando...
        </div>
      )}
    </>
  );
}
export default Consumer(OdsStep1);
