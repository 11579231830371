import React from "react";
import Layout from "../layout";
import Sidebar from "../../components/header/sidebar";
import ListarProductos from "../../components/sistemas/listarProductos";

const Sistemas = () => (

    <Layout>
        <Sidebar />
        <div className="white-space-32"></div>
        <div className="container_fluid d-flex justify-content-center">
            <div className="card_empleado">
            <div className="card">
                <ListarProductos/>
            </div>
            </div>
        </div>
        <div className="white-space-32"></div>
    </Layout>

);

export default Sistemas;
