import React, { Component } from "react";
import { Consumer } from "../../context/index";
import Request from "../../utils/http";
import NewEntry from "./newEntry";
import Modal from "react-modal";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import moment from "moment";
//import DownloadExcel from "../../pages/inventory/downloadExcel";
//import DownloadPDF from "../../pages/inventory/downloadPDF";

import "./modal.css";
import "./tableArticles.css";
import { capitalize } from "@material-ui/core";

const request = new Request();
Modal.setAppElement(document.getElementById("root"));
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    //overflow: "scroll",
  },
};
class entrysOutput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      movimientos: [],
      user: {
        id: 0,
        id_perfil: 0,
        name: "",
      },
      artEddit: {
        id: 0,
        nom: "",
      },
      edditModal: false,
      load: true,
      areas: [],
      id: "%",
      fini: `${moment(new Date()).format("YYYY-MM-")}01`,
      ffin: `${moment(new Date()).format("YYYY-MM-")}${new Date(
        moment(new Date()).format("YYYY"),
        moment(new Date()).format("MM"),
        0
      ).getDate()}`,
      area: "%",
      tipo: "%",
      page: 0,
      rowsPerPage: 25,
    };
  }

  render() {
    return (
      <div className="content-wrapper" id="tableBodega">
        <div className="card" style={{ margin: "1rem" }}>
          <div className="card-header">
            <div
              className="card-title row card-title row row-cols-2 row-cols-md-4 row-cols-lg-6"
              style={{ width: "100%" }}
            >
              <div className="col text-center">
                <TextField
                  label="ID"
                  name="id"
                  type="number"
                  value={this.state.id}
                  onChange={(e) => this.handleChangeFilter(e)}
                />
              </div>
              <div className="col text-center">
                <TextField
                  label="Fecha inicial"
                  name="fini"
                  type="date"
                  value={this.state.fini}
                  onChange={(e) => this.handleChangeFilter(e)}
                />
              </div>
              <div className="col text-center">
                <TextField
                  label="Fecha final"
                  name="ffin"
                  type="date"
                  value={this.state.ffin}
                  onChange={(e) => this.handleChangeFilter(e)}
                />
              </div>
              <div className="col text-center">
                <FormControl>
                  <InputLabel htmlFor="area">Área</InputLabel>
                  <Select
                    value={this.state.area}
                    onChange={(e) => this.handleChangeFilter(e)}
                    input={<Input id="area" />}
                    name="area"
                  >
                    <MenuItem value={"%"}>Todos</MenuItem>
                    {this.state.areas.map((info, key) => (
                      <MenuItem key={key} value={info.descrip}>
                        {info.descrip}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="col text-center">
                <FormControl>
                  <InputLabel htmlFor="tipo">Tipo</InputLabel>
                  <Select
                    value={this.state.tipo}
                    onChange={(e) => this.handleChangeFilter(e)}
                    input={<Input id="tipo" />}
                    name="tipo"
                  >
                    <MenuItem value={"%"}>
                      <i className="fas fa-list"></i>&nbsp;Todos
                    </MenuItem>
                    <MenuItem value={"Entrada%"} className="text-success">
                      <i className="fas fa-arrow-circle-right"></i>
                      &nbsp;Entradas
                    </MenuItem>
                    <MenuItem value={"Salida%"} className="text-danger">
                      <i className="fas fa-arrow-circle-left"></i>&nbsp;Salidas
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="col text-right">
                <button
                  className="btn btn-primary btn-sm button-filter"
                  onClick={this.handleSearch.bind(this)}
                >
                  <i className="fas fa-search"></i>
                </button>
                &nbsp;
                <button
                  className="btn btn-secondary btn-sm button-filter"
                  onClick={this.handleClear.bind(this)}
                >
                  <i className="fas fa-times"></i>
                </button>
                &nbsp;
                <button
                  className="btn btn-info btn-sm bg-gradient-info button-filter"
                  onClick={(e) =>
                    this.openEddit({ id: 0, nom: "Nuevo Movimiento" })
                  }
                >
                  <i className="fas fa-plus"></i>
                </button>
                {/* &nbsp;
                {this.state.movimientos.length !== 0 ? (
                  <>
                    <DownloadExcel excelData={this.state.movimientos} />
                    &nbsp;
                    <DownloadPDF pdfData={this.state.movimientos} />
                  </>
                ) : null} */}
              </div>
            </div>
          </div>
          <div className="card-body">
            <TableContainer>
              <Table
                aria-labelledby="tableTitle"
                size="small"
                aria-label="enhanced table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="center">TIPO</TableCell>
                    <TableCell align="center">ID</TableCell>
                    <TableCell align="center">ÁREA</TableCell>
                    <TableCell align="center">ARTÍCULO</TableCell>
                    <TableCell align="center">CANTIDAD</TableCell>
                    <TableCell align="center">EVENTO</TableCell>
                    <TableCell align="center">FECHA EVENTO</TableCell>
                    <TableCell align="center">VENDEDOR</TableCell>
                    <TableCell align="center">FECHA</TableCell>
                    <TableCell align="center">HOTEL</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!this.state.load ? (
                    this.state.movimientos
                      .slice(
                        this.state.page * this.state.rowsPerPage,
                        this.state.page * this.state.rowsPerPage +
                          this.state.rowsPerPage
                      )
                      .map((row, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell align="left">
                              {row.id_tipo == 1 || row.id_tipo == 3 ? (
                                <span className="text-success">
                                  <i className="fas fa-arrow-circle-right"></i>
                                  &nbsp;Entrada
                                </span>
                              ) : (
                                <span className="text-danger">
                                  <i className="fas fa-arrow-circle-left"></i>
                                  &nbsp;Salida
                                </span>
                              )}
                            </TableCell>
                            <TableCell align="center">
                              {row.id_articulo}
                            </TableCell>
                            <TableCell align="center">{row.area}</TableCell>
                            <TableCell align="center">{row.nomE}</TableCell>
                            <TableCell align="center">{row.cantidad}</TableCell>
                            <TableCell align="center">{row.nombre}</TableCell>
                            <TableCell align="center">
                              {row.Fecha_inicio}
                            </TableCell>
                            <TableCell align="center">{row.vendedor}</TableCell>
                            <TableCell align="center">{row.fecha}</TableCell>
                            <TableCell align="center">{row.hotel}</TableCell>
                          </TableRow>
                        );
                      })
                  ) : (
                    <TableRow>
                      <TableCell align="center" colSpan={11}>
                        <i
                          className="fas fa-spinner fa-spin"
                          style={{ fontSize: "62px", color: "#3f51b5" }}
                        ></i>
                        <br /> Cargando Artículos...
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[25, 50, 100, 150, 200, 250]}
              component="div"
              count={this.state.movimientos.length}
              rowsPerPage={this.state.rowsPerPage}
              page={this.state.page}
              onChangePage={this.handleChangePage.bind(this)}
              onChangeRowsPerPage={this.handleChangeRowsPerPage.bind(this)}
              labelRowsPerPage="Artículos por página"
            />

            <NewEntry
              openModal={this.state.edditModal}
              page={this.state.page}
              setOpenModal={(e) => this.openEddit(e)}
              data={this.state.artEddit}
              newId={(e) => this.newId(e)}
              reload={() => this.reload()}
              handleSearch={() => this.handleSearch()}
            />
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    this.getAlmacen();
    this.handleSearch();
    this.load();
  }
  async load() {
    const user = await this.props.context.loadUser();

    if (user) {
      this.setState({
        user: user
      });
    }
  }
  async reload() {
    this.handleSearch();
  }
  openEddit(data) {
    this.setState({ artEddit: data, edditModal: !this.state.edditModal });
  }
  newId(data) {
    this.setState({ artEddit: data });
  }
  async getAlmacen() {
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }
    let data = {
      token: token,
    };

    const response = await request.post("/auth/inventory/getAlmacen", data);

    if (response && !response.error) {
      if (response.result && !response.empty) {
        this.setState({ areas: response.result.almacen });
      }
    }
  }
  async handleChangeFilter(e) {
    let name = e.target.name;
    switch (name) {
      case "id":
        if (e.target.value.length !== 0) {
          this.setState({ id: parseInt(e.target.value, 10) });
        } else {
          this.setState({ id: "" });
        }
        break;
      case "fini":
        if (e.target.value.length !== 0) {
          this.setState({ fini: e.target.value });
        } else {
          this.setState({ fini: `${moment(new Date()).format("YYYY-MM-")}01` });
        }
        break;
      case "ffin":
        if (e.target.value.length !== 0) {
          this.setState({ ffin: e.target.value });
        } else {
          this.setState({
            ffin: `${moment(new Date()).format("YYYY-MM-")}${new Date(
              moment(new Date()).format("YYYY"),
              moment(new Date()).format("MM"),
              0
            ).getDate()}`,
          });
        }
        break;
      case "area":
        this.setState({ area: e.target.value });
        break;
      case "tipo":
        this.setState({ tipo: e.target.value });
        break;
    }
  }
  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };
  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };
  async handleSearch() {
    this.setState({ load: true });
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }
    let data = {
      token: token,
      idPerfil: this.state.user.id_perfil,
      id: this.state.id,
      fini: this.state.fini,
      ffin: this.state.ffin,
      area: this.state.area,
      tipo: this.state.tipo,
    };

    const response = await request.post(
      "/auth/inventory/getInventoryMovements",
      data
    );
      console.log(response);
    if (response && !response.error) {
      if (response.result && !response.empty) {
        this.setState({ movimientos: response.result, load: false });
      } else {
        this.setState({
          empty: true,
          loading: false,
          message: response.message,
        });
      }
    } else {
      this.setState({
        error: true,
        loading: false,
        message: response.message,
      });
    }
  }
  handleClear() {
    this.setState({
      id: "%",
      area: "%",
      tipo: "%",
    });
  }
}

export default Consumer(entrysOutput);
