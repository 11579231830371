
let validator = data => {

    let fechaActual = new Date().toISOString().slice(0, 10);

    let error = 1;
    if (data.name === null || data.name.length === 0 || data.name === "") {
        document.getElementById("name").classList.add("is-invalid");
      return error;
    }
    
    if (data.last_name===null ||data.last_name.length === 0 || data.last_name === "") {
      document.getElementById("last_name").classList.add("is-invalid");
    return error;
    }
    if (data.sex ===null|| data.sex.length === 0 || data.sex === "") {
      document.getElementById("sex").classList.add("is-invalid");
    return error;
    }

    //fecha nacimiento
    if (data.date_birth === 'Invalid date' || data.date_birth===null || data.date_birth.length === 0 || data.date_birth === "") {
      document.getElementById("date_birth").classList.add("is-invalid");
    return error;
    }
    if (data.date_birth>=fechaActual) {
      document.getElementById("date_birth").classList.add("is-invalid");
      alert("Seleccionar Fecha Nacimiento Correcto");
    return error;
    }

    if (data.civil_status===null || data.civil_status.length === 0 || data.civil_status === "") {
      document.getElementById("civil_status").classList.add("is-invalid");
    return error;
    }
    if (data.blood_type===null  || data.blood_type === "") {
      document.getElementById("blood_type").classList.add("is-invalid");
    return error;
    }

    if (data.address===null || data.address.length === 0 || data.address === "") {
      document.getElementById("address").classList.add("is-invalid");
    return error;
    }

    if (data.curp === null || data.curp.length === 0 || data.curp === "") {
      document.getElementById("curp").classList.add("is-invalid");
    return error;
    }
  
    if (data.nss === null || data.nss.length === 0 || data.nss === "") {
      document.getElementById("nss").classList.add("is-invalid");
    return error;
    }

    if (data.rfc ===null || data.rfc.length === 0 || data.rfc === "") {
      document.getElementById("rfc").classList.add("is-invalid");
    return error;
    }

    if (data.email ===null || data.email.length === 0 || data.email === "") {
      document.getElementById("email").classList.add("is-invalid");
    return error;
    }

    if (data.cel_empresa ===null|| data.cel_empresa === "") {
      document.getElementById("cel_empresa").classList.add("is-invalid");
    return error;
    }

    if (data.cel_personal ===null|| data.cel_personal === "") {
      document.getElementById("cel_personal").classList.add("is-invalid");
    return error;
    }

    if (data.destination ===undefined ||data.destination==null) {
      document.getElementById("destination").classList.add("is-invalid");
    return error;
    }

    if (data.branch ===null|| data.branch.length === 0 || data.branch === "") {
      document.getElementById("branch").classList.add("is-invalid");
    return error;
    }

    if (data.areas ===null || data.areas.length === 0 || data.areas === "") {
      document.getElementById("areas").classList.add("is-invalid");
    return error;
    }

    if (data.puesto ===null || data.puesto.length === 0 || data.puesto === "") {
      document.getElementById("puesto").classList.add("is-invalid");
    return error;
    }

    if (data.contract_type ===null || data.contract_type.length === 0 || data.contract_type === "") {
      document.getElementById("contract_type").classList.add("is-invalid");
    return error;
    }

    if (data.estudio ===null || data.estudio.length === 0 || data.estudio === "") {
      document.getElementById("estudio").classList.add("is-invalid");
    return error;
    }

    if (data.salary_daily===null || data.salary_daily.length === 0 || data.salary_daily === "") {
      document.getElementById("salary_daily").classList.add("is-invalid");
    return error;
    }

    if (data.hours_ex ===null || data.hours_ex.length === 0 || data.hours_ex === "") {
      document.getElementById("hours_ex").classList.add("is-invalid");
    return error;
    }

    if (data.num_locker === null || data.num_locker.length === 0 || data.num_locker === "") {
      document.getElementById("num_locker").classList.add("is-invalid");
    return error;
    }

    if (data.pin_almacen ===null || data.pin_almacen.length === 0 || data.pin_almacen === "") {
      document.getElementById("pin_almacen").classList.add("is-invalid");
    return error;
    }


    if (data.date_hire==='Invalid date' || data.date_hire.length === 0 || data.date_hire === "") {
      document.getElementById("date_hire").classList.add("is-invalid");
    return error;
    }

    if (data.tipo_banco ===null || data.tipo_banco.length === 0 || data.tipo_banco === "") {
      document.getElementById("tipo_banco").classList.add("is-invalid");
    return error;
    }

    if (data.number_cuenta ===null || data.number_cuenta.length === 0 || data.number_cuenta === "") {
      document.getElementById("number_cuenta").classList.add("is-invalid");
    return error;
    }

    if (data.clabe_banco ===null || data.clabe_banco.length === 0 || data.clabe_banco === "") {
      document.getElementById("clabe_banco").classList.add("is-invalid");
    return error;
    }


    if (data.cont_name ===null || data.cont_name.length === 0 || data.cont_name === "") {
      document.getElementById("cont_name").classList.add("is-invalid");
    return error;
    }
     
    if (data.cont_last_name === null || data.cont_last_name.length === 0 || data.cont_last_name === "") {
      document.getElementById("cont_last_name").classList.add("is-invalid");
    return error;
    }

    if (data.cont_sex === null|| data.cont_sex.length === 0 || data.cont_sex === "") {
      document.getElementById("cont_sex").classList.add("is-invalid");
    return error;
    }

    if ( data.cont_parentes ===null|| data.cont_parentes.length === 0 || data.cont_parentes === "") {
      document.getElementById("cont_parentes").classList.add("is-invalid");
    return error;
    }

    if (data.cont_address ===null || data.cont_address.length === 0 || data.cont_address === "") {
      document.getElementById("cont_address").classList.add("is-invalid");
    return error;
    }

    if (data.cont_ocupation ===null || data.cont_ocupation.length === 0 || data.cont_ocupation === "") {
      document.getElementById("cont_ocupation").classList.add("is-invalid");
    return error;
    }

    if (data.cont_num_phone1 ===null|| data.cont_num_phone1.length === 0 || data.cont_num_phone1 === "") {
      document.getElementById("cont_num_phone1").classList.add("is-invalid");
    return error;
    }
    if (data.cont_num_phone2 ===null || data.cont_num_phone2.length === 0 || data.cont_num_phone2 === "") {
      document.getElementById("cont_num_phone2").classList.add("is-invalid");
    return error;
    }

    return data;
}

export default validator;