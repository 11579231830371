import React, { Component } from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import Modal from "react-modal";
import Fab from "@material-ui/core/Fab";
import EditIcon from "@material-ui/icons/Edit";

import Request from "../../utils/http";
import NewFormEmployee from "./forms/newEmployee";

const request = new Request();
Modal.setAppElement(document.getElementById("root"));

const customStyles = {
    content: {
      top: "50%",
      left: "80%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      overflow: "scroll"
     
    }
};


class NewEmployee extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            openModal: false
         }
    }
    closeModal() {
        this.openModal();
    }

    render() { 
        return ( 
            <>
                <Tooltip title={"Nueva Empleado"}>
                    <IconButton
                        className="MuiButtonBase-root MuiIconButton-root MUIDataTableToolbar-icon-49"
                        onClick={this.openModal.bind(this)}
                    >
                        <Fab color="primary" aria-label="add">
                        <AddIcon className="MuiSvgIcon-root" />
                        </Fab>
                    </IconButton>
                </Tooltip>
                {
                <Modal
                    isOpen={this.state.openModal}
                    style={customStyles.content}
                    contentLabel="EditarRequisicion"
                >
                <div className="modal-header">
                    <h2 className="ml-4">Nuevo Empleado</h2>  
                    <button
                        type="button"
                        className="close"
                        onClick={this.openModal.bind(this)}
                        aria-label="Close"
                    >
                    <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div className="modal-body card-primary card-outline card-outline-tabs">
                    <div className="card-body">
                        <NewFormEmployee 
                            recargar={this.props.recargar} 
                            closeModal={this.closeModal.bind(this)}
                        />
                    </div>
                </div>
                </Modal>
                }
            </>
        );
    }


    openModal() {
        this.setState({
          openModal: !this.state.openModal
        });
    }

}
 
export default NewEmployee;