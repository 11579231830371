import React, { Component } from "react";
import StockLocation from "./tabs/stockLocation";
import Modal from "react-modal";
import GeneralData from "./tabs/generalData";
import Purchases from "./tabs/purchases";
import Transfers from "./tabs/transfers";
import History from "./tabs/history";
import TempBlock from "./tabs/tempBlock";
import { Consumer } from "../../context/index";
import "./modal.css";

Modal.setAppElement(document.getElementById("root"));
const customStyles = {
  content: {
    position: "absolute",
    top: "10px",
    left: "50px",
    right: "50px",
    bottom: "10px",
    border: "1px solid rgb(204, 204, 204)",
    background: "rgb(255, 255, 255)",
    overflow: "auto",
    outline: "none",
    padding: "10px",
  },
};
class EdditModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
      user: {
        idUsuario: 0,
        idPerfil: 0,
        nombre: "",
      },
      totalStock: 0,
      load: 0,
    };
  }
  componentDidMount() {
    this.load();
  }
  async load() {
    const user = await this.props.context.loadUser();

    if (user) {
      this.setState({
        user: user,
      });
    }
  }

  closeModal() {
    this.props.setOpenModal({
      id: 0,
      nom: "",
    });
  }
  reload(data) {
    this.props.reload(data);
  }
  totalStock(x) {
    this.setState({ totalStock: x });
  }
  load() {
    let c = this.state.load + 1;
    this.setState({ load: c });
  }

  render() {
    return (
      <Modal
        isOpen={this.props.openModal}
        style={customStyles}
        contentLabel="EditarArticulo"
      >
        <div className="modal-header">
          <h6 className="modal-title">
            Editar Artículo
            <i>"{this.props.data.id + "-" + this.props.data.nom}"</i>
          </h6>
          <button
            type="button"
            className="close"
            onClick={this.closeModal.bind(this)}
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div className="modal-body card-primary card-outline card-outline-tabs">
          <div className="card-header border-bottom-0">
            <ul
              className="nav nav-tabs"
              id="custom-tabs-three-tab"
              role="tablist"
            >
              <li className="nav-item active">
                <a
                  className="nav-link active"
                  id="custom-tabs-three-home-tab"
                  data-toggle="pill"
                  href="#custom-tabs-three-home"
                  role="tab"
                  aria-controls="custom-tabs-three-home"
                  aria-selected="false"
                >
                  Datos Generales
                </a>
              </li>
              {this.props.data.id !== 0 ? (
                <>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="custom-tabs-three-profile-tab"
                      data-toggle="pill"
                      href="#custom-tabs-three-profile"
                      role="tab"
                      aria-controls="custom-tabs-three-profile"
                      aria-selected="false"
                    >
                      Stock por ubicacion
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="custom-tabs-three-messages-tab"
                      data-toggle="pill"
                      href="#custom-tabs-three-messages"
                      role="tab"
                      aria-controls="custom-tabs-three-messages"
                      aria-selected="false"
                    >
                      Costos / Compras
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="custom-tabs-three-settings-tab"
                      data-toggle="pill"
                      href="#custom-tabs-three-settings"
                      role="tab"
                      aria-controls="custom-tabs-three-settings"
                      aria-selected="true"
                    >
                      Tranferencias de articulo
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="custom-tabs-three-history-tab"
                      data-toggle="pill"
                      href="#custom-tabs-three-history"
                      role="tab"
                      aria-controls="custom-tabs-three-history"
                      aria-selected="true"
                    >
                      Historial de eventos
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="custom-tabs-three-block-tab"
                      data-toggle="pill"
                      href="#custom-tabs-three-block"
                      role="tab"
                      aria-controls="custom-tabs-three-block"
                      aria-selected="true"
                    >
                      Bloqueo temporal
                    </a>
                  </li>
                </>
              ) : null}
            </ul>
          </div>
          <div className="card-body">
            <div className="tab-content" id="custom-tabs-three-tabContent">
              <div
                className="tab-pane fade active show"
                id="custom-tabs-three-home"
                role="tabpanel"
                aria-labelledby="custom-tabs-three-home-tab"
              >
                <GeneralData
                  eddit={this.props.data.id !== 0 ? true : false}
                  id={this.props.data.id}
                  closeModal={this.closeModal.bind(this)}
                  reload={(e) => this.reload(e)}
                  saved={(e) => this.props.newId(e)}
                  totalStock={(e) => this.totalStock(e)}
                />
              </div>
              {this.props.data.id !== 0 ? (
                <>
                  <div
                    className="tab-pane fade"
                    id="custom-tabs-three-profile"
                    role="tabpanel"
                    aria-labelledby="custom-tabs-three-profile-tab"
                  >
                    <StockLocation
                      id={this.props.data.id}
                      closeModal={this.closeModal.bind(this)}
                      reload={(e) => this.reload(e)}
                      cant={this.state.totalStock}
                      fload={() => this.load()}
                      load={this.state.load}
                    />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="custom-tabs-three-messages"
                    role="tabpanel"
                    aria-labelledby="custom-tabs-three-messages-tab"
                  >
                    <Purchases
                      eddit={this.props.data.id !== 0 ? true : false}
                      id={this.props.data.id}
                      closeModal={this.closeModal.bind(this)}
                    />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="custom-tabs-three-settings"
                    role="tabpanel"
                    aria-labelledby="custom-tabs-three-settings-tab"
                  >
                    <Transfers
                      id={this.props.data.id}
                      closeModal={this.closeModal.bind(this)}
                      reload={(e) => this.reload(e)}
                      fload={() => this.load()}
                      load={this.state.load}
                    />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="custom-tabs-three-history"
                    role="tabpanel"
                    aria-labelledby="custom-tabs-three-history-tab"
                  >
                    <History id={this.props.data.id} />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="custom-tabs-three-block"
                    role="tabpanel"
                    aria-labelledby="custom-tabs-three-block-tab"
                  >
                    {<TempBlock id={this.props.data.id} />}
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default Consumer(EdditModal);
