import React from "react";
import Layout from "../layout";
import Sidebar from "../../components/header/sidebar";
// import Prestamos from "../../components/almacen/newFormPrestamos";

const PrestamosSistema = () => (

    <Layout>
        <Sidebar />
        <div className="white-space-32"></div>
        <div className="container_fluid d-flex justify-content-center">
            <div className="card_empleado">
            <div className="card">
                <h1>Prestamos de articulos</h1>
            </div>
            </div>
        </div>
        <div className="white-space-32"></div>
    </Layout>

);

export default PrestamosSistema;
