import React, { Component } from "react";
import Modal from "react-modal";
import EditIcon from "@material-ui/icons/Edit";

import FormTipoBanco from "./newTipoBanco";

Modal.setAppElement(document.getElementById("root"));

const customStyles = {
  content: {
    top: "50%",
    left: "52%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    overflow: "scroll"
  }
};

class buttonActions extends Component {

  constructor(props) {
    super(props);
    this.state = {
      openModal: false,     
    };
  }

  closeModal() {this.openModal();}
  reload() {this.props.reload();}


  render() {
    return (
      <div className="btn-group">
            <button
                type="button"
                className="btn btn-md btn-info bg-gradient-info mt-2"
                onClick={this.openModal.bind(this)}
            >
                <i className="fas fa-plus"></i>
            </button>
        
          <Modal
            isOpen={this.state.openModal}
            style={customStyles.content}
            contentLabel=""
          >
            <div className="modal-header">
                <h4 className="modal-title">Tipo de Banco</h4>
              <button
                type="button"
                className="close"
                onClick={this.openModal.bind(this)}
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body card-primary card-outline card-outline-tabs">
              <div className="card-body">
              <FormTipoBanco
                recargar={this.props.recargar}
              />
              </div>
            </div>
          </Modal>
        
      </div>
    );
  }


  openModal() {
    this.setState({
      openModal: !this.state.openModal
    });
  }

}
export default buttonActions;
