import React, { Component } from "react";
import Request from "../../utils/http";
import { toast } from "react-toastify";
const request = new Request();

class UploadPhoto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      labelInput: "Cambiar foto",
      formData: [],
      load: false,
    };
  }
  render() {
    return (
      <div className="form-group">
        {this.props.id != 0 ? (
          <form method="post" id="uploadForm" encType="multipart/form-data">
            <div className="input-group">
              <div className="custom-file">
                <input
                  type="file"
                  name="uploadFile"
                  className="custom-file-input"
                  id="uploadFile"
                  accept="image/jpg,image/jpeg,image/png"
                  onChange={this.handleChange.bind(this)}
                />
                <label className="custom-file-label" htmlFor="uploadFile">
                  {this.state.labelInput}
                </label>
              </div>
              <div className="input-group-append">
                <button
                  ref="uploadButton"
                  type="submit"
                  className="input-group-text btn-outline-success"
                  id="uploadPhoto"
                  title="Boton para cambiar la foto."
                  onClick={this.handleSubmit.bind(this)}
                >
                  {this.state.load ? (
                    <i className="fas fa-spinner fa-spin"></i>
                  ) : (
                    <i className="fas fa-file-upload"></i>
                  )}
                </button>
              </div>
            </div>
          </form>
        ) : (
          "Se esta agregando este tipo de trabajo, guarde los cambios antes de poder agregar una foto."
        )}
      </div>
    );
  }

  handleChange(e) {
    let x = e.target.value.length;
    if (x > 0) {
      let imagen = e.target.files[0].name;

      this.setState({
        labelInput: imagen,
      });
    } else {
      this.setState({
        labelInput: "Cambiar Foto",
      });
    }
  }

  async handleSubmit(e) {
    e.preventDefault();

    this.setState({ load: true });
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }
    let data = new FormData(document.getElementById("uploadForm"));

    data.append("id", this.props.id);
    data.append("imgAnt", this.props.imgAnt);
    let response = null;

    response = await request.upload("/auth/upload/saveWorkPhoto", data, token);

    if (response && !response.error) {
      if (response.result && !response.empty) {
        document.getElementById("uploadForm").reset();
        this.setState({ load: false, labelInput: "Cambiar Foto" });
        this.props.reload(response.result[0]);
        this.props.reloadImg();
        toast.success(
          <span>
            Archivo cargado con
            exito.
          </span>
        );
      } else {
        this.setState({ load: false });
        toast.error(
          <span>
            ¡No se pudo cargar el
            archivo!.
          </span>
        );
      }
    } else {
      this.setState({ load: false });
      toast.error(
        <span>
          ¡No se pudo cargar el
          archivo!.
        </span>
      );
    }
  }
}

export default UploadPhoto;
