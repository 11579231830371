/**
 *  @name: designPage.jsx
 *  @version: 1.0.0
 *  @author: Anahi Aranda
 *  @description: Página privada
 */

import React, { useState, useEffect } from "react";
import { Consumer } from "../context/index";
import Layout from "./layout";
import Sidebar from "../components/header/sidebar";
import Calendar from "../components/calendar/calendarEvents";
import Fab from "@material-ui/core/Fab";
import Chip from "@material-ui/core/Chip";
import Request from "../utils/http";
const request = new Request();

function Design(props) {
  const [modalM, showModalM] = useState(false);
  const [idOds] = useState(0);
  const [search, setSearch] = useState(false);
  const [user, setUser] = useState({
    idUsuario: 0,
    idPerfil: 0,
    nombre: "",
    idUbicacion: 0,
  });
  const [newCot, setNewCot] = useState(false);

  useEffect(() => {
    loadUser();
  }, []);

  const loadUser = async () => {
    const user = await props.context.loadUser();

    if (user) {
      setUser(user);
    }
  };

  return (
    <Layout
      title="Calendario de Diseño"
      description="Página privada de perfil"
      keywords="Reactjs, SEO Ready, Ejemplo"
    >
      <Sidebar />
      <Calendar id_modulo={7} user={user} />
    </Layout>
  );
}

export default Consumer(Design);
