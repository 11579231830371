import React, {useEffect, useState} from "react";
import Request from "../../../utils/http";
import MUIDataTable from "mui-datatables";
import DeleteIcon from '@material-ui/icons/Delete';
import ButtonSave from "../../inventory/tabs/buttonSave";
import validator from "./validatorVacation";

import moment from "moment";

const request = new Request();

const customStyles = {
    content: {
      width: "100px"
    }
};


export default function FormVacation(props){

    const [vacation, setVacation] = useState({
        id_employe:props.datos.id,
        fechaInicio:'',
        fechafin:'',
        estado:''
    });
    const [vacationsC, setVacationC] = useState([]);
    //nuestro token para todos
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
        token = token1.id;
    }
    useEffect(() => {
        getVacations();
    }, []);

    const getVacations = async () => {
        let data = {
            token: token,
            id_employee:props.datos.id
        };

        const response = await request.post("/employee/getVacations", data);
            console.log(response);
            
        if (response && !response.error) {
            if (response.result.vacaciones && !response.empty) {
                setVacationC(response.result.vacaciones);
            } else {
                console.log(response.result.message);
            }
        } else {
            console.log("error en la peticion");
        }
    }

    const save = async () => {

        let datos =validator(vacation); 

        if (datos === 1) {
            alert("favor de llenar los campos");
        } else {
            let data = {
                token: token,
                datos: datos
            };
    
        const response = await request.post("/employee/saveVacations", data);

            if (response && !response.error) {
                if (response.result.ok && !response.empty) {
                    alert("su vacación fue guardado correctamente");
                    getVacations();
                } else {
                  console.log(response.result.message);
                }
              } else {
                console.log("error en la peticion");
            }

        } 

    }

    const eliminar= async(datosId)=>{     

        let data = {
            id: datosId,
            token: token
        };
        const response = await request.post("/employee/deleteVacation", data);       

        if (response && !response.error) {
            let status = (response['result'].hasOwnProperty('status')) ? response['result']['status'] : 0; // 0 = Eliminado
            if (response.result.ok) {
                alert("Empleado Eliminado");
                refresTable(datosId);
                
            } else {
                console.log(response.result.message);
            }
        } else {
            console.log("error en la peticion");
        }

    }

    function refresTable(id_vacations) {
        let new_vacations = Object.assign([],vacationsC);
        if (new_vacations.length>0 || Object.keys(new_vacations).length>0){
            for(let i in new_vacations){
                let empleado = new_vacations[i];
                if (empleado.id == id_vacations){
                    new_vacations.splice(i, 1);
                    break;
                }
            }
            setVacationC([]);
            setVacationC(new_vacations);
            getVacations();
        }
    }



  const columns = [

    {
     name: "nombreEmpleado",
     label: "Nombre",
     options: {
      filter: true,
      sort: false,
     }
    },
    {
     name: "last_name",
     label: "Apellido",
     options: {
      filter: true,
      sort: false,
     }
    },
    {
     name: "date_i",
     label: "Fecha Inicio",
     options: {
      filter: true,
      sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {

            let fecha=moment(value).format("DD-MM-YYYY");                   
            if (fecha=="Invalid date") {
            return <p>{''}</p> 
            }else{
                return <p>{fecha}</p>
            }

        }
     }
    },
    {
     name: "date_f",
     label: "Fecha Fin",
     options: {
      filter: true,
      sort: false,
         customBodyRender: (value, tableMeta, updateValue) => {
            let fecha=moment(value).format("DD-MM-YYYY");                   
            if (fecha=="Invalid date") {
            return <p>{''}</p> 
            }else{
                return <p>{fecha}</p>
            }
        }
     }
    },
    {
        name: "state",
        label: "Aciones",
        options: {
            filter: true,
            sort: false,
            customBodyRender: (value, tableMeta, updateValue) => {
                // console.log(employee[tableMeta.rowIndex].id_vacations);
                return (
                    <div>
                        <div className="btn-group">
                            <button
                                className="btn btn-sm btn-danger"
                                title="Eliminar"
                                onClick={()=>{if(window.confirm('Esta seguro de Elimiar?')){eliminar(vacationsC[tableMeta.rowIndex].id_vacations)}}} >
                                <DeleteIcon />
                            </button>
                        </div>
                    </div>
                )
            }
        }
    }
    
   ];
   
   
   const options = {
    selectableRows: "none",
    filter: false,
    viewColumns: false,
    textLabels: {
      body: {
        noMatch: "No se encontraron resultados.",
        toolTip: "Sort"
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Número de filas:",
        displayRows: "de"
      },

      toolbar: {
        search: "Buscar",
        downloadCsv: "Descargar Excel",
        print: "Imprimir"
      },

      filter: {
        all: "TODOS",
        title: "FILTROS",
        reset: "LIMPIAR"
      },
      viewColumns: {
        title: "Mostar Columnas",
        titleAria: "Mostrar/Ocultar Columnas de la Tabla"
      },
      selectedRows: {
        text: "Fila(s) Seleccionadas",
        delete: "Eliminar",
        deleteAria: "Eliminar Filar Seleccionadas"
      }
    },
  };

    return(
        <div className="tab-content">
            <div className="row mt-2">
                <div className="col-md-6">
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Fecha Inicio</span>
                        </div>
                        <input type="date" className="form-control" id="fechaInicio" name="fechaInicio"                             
                            aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default"
                            value={vacation.fechaInicio} 
                            onChange={e => setVacation({...vacation,fechaInicio:e.target.value})} 
                            
                        />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Fecha Fin</span>
                        </div>
                        <input type="date" className="form-control" id="fechafin" name="fechafin"                             
                            aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default"
                            value={vacation.fechafin} 
                            onChange={e => setVacation({...vacation,fechafin:e.target.value})} 
                           
                        />
                    </div>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-md-4">
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Estado</span>
                        </div>
                        <select className="custom-select" id="estado" name="estado"
                            value={vacation.estado} 
                            onChange={e => setVacation({...vacation,estado:e.target.value})} 
                        >
                            <option></option>
                            <option value="1">Confrimar</option>
                        </select>
                    </div>
                </div> 
                <div className="col-md-4">
                    <ButtonSave 
                        save={save}
                        closeModal={props.closeModal}
                     />
                </div> 
            </div>

            <div className="row mt-2">
                <div className="col-md-12">
                    <MUIDataTable
                        data={vacationsC}
                        columns={columns}
                        options={options}
                    />  
                </div>
            </div>

        </div>
    );
}