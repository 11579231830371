import React from "react";
import Layout from "../layout";
import Sidebar from "../../components/header/sidebar";
import HorasUsers from "../../components/rh/horasExtras/horasUsers";


const HorasExtras = () => (
  <Layout>
    <Sidebar />
    <div className="white-space-32"></div>
    <div className="container_fluid d-flex justify-content-center">
        <div className="card_empleado">
          <div className="card">
            <HorasUsers/>
          </div>
        </div>
    </div>
    <div className="white-space-32"></div>
  </Layout>
);

export default HorasExtras;