import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Request from "../../utils/http";
import { Consumer } from "../../context/index";
const request = new Request();
function Attachment(props) {
  const [id_event] = useState(props.idEvent);
  const [files, setFiles] = useState([]);
  const [type, setType] = useState([]);
  const [load, setLoad] = useState(false);
  const [loadFiles, setLoadFiles] = useState(true);
  const [labelInput, setLabelInput] = useState("Adjuntar Archivo");
  const [toastActiv, setActive] = useState(false);
  const [user, setUser] = useState({
    idUsuario: 0,
    idPerfil: 0,
    nombre: "",
  });

  useEffect(() => {
    getType();
    loadUser();
    getFiles();
  }, []);

  let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
  let token;
  if (token1) {
    token = token1.id;
  }
  const getType = async () => {
    let data = {
      token: token,
    };

    const response = await request.post(
      "/auth/purchases/getTypeAttachment",
      data
    );

    if (response && !response.error) {
      if (response.result && !response.empty) {
        setType(response.result);
      } else {
        setType([]);
      }
    } else {
      console.log("getType :>> ", response);
      setType([]);
    }
  };
  const loadUser = async () => {
    const user = await props.context.loadUser();

    if (user) {
      setUser(user);
    }
  };
  const handleChange = (e) => {
    let x = e.target.value.length;
    if (x > 0) {
      let file = e.target.files[0].name;
      setLabelInput(file);
    } else {
      setLabelInput("Adjuntar Archivo");
    }
  };
  const getFiles = async () => {
    let data = {
      token: token,
      idReq: props.idReq,
    };

    const response = await request.post("/auth/purchases/getAttachment", data);
    console.log("response :>> ", response);
    if (response && !response.error) {
      if (response.result && !response.empty) {
        setFiles(response.result);
        setLoadFiles(false);
        // props.countAdj(response.result.length);
        // window.sessionStorage.setItem(
        //   "Productos",
        //   JSON.stringify(response.result)
        // );
      } else {
        setFiles([]);
        setLoadFiles(false);
      }
    } else {
      setLoadFiles(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoad(true);
    setLoadFiles(true);

    let data = new FormData(document.getElementById("uploadForm"));
    data.append("id", props.idReq);
    data.append("modulo", "purchases");

    console.log("data :>> ", data);
    const response = await request.upload(
      "/auth/upload/saveShopFiles",
      data,
      token
    );
    console.log(response);
    if (response && !response.error) {
      if (response.result && !response.empty) {
        getFiles();
        document.getElementById("uploadForm").reset();
        setLoad(false);
        setLabelInput("Adjuntar Archivo");
        //  getEventFiles();
        setActive(true);
        toast.success(
          <span>
            Archivo cargado con
            exito.
          </span>
        );
      } else {
        setLoad(false);
        setLoadFiles(false);
        setActive(true);
        toast.error(
          <span>
            ¡No se pudo cargar el
            archivo!.
          </span>
        );
      }
    } else {
      setLoad(false);
      setLoadFiles(false);
    }
  };
  const deleteFile = async (id) => {
    setLoadFiles(true);
    let data = {
      token: token,
      idFile: id,
    };

    const response = await request.post("/auth/upload/deleteShopFile", data);
   
    if (response && !response.error) {
      if (response.result && !response.empty) {
        getFiles();
        toast.success(
          <span>
            Archivo borrado con
            exito.
          </span>
        );
      } else {
        setLoadFiles(false);
        console.log("deleteFile->reponse", response);
        toast.error(
          <span>
            ¡No se pudo borrar el
            archivo!.
          </span>
        );
      }
    } else {
      setLoadFiles(false);
      console.log("deleteFile->reponse", response);
    }
  };
  const splitName = (ruta) => {
    let name = ruta.split(".");
    let i = name.length - 1;
    let ext = name[i].toLowerCase();
    let far = "fas fa-file";
    switch (ext) {
      case "pdf":
        far = "fas fa-file-pdf text-red";
        break;
      case "jpeg":
      case "jpg":
      case "png":
        far = "fas fa-file-image";
        break;
      case "xml":
        far = "fas fa-file-code text-yellow";
        break;
      case "doc":
      case "docx":
        far = "fas fa-file-word text-blue";
        break;
      case "xls":
      case "xlsx":
      case "ods":
        far = "fas fa-file-excel text-green";
        break;
      case "ppt":
      case "pptx":
        far = "fas fa-file-powerpoint text-orange";
        break;
      case "zip":
      case "rar":
        far = "fas fa-file-archive text-purple";
        break;
      default:
        far = "fas fa-file";
        break;
    }
    return far;
  };
  return (
    <div className="card">
      <div className="card-header">ARCHIVOS ADJUNTOS</div>
      <div className="card-body">
        {props.attach ? (
          <form method="post" id="uploadForm" encType="multipart/form-data">
            <div className="input-group">
              <div className="custom-file">
                <input
                  type="file"
                  name="uploadFile"
                  className="custom-file-input"
                  id="uploadFile"
                  onChange={(e) => handleChange(e)}
                />
                <label className="custom-file-label" htmlFor="uploadFile">
                  {labelInput}
                </label>
              </div>
            </div>
            <div className="white-space-16"></div>
            <div className="form-group">
              <label>Tipo Archivo</label>
              <select
                name="tipo"
                id="tipo"
                className="form-control"
                required={true}
              >
                <option></option>
                {type.length != 0
                  ? type.map((data, key) => (
                      <option key={key} value={data.id_filet}>
                        {data.descrip}
                      </option>
                    ))
                  : null}
              </select>
            </div>
            <div className="white-space-16"></div>
            <div className="form-group">
              <label>Indicaciones</label>
              <textarea
                className="form-control"
                name="indicacion"
                placeholder="Indicaciones sobre el archivo adjunto."
              ></textarea>
            </div>
            <div className="white-space-16"></div>
            {id_event !== 0 ? (
              <div className="card-footer">
                <button
                  type="submit"
                  onClick={(e) => handleSubmit(e)}
                  className="btn btn-outline-success btn-flat float-right"
                  title="Boton para subir archivo."
                  disabled={load}
                >
                  {load ? (
                    <i className="fas fa-spinner fa-spin"></i>
                  ) : (
                    <i className="fas fa-file-upload"></i>
                  )}
                  &nbsp; Subir
                </button>
              </div>
            ) : (
              "No se tiene id de evento, no se pueden adjuntar archivos."
            )}
            <div className="white-space-32"></div>
          </form>
        ) : null}
        <div className="row d-none d-md-flex data">
          <div className="col-12 col-md-2">ACCIONES</div>
          <div className="col-12 col-md-4">NOMBRE</div>
          <div className="col-12 col-md-2">TIPO</div>
          <div className="col-12 col-md-4">COMENTARIOS</div>
        </div>
        {!loadFiles ? (
          files.map((info, key) => (
            <div className="row data" id={key}>
              <div className="col-12 col-md-2">
                <a
                  href={
                    info.img_char === "http"
                      ? info.ruta
                      : `${request.publicURL()}/compras/${info.ruta}`
                  }
                  target="_blank"
                  className="btn btn-sm btn-primary"
                >
                  <i className="fas fa-eye"></i>
                </a>
                &nbsp;
                {info.id_usuario == user.id ||
                user.id_perfil == 1 ||
                user.id_perfil == 2 ? (
                  <button
                    className="btn btn-sm btn-danger"
                    onClick={() => deleteFile(info.id_file)}
                  >
                    <i className="fas fa-trash-alt"></i>
                  </button>
                ) : null}
              </div>
              <div className="col-12 col-md-4">{info.nombre}</div>
              <div className="col-12 col-md-2">
                <i
                  className={splitName(info.ruta)}
                  style={{ fontSize: "1.3rem" }}
                ></i>
                &nbsp;{info.descrip}
              </div>
              <div className="col-12 col-md-5">{info.indicacion}</div>
            </div>
          ))
        ) : (
          <div className="text-center pt-2">
            <i
              className="fas fa-spinner fa-spin"
              style={{ fontSize: "62px", color: "#3f51b5" }}
            ></i>
            <br /> Buscando Datos...
          </div>
        )}
        {/* <i className="fas fa-spinner fa-spin"></i> */}
      </div>
    </div>
  );
}
export default Consumer(Attachment);
