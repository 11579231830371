import React, { Component } from "react";
import Request from "../../utils/http";
import { Consumer } from "../../context/index";
import MUIDataTable from "mui-datatables";
import Icon from "@material-ui/core/Icon";
import UserPermissions from "./userPermissions";
import UserEdit from "./userEdit";
const request = new Request();

class UsersTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      usuarios: [],
      user: [],
      columns: [
        {
          name: "nombre",
          label: "Nombre",
          options: {
            filter: true,
            sort: true
          }
        },
        {
          name: "usuario",
          label: "Usuario",
          options: {
            filter: true,
            sort: false
          }
        },

        {
          name: "accion",

          label: "Editar",
          options: {
            filter: true,
            sort: false,
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <div>
                  <UserEdit
                    datos={this.state.usuarios[tableMeta.rowIndex]}
                    reload={this.reload.bind(this)}
                  />
                </div>
              );
            }
          }
        },
        {
          name: "accion",

          label: "Permisos",
          options: {
            filter: true,
            sort: false,
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <div>
                  <UserPermissions
                    datos={this.state.usuarios[tableMeta.rowIndex]}
                    reload={this.reload.bind(this)}
                  />
                </div>
              );
            }
          }
        }
      ],
      options: {
        filterType: "checkbox",
        fixedHeader: false,
        selectableRows: "single",
        responsive: "standard",
        onRowsDelete: rowsDeleted => {
          const idsToDelete = rowsDeleted.data.map(
            d => this.state.usuarios[d.dataIndex].id_usuario
          );
          // console.log("idsToDelete: ", idsToDelete);

          // http.delete(idsToDelete, res).then(window.alert('Deleted!')); // your delete request here
        },
        rowsPerPage: 7,
        textLabels: {
          pagination: {
            next: "Next Page",
            previous: "Previous Page",
            rowsPerPage: "siguiente:",
            displayRows: "of"
          },
          toolbar: {
            search: "Buscar",
            downloadCsv: "Descargar CSV",
            print: "Imprimir",
            viewColumns: "View Columns",
            filterTable: "Filter Table"
          },
          selectedRows: {
            text: "fila(s) seleccionada",
            delete: "Eliminar",
            deleteAria: "Eliminar fila(s) seleccionadas"
          },
          viewColumns: {
            title: "Ver columnas",
            titleAria: "Mostrar/Ocultar Columnas"
          }
        }
      }
    };
  }

  reload() {
    this.getUsers();
  }
  render() {
    return (
      <div>
        <MUIDataTable
          title={"Lista de empleados"}
          data={this.state.usuarios}
          columns={this.state.columns}
          options={this.state.options}
        />
      </div>
    );
  }

  componentDidMount = () => {
    this.load();
  };
  async load() {
    const user = await this.props.context.loadUser();

    if (user) {
      this.setState({
        user: {
          idUsuario: user.id,
          idPerfil: user.id_perfil
        }
      });
    }
    this.getUsers();
  }

  async getUsers() {
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }
    let data = {
      token: token
    };
    const response = await request.post("/users/getUsers", data);

    if (response && !response.error) {
      if (response.result && !response.empty) {
        this.setState({ usuarios: response.result });
      } else {
        this.setState({
          empty: true,
          loading: false,
          message: response.message
        });
      }
    } else {
      this.setState({
        error: true,
        loading: false,
        message: response.message
      });
    }
  }
}

export default Consumer(UsersTable);
