import React, { useState, useEffect } from "react";
import { Consumer } from "../../context";
import Request from "../../utils/http";
import {
  Page,
  Text,
  View,
  Image,
  Document,
  StyleSheet,
  Font,
  Link,
  PDFDownloadLink,
} from "@react-pdf/renderer";
const request = new Request();
function OdsPdf(props) {
  Font.register({
    family: "Roboto",
    fonts: [
      { src: "./fonts/Roboto-Regular.ttf" }, // font-style: normal, font-weight: normal
      { src: "./fonts/Roboto-Bold.ttf", fontWeight: "bold" },
      {
        src: "./fonts/Roboto-Italic.ttf",
        fontStyle: "italic",
        fontWeight: "normal",
      },
    ],
  });
  // Create styles
  const styles = StyleSheet.create({
    page: {
      fontSize: !props.ods ? 10 : 9.5,
      padding: "1cm 1.7cm 1.2cm",
      fontFamily: "Roboto",
    },
    section: {
      borderBottom: "1pt dashed gray",
      textAlign: "justify",
      margin: 1,
    },
    sectionTh: {
      fontSize: !props.ods ? 10 : 8,
      border: 1,
      textAlign: "center",
      marginTop: 1,
      paddingTop: 1,
    },
    headerTr: {
      textAlign: "center",
      padding: 1,
      width: "100%",
    },
    headerTh: {
      border: 1,
      textAlign: "center",
      fontSize: 9,
      padding: 1,
    },
    headerTd: {
      borderBottom: "1pt dashed gray",
      //textAlign: "center",
      padding: 1,
    },
    header: {
      fontSize: 11,
      marginBottom: 10,
      textAlign: "center",
      color: "#019ad2",
    },
    pageNumber: {
      position: "absolute",
      fontSize: 9,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: "center",
      color: "grey",
    },
  });
  const [titles, setTitles] = useState([]);
  const [eventHeader, setEventHeader] = useState({
    id_ubicacion_h: null,
    Idioma: "Español MX",
  });
  const [artInfo, setArtInfo] = useState([]);
  const [eventTotal, setEventTotal] = useState([]);
  const [eventTerm, setEventTerm] = useState([]);
  const [loadHeader, setLoadHeader] = useState(false);
  const [loadArt, setLoadArt] = useState(false);
  const [loadTotal, setLoadTotal] = useState(false);
  const [loadTerm, setLoadTerm] = useState(false);
  const getEventHeader = async () => {
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }
    let data = {
      token: token,
      id_event: props.idEvent,
    };

    const response = await request.post("/auth/calendar/getEventHead", data);
    console.log(
      "🚀 ~ file: odspdf.jsx ~ line 110 ~ getEventHeader ~ response",
      response
    ); /**/

    if (response && !response.error) {
      if (response.result && !response.empty) {
        setEventHeader(response.result[0]);
        setLoadHeader(true);

        if (response.result[0].id_ubicacion_h === null) {
          response.result[0].id_ubicacion_h = 1;
        }

        if (response.result[0].eventos >= 1) {
          getEventArt(
            response.result[0].eventos,
            response.result[0].id_ubicacion_h,
            {
              siniva: response.result[0].siniva,
              tdesc: response.result[0].tdesc,
              desc: response.result[0].desc,
              comh: response.result[0].comh,
              subs: response.result[0].eventos,
            }
          );
        }
        // window.sessionStorage.setItem(
        //   "Productos",
        //   JSON.stringify(response.result)
        // );
      } else {
        setLoadHeader(false);
        //   this.setState({
        //     empty: true,
        //     loading: false,
        //     message: response.message
        //   });
      }
    } else {
      setLoadHeader(false);
      // this.setState({
      //   error: true,
      //   loading: false,
      //   message: response.message
      // });
    }
  };
  const getEventArt = async (sub, ubi, total) => {
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }

    let data = {
      token: token,
      id_modulo: 2,
      id_event: props.idEvent,
      id_ubicacion: ubi,
      events: sub,
    };

    const response = await request.post("/auth/calendar/getEventArt_Ods", data);
    console.log(
      "🚀 ~ file: odspdf.jsx ~ line 169 ~ getEventArt ~ response",
      response
    );

    if (response && !response.error) {
      if (response.result && !response.empty) {
        if (response.result[0].length > 0) {
          setArtInfo(response.result);
        }
        if (!props.ods) getEventTotal(total);
        setLoadArt(true);
      } else {
        setLoadArt(false);
      }
    } else {
      setLoadArt(false);
    }
  };
  const getEventTotal = async (param) => {
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }

    let data = {
      token: token,
      id_event: props.idEvent, //Number(props.idEvent),
      siva: parseInt(param.siniva),
      tdesc: parseInt(param.tdesc),
      desc: parseInt(param.desc),
      comh: parseInt(param.comh),
    };

    const response = await request.post("/auth/calendar/getEventTotal", data);
    console.log(
      "🚀 ~ file: odspdf.jsx ~ line 203 ~ getEventTotal ~ response.result",
      data,
      response.result
    );

    if (response && !response.error) {
      if (response.result && !response.empty) {
        setEventTotal(response.result[0]);
        setLoadTotal(true);
        getEventTerm();
      } else {
        setLoadTotal(false);
      }
    } else {
      setLoadTotal(false);
    }
  };
  const getEventTerm = async () => {
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }

    let data = {
      token: token,
      id_evento: props.idEvent,
    };

    const response = await request.post("/auth/sales/getTermnOds", data);
    console.log(
      "🚀 ~ file: odspdf.jsx ~ line 234 ~ getEventTerm ~ response",
      response
    );
    //console.log(data);/**/

    if (response && !response.error) {
      if (response.result && !response.empty) {
        if (response.result.termino.length !== 0) {
          let termino = JSON.parse(response.result.termino[0].id_pie);
          setEventTerm(termino);
        }
        setLoadTerm(true);
      } else {
        setLoadTerm(false);
      }
    } else {
      setLoadTerm(false);
    }
  };
  const FormatNumber = (number) => {
    let val = new Intl.NumberFormat("ES-MX", {
      style: "currency",
      currency: "MXN",
      useGrouping: true,
    }).format(number);
    if (val.length === 8) {
      val = val.substring(0, 2) + "," + val.substring(2, 8);
    }
    return val;
  };
  useEffect(() => {
    if (props.recargaOds === true || props.idEvent !== 0) {
      getEventHeader();
    }
  }, [props.idEvent, props.recargaOds]);
  useEffect(() => {
    setTitles(
      request.odsTitles(!props.ods ? eventHeader.Idioma : "Español MX")
    );
  }, [eventHeader?.Idioma]);

  return (props.ods && loadHeader && loadArt) ||
    (!props.ods && loadHeader && loadArt && loadTotal && loadTerm) ? (
    <PDFDownloadLink
      document={
        <Document
          title={eventHeader.nombre}
          author={eventHeader.nombre_us}
          creator="SistemaZde"
        >
          <Page size="LETTER" style={styles.page}>
            <View style={styles.header} fixed>
              <Text style={{ fontWeight: "bold" }}>
                {!props.ods
                  ? titles.enc
                  : `ORDEN DE SERVICIO PARA EVENTO "${eventHeader.id_evento}"`}
              </Text>
            </View>

            <View
              style={{
                flexDirection: "row",
                textAlign: "center",
                //border: "1px",
              }}
            >
              <View
                style={{
                  width: "48%",
                  margin: "auto",
                  alignSelf: "flex-start",
                }}
              >
                <Text style={styles.sectionTh}>{titles.infoe}</Text>

                <Text style={styles.section}>
                  {titles.event}
                  :&nbsp;{props.ods ? eventHeader.id_evento + "-" : null}
                  {eventHeader.nombre}
                </Text>
                <Text style={styles.section}>
                  {titles.type}:&nbsp;
                  {props.ods ? eventHeader.tipo_esp : eventHeader.tipo_ing}
                </Text>
                <Text style={styles.section}>
                  {titles.fi}:&nbsp;
                  {eventHeader.Fecha_inicio}h
                </Text>
                {props.ods ? (
                  <Text style={styles.section}>
                    Fecha de Termino: {eventHeader.Fecha_fin}h
                  </Text>
                ) : null}
                <Text style={styles.section}>
                  {titles.site}: {eventHeader.nombre_h}
                </Text>
                <Text style={styles.section}>
                  {titles.loc}: {eventHeader.Salon}
                </Text>
                <View style={{ flexDirection: "row" }}>
                  <Text
                    style={[
                      styles.section,
                      { minWidth: "54%", borderRight: "1pt dashed grey" },
                    ]}
                  >
                    {titles.theme}:&nbsp;
                    {props.ods || eventHeader.Idioma != "English US"
                      ? `${eventHeader.Tema}-${eventHeader.T_especial}`
                      : eventHeader.Tema_ing}
                  </Text>
                  <Text style={[styles.section, { minWidth: "44%" }]}>
                    {titles.pax}: {eventHeader.Paxes}
                  </Text>
                </View>
                {props.ods ? (
                  <>
                    <Text style={styles.section}>
                      Fecha de Montaje: {eventHeader.Fecha_montaje}h
                    </Text>
                    <Text style={styles.section}>
                      Fecha de Desmontaje: {eventHeader.Fecha_desmontaje}h
                    </Text>
                    <View style={{ flexDirection: "row" }}>
                      <Text
                        style={[
                          styles.section,
                          { minWidth: "54%", borderRight: "1pt dashed grey" },
                        ]}
                      >
                        Backup: {eventHeader.Bakup}
                      </Text>
                      <Text style={[styles.section, { minWidth: "44%" }]}>
                        Requiere de Diseño:&nbsp;{" "}
                        {eventHeader.Renders === 0 ? "No" : "Sí"}
                      </Text>
                    </View>
                  </>
                ) : null}
                <Text style={styles.section}>
                  {titles.contact}: {eventHeader.Contacto_Hotel}
                </Text>
              </View>
              <View
                style={{
                  width: "48%",
                  margin: "auto",
                  textAlign: "center",
                  alignSelf: "flex-end",
                }}
              >
                <View
                  style={{
                    width: props.ods ? "60%" : "50%",
                    alignSelf: "center",
                    marginBottom: "1.5mm",
                  }}
                >
                  <Image
                    style={{ marginBottom: "1px" }}
                    src="./assets/img/logoOds.jpg"
                  />
                </View>

                <Text style={styles.sectionTh}>{titles.infoz}</Text>
                {props.ods ? (
                  <View style={{ flexDirection: "row" }}>
                    <Text style={[styles.section, { width: "29%" }]}>
                      Status:&nbsp;
                    </Text>
                    <Text
                      style={[
                        styles.section,
                        {
                          width: "69%",
                          fontWeight: "bold",
                          color: "white",
                          backgroundColor: eventHeader.color,
                          textAlign: "center",
                        },
                      ]}
                    >
                      {eventHeader.status}
                    </Text>
                  </View>
                ) : null}
                <Text style={styles.section}>
                  {titles.fc}: {eventHeader.fecha_ods}
                </Text>

                <Text style={styles.section}>
                  {titles.ven}: {eventHeader.nombre_us}
                </Text>
                <Text style={styles.section}>
                  Concierge: {eventHeader.consierge}
                </Text>
                <Text style={styles.section}>
                  {titles.tel}: {eventHeader.telefono}
                </Text>
                <Text style={styles.section}>
                  {titles.mail}: {eventHeader.correo}
                </Text>

                {props.ods ? (
                  <>
                    <Text style={styles.sectionTh}>INFORMACIÓN CONTABLE</Text>
                    <Text style={styles.section}>
                      Cliente a Facturar: {eventHeader.clienteId}
                    </Text>
                    <Text style={styles.section}>
                      Forma de Pago: {eventHeader.formaDescripcion}
                    </Text>
                  </>
                ) : null}
              </View>
            </View>

            {artInfo.map((key, i) =>
              key.length > 0 ? (
                <View
                  key={"View" + i}
                  style={{
                    flexDirection: "column",
                    marginTop: 5,
                  }}
                >
                  {props.ods ? (
                    <>
                      <View
                        style={{
                          flexDirection: "row",
                          textAlign: "center",
                          border: "1px",
                        }}
                      >
                        <Text style={styles.headerTr}>
                          SUBEVENTO {key[0].id_subevento}: {key[0].nombre}{" "}
                          LOCACIÓN: {key[0].locacion_sub}
                        </Text>
                      </View>
                      <View
                        style={{
                          flexDirection: "row",
                          textAlign: "center",
                          border: "1px",
                        }}
                      >
                        <Text style={styles.headerTr}>
                          MONTAJE: {key[0].fecha_montaje}h
                        </Text>
                        <Text style={styles.headerTr}>
                          ENTREGA: {key[0].fecha_entrega}h
                        </Text>
                        <Text style={styles.headerTr}>
                          DESMONTAJE: {key[0].fecha_desmontaje}h
                        </Text>
                      </View>
                    </>
                  ) : (
                    <View
                      style={{
                        flexDirection: "row",
                        textAlign: "center",
                        border: "1px",
                      }}
                    >
                      <Text style={{ width: "100%" }}>
                        {key[0].nombre} {key[0].locacion_sub}
                      </Text>
                    </View>
                  )}
                  <View
                    style={{
                      flexDirection: "row",
                      textAlign: "center",
                      width: "100%",
                    }}
                  >
                    {props.ods ? (
                      <View style={[styles.headerTh, { width: "2cm" }]}>
                        <Text> </Text>
                      </View>
                    ) : null}

                    <View style={[styles.headerTh, { width: "2.5cm" }]}>
                      <Text>{titles.photo}</Text>
                    </View>
                    <View
                      style={[
                        styles.headerTh,
                        { width: props.ods ? "4cm" : "8cm" },
                      ]}
                    >
                      <Text>{titles.item}</Text>
                    </View>

                    {props.ods ? (
                      <View style={[styles.headerTh, { width: "3cm" }]}>
                        <Text>DIMENSIÓN</Text>
                      </View>
                    ) : null}

                    <View style={[styles.headerTh, { width: "2cm" }]}>
                      <Text>{titles.pieces}</Text>
                    </View>
                    {!props.ods ? (
                      <>
                        <View style={[styles.headerTh, { width: "2cm" }]}>
                          <Text>{titles.unit}</Text>
                        </View>
                        <View style={[styles.headerTh, { width: "1.5cm" }]}>
                          <Text>{titles.days}</Text>
                        </View>
                      </>
                    ) : (
                      <>
                        <View style={[styles.headerTh, { width: "4cm" }]}>
                          <Text>INDICACIÓN</Text>
                        </View>
                        <View style={[styles.headerTh, { width: "1.2cm" }]}>
                          <Text>BOD</Text>
                        </View>
                        <View style={[styles.headerTh, { width: "1.2cm" }]}>
                          <Text>OTRO</Text>
                        </View>
                      </>
                    )}

                    {!props.ods ? (
                      <>
                        <View style={[styles.headerTh, { width: "2cm" }]}>
                          <Text>{titles.price}</Text>
                        </View>
                        <View style={[styles.headerTh, { width: "2cm" }]}>
                          <Text>{titles.amount}</Text>
                        </View>
                      </>
                    ) : null}
                  </View>
                  {key.map((info, j) =>
                    (info.oculto_ods == 0 && props.ods) ||
                    (info.oculto_cot == 0 && !props.ods) ? (
                      <View key={j} style={{ flexDirection: "row" }}>
                        {props.ods ? (
                          <View style={[styles.headerTh, { width: "2cm" }]}>
                            <Image
                              src={`data:image/png;base64,${info.qr}`}
                            ></Image>
                          </View>
                        ) : null}
                        <View
                          style={[
                            styles.headerTd,
                            { width: "2.5cm", textAlign: "center" },
                          ]}
                        >
                          <Link
                            src={
                              info.img_char == "http"
                                ? `${info.img_pred}`
                                : `${request.publicURL()}/inventario/${
                                    info.img_pred
                                  }`
                            }
                          >
                            <Image
                              style={{
                                padding: "1px",
                                maxHeight: props.ods ? "85px" : "70px",
                                maxWidth: "80px",
                              }}
                              src={
                                info.img_char == "http"
                                  ? `https://corsproxy.io/?${info.img_pred}`
                                  : `${request.publicURL()}/inventario/${
                                      info.img_pred
                                    }`
                              }
                            />
                          </Link>
                        </View>
                        <View
                          style={[
                            styles.headerTd,
                            {
                              width: props.ods ? "4cm" : "8cm",
                              textAlign: "center",
                            },
                          ]}
                        >
                          {props.ods ? (
                            <Text style={{ paddingTop: 10 }}>
                              {info.id_art} - {info.nomE}
                            </Text>
                          ) : (
                            <>
                              <Text style={{ paddingTop: 10 }}>
                                {eventHeader.Idioma != "English US"
                                  ? info.nomE
                                  : info.nomI}
                              </Text>
                              <Text>{info.comentarios}</Text>
                            </>
                          )}
                        </View>

                        {props.ods ? (
                          <View
                            style={[
                              styles.headerTd,
                              {
                                width: "3cm",
                                textAlign: "center",
                                fontSize: 9,
                              },
                            ]}
                          >
                            <Text style={{ paddingTop: 10 }}>{info.dim}</Text>
                          </View>
                        ) : null}
                        <View
                          style={[
                            styles.headerTd,
                            { width: "2cm", textAlign: "center" },
                          ]}
                        >
                          <Text style={{ paddingTop: 10 }}>
                            {info.total}
                            {props.ods ? ` ${info.unidad}` : null}
                          </Text>
                        </View>

                        {props.ods ? (
                          <View
                            style={[
                              styles.headerTd,
                              {
                                width: "4cm",
                                textAlign: "justify",
                                fontSize: 9,
                              },
                            ]}
                          >
                            <Text style={{ paddingTop: 2 }}>
                              {info.indicaciones}
                            </Text>
                          </View>
                        ) : (
                          <>
                            <View
                              style={[
                                styles.headerTd,
                                { width: "2cm", textAlign: "center" },
                              ]}
                            >
                              <Text style={{ paddingTop: 10 }}>
                                {info.unidad}
                              </Text>
                            </View>
                            <View
                              style={[
                                styles.headerTd,
                                { width: "1.5cm", textAlign: "center" },
                              ]}
                            >
                              <Text style={{ paddingTop: 10 }}>
                                {info.dias}
                              </Text>
                            </View>
                          </>
                        )}
                        {!props.ods ? (
                          <>
                            <View
                              style={[
                                styles.headerTd,
                                { width: "2cm", textAlign: "right" },
                              ]}
                            >
                              <Text style={{ paddingTop: 10 }}>
                                {FormatNumber(info.preciouni)}
                              </Text>
                            </View>
                            <View
                              style={[
                                styles.headerTd,
                                { width: "2cm", textAlign: "right" },
                              ]}
                            >
                              <Text style={{ paddingTop: 10 }}>
                                {FormatNumber(info.importe)}
                              </Text>
                            </View>
                          </>
                        ) : (
                          <>
                            <View
                              style={[
                                styles.headerTd,
                                { width: "1.2cm", textAlign: "center" },
                              ]}
                            >
                              <Text style={{ paddingTop: 10 }}>
                                {info.cantidad}
                              </Text>
                            </View>
                            <View
                              style={[
                                styles.headerTd,
                                { width: "1.2cm", textAlign: "center" },
                              ]}
                            >
                              <Text style={{ paddingTop: 10 }}>
                                {info.produccion}
                              </Text>
                            </View>
                          </>
                        )}
                      </View>
                    ) : null
                  )}
                </View>
              ) : null
            )}
            {!props.ods ? (
              <View
                style={{
                  flexDirection: "column",
                  marginTop: 1,
                  textAlign: "center",
                  borderTop: "2px",
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                    textAlign: "right",
                    width: "100%",
                  }}
                >
                  <View style={{ width: "18cm" }}>
                    <Text>
                      {titles.sub1}
                      &nbsp;
                      {eventHeader.monedaDescripcion
                        ? eventHeader.monedaDescripcion.slice(0, 3)
                        : ""}
                    </Text>
                  </View>
                  <View style={{ width: "2cm", borderBottom: "1px" }}>
                    <Text>{FormatNumber(eventTotal.subtotal)}</Text>
                  </View>
                </View>
                {eventTotal.descuento != 0 ? (
                  <>
                    <View
                      style={{
                        flexDirection: "row",
                        textAlign: "right",
                        width: "100%",
                        color: "darkred",
                        fontWeight: "bold",
                      }}
                    >
                      <View style={{ width: "18cm" }}>
                        <Text>
                          {titles.desc}
                          &nbsp;
                          {eventHeader.monedaDescripcion
                            ? eventHeader.monedaDescripcion.slice(0, 3)
                            : ""}
                        </Text>
                      </View>
                      <View style={{ width: "2cm", borderBottom: "1px" }}>
                        <Text>-{FormatNumber(eventTotal.descuento)}</Text>
                      </View>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        textAlign: "right",
                        width: "100%",
                      }}
                    >
                      <View style={{ width: "18cm" }}>
                        <Text>
                          {titles.sub1}
                          &nbsp;
                          {eventHeader.monedaDescripcion
                            ? eventHeader.monedaDescripcion.slice(0, 3)
                            : ""}
                        </Text>
                      </View>
                      <View style={{ width: "2cm", borderBottom: "1px" }}>
                        <Text>{FormatNumber(eventTotal.subtotal2)}</Text>
                      </View>
                    </View>
                  </>
                ) : null}
                {eventTotal.operacion != 0 ? (
                  <>
                    <View
                      style={{
                        flexDirection: "row",
                        textAlign: "right",
                        width: "100%",
                      }}
                    >
                      <View style={{ width: "18cm" }}>
                        <Text>
                          {titles.op}
                          &nbsp;
                          {eventHeader.monedaDescripcion
                            ? eventHeader.monedaDescripcion.slice(0, 3)
                            : ""}
                        </Text>
                      </View>
                      <View style={{ width: "2cm", borderBottom: "1px" }}>
                        <Text>{FormatNumber(eventTotal.operacion)}</Text>
                      </View>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        textAlign: "right",
                        width: "100%",
                        color: "darkblue",
                        fontWeight: "bold",
                      }}
                    >
                      <View style={{ width: "18cm" }}>
                        <Text>
                          {titles.sub2}&nbsp;
                          {eventHeader.monedaDescripcion
                            ? eventHeader.monedaDescripcion.slice(0, 3)
                            : ""}
                        </Text>
                      </View>
                      <View style={{ width: "2cm", borderBottom: "1px" }}>
                        <Text>{FormatNumber(eventTotal.subtotal3)}</Text>
                      </View>
                    </View>
                  </>
                ) : null}
                {eventTotal.iva != 0 ? (
                  <View
                    style={{
                      flexDirection: "row",
                      textAlign: "right",
                      width: "100%",
                    }}
                  >
                    <View style={{ width: "18cm" }}>
                      <Text>
                        {titles.iva}
                        &nbsp;
                        {eventHeader.monedaDescripcion
                          ? eventHeader.monedaDescripcion.slice(0, 3)
                          : ""}
                      </Text>
                    </View>
                    <View style={{ width: "2cm", borderBottom: "1px" }}>
                      <Text>{FormatNumber(eventTotal.iva)}</Text>
                    </View>
                  </View>
                ) : null}
                <View
                  style={{
                    flexDirection: "row",
                    textAlign: "right",
                    width: "100%",
                    fontWeight: "bold",
                  }}
                >
                  <View style={{ width: "18cm" }}>
                    <Text>
                      TOTAL&nbsp;
                      {eventHeader.monedaDescripcion
                        ? eventHeader.monedaDescripcion.slice(0, 3)
                        : ""}
                    </Text>
                  </View>
                  <View
                    style={{
                      width: "2cm",
                      borderBottom: "1px",
                    }}
                  >
                    <Text>{FormatNumber(eventTotal.total)}</Text>
                  </View>
                </View>
              </View>
            ) : null}
            {!props.ods && eventHeader.cCli?.length != 0 ? (
              <View
                style={{ textAlign: "center", width: "100%", marginTop: 10 }}
              >
                <View style={{ border: 1 }}>
                  <Text>{titles.comCli}</Text>
                </View>
                <View
                  style={{
                    borderBottom: "1pt dashed gray",
                    textAlign: "justify",
                  }}
                >
                  <Text>{eventHeader.cCli}</Text>
                </View>
              </View>
            ) : null}
            {eventTerm?.length != 0 && !props.ods ? (
              <View
                style={{
                  flexDirection: "column",
                  marginTop: 10,
                  textAlign: "center",
                  //border: "1px",
                }}
              >
                <View style={{ width: "100%" }}>
                  <Text
                    style={{
                      border: 1,
                      textAlign: "center",
                      padding: 1,
                    }}
                  >
                    {titles.terms}
                  </Text>
                </View>
                <View
                  style={{
                    flexDirection: "column",
                    textAlign: "justify",
                    width: "100%",
                  }}
                >
                  {eventTerm.map((info, key) => (
                    <Text style={{ padding: 2.5 }} key={key}>
                      * {info.texto}
                    </Text>
                  ))}
                </View>
              </View>
            ) : null}
            {!props.ods ? (
              <View
                style={{
                  flexDirection: "row",
                  marginTop: 100,
                  textAlign: "center",
                  //border: "1px",
                }}
              >
                <View
                  style={{
                    width: "40%",
                    margin: "auto",
                  }}
                >
                  <Text style={{ borderTop: 1, paddingTop: 3 }}>
                    {eventHeader.cFinal}
                  </Text>
                  <Text>{titles.client}</Text>
                  <Text>&nbsp;</Text>
                </View>
                <View
                  style={{
                    width: "40%",
                    margin: "auto",
                  }}
                >
                  <Text style={{ borderTop: 1, paddingTop: 3 }}>
                    {eventHeader.nombre_us}
                  </Text>
                  <Text>{titles.ven}</Text>
                  <Text>ZDE BODAS Y EVENTOS S.A de C.V.</Text>
                </View>
              </View>
            ) : eventHeader.Comentarios_int?.length != 0 ? (
              <View
                style={{ textAlign: "center", width: "100%", marginTop: 10 }}
              >
                <View style={{ border: 1 }}>
                  <Text>COMENTARIOS PARA OPERACIÓN</Text>
                </View>
                <View
                  style={{
                    borderBottom: "1pt dashed gray",
                    textAlign: "justify",
                  }}
                >
                  <Text>{eventHeader.Comentarios_int}</Text>
                </View>
              </View>
            ) : null}

            <Text
              style={styles.pageNumber}
              render={({ pageNumber, totalPages }) =>
                `${pageNumber} / ${totalPages}`
              }
              fixed
            />
          </Page>
        </Document>
      }
      fileName={
        props.ods
          ? `ODS_${eventHeader.nombre}`
          : `Cotizacion_${eventHeader.nombre}`
      }
      style={{ color: "black" }}
    >
      <i
        className="far fa-file-pdf"
        style={{ fontSize: "1.4rem", color: "#FA0F00" }}
      ></i>
      &nbsp;{!props.ods ? "Cotización" : " ODS"}
    </PDFDownloadLink>
  ) : (
    <>
      <i
        className="fas fa-spinner fa-spin"
        style={{ fontSize: "1.4rem", color: "#3f51b5" }}
      ></i>
      &nbsp;Cargando...
    </>
  );
}
export default Consumer(OdsPdf);
