import React, { useState } from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Modal from "react-modal";
import Request from "../../utils/http";
import Attachment from "./attachment";

import { toast } from "react-toastify";
const request = new Request();

function SimpleMenu(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [mouseX, setMouseX] = useState(null);
  const [mouseY, setMouseY] = useState(null);
  const [openModal, setModal] = useState(false);
  
  const customStyles = {
    content: {
      position: "absolute",
      top: "15px",
      left: "55px",
      right: "55px",
      bottom: "15px",
      border: "1px solid rgb(204, 204, 204)",
      background: "rgb(255, 255, 255)",
      overflow: "auto",
      outline: "none",
      padding: "15px",
    },
  };
  const handleClick = (event) => {
    setMouseX(event.clientX - 2);
    setMouseY(event.clientY - 4);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const reload = () => {
    props.reload();
    setAnchorEl(null);
  };

  let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
  let token;
  if (token1) {
    token = token1.id;
  }
  const canceled = async (dato) => {
    let data = {
      idReq: dato,
      idStatus: 7,
      token: token,
    };
    const response = await request.post(
      "/auth/purchases/cancelRequisition",
      data
    );
    console.log("Canceled->response :>> ", response);
    if (response && !response.error) {
      if (response.result && !response.empty) {
        
        toast.success(`Requisición ${dato} cancelada con exito!`);
        props.reload();
      } else {
        
        toast.error(
          "UPSS!!!, algo salio mal.. intentalo de nuevo, si el problema continua contacta a sistemas.",
          {
            position: toast.POSITION.TOP_RIGHT,
            theme: "colored",
          }
        );
      }
    } else {
      toast.error(
        "UPSS!!!, algo salio mal.. intentalo de nuevo, si el problema continua contacta a sistemas."
      );
    }
  };

  const authorize = async (dato) => {
    let data = {
      idReq: dato,
      idUsuario: props.user.id,
      token: token,
    };
    const response = await request.post(
      "/auth/purchases/authorizeRequisition",
      data
    );
    console.log(response);
    if (response && !response.error) {
      if (response.result && !response.empty) {
        
        toast.success(`Requisición ${dato} autorizada con exito!`);
        props.reload();
      } else {
        
        toast.error(
          "UPSS!!!, algo salio mal.. intentalo de nuevo, si el problema continua contacta a sistemas.",
          {
            position: toast.POSITION.TOP_RIGHT,
            theme: "colored",
          }
        );
      }
    } else {
      toast.error(
        "UPSS!!!, algo salio mal.. intentalo de nuevo, si el problema continua contacta a sistemas."
      );
    }
  };
  const copy = async (dato) => {
    let data = {
      idReq: dato,
      idUsuario: props.user.id,
      token: token,
    };
    const response = await request.post(
      "/auth/purchases/copyRequisition",
      data
    );
    console.log("Copy->",response);
    if (response && !response.error) {
      if (response.result && !response.empty) {
        
        toast.success(`Requisición ${dato} copiada con exito!`);
        props.reload();
      } else {
        
        toast.error(
          "UPSS!!!, algo salio mal.. intentalo de nuevo, si el problema continua contacta a sistemas.",
          {
            position: toast.POSITION.TOP_RIGHT,
            theme: "colored",
          }
        );
      }
    } else {
      toast.error(
        "UPSS!!!, algo salio mal.. intentalo de nuevo, si el problema continua contacta a sistemas."
      );
    }
  };

  const edditReq = () => {
    props.edditReq();
    setAnchorEl(null);
  };

  return (
    <>
      <button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        className="btn btn-sm btn-info bg-gradient-info"
      >
        <ExpandMoreIcon style={{ color: "#eceff1" }} />
      </button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          mouseY !== null && mouseX !== null
            ? { top: mouseY, left: mouseX }
            : undefined
        }
      >
        {!props.us_autoriza &&
        props.id_status !== 4 &&
        props.id_status !== 7 ? (
          <MenuItem
            onClick={() => {
              authorize(props.idReq);
              handleClose();
            }}
            style={{ color: "green" }}
          >
            <i className="far fa-thumbs-up"></i>&nbsp; Autorizar {props.auth}
          </MenuItem>
        ) : null}
        {props.id_status !== 4 && props.id_status !== 7 ? (
          <MenuItem
            onClick={() => {
              canceled(props.idReq);
              handleClose();
            }}
            style={{ color: "red" }}
          >
            <i className="fas fa-ban"></i>&nbsp; Cancelar
          </MenuItem>
        ) : null}
        <hr />
        <MenuItem onClick={() => edditReq()}>
          <i className="fas fa-pen"></i>&nbsp; Editar
        </MenuItem>
        <MenuItem
          onClick={() => {
            setModal(true);
            handleClose();
          }}
        >
          <i className="fas fa-paperclip"></i>&nbsp; Adjuntar
        </MenuItem>
        <MenuItem
          onClick={() => {
            copy(props.idReq);
            handleClose();
          }}
        >
          <i className="far fa-copy"></i>&nbsp; Copiar
        </MenuItem>
        <hr />

        <hr />
        {props.mailProv !== 0 ? (
          <MenuItem
            onClick={() => {
              window.location.href = `mailto:${props.mailProv}"`;
              handleClose();
            }}
          >
            <i className="fas fa-envelope"></i>&nbsp; Correo proveedor
          </MenuItem>
        ) : null}
      </Menu>
      <Modal
        isOpen={openModal}
        style={customStyles}
        contentLabel="Adjuntar Archivo"
      >
        <div className="modal-header">
          <h4>Adjuntar archivos a la requisición {props.idReq}</h4>
          <button
            type="button"
            className="close"
            onClick={() => {
              setModal(false);
            }}
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div
          className="modal-body card-primary card-outline card-outline-tabs"
          style={{ zoom: "95%", padding: "0rem" }}
        >
          <div className="card-body" style={{ padding: "0rem" }}>
            <Attachment idReq={props.idReq} attach={true} />
          </div>
        </div>
      </Modal>
    </>
  );
}
export default SimpleMenu;
