import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Request from "../../utils/http";
import "./../calendar/tabs/ods.css";
import { Consumer } from "../../context/index";
const request = new Request();
function Attachment(props) {
  const [id_event] = useState(props.idEvent);
  const [files, setFiles] = useState({ 0: [], 1: [] });
  const [load, setLoad] = useState(false);
  const [labelInput, setLabelInput] = useState("Adjuntar Archivo");
  const [user, setUser] = useState({
    idUsuario: 0,
    idPerfil: 0,
    nombre: "",
  });

  useEffect(() => {
    loadUser();
    getEventFiles();
  }, []);
  const loadUser = async () => {
    const user = await props.context.loadUser();

    if (user) {
      setUser(user);
    }
  };
  const handleChange = (e) => {
    let x = e.target.value.length;
    if (x > 0) {
      let file = e.target.files[0].name;
      setLabelInput(file);
    } else {
      setLabelInput("Adjuntar Archivo");
    }
  };
  const getEventFiles = async () => {
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }

    let data = {
      token: token,
      id_event: props.idEvent,
    };

    const response = await request.post(
      "/auth/design/getDesignAttachment",
      data
    );
    console.log(
      "🚀 ~ file: attachment.jsx ~ line 60 ~ getEventFiles ~ response",
      response
    );

    if (response && !response.error) {
      if (response.result && !response.empty) {
        props.countAdj(response.result.length);
        let d = [];
        let p = [];
        for (let i = 0; i < response.result.length; i++) {
          switch (response.result[i].id_tipo) {
            case 1:
              p.push(response.result[i]);
              break;

            case 2:
              d.push(response.result[i]);
              break;
          }
        }
        setFiles({ 0: p, 1: d });
        
        // window.sessionStorage.setItem(
        //   "Productos",
        //   JSON.stringify(response.result)
        // );
      } else {
        setFiles([]);
        props.countAdj(0);
        // this.setState({
        //   empty: true,
        //   loading: false,
        //   message: response.message
        // });
      }
    } else {
      // this.setState({
      //   error: true,
      //   loading: false,
      //   message: response.message
      // });
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoad(true);
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }
    let data = new FormData(document.getElementById("uploadForm"));
    data.append("idEvent", props.idEvent);
    data.append("usu", user.id);
    data.append("modulo", "design");

    const response = await request.upload(
      "/auth/upload/saveAttachDesignFiles",
      data,
      token
    );
    console.log(data, response);
    if (response && !response.error) {
      if (response.result && !response.empty) {
        document.getElementById("uploadForm").reset();
        setLoad(false);
        setLabelInput("Adjuntar Archivo");
        getEventFiles();
        toast.success(
          <span>
            Archivo cargado con
            exito.
          </span>
        );
      } else {
        setLoad(false);
        toast.error(
          <span>
            ¡No se pudo cargar el
            archivo!.
          </span>
        );
      }
    } else {
      setLoad(false);
      toast.error(
        <span>
          ¡No se pudo cargar el
          archivo!.
        </span>
      );
    }
  };
  const deleteFile = async (e, id) => {
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }
    let data = {
      token: token,
      idFile: id,
    };

    const response = await request.post("/auth/upload/deleteDesignFile", data);
    console.log(
      "🚀 ~ file: attachment.jsx ~ line 142 ~ deleteDesignFile ~ response",
      response
    );

    if (response && !response.error) {
      if (response.result && !response.empty) {
        getEventFiles();
        toast.success(
          <span>
            Archivo eliminado con
            exito.
          </span>
        );
      } else {
        toast.error(
          <span>
            ¡No se pudo eliminar el
            archivo!.
          </span>
        );
      }
    } else {
      toast.error(
        <span>
          ¡No se pudo eliminar el
          archivo!.
        </span>
      );
    }
  };
  return (
    <>
      <div className="card">
        <div className="card-header pt-1 pb-1 pl-1 pr-1">
          PLANOS CONSTRUCTIVOS
        </div>
        <div className="card-body">
          <div className="row d-none d-md-flex data">
            <div className="col-12 col-md-2 mt-3">ACCIONES</div>
            <div className="col-12 col-md-2 mt-3">ID</div>
            <div className="col-12 col-md-2 mt-3">TIPO</div>
            <div className="col-12 col-md-3 mt-3">NOMBRE</div>
            <div className="col-12 col-md-3 mt-3">COMENTARIOS</div>
          </div>
          {files[0].map((info, key) => (
            <div className="row data" id={key} key={key}>
              <div className="col-12 col-md-2">
                <a
                  href={
                    info.img_char === "http"
                      ? info.ruta
                      : `${request.publicURL()}/diseno/${info.ruta}`
                  }
                  target="_blank"
                  className="btn btn-sm btn-primary"
                >
                  <i className="fas fa-eye"></i>
                </a>
                {info.id_usuario == user.id ||
                user.id_perfil == 1 ||
                user.id_perfil == 2 ? (
                  <button
                    className="btn btn-sm btn-danger ml-3"
                    onClick={(e) => deleteFile(e, info.id_file)}
                  >
                    <i className="fas fa-trash-alt"></i>
                  </button>
                ) : null}
              </div>
              <div className="col-12 col-md-2">{info.id_file}</div>
              <div className="col-12 col-md-2">{info.descrip}</div>
              <div className="col-12 col-md-3">{info.nombre}</div>
              <div className="col-12 col-md-3">{info.comentarios}</div>
            </div>
          ))}
        </div>
      </div>
      <div className="card">
        <div className="card-header pt-1 pb-1 pl-1 pr-1">DISEÑO APROBADO</div>
        <div className="card-body">
          <div className="row d-none d-md-flex data">
            <div className="col-12 col-md-2 mt-3">ACCIONES</div>
            <div className="col-12 col-md-2 mt-3">ID</div>
            <div className="col-12 col-md-2 mt-3">TIPO</div>
            <div className="col-12 col-md-3 mt-3">NOMBRE</div>
            <div className="col-12 col-md-3 mt-3">COMENTARIOS</div>
          </div>
          {files[1].map((info, key) => (
            <div className="row data" id={key} key={key}>
              <div className="col-12 col-md-2">
                <a
                  href={
                    info.img_char === "http"
                      ? info.ruta
                      : `${request.publicURL()}/diseno/${info.ruta}`
                  }
                  target="_blank"
                  className="btn btn-sm btn-primary"
                >
                  <i className="fas fa-eye"></i>
                </a>
                {info.id_usuario == user.id ||
                user.id_perfil == 1 ||
                user.id_perfil == 2 ? (
                  <button
                    className="btn btn-sm btn-danger ml-3"
                    onClick={(e) => deleteFile(e, info.id_file)}
                  >
                    <i className="fas fa-trash-alt"></i>
                  </button>
                ) : null}
              </div>
              <div className="col-12 col-md-2">{info.id_file}</div>
              <div className="col-12 col-md-2">{info.descrip}</div>
              <div className="col-12 col-md-3">{info.nombre}</div>
              <div className="col-12 col-md-3">{info.comentarios}</div>
            </div>
          ))}
        </div>
      </div>
      {props.attach ? (
        <div className="card">
          <div className="card-header pt-1 pb-1 pl-1 pr-1">
            AGREGAR ARCHIVOS ADJUNTOS DISEÑO
          </div>
          <div className="card-body">
            <form method="post" id="uploadForm" encType="multipart/form-data">
              <div className="row">
                <div className="col-12">
                  <div className="input-group">
                    <div className="custom-file">
                      <input
                        type="file"
                        name="uploadFile"
                        className="custom-file-input"
                        id="uploadFile"
                        onChange={(e) => handleChange(e)}
                      />
                      <label className="custom-file-label" htmlFor="uploadFile">
                        {labelInput}
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-3 mt-3">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">TIPO:</span>
                    </div>
                    <select
                      name="type"
                      className="form-control"
                      aria-label=""
                      // value={this.state.id_trabajo}
                    >
                      <option></option>
                      <option value="1">Planos constructivos</option>
                      <option value="2">Diseño aprobado</option>
                    </select>
                  </div>
                </div>
                <div className="col-3 mt-3">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">VERSIÓN:</span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      name="version"
                      aria-label=""
                      // value={this.state.id_trabajo}
                    ></input>
                  </div>
                </div>
                <div className="col-6 mt-3">
                  <div className="form-group">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">COMENTARIOS</span>
                      </div>
                      <textarea
                        className="form-control"
                        name="indicacion"
                        placeholder="Indicaciones sobre el archivo adjunto."
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  {id_event !== 0 ? (
                    <div className="card-footer">
                      <button
                        type="submit"
                        onClick={(e) => handleSubmit(e)}
                        className="btn btn-outline-success btn-flat float-right"
                        title="Boton para subir archivo."
                        disabled={load}
                      >
                        {load ? (
                          <i className="fas fa-spinner fa-spin"></i>
                        ) : (
                          <i className="fas fa-file-upload"></i>
                        )}
                        &nbsp; Subir
                      </button>
                    </div>
                  ) : (
                    "No se tiene id de evento, no se pueden adjuntar archivos."
                  )}
                </div>
                <div className="white-space-32"></div>
              </div>
            </form>{" "}
          </div>
        </div>
      ) : null}
    </>
  );
}
export default Consumer(Attachment);
