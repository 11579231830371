import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import Modal from "react-modal";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Request from "../../utils/http";
import NewWarehouse from "./newWarehouse";

const request = new Request();

function WarehouseList() {
  //set de valores
  const [warehouse, setWarehouse] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setModal] = useState(false);
  const [idW, setIdW] = useState(0);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const customStyles = {
    content: {
      position: "absolute",
      top: "20%",
      left: "25%",
      right: "25%",
      bottom: "35%",
      border: "1px solid rgb(204, 204, 204)",
      background: "rgb(255, 255, 255)",
      overflow: "auto",
      outline: "none",
      padding: "15px",
    },
  };
  //obteneos token
  let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
  let token;
  if (token1) {
    token = token1.id;
  }
  useEffect(() => {
    getWarehouse();
  }, []);

  const columns = [
    {
      name: "actions",
      label: "ACCIONES",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <NewWarehouse
              reload={getWarehouse}
              eddit={true}
              idW={tableMeta.rowData[1]}
            />
          );
        }
      }
    },
    {
      name: "id",
      label: "ID",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "descripcion",
      label: "DESCRIPCIÓN",
      options: {
        filter: true,
        sort: true,
      },
    },
  ];
  const options = {
    selectableRows: 'none',
    filter: false,
    viewColumns: false,
    textLabels: {
      body: {
        noMatch: "No se encontraron resultados.",
        toolTip: "Sort",
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Número de filas:",
        displayRows: "de",
      },
      toolbar: {
        search: "Buscar",
        downloadCsv: "Descargar Excel",
        print: "Imprimir",
      },
      filter: {
        all: "TODOS",
        title: "FILTROS",
        reset: "LIMPIAR",
      },
      viewColumns: {
        title: "Mostar Columnas",
        titleAria: "Mostrar/Ocultar Columnas de la Tabla",
      },
      selectedRows: {
        text: "Fila(s) Seleccionadas",
        delete: "Eliminar",
        deleteAria: "Eliminar Filar Seleccionadas",
      },
    },
    customToolbar: () => {
      return <NewWarehouse reload={getWarehouse} eddit={false} idW={0} />;
    },
  };
  const getWarehouse = async () => {
    let data = {
      token: token,
    };
    const response = await request.post(
      "/auth/inventory/getWarehouseList",
      data
    );

    //validar los datos que recibimos
    if (response && !response.error) {
      if (response.result && !response.empty) {
        //console.log(response.result);
        setWarehouse(response.result);
      } else {
        console.log(response.result.message);
      }
    } else {
      console.log("error en la peticion");
    }
  };
  const saveWarehouse = async () => {};
  return (
      <MUIDataTable
        title={"LISTA DE ALMACENES"}
        data={warehouse}
        columns={columns}
        options={options}
      />
  );
}
export default WarehouseList;
