import React, { Component } from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import Modal from "react-modal";
import Fab from "@material-ui/core/Fab";
import Button from "@material-ui/core/Button";
import validator from "./validatorPurchases";
import { Consumer } from "../../context/index";
import Request from "../../utils/http";
import EditIcon from "@material-ui/icons/Edit";
import { toast } from "react-toastify";
const request = new Request();
Modal.setAppElement(document.getElementById("root"));

const customStyles = {
  content: {
    top: "50%",
    left: "80%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    overflow: "scroll",
  },
};
class NewProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
      idArt: 0,
      eddit: this.props.eddit,
      saved: 0,
      token: "",
      id: 0,
      rfc: "xxxxxxxxxxxxx",
      demComerc: "",
      razonS: "",
      repLeg: "",
      curp: "",
      banco: "",
      numCuenta: 0,
      clabeInt: "",
      NombreCuenta: "",
      contacto: "",
      idCliente: "",
      tel1: "",
      tel2: "",
      email1: "",
      email2: "",
      web: "",
      cp: "",
      estado: "",
      municipio: "",
      ciudad: "",
      colonia: "",
      calle: "",

      nExt: "",
      nInt: "",
      pais: "",
      error: false,
      idUsuario: 0,
      idPerfil: 0,
      nombre: "",
    };
  }
  closeModal() {
    this.openModal();
  }
  reload() {
    this.props.reload();
  }
  saved(id) {
    let cont = this.state.saved + 1;
    this.setState({
      saved: cont,
      idArt: id,
    });
  }
  render() {
    return (
      <>
        {this.props.edit === false ? (
          <Tooltip title={"Agregar Proveedor"}>
            <Fab
              color="primary"
              size="small"
              aria-label="add"
              className="MuiButtonBase-root MuiIconButton-root MUIDataTableToolbar-icon-49"
              style={{ padding: "0px" }}
              onClick={this.openModal.bind(this)}
            >
              <AddIcon className="MuiSvgIcon-root" />
            </Fab>
          </Tooltip>
        ) : (
          <div>
            <button
              className="btn btn-sm btn-info bg-gradient-info"
              title="Editar"
              onClick={this.openModal.bind(this)}
            >
              <EditIcon />
            </button>
          </div>
        )}

        <Modal
          isOpen={this.state.openModal}
          style={customStyles.content}
          contentLabel="EditarArticulo"
        >
          <div className="modal-header" style={{'zoom':'85%'}}>
            <h4 className="modal-title">
              {this.props.idProv !== 0
                ? this.state.id + " - " + this.state.demComerc
                : "Nuevo Proveedor"}
            </h4>
            <button
              type="button"
              className="close"
              onClick={this.openModal.bind(this)}
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body card-primary card-outline card-outline-tabs">
            <div className="card-body">
              <div className="tab-content" id="custom-tabs-three-tabContent">
                <div className="row mt-4">
                  <div className="col-md-12 text-center">
                    <h6>DATOS GENERALES</h6>
                  </div>
                </div>
                <div className="white-space-16"></div>
                <div className="row mb-2">
                  <div className="col-12 col-md-6 col-lg-2">
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <span className="input-group-text">ID:</span>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        aria-label=""
                        value={this.state.id}
                        readOnly
                      ></input>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4">
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <span className="input-group-text">RFC:</span>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        aria-label=""
                        value={this.state.rfc}
                        name="rfc"
                        id="rfc"
                        onChange={(e) => this.setState({ rfc: e.target.value })}
                      ></input>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          DEN. COMERCIAL:
                        </span>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        aria-label=""
                        value={this.state.demComerc}
                        name="demComerc"
                        id="demComerc"
                        onChange={(e) =>
                          this.setState({ demComerc: e.target.value })
                        }
                      ></input>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <span className="input-group-text">RAZON SOCIAL:</span>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        aria-label=""
                        value={this.state.razonS}
                        id="razonS"
                        onChange={(e) =>
                          this.setState({ razonS: e.target.value })
                        }
                      ></input>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          REPRESENTANTE LEGAL:
                        </span>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        aria-label=""
                        value={this.state.repLeg}
                        onChange={(e) =>
                          this.setState({ repLeg: e.target.value })
                        }
                      ></input>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <span className="input-group-text">CURP:</span>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        aria-label=""
                        value={this.state.curp}
                        onChange={(e) =>
                          this.setState({ curp: e.target.value })
                        }
                      ></input>
                    </div>
                  </div>
                </div>
                <div className="white-space-16"></div>
                <div className="row mt-4">
                  <div className="col-md-12 text-center">
                    <h6>DATOS BANCARIOS</h6>
                  </div>
                </div>
                <div className="white-space-16"></div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <span className="input-group-text">BANCO:</span>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        aria-label=""
                        value={this.state.banco}
                        onChange={(e) =>
                          this.setState({ banco: e.target.value })
                        }
                      ></input>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <span className="input-group-text">NUM.CUENTA:</span>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        aria-label=""
                        value={this.state.numCuenta}
                        onChange={(e) =>
                          this.setState({ numCuenta: e.target.value })
                        }
                      ></input>
                    </div>
                  </div>
                </div>
                <div className="white-space-16"></div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <span className="input-group-text">CLABE:</span>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        aria-label=""
                        value={this.state.clabeInt}
                        onChange={(e) =>
                          this.setState({ clabeInt: e.target.value })
                        }
                      ></input>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <span className="input-group-text">A NOMBRE DE:</span>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        aria-label=""
                        value={this.state.NombreCuenta}
                        onChange={(e) =>
                          this.setState({ NombreCuenta: e.target.value })
                        }
                      ></input>
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-md-12 text-center">
                    <h6>DATOS DE CONTACTO</h6>
                  </div>
                </div>
                <div className="white-space-16"></div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <span className="input-group-text">CONTACTO:</span>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        aria-label=""
                        value={this.state.contacto}
                        id="contacto"
                        onChange={(e) =>
                          this.setState({ contacto: e.target.value })
                        }
                      ></input>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <span className="input-group-text">ID CLIENTE:</span>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        aria-label=""
                        value={this.state.idCliente}
                        onChange={(e) =>
                          this.setState({ idCliente: e.target.value })
                        }
                      ></input>
                    </div>
                  </div>
                </div>
                <div className="white-space-16"></div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <span className="input-group-text">TELEFONO 1:</span>
                      </div>
                      <input
                        type="number"
                        className="form-control"
                        aria-label=""
                        value={this.state.tel1}
                        id="tel1"
                        onChange={(e) =>
                          this.setState({ tel1: e.target.value })
                        }
                      ></input>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <span className="input-group-text">TELEFONO 2:</span>
                      </div>
                      <input
                        type="number"
                        className="form-control"
                        aria-label=""
                        value={this.state.tel2}
                        onChange={(e) =>
                          this.setState({ tel2: e.target.value })
                        }
                      ></input>
                    </div>
                  </div>
                </div>
                <div className="white-space-16"></div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <span className="input-group-text">CORREO 1:</span>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        aria-label=""
                        value={this.state.email1}
                        id="email1"
                        onChange={(e) =>
                          this.setState({ email1: e.target.value })
                        }
                      ></input>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <span className="input-group-text">CORREO 2:</span>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        aria-label=""
                        value={this.state.email2}
                        onChange={(e) =>
                          this.setState({ email2: e.target.value })
                        }
                      ></input>
                    </div>
                  </div>
                </div>
                <div className="white-space-16"></div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <span className="input-group-text">WEB:</span>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        aria-label=""
                        value={this.state.web}
                        onChange={(e) => this.setState({ web: e.target.value })}
                      ></input>
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-md-12 text-center">
                    <h6>DOMICILIO</h6>
                  </div>
                </div>
                <div className="white-space-16"></div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <span className="input-group-text">CODIGO POSTAL:</span>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        aria-label=""
                        value={this.state.cp}
                        onChange={(e) => this.setState({ cp: e.target.value })}
                      ></input>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <span className="input-group-text">ESTADO:</span>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        aria-label=""
                        value={this.state.estado}
                        onChange={(e) =>
                          this.setState({ estado: e.target.value })
                        }
                      ></input>
                    </div>
                  </div>
                </div>
                <div className="white-space-16"></div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <span className="input-group-text">MUNICIPIO:</span>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        aria-label=""
                        value={this.state.municipio}
                        onChange={(e) =>
                          this.setState({ municipio: e.target.value })
                        }
                      ></input>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <span className="input-group-text">CIUDAD:</span>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        aria-label=""
                        value={this.state.ciudad}
                        onChange={(e) =>
                          this.setState({ ciudad: e.target.value })
                        }
                      ></input>
                    </div>
                  </div>
                </div>
                <div className="white-space-16"></div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <span className="input-group-text">COLONIA:</span>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        aria-label=""
                        value={this.state.colonia}
                        onChange={(e) =>
                          this.setState({ colonia: e.target.value })
                        }
                      ></input>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <span className="input-group-text">CALLE:</span>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        aria-label=""
                        value={this.state.calle}
                        onChange={(e) =>
                          this.setState({ calle: e.target.value })
                        }
                      ></input>
                    </div>
                  </div>
                </div>
                <div className="white-space-16"></div>
                <div className="row">
                  <div className="col-md-4">
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <span className="input-group-text">NUM.EXTERIOR:</span>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        aria-label=""
                        value={this.state.nExt}
                        onChange={(e) =>
                          this.setState({ nExt: e.target.value })
                        }
                      ></input>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <span className="input-group-text">NUM.INTERIOR:</span>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        aria-label=""
                        value={this.state.nInt}
                        onChange={(e) =>
                          this.setState({ nInt: e.target.value })
                        }
                      ></input>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <span className="input-group-text">PAIS:</span>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        aria-label=""
                        value={this.state.pais}
                        onChange={(e) =>
                          this.setState({ pais: e.target.value })
                        }
                      ></input>
                    </div>
                  </div>
                </div>
                <div className="white-space-32"></div>
                <div className="modal-footer justify-content-left ">
                  <button
                    type="button"
                    className="btn btn-outline-success btn-flat "
                    onClick={this.handleSubmit.bind(this)}
                  >
                    <i className="far fa-save"></i>&nbsp; Guardar
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-danger btn-flat"
                    onClick={this.openModal.bind(this)}
                  >
                    <i className="fas fa-ban"></i> Cerrar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  }

  componentDidMount() {
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }
    this.setState({ token: token });
    this.getUser();
  }

  async getProvider() {
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }
    let data = {
      token: token,
      idProv: this.props.idProv,
    };

    const response = await request.post(
      "/auth/purchases/getProviderInfo",
      data
    );

    if (response && !response.error) {
      if (response.result && !response.empty) {
        this.setState({
          id: response.result[0].id_prov,
          rfc: response.result[0].RFC,
          demComerc: response.result[0].den_com,
          razonS: response.result[0].razon,
          repLeg: response.result[0].representante,
          curp: response.result[0].CURP,
          banco: response.result[0].banco,
          numCuenta: response.result[0].cuenta,
          clabeInt: response.result[0].clabe,
          NombreCuenta: response.result[0].nombre_cuenta,
          contacto: response.result[0].vendedor,
          idCliente: response.result[0].id_cliente,
          tel1: response.result[0].tel1,
          tel2: response.result[0].tel2,
          email1: response.result[0].correo,
          email2: response.result[0].correo2,
          web: response.result[0].web,
          cp: response.result[0].cp,
          estado: response.result[0].estado,
          municipio: response.result[0].municipio,
          ciudad: response.result[0].ciudad,
          colonia: response.result[0].colonia,
          calle: response.result[0].calle,

          nExt: response.result[0].num_ext,
          nInt: response.result[0].num_int,
          pais: response.result[0].pais,
        });
      } else {
        this.setState({
          empty: true,
          loading: false,
          message: response.message,
        });
      }
    } else {
      this.setState({
        error: true,
        loading: false,
        message: response.message,
      });
    }
  }
  async getUser() {
    const user = await this.props.context.loadUser();

    if (user) {
      this.setState({
        idUsuario: user.id,
        idPerfil: user.id_perfil,
        nombre: user.name,
      });
    }
  }
  openModal() {
    this.setState({
      openModal: !this.state.openModal,
    });
    if (this.props.edit === true) {
      this.getProvider();
    }
  }
  async handleSubmit() {
    let data = {
      token: this.state.token,
      id: this.state.id,
      rfc: this.state.rfc,
      demComerc: this.state.demComerc,
      razonS: this.state.razonS,
      repLeg: this.state.repLeg,
      curp: this.state.curp,
      banco: this.state.banco,
      numCuenta: this.state.numCuenta,
      clabeInt: this.state.clabeInt,
      NombreCuenta: this.state.NombreCuenta,
      contacto: this.state.contacto,
      idCliente: this.state.idCliente,
      tel1: this.state.tel1,
      tel2: this.state.tel2,
      email1: this.state.email1,
      email2: this.state.email2,
      web: this.state.web,
      cp: this.state.cp,
      estado: this.state.estado,
      municipio: this.state.municipio,
      ciudad: this.state.ciudad,
      colonia: this.state.colonia,
      calle: this.state.calle,
      nExt: this.state.nExt,
      nInt: this.state.nInt,
      pais: this.state.pais,
      idUsuario: this.state.idUsuario,
    };
    let error = validator(data);
    if (error > 0) {
      alert("Asegurate de llenar correctamente los campos marcados con rojo");
    } else {
      const response = await request.post(
        "/auth/purchases/saveProviders",
        data
      );

      if (response && !response.error) {
        if (response.result && !response.empty) {
          this.props.reload();
          toast.success("Datos Guardados con exito!",
          {
            position: toast.POSITION.TOP_RIGHT,
            theme: "colored",
          });
        } else {
          toast.error(
            "UPSS!!!, algo salio mal.. intentalo de nuevo, si el problema continua contacta a sistemas.",
            {
              position: toast.POSITION.TOP_RIGHT,
              theme: "colored",
            }
          );
          console.log(response.result);
        }
      } else {
        console.log("error en la peticion");
      }
    }
  }
}
export default Consumer(NewProvider);
