import React, {useEffect, useState} from "react";
import Request from "../../../utils/http";
import MUIDataTable from "mui-datatables";
import ButtonSave from "../../general/buttonSave";
import moment from "moment";
import ButtonVerUsuarios from "./buttonVerUsuarios";
import validarPaseLista from "../validacion/validarPaseLista";


// let idAsistencia =[];
export default function FormVacation(props){

    const request = new Request();
    //hoocks---
    const [asistencia, setAsistencia]= useState({
        id_tipo:'',
        fecha:'',
        hora:''
    });
    const [listaAuxiliares, setListaAuxiliares] = useState([]);
    const [paseLista, setPaseLista] = useState([]);
    const [respuestaTerminado, setRespuesta] = useState([]);

    //nuestro token para todos
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
        token = token1.id;
    }

    useEffect(() => {
        AuxiliarAgregadoEvento();
        getAsistencia();
    }, []);

    const AuxiliarAgregadoEvento= async() => {
        let data = {
            token: token,
            id_evento:props.datos.id_evento
        };
        const response = await request.post("/horasExtras/AuxiliarAgregadoEvento", data);
        if (response && !response.error) {
            if (response.result.AuxiliarAgregadoEvento && !response.empty) {
                setListaAuxiliares(response.result.AuxiliarAgregadoEvento);
            } else {
                console.log(response.result.message);
            }
        } else {
            console.log("error en la peticion");
        }
    }

    const getAsistencia= async() => {
        let data = {
            token: token,
            id_evento:props.datos.id_evento,
        };
        const response = await request.post("/horasExtras/getAsistencia", data);
        if (response && !response.error) {
            if (response.result.asistencia && !response.empty) {
                setPaseLista(response.result.asistencia);
            } else {
                console.log(response.result.message);
            }
        } else {
            console.log("error en la peticion");
        }
    }
 
    const save = async () => {
        let datosAssintecia=validarPaseLista(asistencia); 
        let fechaAltual = new Date();
        //cambiar formato de fecha
        let fecha=moment(fechaAltual).format("YYYY-MM-DD");
        let horaActual = (new Date()).getHours()+':'+(new Date()).getMinutes()+':'+(new Date()).getSeconds(); 
        //obtener pase de lista por cada usuario y procede el guardado
        let arrUsers = [];
        let htmlUsers = document.getElementsByClassName('users');
        for (let input of htmlUsers){
            if (input.checked==true){
                let id = input.getAttribute('data-id');
                arrUsers.push(id);
            }
        }
        //verificamos si array no esta vacio
        if(arrUsers!='' && datosAssintecia!=1) {
            let data = {
                token: token,
                id_evento:props.datos.id_evento,
                datos:datosAssintecia,
                id_users:arrUsers,
                fecha:fecha,
                hora:horaActual

            };
            const response = await request.post("/horasExtras/saveAsistencia", data);
            
            if (response && !response.error) {
                if (response.result.ok && !response.empty) {
                        alert("asistencia guardado");
                        getAsistencia();

                        let docs=listaAuxiliares.slice()
                        console.log(docs);
                        for (let i = 0; i < docs.length; i++) {
                          docs[i].checked=false
                        }
                        
                    } else {
                      console.log(response.result.message);
                    }
            } else {
                    console.log("error en la peticion");
            }
            
        }else{
            alert("campo no rellenado");
        }
    }

    const terminarAsisntencia = async (id) => {   
        // idAsistencia.push(id);
        setRespuesta(id);
        var horaActual = (new Date()).getHours()+':'+(new Date()).getMinutes()+':'+(new Date()).getSeconds();
        let fechaAltual = new Date();

        
        let data = {
            token: token,
            id_asistencia:id,
            hora:horaActual,
            fecha: moment(fechaAltual).format("YYYY-MM-DD")

        };

        const response = await request.post("/horasExtras/TerminarAsistencia", data);
        if (response && !response.error) {
            if (response.result.ok && !response.empty) {
                
                getAsistencia();
            } else {
              console.log(response.result.message);
            }
        } else {
            console.log("error en la peticion");
        }
    }

    const EliminarAsistencia = async(id)=>{
        
        let data = {
            token: token,
            id_asistencia:id
        };

        const response = await request.post("/horasExtras/EliminarAsistencia", data);
        if (response && !response.error) {
            if (response.result.ok && !response.empty) {
                getAsistencia();
            } else {
              console.log(response.result.message);
            }
        } else {
            console.log("error en la peticion");
        }

    }
    
    const listaAux = async(status,id_document,posicion)=>{   
        //permite borrar los checkbox al guardar un pase de lista
        let listAux=listaAuxiliares.slice()
        listAux[posicion].checked=status
        console.log(listAux);
        setListaAuxiliares(listAux);
    }


    const columns = [
        {
            name: "type",
            label: "Tipo Asistencia",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    let tipo=value; 
                    return <p>{
                        tipo==1?'Inicio/Montaje':'Inicio/Desmontaje'  
                    }</p>
                },
            }
        },
        {
            name: "date",
            label: "Fecha Inicio",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    let fecha=moment(value).format("DD-MM-YYYY"); 
                    return <p>{fecha=="Invalid date"? '':`${fecha}`}</p>
                }
            }
        },
        {
            name: "hour",
            label: "Hora/inicio",
            options: {
                filter: true,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    let hora_Inicio=value;
                    return <p>{hora_Inicio==null? '':`${hora_Inicio}`}</p>
                }
            }
        },
        {
            name: "fin_date",
            label: "Fecha Fin",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    let fecha=moment(value).format("DD-MM-YYYY"); 
                    return <p>{fecha=="Invalid date"? '':`${fecha}`}</p>
                }
            }
        },
        {
            name: "fin_hour",
            label: "Hora/Fin",
            options: {
                filter: true,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    let hora_fin=value;
                    return <p>{hora_fin==null? '':`${hora_fin}`}</p>
                }
            }
        },

        {
            name: "",
            label: "Aciones",
            options: {
                filter: true,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    //console.log(employee[tableMeta.rowIndex].id);
                    return (
                        <div>
                            <ButtonVerUsuarios datos={paseLista[tableMeta.rowIndex].id}></ButtonVerUsuarios>
                            <div className="btn-group">
                                <button
                                
                                        className="btn btn-sm btn-success"
                                        title="Terminar"
                                        disabled={paseLista[tableMeta.rowIndex].fin_hour==null && paseLista[tableMeta.rowIndex].fin_date==null?false:true}
                                        onClick={()=>{
                                                if(window.confirm('Esta seguro deseas terminar?')){
                                                    terminarAsisntencia(paseLista[tableMeta.rowIndex].id);
                                                }
                                            }} >
                                        Terminar
                                </button>
                            </div>
                            <div className="btn-group">
                                <button
                                        className="btn btn-sm btn-danger m-1"
                                        title="Eliminar"
                                        disabled={paseLista[tableMeta.rowIndex].fin_hour==null && paseLista[tableMeta.rowIndex].fin_date==null?false:true}
                                        onClick={()=>{
                                                if(window.confirm('Esta seguro de Eliminar?')){
                                                    EliminarAsistencia(paseLista[tableMeta.rowIndex].id)
                                                    
                                                }
                                            }} >
                                        Eliminar
                                </button>
                            </div>
                        </div>
                    )
                }
            }
        },

    ];

    const options = {
        selectableRows: 'none',
        filter: false,
        viewColumns: false,
        responsive: "standard",
        textLabels: {
            body: {
                noMatch: "No se encontraron resultados.",
                toolTip: "Sort"
            },
            pagination: {
                next: "Siguiente",
                previous: "Anterior",
                rowsPerPage: "Número de filas:",
                displayRows: "de"
            },
    
            toolbar: {
                search: "Buscar",
                downloadCsv: "Descargar Excel",
                print: "Imprimir"
            },
    
            filter: {
                all: "TODOS",
                title: "FILTROS",
                reset: "LIMPIAR"
            },
            viewColumns: {
                title: "Mostar Columnas",
                titleAria: "Mostrar/Ocultar Columnas de la Tabla"
            },
            selectedRows: {
                text: "Fila(s) Seleccionadas",
                delete: "Eliminar",
                deleteAria: "Eliminar Filar Seleccionadas"
            }
    
        },
    };

    return(
        <div className="tab-content">
            <div className="row mt-2">
                <div className="col-md-8">
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Tipo Pase lista</span>
                        </div>
                        <select className="form-control" id="id_tipo"
                            name="id_tipo"
                            value={asistencia.id_tipo} 
                            onChange={e => setAsistencia({...asistencia,id_tipo:e.target.value})}
                            >
                            <option></option>
                            <option value="1">Inicio/Montaje</option>
                            <option value="2">Inicio/Desmontaje</option>
                        </select>
                    </div>
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-md-12">
                    <table className="table">
                        <thead>
                        <tr>
                            <th scope="col">Checked Pase Lista</th>
                            <th scope="col">Nombre</th>
                        </tr>
                        </thead>
                        <tbody>

                        {listaAuxiliares==''?
                            <tr>
                                <td>No hay Auxiliares Agregado</td>
                            </tr>
                        :
                            listaAuxiliares.map((val, key)=>(
                                <tr>
                                    <td>
                                        <input type="checkbox" 
                                        className="users"
                                        //  defaultChecked={val.checked}
                                        checked={val.checked}
                                        data-id={val.id_users}
                                            onClick={(e) => {
                                                listaAux(
                                                    e.target.checked,
                                                    val.id_users, key                             
                                                );
                                            }}
                                        />
                                    </td>
                                    <td>{val.name +' '+ val.last_name}</td>
                                </tr>
                            ))
                        }
                        </tbody>
                    </table>
                </div>
            </div>
            <ButtonSave
                save={save} 
                closeModal={props.closeModal}
            />
            <div className="row mt-3">
                <div className="col-md-12">
                    <MUIDataTable
                        data={paseLista}
                        columns={columns}
                        options={options}
                    />
                </div>
            </div>
        </div>
    );
}