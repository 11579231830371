import React, { Component } from "react";
import Icon from "@material-ui/core/Icon";
import "./modal.css";
import Modal from "react-modal";
import Request from "../../utils/http";
const request = new Request();
// Modal.setAppElement("#yourAppElement");
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)"
  }
};
class UserPermissions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      id: 0,
      modulos: [],
      listaMenus: [],
      save: false
    };
  }
  render() {
    return (
      <div>
        <Icon
          className="fas fa-id-card-alt"
          data-toggle="modal"
          data-target="#exampleModal"
          onClick={this.openModal.bind(this)}
        />
        <Modal
          isOpen={this.state.showModal}
          style={customStyles}
          contentLabel="Example Modal"
          overlayClassName="Overlay"
        >
          <div className="card">
            <div className="row">
              <div className="col">
                <h3>Permisos</h3>
              </div>
              <div className="col justify-end">
                <button
                  className="btn btn-icon btn-3 btn-default"
                  type="button"
                  onClick={this.handleClose.bind(this)}
                >
                  <i className="fas fa-times" />
                </button>
              </div>
            </div>
            <div
              className="card-body  "
              style={{ width: 35 + "rem", height: 37 + "rem" }}
            >
              <div className="row ">
                <div
                  className="col-md-6 justify-content-center"
                  style={{ backgroundColor: "#f1f1f1" }}
                >
                  <h4 style={{ marginLeft: 50 + "px" }}>Modulo</h4>
                </div>
                <div
                  className="col-md-6 justify-content-center"
                  style={{ backgroundColor: "#f1f1f1" }}
                >
                  <h4 style={{ marginLeft: 50 + "px" }}>Acceso</h4>
                </div>
              </div>
              <form onSubmit={this.handleSubmit.bind(this)}>
                {this.state.modulos.map((modulo, key) => (
                  <div className="row" key={key}>
                    <div className="col-md-6">
                      <h4>{modulo.modulo}</h4>
                    </div>
                    <div className="col-md-6 custom-control custom-checkbox mb-3">
                      <input
                        type="checkbox"
                        name="contratado"
                        id="contratado"
                        onChange={this.setOption.bind(
                          this,
                          "contratado",
                          modulo,
                          key
                        )}
                        checked={modulo.acceso ? true : false}
                      />
                    </div>
                  </div>
                ))}
                <div className="white-space-16"></div>

                {this.state.save ? (
                  <button type="button" disabled className="btn btn-danger">
                    <i className="fas fa-spinner  fa-spin"></i> Guardando....
                  </button>
                ) : (
                  <button type="submit" className="btn btn-danger">
                    Guardar cambios
                  </button>
                )}

                <button
                  type="button"
                  onClick={this.handleClose.bind(this)}
                  className="btn btn-default"
                >
                  cancelar
                </button>
                <div className="white-space-16"></div>
              </form>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
  componentDidMount() {
    Modal.setAppElement("body");
  }
  openModal() {
    this.setState({
      showModal: true,
      id: this.props.datos.id_usuario
    });
    this.getMenus();
  }
  handleClose() {
    this.setState({ showModal: false });
  }
  async getMenus() {
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }
    let data = {
      token: token,
      idUsuario: this.props.datos.id_usuario
    };
    const response = await request.post("/users/getPermissions", data);

    if (response && !response.error) {
      if (response.result.modulos && !response.empty) {
        this.setState({ modulos: response.result.modulos });
      } else {
        this.setState({
          empty: true,
          loading: false,
          message: response.message
        });
      }
    } else {
      this.setState({
        error: true,
        loading: false,
        message: response.message
      });
    }
  }
  setOption(option, modulo, key, event) {
    const perfil = this.state.modulos;

    if (option === "contratado") {
      if (event.target.checked === true) {
        perfil[key].acceso = 1;
      } else {
        perfil[key].acceso = 0;
      }
    }

    this.setState({ modulos: perfil });
    let valores = [];
    this.state.modulos.forEach(valor => {
      if (
        valor.acceso === null ||
        valor.acceso === false ||
        valor.acceso === 0
      ) {
        valores.push(`${valor.id_menu}:${0}`);
      } else {
        valores.push(`${valor.id_menu}:${1}`);
      }
    });

    this.setState({ listaMenus: valores.toString(), disable: false });
  }
  async handleSubmit(e) {
    e.preventDefault();
    this.setState({ save: true });
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }
    let data = {
      token: token,
      idUsuario: this.props.datos.id_usuario,
      datos: this.state.listaMenus
    };
    const response = await request.post("/users/setpermissions", data);
    console.log("response: ", response);
    if (response && !response.error) {
      if (response.result.ok && !response.empty) {
        this.setState({ showModal: false, save: false });
      } else {
        this.setState({
          empty: true,
          loading: false,
          message: response.message
        });
      }
    } else {
      this.setState({
        error: true,
        loading: false,
        message: response.message
      });
    }
    this.props.reload();
  }
}

export default UserPermissions;
