/**
 *  @name: header.jsx
 *  @version: 1.0.0
 *  @author: TAlexis Duarte
 *  @description: Componente Header
 */

import React, { Component } from "react";

import Request from "../../utils/http";
import { Consumer } from "../../context/index";
import SesionClose from "../login/sesionClose";
import SubModules from "./subModules";
import CheckVersion from "../general/checkVersion";
const request = new Request();

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menus: [],
      user: {
        id: 0,
        id_perfil: 0,
        name: "",
        ubicacion: 0,
        image: "",
      },
    };
  }
  render() {
    return (
      <aside className="main-sidebar main-sidebar-custom elevation-4 sidebar-no-expand sidebar-dark-info">
        <a
          href="#"
          className="brand-link elevation-4"
          style={{ opacity: ".8" }}
        >
          <img
            src={`${this.state.user.image}`}
            className="brand-image img-circle elevation-3"
            alt={this.state.user.name}
            onError={(e) => {
              e.target.src = "./assets/uploads/person.png";
            }}
          />
          <span className="brand-text font-weight-light">
            {this.state.user.name}
          </span>
        </a>

        <div className="sidebar">
          <CheckVersion />
          <nav className="mt-4">
            <ul
              className="nav nav-pills nav-sidebar flex-column nav-child-indent nav-flat nav-compact"
              role="menu"
              data-accordion="false"
            >
              {this.state.menus.map((menu, key) => (
                <SubModules menu={menu} key={key} />
              ))}
            </ul>
          </nav>
        </div>
        <div className="sidebar-custom">
          <ul
            className="nav nav-pills nav-sidebar flex-column"
            data-widget="treeview"
            role="menu"
            data-accordion="false"
          >
            <li className="nav-item">
              <SesionClose />
            </li>
          </ul>
          {/* <a href="#" className="btn btn-secondary hide-on-collapse pos-right">Help</a> */}
        </div>
      </aside>
    );
  }
  componentDidMount() {
    this.load();
  }
  loadMenus(idUsuario) {
    let menus = JSON.parse(window.sessionStorage.getItem("Menus"));

    if (menus) {
      if (menus.length !== 0) {
        this.setState({ menus: menus });
      } else {
        this.getMenus(idUsuario);
      }
    } else {
      this.getMenus(idUsuario);
    }
  }

  async getMenus(idUsuario) {
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }
    let data = {
      user: idUsuario,
      token: token,
    };

    const response = await request.post("/auth/menu/modules", data);
    console.log("🚀 ~ file: sidebar.jsx ~ line 108 ~ Sidebar ~ getMenus ~ response", response)

    if (response && !response.error) {
      if (response.result.modulos && !response.empty) {
        this.setState({ menus: response.result.modulos });
        window.sessionStorage.setItem(
          "Menus",
          JSON.stringify(response.result.modulos)
        );
      } else {
        this.setState({
          empty: true,
          loading: false,
          message: response.message,
        });
      }
    } else {
      this.setState({
        error: true,
        loading: false,
        message: response.message,
      });
    }
  }

  async load() {
    const user = await this.props.context.loadUser();

    if (user) {
      this.setState({user:user});
      this.loadMenus(user.id);
    }
  }
}
export default Consumer(Sidebar);
