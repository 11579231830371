
let validator = data => {

    let error = 1;

    if (data.fechaInicio.length === 0 || data.fechaInicio === "") {
      document.getElementById("fechaInicio").classList.add("is-invalid");
      return error;
    }
    if (data.fechafin.length === 0 || data.fechafin === "") {
      document.getElementById("fechafin").classList.add("is-invalid");
      return error;
    }
    if (data.estado.length === 0 || data.estado === "") {
      document.getElementById("estado").classList.add("is-invalid");
      return error;
    }

      return data;
}

export default validator;
  