import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import DeleteIcon from "@material-ui/icons/Delete";
import LoopIcon from "@material-ui/icons/Loop";
import Request from "../../../utils/http";
import { toast } from "react-toastify";
const request = new Request();

function ActivateMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const activar = () => {
    sendActivar(1);
  };
  const desactivar = () => {
    sendActivar(0);
  };

  const reload = (data) => {
    props.reload(data);
    setAnchorEl(null);
  };

  const sendActivar = async dato => {
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }
    let data = {
      idArt: props.value,
      activo: dato,
      token: token
    };
    const response = await request.post("/auth/inventory/statusArticleTemp", data);
    if (response && !response.error) {
      if (response.result && !response.empty) {
        toast.success(
          <span>
             Se{" "}
            {data.activo === 0 ? "desactivo" : "activo"} el artículo con id:"
            {data.idArt}" correctamente.
          </span>
        );
        reload(response.result[0]);
      } else {
        toast.error(
          <span>
            "UPSS!!!, algo salio
            mal.. intentalo de nuevo, si el problema continua contacta a
            sistemas."
          </span>
        );
      }
    } else {
      toast.error(
        <span>
          "UPSS!!!, algo salio
          mal.. intentalo de nuevo, si el problema continua contacta a
          sistemas."
        </span>
      );
    }
  };

  return (
    <>
      <button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        className="btn btn-info bg-gradient-info"
        size="small"
      >
        <i className="fas fa-sort-down"></i>
      </button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={desactivar}>
          <DeleteIcon style={{ color: "#b71c1c" }} />
          Desactivar
        </MenuItem>
        <MenuItem onClick={activar}>
          <LoopIcon style={{ color: "#4caf50" }} />
          Activar
        </MenuItem>
      </Menu>
    </>
  );
}
export default ActivateMenu;