import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import Request from "../../utils/http";
import moment from "moment";
import {Consumer} from "../../context/index";

function TablaBajasEmpleado(props) {

    const request = new Request();
    const [employee, setEmployee] = useState([]);

    //obteneos token
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
        token = token1.id;
    }
    useEffect(() => {
        getUser();
    }, []);

    //con este metodo nos permite ver usuario que inicio session
    const getUser = async () => {
        const user = await props.context.loadUser();
        //nos permite enviar datos quien inicio sesion
        if(user.id!=null && user.id_perfil!=null && user.ubicacion!=null){
            getEmpleados(user.id,user.id_perfil,user.ubicacion);
        }
    }

    const getEmpleados = async (id_users,perfil,ubicacion) => {

        let data = {
            token: token,
            baja:1,
            id_users:id_users,
            perfil:perfil,
            ubicacion:ubicacion
        }
        const response = await request.post("/employee/getEmployee", data);
        //validar los datos que recibimos
        if (response && !response.error) {
            if (response.result.empleados && !response.empty) {
                setEmployee(response.result.empleados);
            } else {
                console.log(response.result.message);
            }
        } else {
            console.log("error en la peticion");
        }
    }

    const columns = [
        {
            name: "id",
            label: "Num-Emp",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "avatar",
            label: "Foto",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    let foto=employee[tableMeta.rowIndex].avatar;   
                    return <img  id="foto" src={foto=='' ? `./assets/uploads/empleados/no-image.jpg`:`./assets/uploads/empleados/${foto}`}  
                    alt=""/>
                }
            }
        },
        {
            name: "name",
            label: "Nombre",
            options: {
                filter: true,
                sort: true,
            }
        },

        {
            name: "last_name",
            label: "Apellido",
            options: {
                filter: true,
                sort: false,
            }
        },
        {
            name: "motivo",
            label: "Motivo de baja",
            options: {
                filter: true,
                sort: false,

            }
        },
        {
            name: "date_baja",
            label: "Fecha de baja",
            options: {
                filter: true,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    let fecha_baja=moment(value).format("DD-MM-YYYY"); 
                    return <p>{fecha_baja=="Invalid date"? '':`${fecha_baja}`}</p>
                }
            }
        },
        {
            name: "date_hire",
            label: "Fecha Ingreso",
            options: {
                filter: true,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    let fecha=moment(value).format("DD-MM-YYYY"); 
                    return <p>{fecha=="Invalid date"? '':`${fecha}`}</p>
                }
            }
        },

        {
            name: "lugar",
            label: "Ubicación",
            options: {
                filter: true,
                sort: false,
            }
        },

        {
            name: "sucursal",
            label: "Sucursal",
            options: {
                filter: true,
                sort: false,
            }
        },

        {
            name :"salary_mensual",
            label: "Salario Mensual",
            options: {
                filter: true,
                sort: false,
            }
        },
    ];

    const options = {
        selectableRows: 'none',
        filter: false,
        viewColumns: false,
        download:false,
        print:false,
        responsive: "standard",
        textLabels: {
            body: {
                noMatch: "No se encontraron resultados.",
                toolTip: "Sort"
            },
            pagination: {
                next: "Siguiente",
                previous: "Anterior",
                rowsPerPage: "Número de filas:",
                displayRows: "de"
            },

            toolbar: {
                search: "Buscar",

            },

            filter: {
                all: "TODOS",
                title: "FILTROS",
                reset: "LIMPIAR"
            },
            viewColumns: {
                title: "Mostar Columnas",
                titleAria: "Mostrar/Ocultar Columnas de la Tabla"
            },
        }

    };

    return (
        <MUIDataTable
            title={"Bajas Empleados"}
            data={employee}
            columns={columns}
            options={options}
        />
    );

}
export default Consumer(TablaBajasEmpleado);
