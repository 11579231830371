import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Select from "react-select";
import { Consumer } from "../../context/index";
import Request from "../../utils/http";
import MUIDataTable from "mui-datatables";
import moment from "moment";
import NewProvider from "./newProvider";
import AddProducts from "./addProducts";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const request = new Request();
const customStyles = {
  content: {
    top: "50%",
    left: "80%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    overflow: "scroll",
  },
};
const customStylesSelect = {
  control: (styles) => {
    return { ...styles, marginTop: -7 + "px", borderRadius: 0 };
  },
};
function ModalRequisition(props) {
  const [user, setUser] = useState({
    idUsuario: 0,
    idPerfil: 0,
    nombre: "",
    depto: "",
    ubicacion: 1,
  });
  const [modal, setModal] = useState(false);
  const [providers, setProviders] = useState([]);
  const [priorityList, setPriorityList] = useState([]);
  const [currencyList, setCurrencyList] = useState([]);
  const [paymentList, setPaymentList] = useState([]);
  const [reference, setReference] = useState([]);
  const [location, setLocation] = useState([]);
  const [infoHeader, setHeader] = useState({
    idReq: 0,
    priority: 2,
    us_nombre: "",
    fSolicita: moment().format("YYYY-MM-DD"),
    fEntrega: moment().format("YYYY-MM-DD"),
    observacion_requi: "",
    provider: null,
    tipo_pago: 0,
    id_ubicacion: 1,
  });
  const [infoProd, setProducts] = useState([]);
  const [moneda, setMoneda] = useState("MXN");
  const [totales, setTotales] = useState({
    subtotal: 0,
    desc: 0,
    iva: 0,
    total: 0,
  });
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    loadUser();
    getProvider(0);
    getPrioridadF();
    getMoney();
    tipoPago();
    getReference();
    getLocations();
    if (props.eddit) {
      getHeader();
    }
  }, []);
  // const closeModal = () => {
  //     openModal();
  // }
  const loadUser = async () => {
    const user = await props.context.loadUser();

    if (user) {
      setUser(user);
      let info = infoHeader;
      info.us_nombre = user.name;
      info.depto = user.depto;
    }
  };
  const obtenerToken = () => {
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }
    return token;
  };
  const getProvider = async (e) => {
    let token = obtenerToken();
    let data = {
      token: token,
    };
    const response = await request.post("/auth/purchases/getProviders", data);
    //console.log("getProvider->response :>> ", response);
    if (response && !response.error) {
      if (response.result && !response.empty) {
        let prov = [];
        response.result.map((info, key) =>
          prov.push({
            value: info.id_prov,
            label: info.den_com + " - " + info.razon,
          })
        );
        setProviders(prov);
        if (e !== 0) {
          setHeader({ ...infoHeader, provider: prov[prov.length - 1] });
        }
      } else {
        //console.log(response.result.message);
      }
    } else {
      //console.log(response.result);
    }
  };
  //prioridad
  const getPrioridadF = async () => {
    let token = obtenerToken();
    let data = {
      token: token,
    };
    const response = await request.post("/auth/purchases/getPrioridad", data);
    if (response && !response.error) {
      if (response.result && !response.empty) {
        setPriorityList(response.result);
      } else {
        //console.log(response.result.message);
      }
    } else {
      //console.log(response.result);
    }
  };
  //moneda
  const getMoney = async () => {
    let token = obtenerToken();
    let data = {
      token: token,
    };
    const response = await request.post("/auth/purchases/getMoneda", data);

    if (response && !response.error) {
      if (response.result && !response.empty) {
        setCurrencyList(response.result);
      } else {
        //console.log(response.result.message);
      }
    } else {
      //console.log(response.result);
    }
  };
  //pago
  const tipoPago = async () => {
    let token = obtenerToken();
    let data = {
      token: token,
    };
    const response = await request.post("/auth/purchases/getTipoPago", data);
    //console.log("tipoPagop->responce :>> ", response);
    if (response && !response.error) {
      if (response.result && !response.empty) {
        setPaymentList(response.result);
      } else {
        //console.log(response.result.message);
      }
    } else {
      //console.log(response.result);
    }
  };
  const getHeader = async () => {
    let token = obtenerToken();
    let data = {
      token: token,
      idReq: props.idReq,
    };
    const response = await request.post(
      "/auth/purchases/getRequisitionHead",
      data
    );
    //console.log("getHeader->response :>> ", response);
    if (response && !response.error) {
      if (response.result && !response.empty) {
        let info = response.result[0];
        info.provider = {
          label: info.prov_nombre + "-" + info.razon_social,
          value: info.id_prov,
        };
        setHeader(info);
        getProducts();
      } else {
        //console.log(response.result.message);
      }
    } else {
      //console.log(response.result);
    }
  };
  const getProducts = async () => {
    let token = obtenerToken();
    let data = {
      token: token,
      idReq: props.idReq,
    };
    const response = await request.post(
      "/auth/purchases/getRequisitionProd",
      data
    );
    console.log("getProd->response :>> ", response);
    if (response && !response.error) {
      if (response.result && !response.empty) {
        let P = response.result;
        let sub = 0;
        let desc = 0;
        let iva = 0;
        let total = 0;
        if (P.length !== 0) {
          setMoneda(P[0].moneda);
          for (let i = 0; i < P.length; i++) {
            let importe = P[i].precio_uni * P[i].cantidad_producto;
            let t_desc = 0;
            let t_iva = P[i].iva / 100;
            sub = sub + importe;
            if (P[i].t_desc === 0) {
              t_desc =
                P[i].precio_uni *
                P[i].cantidad_producto *
                (P[i].descuento / 100);
            } else {
              t_desc = P[i].descuento;
            }
            desc = desc + t_desc;
            iva = iva + (importe - t_desc) * t_iva;

            P[i].importe = importe;
            P[i].r_desc = t_desc;
            P[i].t_iva = (importe - t_desc) * t_iva;
          }
          total = sub - desc + iva;
          setProducts(P);
          setTotales({
            subtotal: sub,
            desc: desc,
            iva: iva,
            total: total,
          });
        }
      } else {
        //console.log(response.result.message);
      }
    } else {
      //console.log(response.result);
    }
  };
  const getReference = async () => {
    let token = obtenerToken();
    let data = {
      token: token,
    };
    const response = await request.post(
      "/auth/purchases/getReferenceReqProd",
      data
    );

    if (response && !response.error) {
      if (response.result && !response.empty) {
        setReference(response.result);
      } else {
        //console.log(response.result.message);
      }
    } else {
      //console.log(response.result);
    }
  };
  const getLocations = async () => {
    let token = obtenerToken();
    let data = {
      token: token,
    };

    const response = await request.post("/auth/sales/getubicaciones", data);

    if (response && !response.error) {
      if (response.result && !response.empty) {
        setLocation(response.result.ubicaciones);
      } else {
        // this.setState({
        //   empty: true,
        //   loading: false,
        //   message: response.message
        // });
      }
    } else {
      // this.setState({
      //   error: true,
      //   loading: false,
      //   message: response.message
      // });
    }
  };
  const addRow = (e) => {
    // console.log(e);

    let pList = infoProd;
    let i = pList.length;
    let newP = {
      id_lista: 0,
      id_requisicion: infoHeader.idReq,
      id_producto: e[1],
      nombre: e[2],
      caracteristicas: e[3],
      cod_alt: e[4],
      referencia: "",
      cantidad_producto: 1,
      unidad: e[5],
      precio_uni: 0,
      moneda: moneda,
      iva: 0,
      descuento: 0,
      t_desc: 0,
      ref: 0,
    };
    setProducts([...infoProd, newP]);
    console.log(infoProd);
    toast.success(
      <span>
        
        {newP.id_producto + "-" + newP.nombre} agregado con exito.
      </span>,
      {
        position: toast.POSITION.TOP_RIGHT,
        theme: "colored",
      }
    );
  };
  const deleteRow = async (indice) => {
    let copia = infoProd.slice();
    let borrar = window.confirm(
      `Estas seguro de eliminar el articulo: ${copia[indice].nombre}  de la requicisión?. No podra deshacer esta acción.`
    );
    if (borrar) {
      if (copia[indice].id_lista === 0) {
        copia.splice(indice, 1);
        setProducts(copia);
      } else {
        let token = obtenerToken();
        let data = {
          token: token,
          id_lista: copia[indice].id_lista,
        };

        const response = await request.post(
          "/auth/purchases/deleteRequisitionProd",
          data
        );
        console.log("deleteRow->response :>> ", response);
        if (response && !response.error) {
          if (response.result && !response.empty) {
            copia.splice(indice, 1);
            setProducts(copia);
          }
        }
      }
    }
  };
  const changeData = (posicion, name, valor) => {
    switch (name) {
      case "cantidad_producto":
      case "precio_uni":
      case "descuento":
        infoProd[posicion][name] = parseFloat(valor);
        break;
      default:
        infoProd[posicion][name] = valor;
        break;
    }
    //events[i]=evento;
    setProducts([...infoProd]);

    //setProductos(copia);

    console.log(posicion, name, valor);
  };
  const saveHeader = async () => {
    let token = obtenerToken();
    let data = {
      token: token,
      idReq: props.idReq,
      idUser: user.id,
      info: infoHeader,
    };
    // alert('guardar encabezado')
    const response = await request.post(
      "/auth/purchases/saveRequisitionHeader",
      data
    );
    //console.log("saveHeader -> response", response);

    if (response && !response.error) {
      if (response.result[0] && !response.empty) {
        if (props.idReq === 0) {
          props.setIdReq(response.result[0].idReq);
          props.setEddit(true);
        }
        saveProds(response.result[0].idReq);
      } else {
        //console.log(response.result.message);

        toast.error(
          "UPSS!!!, algo salio mal.. intentalo de nuevo, si el problema continua contacta a sistemas.",
          {
            position: toast.POSITION.TOP_RIGHT,
            theme: "colored",
          }
        );
      }
    } else {
      //console.log(response.result);

      toast.error(
        "UPSS!!!, algo salio mal.. intentalo de nuevo, si el problema continua contacta a sistemas."
      );
    }
  };
  const saveProds = async (id) => {
    let copia = infoProd.slice();
    if (copia.length != 0) {
      for (let i = 0; i < copia.length; i++) {
        copia[i].id_requisicion = id;
        copia[i].moneda = moneda;
      }

      let token = obtenerToken();
      let data = {
        token: token,
        info: copia,
      };
      // alert('guardar encabezado')
      const response = await request.post(
        "/auth/purchases/saveRequisitionProds",
        data
      );
      console.log("saveProds -> response", response);

      if (response && !response.error) {
        if (response.result && !response.empty) {
          toast.success("Requisición guardada con exito!", {
            position: toast.POSITION.TOP_RIGHT,
            theme: "colored",
          });
          getProducts();
        } else {
          //console.log(response.result.message);

          toast.error(
            "UPSS!!!, algo salio mal.. intentalo de nuevo, si el problema continua contacta a sistemas.",
            {
              position: toast.POSITION.TOP_RIGHT,
              theme: "colored",
            }
          );
        }
      } else {
        //console.log(response.result);

        toast.error(
          "UPSS!!!, algo salio mal.. intentalo de nuevo, si el problema continua contacta a sistemas.",
          {
            position: toast.POSITION.TOP_RIGHT,
            theme: "colored",
          }
        );
      }
    }
  };
  const columns = [
    {
      name: "id_lista",
      label: "ACCIÓN",
      options: {
        filter: false,
        sort: false,
        searchable: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <button
              className="btn btn-danger bg-gradient-danger"
              onClick={(e) => deleteRow(tableMeta.rowIndex)}
            >
              <i className="fas fa-trash-alt"></i>
            </button>
          );
        },
      },
    },
    {
      name: "id_producto",
      label: "ID",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "nombre",
      label: "NOMBRE",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "caracteristicas",
      label: "DETALLES",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "referencia",
      label: "REFERENCIA",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          let ref = tableMeta.rowData[11];

          return (
            <>
              <select
                name="ref"
                className="form-control"
                value={ref}
                onChange={(e) =>
                  changeData(tableMeta.rowIndex, e.target.name, e.target.value)
                }
              >
                <option></option>
                {reference.map((key, option) => (
                  <option key={option} value={reference[option].id_ref}>
                    {reference[option].refer}
                  </option>
                ))}
              </select>
              <br />
              <input
                type="text"
                name="referencia"
                className="form-control"
                value={value}
                onChange={(e) =>
                  changeData(tableMeta.rowIndex, e.target.name, e.target.value)
                }
              />
            </>
          );
        },
      },
    },
    {
      name: "unidad",
      label: "UNIDAD",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "cantidad_producto",
      label: "CANTIDAD",
      options: {
        filter: false,
        sort: false,
        searchable: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <input
              className="form-control"
              type="number"
              step="any"
              name="cantidad_producto"
              min={1}
              defaultValue={value}
              onChange={(e) =>
                changeData(tableMeta.rowIndex, e.target.name, e.target.value)
              }
            />
          );
        },
      },
    },
    {
      name: "precio_uni",
      label: "PRECIO_UNI",
      options: {
        filter: false,
        sort: false,
        searchable: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <input
              className="form-control"
              type="number"
              name="precio_uni"
              step="any"
              min={1}
              defaultValue={value}
              onChange={(e) =>
                changeData(tableMeta.rowIndex, e.target.name, e.target.value)
              }
            />
          );
        },
      },
    },
    {
      name: "iva",
      label: "IVA",
      options: {
        filter: false,
        sort: false,
        searchable: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <select
              name="iva"
              className="form-control"
              defaultValue={value}
              onChange={(e) =>
                changeData(tableMeta.rowIndex, e.target.name, e.target.value)
              }
              style={{ minWidth: "97px" }}
            >
              <option value="16">16%</option>
              <option value="0">Sin IVA</option>
            </select>
          );
        },
      },
    },
    {
      name: "descuento",
      label: "DESCUENTO",
      options: {
        filter: false,
        sort: false,
        searchable: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          //   console.log(tableMeta);
          return (
            <div className="input-group">
              <input
                type="text"
                name="descuento"
                className="form-control"
                min="0"
                step="any"
                placeholder="00.00"
                defaultValue={value}
                onChange={(e) =>
                  changeData(tableMeta.rowIndex, e.target.name, e.target.value)
                }
              />
              <div className="input-group-append">
                <select
                  name="t_desc"
                  className="form-control"
                  value={tableMeta.rowData[10]}
                  //defaultValue={tableMeta.rowData[10]}
                  onChange={(e) =>
                    changeData(
                      tableMeta.rowIndex,
                      e.target.name,
                      e.target.value
                    )
                  }
                >
                  <option value="0">%</option>
                  <option value="1">$</option>
                </select>
              </div>
            </div>
          );
        },
      },
    },
    {
      name: "t_desc",
      label: "t_desc",
      options: {
        filter: false,
        sort: false,
        searchable: false,
        display: false,
      },
    },
    {
      name: "ref",
      label: "ID_REF",
      options: {
        filter: false,
        sort: false,
        searchable: false,
        display: false,
      },
    },
    {
      name: "evento",
      label: "EVENT",
      options: {
        filter: false,
        sort: false,
        searchable: false,
        display: false,
      },
    },
  ];

  const options = {
    selectableRows: "none",
    responsive: "standard",
    pagination: false,
    search: false,
    print: false,
    download: false,
    filter: false,
    viewColumns: false,
    textLabels: {
      body: {
        noMatch: "No se encontraron resultados.",
        toolTip: "Sort",
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Número de filas:",
        displayRows: "de",
      },
      toolbar: {
        search: "Buscar",
        downloadCsv: "Descargar Excel",
        print: "Imprimir",
      },
      filter: {
        all: "TODOS",
        title: "FILTROS",
        reset: "LIMPIAR",
      },
      viewColumns: {
        title: "Mostar Columnas",
        titleAria: "Mostrar/Ocultar Columnas de la Tabla",
      },
      selectedRows: {
        text: "Fila(s) Seleccionadas",
        delete: "Eliminar",
        deleteAria: "Eliminar Filar Seleccionadas",
      },
    },
    customToolbar: () => {
      return infoHeader.provider !== null ? (
        <AddProducts idProv={infoHeader.provider} addRow={(e) => addRow(e)} />
      ) : (
        false
      );
    },
  };

  const FormatNumber = (number) => {
    let val = new Intl.NumberFormat("ES-MX", {
      style: "currency",
      currency: "MXN",
      useGrouping: true,
    }).format(number);
    if (val.length === 8) {
      val = val.substring(0, 2) + "," + val.substring(2, 8);
    }
    return val;
  };
  return (
    <div
      className="tab-content"
      id="custom-tabs-three-tabContent"
      style={{ zoom: "95%" }}
    >
      <div className="row">
        <div className="col-md-2">
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text text-sm">ID:</span>
            </div>
            <input
              type="text"
              name="idReq"
              className="form-control"
              aria-label=""
              value={props.idReq}
              disabled={true}
            />
          </div>
        </div>
        <div className="col-md-3">
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text text-sm">FECHA SOLICITUD:</span>
            </div>
            <input
              type="date"
              name="fSolicitud"
              className="form-control"
              aria-label=""
              value={moment(infoHeader.fSolicita).format("YYYY-MM-DD")}
              disabled={true}
            />
          </div>
        </div>
        <div className="col-md-3">
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text text-sm">FECHA ENTREGA:</span>
            </div>
            <input
              type="date"
              name="fEntrega"
              className="form-control"
              aria-label=""
              disabled={props.openModalV}
              value={moment(infoHeader.fEntrega).format("YYYY-MM-DD")}
              onChange={(e) =>
                setHeader({ ...infoHeader, fEntrega: e.target.value })
              }
            />
          </div>
        </div>
        <div className="col-md-2">
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text text-sm">PRIORIDAD:</span>
            </div>
            {props.openModalV ? (
              <span className="form-control">
                <i
                  className="fas fa-bookmark"
                  style={{ color: infoHeader.color_priority }}
                ></i>
                &nbsp;{infoHeader.nom_priority}
              </span>
            ) : (
              <select
                className="form-control"
                name="priority"
                value={infoHeader.priority}
                onChange={(e) =>
                  setHeader({
                    ...infoHeader,
                    priority: parseInt(e.target.value),
                  })
                }
              >
                {priorityList.map((prioridad, key) => (
                  <option
                    key={key}
                    value={prioridad.id_p}
                    style={{ color: prioridad.color }}
                  >
                    {prioridad.descrip}
                  </option>
                ))}
              </select>
            )}
          </div>
        </div>
        <div className="col-md-2">
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text text-sm">LUGAR:</span>
            </div>
            <select
              className="form-control"
              name="location"
              value={infoHeader.id_ubicacion}
              disabled={props.openModalV}
              onChange={(e) =>
                setHeader({
                  ...infoHeader,
                  id_ubicacion: parseInt(e.target.value),
                })
              }
            >
              {location.map((lugar, key) => (
                <option key={key} value={lugar.id_ubicacion}>
                  {lugar.lugar}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-5">
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text text-sm">SOLICITADO POR:</span>
            </div>
            <input
              type="text"
              name="solicita"
              className="form-control"
              aria-label=""
              value={infoHeader.us_nombre}
              disabled={true}
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text text-sm">DEPTO. SOLICITA:</span>
            </div>
            <input
              type="text"
              name="depto"
              className="form-control"
              aria-label=""
              defaultValue={infoHeader.depto}
              disabled={true}
            ></input>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-5">
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text text-sm">PROVEEDOR:</span>
            </div>
            {props.eddit && infoHeader.provider !== null ? (
              <span className="form-control text-sm">
                {infoHeader.provider.label}
              </span>
            ) : (
              <>
                <Select
                  className="pl-0 form-control"
                  name="provider"
                  id="provider"
                  options={providers}
                  value={infoHeader.provider}
                  styles={customStylesSelect}
                  onChange={(value) =>
                    setHeader({ ...infoHeader, provider: value })
                  }
                />
                <div className="input-group-append">
                  <NewProvider
                    reload={(e) => getProvider(1)}
                    edit={false}
                    idProv={0}
                  />
                </div>
              </>
            )}
          </div>
        </div>
        <div className="col-md-3">
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text text-sm">MONEDA:</span>
            </div>
            <select
              className="form-control"
              name="priority"
              value={moneda}
              onChange={(e) => setMoneda(e.target.value)}
              disabled={props.eddit}
            >
              <option></option>
              {currencyList.map((mon, key) => (
                <option key={key} value={mon.abr}>
                  {mon.descripcion}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="col-md-4">
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text text-sm">TIPO DE PAGO:</span>
            </div>
            {props.openModalV ? (
              <span className="form-control">
                {infoHeader.tipo_pago}-{infoHeader.nom_pago}
              </span>
            ) : (
              <select
                className="form-control"
                name="tipo_pago"
                value={infoHeader.tipo_pago}
                onChange={(e) =>
                  setHeader({
                    ...infoHeader,
                    tipo_pago: parseInt(e.target.value),
                  })
                }
              >
                <option></option>
                {paymentList.map((pago, key) => (
                  <option key={key} value={pago.id_tpago}>
                    {pago.id_tpago + "-" + pago.nombre}
                  </option>
                ))}
              </select>
            )}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          {props.openModalV ? (
            <div className="table-responsive">
              <table className="table table-bordered table-striped table-hover table-sm">
                <thead className="table-secondary">
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">CANTIDAD</th>
                    <th scope="col">UNIDAD</th>
                    <th scope="col">NOMBRE</th>
                    <th scope="col">REFERENCIA</th>
                    <th scope="col">PRECIO_UNI</th>
                    <th scope="col">IMPORTE</th>
                    <th scope="col">IVA</th>
                    <th scope="col">DESCUENTO</th>
                  </tr>
                </thead>
                <tbody>
                  {infoProd.map((prod, key) => (
                    <tr key={key}>
                      <td role="cell" scope="row">
                        {prod.id_producto}
                      </td>
                      <td role="cell" scope="row">
                        {prod.cantidad_producto}
                      </td>
                      <td role="cell" scope="row">
                        {prod.unidad}
                      </td>
                      <td role="cell" scope="row">
                        {prod.nombre}
                      </td>
                      <td role="cell" scope="row">
                        {prod.referencia}
                      </td>
                      <td role="cell" scope="row">
                        {FormatNumber(prod.precio_uni)}
                      </td>
                      <td role="cell" scope="row">
                        {FormatNumber(prod.importe)}
                      </td>
                      <td role="cell" scope="row">
                        {FormatNumber(prod.t_iva)}
                      </td>
                      <td role="cell" scope="row">
                        {FormatNumber(prod.r_desc)}
                      </td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <th className="text-right" colSpan="6">
                      SUBTOTAL {moneda}
                    </th>
                    <td>{FormatNumber(totales.subtotal)}</td>
                    <td colSpan="2"></td>
                  </tr>
                  <tr>
                    <th
                      className="text-right"
                      style={{ color: "red" }}
                      colSpan="6"
                    >
                      DESCUENTO {moneda}
                    </th>
                    <td style={{ color: "red" }}>
                      -{FormatNumber(totales.desc)}
                    </td>
                    <td colSpan="2"></td>
                  </tr>
                  <tr>
                    <th className="text-right" colSpan="6">
                      IVA 16% {moneda}
                    </th>
                    <td>{FormatNumber(totales.iva)}</td>
                    <td colSpan="2"></td>
                  </tr>
                  <tr>
                    <th className="text-right" colSpan="6">
                      TOTAL {moneda}
                    </th>
                    <td>{FormatNumber(totales.total)}</td>
                    <td colSpan="2"></td>
                  </tr>
                </tfoot>
              </table>
            </div>
          ) : (
            <MUIDataTable
              title={"PRODUCTOS"}
              data={infoProd}
              columns={columns}
              options={options}
            />
          )}
        </div>
      </div>
      <div className="white-space-16"></div>
      <div className="row">
        <div className="col-12">
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text">Comentarios:</span>
            </div>
            <textarea
              name="observacion_requi"
              className="form-control"
              defaultValue={infoHeader.observacion_requi}
              onChange={(e) =>
                setHeader({ ...infoHeader, observacion_requi: e.target.value })
              }
            ></textarea>
          </div>
        </div>
      </div>
      {/*<div>
        <Button variant="outlined" color="primary" onClick={handleClickOpen}>
          Open alert dialog
        </Button>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <i className="fas fa-exclamation-circle"></i>&nbsp;¿Estas seguro de
            eliminar el artículo?
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              ¿Estas seguro de eliminar el artículo de la requicisión?. No podra
              deshacer esta acción.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Eliminar
            </Button>
            <Button onClick={handleClose} color="primary" autoFocus>
              Cancelar
            </Button>
          </DialogActions>
            </Dialog>
      </div>*/}
      <div className="modal-footer justify-content-left ">
        {props.openModalV ? null : (
          <button
            type="button"
            className="btn btn-outline-success btn-flat"
            onClick={(e) => saveHeader()}
          >
            <i className="far fa-save"></i>&nbsp; Guardar
          </button>
        )}
        <button
          type="button"
          className="btn btn-outline-danger btn-flat"
          onClick={() => props.close()}
        >
          <i className="fas fa-ban"></i> Cerrar
        </button>
      </div>
    </div>
  );
}
export default Consumer(ModalRequisition);
