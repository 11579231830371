import React, { Component } from "react";
import Modal from "react-modal";
import DeleteIcon from '@material-ui/icons/Delete';
import FormElimnarEmpleado from "./formElimnarEmpleado";

Modal.setAppElement(document.getElementById("root"));

const customStyles = {
  content: {
    top: "50%",
    left: "52%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    overflow: "scroll"
  }
};
const iconofa = {
    content: {
      fontSize: '20px'
    }
};


class buttonActions extends Component {

  constructor(props) {
    super(props);
    this.state = {
      openModal: false,     
    };
    
  }
  closeModal() {
    this.openModal();

  }

  render() {
    return (
      <div className="btn-group">
        <button
          className="btn btn-sm btn-info bg-gradient-info mt-1 m-1"
          title="Eliminar"
          onClick={this.openModal.bind(this)}
        >
          <i className="fas fa-trash" style={iconofa.content}></i>
        </button>

          <Modal
            isOpen={this.state.openModal}
            style={customStyles.content}
          >
            <div className="modal-header">
              <h4 className="modal-title">
                Dar de Baja  Empleado
              </h4>
              <button
                type="button"
                className="close"
                onClick={this.openModal.bind(this)}
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body card-primary card-outline card-outline-tabs">
              <div className="card-body">
                <FormElimnarEmpleado  
                  datos={this.props.datos} 
                  recargar={this.props.updateEmpleado}
                  closeModal={this.closeModal.bind(this)}
                />
              </div>
            </div>
          </Modal>
        
      </div>
    );
  }


  openModal() {
    this.setState({
      openModal: !this.state.openModal
    });
  }

}
export default buttonActions;
