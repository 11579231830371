import React, { useEffect, useState } from "react";
import { Consumer } from "../../context/index";
import Request from "../../utils/http";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";

import moment from "moment";
import Modal from "react-modal";
import ModalRequisition from "./modalRequisition";
import Actions from "./actions";
import FilesList from "./filesList";

const request = new Request();

function PurchasesTable(props) {
  //set de valores
  const [compraR, setCompra] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [usuario, setUser] = useState({
    idUsuario: 0,
    idPerfil: 0,
    nombre: 0,
  });
  const [idReq, setIdReq] = useState(0);
  const [eddit, setEddit] = useState(false);
  const [valor, setValor] = useState(0);
  const [openModal, setModal] = useState(false);
  const [openModalV, setModalV] = useState(false);

  const [loadRows, setLoad] = useState(false);
  const [loadLocation, setLoadLocation] = useState(true);
  const [loadStatus, setLoadStatus] = useState(true);

  //Filtros
  const [filter, setFilter] = useState("");
  const [text, setText] = useState("");
  const [location, setLocation] = useState("");
  const [status, setStatus] = useState("");
  const [fechai, setFechai] = useState(
    `${moment(new Date()).format("YYYY-MM-")}01`
  );
  const [fechaf, setFechaf] = useState(
    `${moment(new Date()).format("YYYY-MM-")}${new Date(
      moment(new Date()).format("YYYY"),
      moment(new Date()).format("MM"),
      0
    ).getDate()}`
  );

  //obteneos token
  let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
  let token;
  if (token1) {
    token = token1.id;
  }
  const customStyles = {
    content: {
      position: "absolute",
      top: "15px",
      left: "15px",
      right: "15px",
      bottom: "15px",
      border: "1px solid rgb(204, 204, 204)",
      background: "rgb(255, 255, 255)",
      overflow: "auto",
      outline: "none",
      padding: "15px",
    },
  };
  useEffect(() => {
    getUser();
    getLocations();
    getStatus();
  }, []);

  const getUser = async () => {
    const user = await props.context.loadUser();

    if (user) {
      setUser(user);

      getRequisicion(user.id_perfil, user.id);
    }
  };

  const getRequisicion = async (perfil, id) => {
    let data = {
      token: token,
      idPerfil: perfil,
      id: id,
      bandi: fechai,
      bandf: fechaf,
    };
    const response = await request.post(
      "/auth/purchases/getRequisiciones",
      data
    );
    console.log("getReq->", data);
    //validar los datos que recibimos
    if (response && !response.error) {
      if (response.result && !response.empty) {
        //let r = response.result.list;
        setLoad(false);
        /*for (let i = 0; i < r.length; i++) {
          r[i].id_lista = i;
          r[i].checked = false;
        }*/
        setCompra(response.result);
      } else {
        console.log(response.result.message);
      }
    } else {
      console.log("error en la peticion");
    }
  };
  const searchRequisicion = async () => {
    setLoad(true);

    let data = {
      token: token,
      idPerfil: usuario.idPerfil,
      id: usuario.idUsuario,
      bandi: fechai,
      bandf: fechaf, //moment(fecha).format("YYYY-MM-DD"),
      filtro: filter,
      texto: text,
      ubicacion: location,
      status: status,
    };
    const response = await request.post(
      "/auth/purchases/searchRequisiciones",
      data
    );
    console.log(response);
    //validar los datos que recibimos
    if (response && !response.error) {
      if (response.result && !response.empty) {
        //let r = response.result.list;
        setLoad(false);
        /*for (let i = 0; i < r.length; i++) {
          r[i].id_lista = i;
          r[i].checked = false;
        }*/
        setCompra(response.result);
      } else {
        console.log(response.message);
      }
    } else {
      console.log("error en la peticion");
    }
  };
  const getLocations = async () => {
    let data = {
      token: token,
    };

    const response = await request.post("/auth/sales/getubicaciones", data);

    if (response && !response.error) {
      if (response.result && !response.empty) {
        setLocationList(response.result.ubicaciones);
        setLoadLocation(false);
      } else {
        // this.setState({
        //   empty: true,
        //   loading: false,
        //   message: response.message
        // });
      }
    } else {
      // this.setState({
      //   error: true,
      //   loading: false,
      //   message: response.message
      // });
    }
  };
  const getStatus = async () => {
    let data = {
      token: token,
    };

    const response = await request.post("/auth/purchases/getStatus", data);
    console.log(response);
    if (response && !response.error) {
      if (response.result && !response.empty) {
        setStatusList(response.result);
        setLoadStatus(false);
      } else {
        // this.setState({
        //   empty: true,
        //   loading: false,
        //   message: response.message
        // });
      }
    } else {
      // this.setState({
      //   error: true,
      //   loading: false,
      //   message: response.message
      // });
    }
  };

  const FormatNumber = (number) => {
    let val = new Intl.NumberFormat("ES-MX", {
      style: "currency",
      currency: "MXN",
      useGrouping: true,
    }).format(number);
    if (val.length === 8) {
      val = val.substring(0, 2) + "," + val.substring(2, 8);
    }
    return val;
  };
  const edditReq = (id) => {
    setIdReq(id);
    setEddit(true);
    setModal(true);
    setModalV(false);
  };

  const clearSearch = () => {
    setFilter("");
    setText("");
    setLocation("");
    setStatus("");
  };

  const viewReq = (id) => {
    setIdReq(id);
    setEddit(true);
    setModal(true);
    setModalV(true);
  };

  return (
    <>
      <div className="card-header">
        <div
          className="card-title row card-title row"
          style={{ width: "100%" }}
        >
          <div className="col-2 text-center">
            <div className="row">
              <div className="col-1">
                <button
                  onClick={() => setModal(true)}
                  className="btn btn-info btn-sm bg-gradient-info button-filter"
                  title="Nueva Requisición"
                >
                  <i className="fas fa-plus"></i>
                </button>
              </div>
              &nbsp;&nbsp;
              <div className="col">
                <FormControl>
                  <InputLabel htmlFor="filter">Buscar por</InputLabel>
                  <Select
                    value={filter}
                    onChange={(e) => setFilter(e.target.value)}
                    input={<Input id="filter" />}
                    name="filter"
                  >
                    <MenuItem value="">
                      <em>Todos</em>
                    </MenuItem>
                    <MenuItem value="prov_nombre">Proveedor</MenuItem>
                    <MenuItem value="us_nombre">Usuario</MenuItem>
                    <MenuItem value="id_requisicion">ID Requisición</MenuItem>
                    <MenuItem value="depto">Departamento</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
          <div className="col text-center">
            <TextField
              label="Texto"
              name="texto"
              value={text}
              onChange={(e) => setText(e.target.value)}
            />
          </div>
          <div className="col text-center">
            {loadLocation ? (
              <i
                className="fas fa-spinner fa-spin"
                style={{ color: "#3f51b5" }}
              ></i>
            ) : (
              <FormControl>
                <InputLabel htmlFor="ubicacion">Ubicación</InputLabel>
                <Select
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                  input={<Input id="ubicacion" />}
                  //renderValue={(selected) => selected.join(", ")}
                  name="ubicacion"
                >
                  <MenuItem value="">
                    <em>Todos</em>
                  </MenuItem>
                  {locationList.map((info, key) => (
                    <MenuItem key={key} value={info.id_ubicacion}>
                      {info.lugar}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </div>
          <div className="col text-center">
            {loadStatus ? (
              <i
                className="fas fa-spinner fa-spin"
                style={{ color: "#3f51b5" }}
              ></i>
            ) : (
              <FormControl>
                <InputLabel htmlFor="status">Estatus</InputLabel>
                <Select
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                  input={<Input id="status" />}
                  //renderValue={(selected) => selected.join(", ")}
                  name="status"
                >
                  <MenuItem value="">
                    <em>Todos</em>
                  </MenuItem>
                  {statusList.map((info, key) => (
                    <MenuItem key={key} value={info.id_status}>
                      {info.descrip}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </div>
          <div className="col text-center">
            <TextField
              label="Fecha Inicial"
              name="fechai"
              type="date"
              value={fechai}
              onChange={(e) => setFechai(e.target.value)}
            />
          </div>
          <div className="col text-center">
            <TextField
              label="Fecha Final"
              name="fechaf"
              type="date"
              value={fechaf}
              onChange={(e) => setFechaf(e.target.value)}
            />
          </div>
          <div className="col text-left">
            <button
              className="btn btn-primary btn-sm button-filter"
              onClick={() => searchRequisicion()}
            >
              <i className="fas fa-search"></i>
            </button>
            &nbsp;
            <button
              className="btn btn-secondary btn-sm button-filter"
              onClick={() => clearSearch()}
            >
              <i className="fas fa-times"></i>
            </button>
          </div>
        </div>
      </div>
      <div className="card-body">
        {!loadRows ? (
          <div className="table-responsive">
            <table className="table table-bordered table-striped table-hover table-sm">
              <thead className="table-secondary">
                <tr>
                  <td scope="col">ACCIONES</td>
                  <td scope="col">ID</td>
                  <td scope="col">FECHA</td>
                  <td scope="col">SOLICITA</td>
                  <td scope="col">DEPTO/LUGAR</td>
                  <td scope="col">PROVEEDOR</td>
                  <td scope="col">IMPORTE</td>
                  <td scope="col">PAGO</td>
                  <td scope="col">ESTATUS</td>
                  <td scope="col">AUTORIZADO</td>
                  <td scope="col">DESCARGAS</td>
                </tr>
              </thead>
              <tbody>
                {compraR.map((row, key) => (
                  <tr key={"row" + key}>
                    <td scope="col">
                      <div className="btn-group">
                        <button
                          className="btn btn-sm btn-info bg-gradient-info"
                          aria-controls="simple-menu"
                          aria-haspopup="true"
                          onClick={(e) => viewReq(row["id_requisicion"])}
                        >
                          <i className="fas fa-eye"></i>
                        </button>
                        <Actions
                          id={"actions" + key}
                          edditReq={() => edditReq(row.id_requisicion)}
                          idReq={row.id_requisicion}
                          mailProv={row.prov_correo}
                          reload={() => searchRequisicion()}
                          id_status={row.id_status}
                          user={usuario}
                          us_autoriza={row.us_autoriza}
                        />
                      </div>
                    </td>
                    <td scope="col">
                      <i
                        className="fas fa-bookmark"
                        style={{ color: row.prioridad_color }}
                      ></i>
                      &nbsp;{row.id_requisicion}
                    </td>
                    <td
                      scope="col"
                      style={{ minWidth: "5.2rem", maxWidth: "5.2rem" }}
                    >
                      {row.fecha_requi}
                    </td>
                    <td scope="col" style={{ maxWidth: "6rem" }}>
                      {row.us_nombre}
                    </td>
                    <td scope="col" style={{ maxWidth: "6rem" }}>
                      {row.depto}
                      <br />
                      {row.zona}
                    </td>
                    <td scope="col" style={{ maxWidth: "13rem" }}>
                      {row.prov_nombre}
                    </td>
                    <td scope="col" style={{ textAlign: "right" }}>
                      {FormatNumber(row.importe)}
                    </td>
                    <td scope="col">
                      {row.tipo_pago.toString().padStart(2, "0")}-{row.tpago}
                    </td>
                    <td
                      scope="col"
                      style={{
                        maxWidth: "6rem",
                        color: row.color,
                        textShadow: "0.5px 0.5px black",
                      }}
                    >
                      {row.descrip}
                      <br />
                      {row.fecha_mod}
                    </td>
                    <td scope="col">
                      {!row.us_autoriza ? (
                        <b style={{ color: "gray" }}>Sin autorizar</b>
                      ) : (
                        <>
                          <b style={{ color: "green" }}>Autorizado</b>
                          <br />
                          {row.fecha_autoriza}
                        </>
                      )}
                    </td>
                    <td scope="col">
                      <FilesList idReq={row.id_requisicion} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="text-center pt-2">
            <i
              className="fas fa-spinner fa-spin"
              style={{ fontSize: "62px", color: "#3f51b5" }}
            ></i>
            <br /> Buscando Datos...
          </div>
        )}
      </div>
      <Modal
        isOpen={openModal}
        style={customStyles}
        contentLabel="EditarRequisicion"
      >
        <div className="modal-header">
          <h4>
            {openModalV ? "Datos de " : null}Requisición{" "}
            {idReq == 0 ? "Nueva" : idReq}
          </h4>
          <button
            type="button"
            className="close"
            onClick={() => {
              setModal(false);
              setModalV(false);
              setEddit(false);
              setIdReq(0);
            }}
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div
          className="modal-body card-primary card-outline card-outline-tabs"
          style={{ zoom: "95%", padding: "0rem" }}
        >
          <div className="card-body">
            <ModalRequisition
              eddit={eddit}
              openModalV={openModalV}
              idReq={idReq}
              setIdReq={(e) => setIdReq(e)}
              setEddit={() => setEddit(true)}
              close={() => {
                setModal(false);
                setModalV(false);
                setEddit(false);
                setIdReq(0);
              }}
            />
          </div>
        </div>
      </Modal>
    </>
  );
}

export default Consumer(PurchasesTable);
