import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import CreateIcon from "@material-ui/icons/Create";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { toast } from "react-toastify";
import { Consumer } from "../../../context/index";
import Request from "../../../utils/http";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import NotificationsIcon from "@material-ui/icons/Notifications";
import DeleteEvent from "./deleteEvent";
import OdsPdf from "../../downloads/odsPdf";
import OdsExcel from "../../downloads/odsExcel";

const request = new Request();

function SimpleMenu(props) {
  // states del componente
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuDown, setMenuDown] = useState(null);
  const [user, setUser] = useState({
    idUsuario: 0,
    idPerfil: 0,
    nombre: "",
  });

  useEffect(() => {
    loadUser();
  }, []);

  // terminan states del componente

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickDownload = (event) => {
    setMenuDown(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseDownload = () => {
    setMenuDown(null);
  };

  const loadUser = async () => {
    const user = await props.context.loadUser();

    if (user) {
      setUser(user);
    }
  };
  const copyEvent = async (id) => {
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }

    let data = {
      token: token,
      idEv: id,
      idUs: user.id,
    };

    const response = await request.post("/auth/calendar/copyEvent", data);

    if (response && !response.error) {
      if (response.result && !response.empty) {
        toast.success("Cotización copiada con exito con la fecha de hoy.", {
          position: toast.POSITION.TOP_RIGHT,
          theme: "colored",
        });
        props.changeEvent(response.result[0]);
      } else {
        toast.error("Error:" + response.result.message, {
          position: toast.POSITION.TOP_RIGHT,
          theme: "colored",
        });
      }
    } else {
    }
  };
  const attachFile = () => {
    document.getElementById("custom-tabs-three-adj-tab").click();
    props.newAttachFile();
  };
  const deleteCalendarEvent = async (id) => {
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }

    let data = {
      token: token,
      idEv: id,
    };

    const response = await request.post("/auth/calendar/deleteEvent", data);

    if (response && !response.error) {
      if (response.result && !response.empty) {
        toast.success(<span>Cotización eliminada con exito.</span>);
        props.deleteCalendarEvent(id);
      } else {
        toast.error(
          <span>
            Error:
            {response.result.message}
          </span>
        );
      }
    } else {
    }
  };

  return (
    <>
      {props.idModulo !== 1 &&
      props.idModulo !== 3 &&
      (props.idUser === user.id ||
        user.id_perfil === 1 ||
        user.id_perfil === 2) ? (
        <Button
          aria-controls="eddit-menu"
          aria-haspopup="true"
          onClick={handleClick}
          className="btn btn-sm btn-primary bg-gradient-primary"
          size="medium"
          color="primary"
          variant="contained"
        >
          Opciones
          <ExpandMoreIcon style={{ color: "#eceff1" }} />
        </Button>
      ) : null}
      <Menu
        id="eddit-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            props.setModalEddit(true);
            handleClose();
          }}
        >
          <CreateIcon style={{ color: "#027109" }} />
          Editar
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            copyEvent(props.idEvent);
            handleClose();
          }}
        >
          <FileCopyIcon style={{ color: "#4AB6DE" }} />
          Copiar
        </MenuItem>

        <MenuItem
          onClick={() => {
            attachFile();
            handleClose();
          }}
        >
          <AttachFileIcon style={{ color: "#D7DD11" }} />
          Adjuntar
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <NotificationsIcon style={{ color: "#3390FF" }} />
          
            <a href="#" className="MuiMenuItem-root" data-toggle="control-sidebar">Notificar</a>
            
          
        </MenuItem>
        <hr></hr>
        <DeleteEvent
          handleClose={handleClose}
          idEv={props.idEvent}
          deleteCalendarEvent={(e) => deleteCalendarEvent(e)}
        />
      </Menu>
      &nbsp;
      <Button
        aria-controls="download-menu"
        aria-haspopup="true"
        onClick={handleClickDownload}
        className="btn btn-sm btn-primary bg-gradient-primary"
        size="medium"
        color="primary"
        variant="contained"
        title="Descargar Archivo"
      >
        <i className="fas fa-file-download"></i>&nbsp;
        <ExpandMoreIcon style={{ color: "#eceff1" }} />
      </Button>
      <Menu
        id="download-menu"
        anchorEl={menuDown}
        keepMounted
        open={Boolean(menuDown)}
        onClose={handleCloseDownload}
      >
        <MenuItem onClick={handleCloseDownload}>
          <OdsPdf
            ods={true}
            idEvent={props.idEvent}
            recargaOds={props.recargaOds}
          />
        </MenuItem>
        <MenuItem onClick={handleCloseDownload}>
          <OdsExcel
            ods={true}
            idEvent={props.idEvent}
            recargaOds={props.recargaOds}
          />
        </MenuItem>
        {props.idModulo !== 1 &&
        props.idModulo !== 3 &&
        (props.idUser === user.id ||
          user.id_perfil === 1 ||
          user.id_perfil === 2 ||
          user.id_perfil === 20) ? (
          <div>
            <hr />
            <MenuItem onClick={handleCloseDownload}>
              <OdsPdf
                ods={false}
                idEvent={props.idEvent}
                recargaOds={props.recargaOds}
              />
            </MenuItem>
            <MenuItem onClick={handleCloseDownload}>
              <OdsExcel
                ods={false}
                idEvent={props.idEvent}
                recargaOds={props.recargaOds}
              />
            </MenuItem>
          </div>
        ) : null}
      </Menu>
    </>
  );
}
export default Consumer(SimpleMenu);
