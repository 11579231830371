import React, {useEffect, useState} from "react";
import Request from "../../../utils/http";
import MUIDataTable from "mui-datatables";
import ButtonSave from "../../inventory/tabs/buttonSave";

const request = new Request();

export default function FormArea(props){

    const [area, setArea] = useState({
        name_area:'',
    });

    //nuestro token para todos
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token = token1.id;

    useEffect(() => {

    }, []);

    let validarNewArea=data=>{
      let error = 1;
      if (data.name_area.length === 0 || data.name_area === "") {
          document.getElementById("name_area").classList.add("is-invalid");
        return error;
      }
      return data;
    }
    const save = async () => {
        let datos=validarNewArea(area);
        if(datos==1){
          alert("Favor de llenar los Campos");
        }else{
          let data = {
            token: token,
            datos: datos
          }
          const response = await request.post("/employee/saveArea", data);
          if (response && !response.error) {
              if (response.result.ok && !response.empty) {
                  alert("Departamento Guardado");
                  props.recargar();
                  props.closeModal();
              } else {
                console.log(response.result.message);
              }
          } else {
              console.log("error en la peticion");
          }
        }
    }

    return(
        <div className="tab-content">
            <div className="row mt-2">
                <div className="col-md-6">
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Departamento</span>
                        </div>
                        <input type="text"  className="form-control text-uppercase" 
                            id="name_area" 
                            name="name_area" 
                            value={area.name_area} 
                            onChange={e => setArea({...area,name_area:e.target.value.toUpperCase()})} 
                         />
                    </div>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-md-12">
                    <ButtonSave  
                    save={save}
                    closeModal={props.closeModal}
                    />
                </div> 
            </div>
        </div>
    );
}