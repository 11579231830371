/**
 *  @name: public.jsx
 *  @version: 1.0.0
 *  @author: Alexis Duarte
 *  @description: Página de login
 */

import React from "react";
import LoginBox from "../components/login";

const LoginPage = () => {
  return <LoginBox />;
};

export default LoginPage;
