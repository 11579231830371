/**
 *  @name: routes.jsx
 *  @version: 1.0.0
 *  @author: Alexis duarte
 *  @description: Manejo de todas las rutas de la aplicación
 * 	@process: 3
 */

import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { GlobalContext } from "../context";

// Páginas
import LoginPage from "../pages/login";
import Dashoard from "../pages/Dashboard";
import Page404 from "../pages/404";
import InicioPage from "../pages/InicioPage";
import Operation from "../pages/operation/operationPage";
import Daily from "../pages/operation/dailyPage";
import Supervisory from "../pages/operation/supervisoryPage";
import Sales from "../pages/salesPage";
import SalesStatement from "../pages/sales/Statement";
import Purchases from "../pages/purchasesPage";
import Inventary from "../pages/inventory/inventoryPage";
import Warehouse from "../pages/inventory/warehousePage";
import Design from "../pages/designPage";
import Users from "../pages/usersPage";
import Temporary from "../pages/inventory/articleTemp";
import Entrys from "../pages/inventory/entryOutputs";
import EventsHistory from "../pages/inventory/eventsHistory";
import Provider_page from "../pages/provider";
import Product_page from "../pages/product";
import collentionQuote from "../pages/sales/collentionQuote";

//rh
import Empleados from "../pages/recursos_humanos/empleados";
import Permisos from "../pages/recursos_humanos/permisos";
import Eventos from "../pages/recursos_humanos/Eventos";
import HorasExtras from "../pages/recursos_humanos/horasExtras";
import ReporteBajasEmpleado from "../pages/recursos_humanos/reporteBajasEmpleado";
// Función para declarar rutas privadas
import Almacen from "../pages/almacen/almacen";
import PrestamosAlmacen from "../pages/almacen/prestamo";

//
import Sistemas from "../pages/sistemas/sistemas";
import PrestamosSistema from "../pages/sistemas/prestamos";
import PurchasesCalendar from "../pages/purchasesCalendarPage";
import invoicingPage from "../pages/accounting/invoicingPage";
import listWorks from "../pages/design/listWorks";

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      const auth = window.sessionStorage.getItem("decoAdmin");
      // const auth = 2 > 1;
      if (auth) {
        return <Component {...props} />;
      } else {
        return (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        );
      }
    }}
  />
);
const Routes = () => (
  <GlobalContext>
    <BrowserRouter>
          <Switch>
            <Route path="/" exact component={LoginPage} />
            <PrivateRoute path="/inicio" exact component={InicioPage} />
            <PrivateRoute path="/dashboard" exact component={Dashoard} />
            <PrivateRoute path="/operaciones" exact component={Operation} />
            <PrivateRoute path="/daily_list" exact component={Daily} />
            <PrivateRoute path="/supervisores" exact component={Supervisory} />
            <PrivateRoute path="/ventas" exact component={Sales} />
            <PrivateRoute path="/ventas_concentrado" exact component={SalesStatement} />
            <PrivateRoute path="/ventas_machotes" exact component={collentionQuote} />
            
            <PrivateRoute path="/compras" exact component={Purchases} />

            <PrivateRoute path="/proveedor" exact component={Provider_page} />
            <PrivateRoute path="/productos" exact component={Product_page} />
            <PrivateRoute
              path="/inventario_temporal"
              exact
              component={Temporary}
            />
            <PrivateRoute
              path="/entradas_salidas"
              exact
              component={Entrys}
            />
            <PrivateRoute
              path="/historial_eventos"
              exact
              component={EventsHistory}
            />
            <PrivateRoute path="/compras_calendario" exact component={PurchasesCalendar} />
            <PrivateRoute path="/inventario" exact component={Inventary} />
            <PrivateRoute path="/catalogo_almacenes" exact component={Warehouse} />
            <PrivateRoute path="/diseno" exact component={Design} />
            <PrivateRoute path="/diseno_catalogo" exact component={listWorks} />

            <PrivateRoute path="/contabilidad_concentrado" exact component={SalesStatement} />
            <PrivateRoute path="/contabilidad_factura" exact component={invoicingPage} />
            {/*/<PrivateRoute path="/contabilidad" exact component={Accounting} />*/}
            

            <PrivateRoute path="/usuarios" exact component={Users} />
            

            <PrivateRoute path="/recursos_humanos" exact component={Empleados} />
            <PrivateRoute path="/permisos" exact component={Permisos} />
            <PrivateRoute path="/Eventos" exact component={Eventos} />
            <PrivateRoute path="/horasExtras" exact component={HorasExtras} />

            <PrivateRoute path="/reporteBajasEmpleado" exact component={ReporteBajasEmpleado} />
            


            <PrivateRoute path="/almacen" exact component={Almacen} />
            <PrivateRoute path="/prestamosAlmacen" exact component={PrestamosAlmacen} />

            <PrivateRoute path="/sistemas" exact component={Sistemas} />
            <PrivateRoute path="/prestamoSistemas" exact component={PrestamosSistema} />

            <Route component={Page404} />
          </Switch>
    </BrowserRouter>
  </GlobalContext>
);

export default Routes;
