/**
 *  @name: private.jsx
 *  @version: 1.0.0
 *  @author: Trejocode - Sergio
 *  @description: Página privada
 */

import React, { useState, useEffect } from "react";
import { Consumer } from "../context/index";
import Layout from "./layout";
import Sidebar from "../components/header/sidebar";
import Calendar from "../components/calendar/calendarEvents";
import Fab from "@material-ui/core/Fab";
import Chip from "@material-ui/core/Chip";
import AddIcon from "@material-ui/icons/Add";
import Request from "../utils/http";

const request = new Request();

function Sales(props) {
  const [user, setUser] = useState({
    idUsuario: 0,
    idPerfil: 0,
    nombre: "",
    ubicacion: 0,
  });
  useEffect(() => {
    loadUser();
  }, []);

  const loadUser = async () => {
    const user = await props.context.loadUser();

    if (user) {
      setUser(user);
    }
  };

  return (
    <Layout
      title="Calendario de Ventas"
      description="Página privada de perfil"
      keywords="Reactjs, SEO Ready, Ejemplo"
    >
      <Sidebar />
      <Calendar id_modulo={2} user={user}/>
    </Layout>
  );
}

export default Consumer(Sales);
