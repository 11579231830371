import React, { useEffect, useState } from "react";
import moment from "moment";
import "moment/locale/es.js";
import { blue } from "@material-ui/core/colors";
const ExcelJS = require("exceljs");

function DownloadExcel(props) {
  const exportExcelFile = async () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Hoja1");

    sheet.columns = [
      {
        header: "GERENTE",
        key: "gerente",
        width: 11,
      },
      { header: "STATUS", key: "status", width: 13 },
      {
        header: "MES",
        key: "mes",
        width: 9,
      },
      {
        header: "FECHAS",
        key: "fechas",
        width: 15,
      },
      {
        header: "BODA/GRUPO",
        key: "evento",
        width: 36,
      },
      {
        header: "EVENTOS",
        key: "subs",
        width: 9,
      },
      {
        header: "LUGAR",
        key: "lugar",
        width: 15,
      },
      {
        header: "PAX",
        key: "pax",
        width: 5,
      },
      {
        header: "TIPO",
        key: "tipo",
        width: 12,
      },
      {
        header: "MONTO ARTÍCULOS",
        key: "montoa",
        width: 15,
      },
      {
        header: "DESCUENTO",
        key: "descuento",
        width: 15,
      },
      {
        header: "MONTO OPERACIÓN",
        key: "montop",
        width: 15,
      },
      {
        header: "IVA",
        key: "iva",
        width: 15,
      },
      {
        header: "FACTURA",
        key: "factura",
        width: 15,
      },
      {
        header: "MONEDA",
        key: "moneda",
        width: 9,
      },
      {
        header: "PAGO MEDIANTE",
        key: "pago",
        width: 15,
      },
      {
        header: "RECOMENDADO",
        key: "recom",
        width: 15,
      },
      {
        header: "% COMISIÓN",
        key: "com",
        width: 10,
      },
      {
        header: "COORDINADORA/\nCONTACTO",
        key: "contacto",
        width: 18,
      },
      {
        header: "FACTURA STATUS",
        key: "fstatus",
        width: 12,
      },
      {
        header: "No. FACTURA",
        key: "numfact",
        width: 12,
      },
      {
        header: "FECHA FACTURA",
        key: "ffact",
        width: 12,
      },
      {
        header: "MONTO FACTURA",
        key: "montof",
        width: 15,
      },
      {
        header: "MONEDA FACTURA",
        key: "monf",
        width: 9,
      },
      {
        header: "% COMISIÓN FACTURA",
        key: "comfact",
        width: 9,
      },
    ];
    let usd = 0,
      u = "0",
      mx = 0,
      m = "0",
      fusd = 0,
      fu = "0",
      fmx = 0,
      fm = "0";

    props.excelData?.map((event, key) => {
      if (event.Moneda == "USD") {
        usd = usd + event?.total;
        u = u + "+N" + (key + 2);
      } else {
        mx = mx + event?.total;
        m = m + "+N" + (key + 2);
      }
      if (event.fac_moneda == "USD") {
        fusd = fusd + event?.fac_monto;
        fu = fu + "+N" + (key + 2);
      } else {
        fmx = fmx + event?.fac_monto;
        fm = fm + "+N" + (key + 2);
      }

      sheet.addRow({
        gerente: event?.usuario,
        status: event?.status,
        mes: moment(event?.Fecha_inicio).format("MMM"),
        fechas:
          moment(event?.Fecha_fin).diff(moment(event?.Fecha_inicio), "years") >
          0
            ? `${moment(event?.Fecha_inicio).format("DD-MMM-YYYY")} al ${moment(
                event?.Fecha_fin
              ).format("DD-MMM-YYYY")}`
            : moment(event?.Fecha_fin).diff(
                moment(event?.Fecha_inicio),
                "months"
              ) > 0
            ? `${moment(event?.Fecha_inicio).format("DD-MMM")} al ${moment(
                event?.Fecha_fin
              ).format("DD-MMM-YYYY")}`
            : moment(event?.Fecha_fin).diff(
                moment(event?.Fecha_inicio),
                "days"
              ) > 0
            ? `${moment(event?.Fecha_inicio).format("DD")} al ${moment(
                event?.Fecha_fin
              ).format("DD-MMM-YYYY")}`
            : moment(event?.Fecha_inicio).format("DD-MMM-YYYY"),
        evento: event?.nom_evento,
        subs: event?.Eventos,
        lugar: event?.hotel,
        pax: event?.Paxes,
        tipo: event?.tipo_esp,
        montoa: event?.importe,
        descuento: event?.total_descuento,
        montop: event?.operacion,
        iva: event?.total_iva,
        factura: event?.total,
        moneda: event?.Moneda,
        pago: event?.Forma_pago,
        recom: event?.Empresa.replace("@", " @")
          .replace(".com", " .com")
          .replace("mailto:", " "),
        com: event?.total_comision,
        contacto: event?.Contacto_Hotel.replace("@", " @")
          .replace(".com", " .com")
          .replace("mailto:", " "),
        fstatus: event?.fs_nombre,
        numfact: event?.num_factura,
        ffact: moment(event?.fecha_fac).format("DD-MMM-YYYY"),
        montof: parseFloat(event?.fac_monto),
        monf: event?.fac_moneda,
        comfact: event?.fac_comision,
      });
    });

    sheet.addRow({
      factura: { formula: m, result: mx },
      moneda: "MX",
      montof: { formula: fm, result: fmx },
      monf: "MX",
    });
    sheet.addRow({
      factura: { formula: u, result: usd },
      moneda: "USD",
      montof: { formula: fu, result: fusd },
      monf: "USD",
    });
    sheet.getColumn("O").eachCell((cell, row) => {
      let cellValue = sheet.getCell(cell?.address).value;
      // add a condition to set styling
      if (cellValue == "USD") {
        sheet.getCell(`J${row}`).font = { color: { argb: "0000FF" } };
        sheet.getCell(`K${row}`).font = { color: { argb: "0000FF" } };
        sheet.getCell(`L${row}`).font = { color: { argb: "0000FF" } };
        sheet.getCell(`M${row}`).font = { color: { argb: "0000FF" } };
        sheet.getCell(`N${row}`).font = { color: { argb: "0000FF" } };
        sheet.getCell(cell?.address).font = { color: { argb: "0000FF" } };
      } else if (cellValue == "MX" || cellValue == "MXN") {
        sheet.getCell(`J${row}`).font = { color: { argb: "FF0000" } };
        sheet.getCell(`K${row}`).font = { color: { argb: "FF0000" } };
        sheet.getCell(`L${row}`).font = { color: { argb: "FF0000" } };
        sheet.getCell(`M${row}`).font = { color: { argb: "FF0000" } };
        sheet.getCell(`N${row}`).font = { color: { argb: "FF0000" } };
        sheet.getCell(cell?.address).font = { color: { argb: "FF0000" } };
      }
    });
    sheet.getColumn("X").eachCell((cell, row) => {
      let cellValue = sheet.getCell(cell?.address).value;
      // add a condition to set styling
      if (cellValue == "USD") {
        sheet.getCell(`W${row}`).font = { color: { argb: "0000FF" } };
        sheet.getCell(cell?.address).font = { color: { argb: "0000FF" } };
      } else if (cellValue == "MX" || cellValue == "MXN") {
        sheet.getCell(`W${row}`).font = { color: { argb: "FF0000" } };
        sheet.getCell(cell?.address).font = { color: { argb: "FF0000" } };
      }
    });
    let format = '"$"#,##0.00;-"$"#,##0.00';
    sheet.getColumn("J").numFmt = format;
    sheet.getColumn("K").numFmt = format;
    sheet.getColumn("L").numFmt = format;
    sheet.getColumn("M").numFmt = format;
    sheet.getColumn("N").numFmt = format;
    sheet.getColumn("W").numFmt = format;
    workbook.xlsx.writeBuffer().then(function (data) {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = "concentrado";
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };
  useEffect(() => {
    console.log("reload :>> ", "reload");
  }, [props.excelData]);
  return (
    <button
      onClick={exportExcelFile}
      class="btn btn-success btn-sm button-filter"
    >
      <i class="fas fa-file-excel"></i>
    </button>
  );
}
export default DownloadExcel;

/*class DownloadExcel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        { title: "GERENTE", width: { wpx: 90 } },
        { title: "STATUS", width: { wpx: 80 } },
        { title: "MES", width: { wpx: 45 } },
        { title: "FECHA", width: { wpx: 85 } },
        { title: "BODA/GRUPO", width: { wpx: 100 } },
        { title: "EVENTOS", width: { wpx: 80 } },
        { title: "LUGAR", width: { wpx: 90 } },
        { title: "PAX", width: { wpx: 45 } },
        { title: "TIPO", width: { wpx: 90 } },
        { title: "MONTO ARTÍCULOS", width: { wpx: 90 } },
        { title: "DESCUENTO", width: { wpx: 90 } },
        { title: "MONTO OPERACIÓN", width: { wpx: 90 } },
        { title: "IVA", width: { wpx: 90 } },
        { title: "FACTURA", width: { wpx: 90 } },
        { title: "MONEDA", width: { wpx: 90 } },
        { title: "FORMA PAGO", width: { wpx: 90 } },
        { title: "RECOMENDADO", width: { wpx: 90 } },
        { title: "% COMISIÓN", width: { wpx: 90 } },
        { title: "CONTACTO", width: { wpx: 90 } },
      ],
      data: [],
    };
  }
  componentDidMount() {
    if (this.props.excelData.length !== 0) {
      let value = [];
      let usd = 0;
      let mx = 0;
      let format = "[$$-80A]#,##0.00;[RED]-[$$-80A]#,##0.00";

      this.props.excelData.forEach((e) => {
        let fColor = e.Moneda === "MX" ? "ca0000" : "00008b";
        if (e.Moneda === "MX") {
          mx = mx + e.total;
        } else {
          usd = usd + e.total;
        }
        let fechaInicio =
          moment(e.Fecha_fin).diff(moment(e.Fecha_inicio), "years") > 0
            ? `${moment(e.Fecha_inicio).format("DD-MMM-YYYY")} al ${moment(
                e.Fecha_fin
              ).format("DD-MMM-YYYY")}`
            : moment(e.Fecha_fin).diff(moment(e.Fecha_inicio), "months") > 0
            ? `${moment(e.Fecha_inicio).format("DD-MMM")} al ${moment(
                e.Fecha_fin
              ).format("DD-MMM-YYYY")}`
            : moment(e.Fecha_fin).diff(moment(e.Fecha_inicio), "days") > 0
            ? `${moment(e.Fecha_inicio).format("DD")} al ${moment(
                e.Fecha_fin
              ).format("DD-MMM-YYYY")}`
            : moment(e.Fecha_inicio).format("DD-MMM-YYYY");

        value.push([
          {
            value: e.usuario,
            style: {
              font: { sz: "10" },
              fill: {
                patternType: "solid",
                fgColor: { rgb: e.color_us.replace("#", "") },
              },
            },
          },
          {
            value: e.status,
            style: {
              font: { sz: "10" },
              fill: {
                patternType: "solid",
                fgColor: { rgb: e.color_st.replace("#", "") },
              },
            },
          },
          {
            value: moment(e.Fecha_inicio).format("MMMM"),
            style: { font: { sz: "10" } },
          },
          {
            value: fechaInicio,
            style: { font: { sz: "10" } },
          },
          { value: e.nom_evento, style: { font: { sz: "10" } } },
          { value: e.Eventos, style: { font: { sz: "10" } } },
          { value: e.hotel, style: { font: { sz: "10" } } },
          { value: e.Paxes, style: { font: { sz: "10" } } },
          { value: e.tipo_esp, style: { font: { sz: "10" } } },
          {
            value: e.importe,
            style: {
              font: { sz: "10", color: { rgb: fColor } },
              numFmt: format,
            },
          },
          {
            value: e.total_descuento,
            style: {
              font: { sz: "10", color: { rgb: fColor } },
              numFmt: format,
            },
          },
          {
            value: e.operacion,
            style: {
              font: { sz: "10", color: { rgb: fColor } },
              numFmt: format,
            },
          },
          {
            value: e.total_iva,
            style: {
              font: { sz: "10", color: { rgb: fColor } },
              numFmt: format,
            },
          },
          {
            value: e.total,
            style: {
              font: { sz: "10", color: { rgb: fColor } },
              numFmt: format,
            },
          },
          {
            value: e.Moneda,
            style: { font: { sz: "10", color: { rgb: fColor } } },
          },
          { value: e.Forma_pago, style: { font: { sz: "10" } } },
          { value: e.Empresa, style: { font: { sz: "10" } } },
          {
            value: e.total_comision,
            style: { font: { sz: "10" } },
          },
          {
            value: e.Contacto_Hotel,
            style: { font: { sz: "10" } },
          },
        ]);
      });
      value.push(
        [],
        [
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {
            value: "TOTAL USD",
            style: {
              font: { sz: "12", bold: true, color: { rgb: "00008b" } },
            },
          },
          {
            value: usd,
            style: {
              font: { sz: "10", bold: true, color: { rgb: "00008b" } },
              numFmt: format,
            },
          },
        ],
        [
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {
            value: "TOTAL MXN",
            style: {
              font: { sz: "12", bold: true, color: { rgb: "ca0000" } },
            },
          },
          {
            value: mx,
            style: {
              font: { sz: "10", bold: true, color: { rgb: "ca0000" } },
              numFmt: format,
            },
          },
        ]
      );
      this.setState({ data: value });
    }
  }

  render() {
    return this.props.excelData.length !== 0 ? (
      <ExcelFile
        filename={`Concentrado de Ventas del ${moment(this.props.inicio).format(
          "DD-MMM-YYYY"
        )} al ${moment(this.props.final).format("DD-MMM-YYYY")}`}
        element={
          <button className="btn btn-success btn-sm button-filter">
            <i className="fas fa-file-excel"></i>
          </button>
        }
      >
        <ExcelSheet
          dataSet={[{ columns: this.state.columns, data: this.state.data }]}
          name="Concentrado"
        />
      </ExcelFile>
    ) : null;
  }
}
export default DownloadExcel;
*/
