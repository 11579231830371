import React, { Component } from "react";
import Request from "../../../utils/http";
import ButtonSave from "./buttonSave";
import { Consumer } from "../../../context/index";
import { toast } from "react-toastify";

const request = new Request();
class StockLocation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id_art: this.props.id,
      stockData: [],
      load: false,
      loading: false,
      user: {
        idUsuario: 0,
        idPerfil: 0,
        idUbi: 0,
        nombre: "",
      },
      asignado: 0,
    };
  }
  save() {
    this.setState({ load: true });
    this.handleSubmit();
  }
  saved() {
    this.props.saved();
  }
  render() {
    return this.state.loading ? (
      <>
        <div className="card-header">
          <div className="row">
            <div className="col-md-2">
              <div className="description-block">
                <h5 className="description-header">Cantidad Total</h5>
                <span className="description-text">{this.props.cant}</span>
              </div>
            </div>
            <div className="col-md-2">
              <div className="description-block">
                <h5 className="description-header">Sin Asignar</h5>
                <span className="description-text">{this.state.asignado}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            {this.state.stockData.map((ubicacion, key) => (
              <div className="col-md-6" key={key}>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">{ubicacion.lugar}</label>
                  <p>{ubicacion.descrip}</p>
                  <input
                    type="number"
                    className="form-control"
                    value={ubicacion.cantidad === null ? 0 : ubicacion.cantidad}
                    placeholder="Ingresa un "
                    readOnly={
                      ubicacion.id_ubicacion === 5 ||
                      ubicacion.id_ubicacion === 6 ||
                      (this.state.user.idUbi !== ubicacion.id_ubicacion &&
                        this.state.user.id_perfil === 5)
                        ? true
                        : false
                    }
                    onChange={this.handleChange.bind(
                      this,
                      key,
                      ubicacion,
                      "cambio"
                    )}
                    min="0"
                  />
                </div>
              </div>
            ))}
          </div>
          <div className="white-space-32"></div>
          <ButtonSave
            closeModal={this.props.closeModal}
            save={this.save.bind(this)}
            load={this.state.load}
          />
        </div>
      </>
    ) : (
      <div className="text-center">
        <i
          className="fas fa-spinner fa-spin"
          style={{ fontSize: "62px", color: "#3f51b5" }}
        ></i>
        <br /> Cargando Datos del Artículo...
      </div>
    );
  }
  componentDidMount() {
    this.load();
    this.loadData();
  }
  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (
      this.props.cant !== prevProps.cant ||
      this.props.load !== prevProps.load
    ) {
      this.load();
      this.loadData();
    }
  }
  async load() {
    const user = await this.props.context.loadUser();

    if (user) {
      this.setState({
        user: {
          idUsuario: user.id,
          idPerfil: user.id_perfil,
          idUbi: user.ubicacion,
          nombre: user.name,
        },
      });
    }
  }
  async loadData() {
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }
    let data = {
      token: token,
      idArt: this.state.id_art,
    };

    const response = await request.post("/auth/inventory/getStockArt", data);

    if (response && !response.error) {
      if (response.result && !response.empty) {
        for (let i = 0; i < response.result.length; i++) {
          let total = this.setTotal(response.result);
          this.setState({
            loading: true,
            stockData: response.result,
            dif0: response.result[0].cantidad,
            dif1: response.result[1].cantidad,
            dif2: response.result[2].cantidad,
            dif3: response.result[3].cantidad,
            dif4: response.result[4].cantidad,
            //dif5: response.result[5].cantidad,
            asignado: this.props.cant - total,
          });
        }
      } else {
        this.setState({
          empty: true,
          loading: true,
          message: response.message,
        });
      }
    } else {
      this.setState({
        error: true,
        loading: true,
        message: response.message,
      });
    }
  }
  setTotal(array) {
    let total = array.map((c) => Number(c.cantidad)).reduce((a, b) => a + b, 0);
    return total;
  }
  handleChange(key, ubicacion, option, event) {
    let valores = this.state.stockData;

    if (option === "cambio") {
      valores[key].cantidad = parseInt(event.target.value);
    }
    this.setState({ stockData: valores });
  }
  async handleSubmit() {
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }
    let dif = [
      {
        cantidad: this.state.dif0,
      },
      {
        cantidad: this.state.dif1,
      },
      {
        cantidad: this.state.dif2,
      },
      {
        cantidad: this.state.dif3,
      },
      {
        cantidad: this.state.dif4,
      },
      /*{
        cantidad: this.state.dif5,
      },*/
    ];
    if (this.props.cant - this.setTotal(this.state.stockData) >= 1) {
      this.setState({
        load: false,
      });
      return toast.error(
        <span>
          No pueden quedar unidades
          sin asignar, ajuste las cantidades.
        </span>
      );
    } else if (this.props.cant - this.setTotal(this.state.stockData) <= -1) {
      this.setState({
        load: false,
      });
      return toast.error(
        <span>
          La suma de los almacenes
          es mayor que la cantidad total, ajuste las cantidades.
        </span>
      );
    }
    let stock = this.state.stockData;
    for (let i = 0; i < stock.length; i++) {
      if (stock[i].cantidad === null) {
        stock[i].cantidad = 0;
      }
    }

    let data = {
      idArt: this.state.id_art,
      idUser: this.state.user.id,
      stock: stock,
      dif: dif,
      token: token,
    };

    const response = await request.post("/auth/inventory/saveStock", data);
    //console.log("🚀 ~ file: stockLocation.jsx ~ line 191 ~ StockLocation ~ handleSubmit ~ response", response)

    if (response && !response.error) {
      if (response.result && !response.empty) {
        this.setState({
          load: false,
        });
        this.props.reload(response.result[1].original[0]);
        this.props.fload();
        toast.success(
          <span>
            Stock del artículo,
            guardado con exito.
          </span>
        );
      } else {
        this.setState({
          empty: true,
          loading: false,
          load: false,
          message: response.message,
        });
        toast.error(
          <span>
            ¡¡¡UPSS!!!, algo salio
            mal.. intentalo de nuevo, si el problema continua contacta a
            sistemas.
          </span>
        );
      }
    } else {
      this.setState({
        error: true,
        loading: false,
        load: false,
        message: response.message,
      });
      toast.error(
        <span>
          ¡¡¡UPSS!!!, algo salio
          mal.. intentalo de nuevo, si el problema continua contacta a sistemas.
        </span>
      );
    }
  }
}

export default Consumer(StockLocation);
