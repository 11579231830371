/**
 *  @name: footer.jsx
 *  @version: 1.0.0
 *  @author: Trejocode - Sergio
 *  @description: Componente Footer
 */

import React from "react";

const Footer = () => {
  return (
    <footer className="main-footer text-sm">
    <div className="float-right d-none d-sm-block">
      <b>Version</b> 4.0.0
    </div>
    <strong>Copyright © 2016-2024 <b>ZDE Bodas y Eventos</b>.</strong> All rights
    reserved.
  </footer>
  );
};

export default Footer;
