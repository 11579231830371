import React, { useState, useEffect } from "react";
import Popover from "@material-ui/core/Popover";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import "moment/locale/es.js";
import Request from "../../utils/http";

const request = new Request();

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: theme.spacing(1),
  },
}));

function TableProductsExistences(props) {
  const classes = useStyles();
  const [artExistences, setExistences] = useState([]);
  const [cotizados, setCotizados] = React.useState(null);
  const [bloqueados, setBloqueados] = React.useState(null);
  const [stock, setStock] = React.useState(0);
  const [load, setLoad] = useState(true);

  useEffect(() => {
    if (props.idArt && props.stock) {
      getExistencesInfo();
      setStock(props.stock);
    }
  }, [props.idArt, props.stock]);
  useEffect(() => {
    getStock();
    console.log(props.idUbicacion);
  }, [props.idUbicacion]);

  /*COTIZADOS*/
  const handleCotizadosOpen = (event) => {
    setCotizados(event.currentTarget);
  };

  const handleCotizadosClose = () => {
    setCotizados(null);
  };

  const openCot = Boolean(cotizados);

  /*BLOQUEDAOS*/
  const handleBloqueadosOpen = (event) => {
    setBloqueados(event.currentTarget);
  };

  const handleBloqueadosClose = () => {
    setBloqueados(null);
  };

  const openBloq = Boolean(bloqueados);

  const getExistencesInfo = async () => {
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }

    let data = {
      token: token,
      idEvent: props.idEvent,
      idArt: props.idArt,
    };

    const response = await request.post(
      "/auth/inventory/getProductosExistancesInfo",
      data
    );
    console.log("GETEXISTENCES", response);
    if (response && !response.error) {
      if (response.result && !response.empty) {
        if (response.result.length !== 0) {
          getExistences(
            response.result[0].id_ubicacion,
            response.result[0].Fecha_inicio,
            response.result[0].Fecha_fin
          );
        } else {
          getExistences(
            props.idUbicacion,
            moment(props.fechaM, "DD-MM-YYYY HH:mm").isValid()
              ? moment(props.fechaM, "DD-MM-YYYY HH:mm").format("YYYY-MM-DD")
              : moment(props.fechaI, "DD-MM-YYYY HH:mm").format("YYYY-MM-DD"),
            moment(props.fechaD, "DD-MM-YYYY HH:mm").isValid()
              ? moment(props.fechaD, "DD-MM-YYYY HH:mm").format("YYYY-MM-DD")
              : moment(props.fechaF, "DD-MM-YYYY HH:mm").format("YYYY-MM-DD")
          );
        }
      }
    }
  };
  const getExistences = async (ubi, ini, fin) => {
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }

    let data = {
      token: token,
      idEvent: props.idEvent,
      idSub: props.idSub,
      idArt: props.idArt,
      idUbi: ubi,
      ini: ini,
      fin: fin,
    };

    const response = await request.post(
      "/auth/inventory/getProductosExistances",
      data
    );
    // console.log(
    //   "TableProductsExistences->getExistences->106 response",
    //   response
    // );

    if (response && !response.error) {
      if (response.result && !response.empty) {
        let respuesta = response.result;
        let info = [
          {
            cot: { total: 0, info: [] },
            bloq: { total: 0, info: [] },
            disp: 0,
          },
        ];

        if (respuesta.length !== 0) {
          respuesta.map((datos, key) => {
            switch (datos.id_status) {
              case 3:
                info[0].bloq.total = datos.total;
                info[0].bloq.info = JSON.parse(datos.info);
                break;

              default:
                info[0].cot.total = datos.total;
                info[0].cot.info = JSON.parse(datos.info);
                break;
            }
            return true;
          });
          info[0].disp = props.stock - info[0].bloq.total;
          setExistences(info);
          setLoad(false);
        } else {
          info[0].disp = props.stock;
          setExistences(info);
          setLoad(false);
        }
      }
    }
  };
  const getStock = async () => {
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }

    let data = {
      token: token,
      idArt: props.idArt,
      idUbi: props.idUbicacion,
    };

    const response = await request.post("/auth/inventory/getStock", data);
    // console.log(
    //   "🚀 ~ file: tableProductsExistences.jsx ~ line 135 ~ getStock ~ response",
    //   response
    // );

    if (response && !response.error) {
      if (response.result && !response.empty) {
        setStock(response.result[0].cantidad);
      }
    }
  };

  return !load ? (
    <>
      <div className="info-box bg-gradient-info" title="En Bodega">
        <span className="info-box-icon">
          <i className="fas fa-boxes"></i>
        </span>

        <div className="info-box-content">
          <span className="info-box-number">{props.stock ? stock : 0}</span>
        </div>
      </div>
      <div
        className="info-box bg-gradient-primary"
        aria-owns={openCot ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handleCotizadosOpen}
        onMouseLeave={handleCotizadosClose}
      >
        <span className="info-box-icon">
          <i className="fas fa-list"></i>
        </span>

        <div className="info-box-content">
          <span className="info-box-number">
            {artExistences.length !== 0 ? artExistences[0].cot.total : 0}
          </span>
        </div>
      </div>
      <div
        className="info-box bg-gradient-danger"
        aria-owns={openBloq ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handleBloqueadosOpen}
        onMouseLeave={handleBloqueadosClose}
      >
        <span className="info-box-icon">
          <i className="fas fa-lock"></i>
        </span>

        <div className="info-box-content">
          <span className="info-box-number">
            {artExistences.length !== 0 ? artExistences[0].bloq.total : 0}
          </span>
        </div>
      </div>
      <div className="info-box bg-gradient-success" title="Disponibles">
        <span className="info-box-icon">
          <i className="fas fa-unlock-alt"></i>
        </span>

        <div className="info-box-content">
          <span className="info-box-number">
            {artExistences.length !== 0
              ? stock - artExistences[0].bloq.total
              : 0}
          </span>
        </div>
      </div>
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={openCot}
        anchorEl={cotizados}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        onClose={handleCotizadosClose}
        disableRestoreFocus
      >
        {artExistences.length !== 0 ? (
          artExistences[0].cot.total !== 0 ? (
            <table className="table table-sm table-striped">
              <tbody>
                <tr>
                  <th></th>
                  <th>Evento Cotizado</th>
                  <th>Vendedor</th>
                  <th>Cantidad</th>
                </tr>
                {artExistences[0].cot.info.map((info_cot, key) => (
                  <tr key={key}>
                    <td>{key + 1}</td>
                    <td>{info_cot.nombre}</td>
                    <td>{info_cot.usuario}</td>
                    <td>{info_cot.cantidad}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            "No hay eventos que soliciten este artículo."
          )
        ) : (
          "No hay eventos que soliciten este artículo."
        )}
      </Popover>
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={openBloq}
        anchorEl={bloqueados}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        onClose={handleBloqueadosClose}
        disableRestoreFocus
      >
        {artExistences.length !== 0 ? (
          artExistences[0].bloq.total !== 0 ? (
            <table className="table table-sm table-striped">
              <tbody>
                <tr>
                  <th></th>
                  <th>Evento Confirmado</th>
                  <th>Vendedor</th>
                  <th>Cantidad</th>
                </tr>
                {artExistences[0].bloq.info.map((info_bloq, key) => (
                  <tr>
                    <td>{key + 1}</td>
                    <td>{info_bloq.nombre}</td>
                    <td>{info_bloq.usuario}</td>
                    <td>{info_bloq.cantidad}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            "No hay eventos que reserven este artículo."
          )
        ) : (
          "No hay eventos que reserven este artículo."
        )}
      </Popover>
    </>
  ) : (
    <i class="fas fa-spinner fa-spin"></i>
  );
}

export default TableProductsExistences;
