/**
 *  @name: private.jsx
 *  @version: 1.0.0
 *  @author: Anahi Aranda
 *  @description: Página privada
 */

import React, { useState, useEffect } from "react";
import { Consumer } from "../../context/index";
import { toast } from "react-toastify";
import moment from "moment";
import Modal from "react-modal";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import Layout from "../layout";
import Sidebar from "../../components/header/sidebar";
import Request from "../../utils/http";
import DownloadExcel from "../../components/sales/statement/downloadExcel";
import "moment/locale/es.js";
import "../sales/Statement.css";
const request = new Request();

function InvoicingPage(props) {
  const customStyles = {
    content: {
      position: "absolute",
      top: "5%",
      left: "10%",
      right: "10%",
      bottom: "5%",
      border: "1px solid rgb(204, 204, 204)",
      background: "rgb(255, 255, 255)",
      overflow: "auto",
      outline: "none",
      padding: "10px",
    },
  };
  // ///////////////hooks//////////////////
  const [cotizations, setCotizations] = useState([]);
  const [modal, setModal] = useState(false);
  const [edit, setEdit] = useState([]);
  const [load, setSave] = useState(false);
  const [user, setUser] = useState({
    idUsuario: 0,
    idPerfil: 0,
    nombre: "",
  });
  const [totales, setTotales] = useState({
    cot_mxn: 0,
    cot_usd: 0,
    fac_mxn: 0,
    fac_usd: 0,
  });
  const [fInicio, asignFInicio] = useState(
    `${moment(new Date()).format("YYYY-MM-")}01`
  );
  const [fFinal, asignFFinal] = useState(
    `${moment(new Date()).format("YYYY-MM-")}${new Date(
      moment(new Date()).format("YYYY"),
      moment(new Date()).format("MM"),
      0
    ).getDate()}`
  );
  const [searchDate, setSDate] = useState({
    fInicio: `${moment(new Date()).format("YYYY-MM-")}01`,
    fFinal: `${moment(new Date()).format("YYYY-MM-")}${new Date(
      moment(new Date()).format("YYYY"),
      moment(new Date()).format("MM"),
      0
    ).getDate()}`,
  });
  const [loadRows, setLoad] = useState(true);
  const [loadType, setLoadType] = useState(true);
  const [loadSeller, setLoadSeller] = useState(true);
  const [loadStatus, setLoadStatus] = useState(true);

  /**
   * guardar resultados de las consultas
   */
  const [typeList, setTypeList] = useState([]);
  const [sellerList, setSellerList] = useState([]);
  const [statusList, setStatusList] = useState([]);

  /**
   * valores predeterminados de busqueda
   */
  //const [text, setText] = useState([]);
  const [type, setType] = useState([]);
  const [seller, setSeller] = useState([]);
  const [status, setStatus] = useState([]);
  const [cStatus, setCstatus] = useState(0);
  const [eventos, setEventos] = useState([]);

  useEffect(() => {
    loadUser();
    getTypes();
    getSellers();
    getStatus();
  }, []);

  // const downloadExcel = () => {
  //   let FS = JSON.parse(sessionStorage.getItem("FilterStatemet"));
  //   if (FS === null) {
  //     FS = JSON.parse(
  //       "[[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]]"
  //     );
  //   }
  //   let filter = JSON.stringify({
  //     idPerfil: user.id_perfil,
  //     idUser: user.id,
  //     fi: dateInicio.fInicio,
  //     ff: dateFinal.fFinal,
  //     vend: FS[0].length !== 0 ? FS[0] : [],
  //     status: FS[1].length !== 0 ? FS[1] : [],
  //     tipo: FS[9].length !== 0 ? FS[9] : [],
  //   })
  //     .normalize("NFD")
  //     .replace(/[\u0300-\u036f]/g, "");
  //   window.open(
  //     `http://decocancun.com/download/excel_cons.php?filter=${btoa(filter)}`,
  //     "_blank"
  //   );
  // };

  ////////////funciones///////////////////////

  const getData = async (inicio, fin, idUsuario, idPerfil) => {
    setLoad(true);
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }

    let data = {
      inicio: inicio,
      fin: fin,
      perfil: idPerfil,
      usuario: idUsuario,
      type: [],
      seller: seller,
      status: ["Confirmado"],
      status_fac: status,
      hotel: type,
      token: token,
    };
    setSDate({ fInicio: inicio, fFinal: fin });
    const response = await request.post("/auth/reports/salesStatement", data);

    if (response.result) {
      if (response.result.movement) {
        setCotizations(response.result.movement);
        addTotal(response.result.movement);
        setLoad(false);
      } else {
        setCotizations([]);
        setLoad(false);
        console.log(response.result.message);
      }
    } else {
      setCotizations([]);
      setLoad(false);
      console.log("algo salio mal");
    }
    setCstatus(0);
    setEventos([]);
  };
  const loadUser = async () => {
    const user = await props.context.loadUser();

    if (user) {
      setUser(user);
    }
    getData(fInicio, fFinal, user.id, user.id_perfil);
  };
  const getTypes = async () => {
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }
    let data = {
      token: token,
    };

    const response = await request.post("/auth/sales/getHotel", data);

    if (response && !response.error) {
      if (response.result && !response.empty) {
        setTypeList(response.result.hotels);
        setLoadType(false);
        // response.result.types.forEach((element) => {
        //   typeDB.add(element);
        // });
      } else {
        // this.setState({
        //   empty: true,
        //   loading: false,
        //   message: response.message
        // });
      }
    } else {
      // this.setState({
      //   error: true,
      //   loading: false,
      //   message: response.message
      // });
    }
  };
  const getSellers = async () => {
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }
    let data = {
      token: token,
    };

    const response = await request.post("/auth/sales/getSellers", data);

    if (response && !response.error) {
      if (response.result && !response.empty) {
        setSellerList(response.result.vendedores);
        setLoadSeller(false);
        // response.result.vendedores.forEach((element) => {
        //   sellerDB.add(element);
        // });
      } else {
        // this.setState({
        //   empty: true,
        //   loading: false,
        //   message: response.message
        // });
      }
    } else {
      // this.setState({
      //   error: true,
      //   loading: false,
      //   message: response.message
      // });
    }
  };
  const getStatus = async () => {
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }
    let data = {
      token: token,
    };

    const response = await request.post(
      "/auth/accounting/getStatusInvoicing",
      data
    );

    if (response && !response.error) {
      if (response.result && !response.empty) {
        setStatusList(response.result);
        setLoadStatus(false);
        // response.result.type.forEach((element) => {
        //   statusDB.add(element);
        // });
      } else {
        // this.setState({
        //   empty: true,
        //   loading: false,
        //   message: response.message
        // });
      }
    } else {
      // this.setState({
      //   error: true,
      //   loading: false,
      //   message: response.message
      // });
    }
  };
  const FormatNumber = (num) => {
    let number = num || "0";
    number = number.toString().replace(/[^0-9.]/g, "");
    let val = new Intl.NumberFormat("ES-MX", {
      style: "currency",
      currency: "MXN",
      useGrouping: true,
    }).format(number);
    if (val.length === 8) {
      val = val.substring(0, 2) + "," + val.substring(2, 8);
    }
    return val;
  };
  const handleChangeFilter = async (event) => {
    let filter = JSON.parse(localStorage.getItem("statementFilter"));
    let state = [];
    if (!filter) {
      state = {
        type: type,
        seller: seller,
        status: status,
        inicio: fInicio,
        final: fFinal,
      };
    } else {
      state = {
        type: filter.type,
        seller: filter.seller,
        status: filter.status,
        inicio: filter.inicio,
        final: filter.final,
      };
    }
    switch (event.target.name) {
      case "type":
        state.type = event.target.value;
        setType(event.target.value);
        break;
      case "vendedor":
        state.seller = event.target.value;
        setSeller(event.target.value);
        break;
      case "status":
        state.status = event.target.value;
        setStatus(event.target.value);
        break;
      case "inicio":
        state.inicio = event.target.value;
        asignFInicio(event.target.value);
        break;
      case "final":
        state.final = event.target.value;
        asignFFinal(event.target.value);
        break;
      default:
        setType(state.type);
        setSeller(state.seller);
        setStatus(state.status);
        asignFInicio(state.inicio);
        asignFFinal(state.final);
        break;
    }

    window.localStorage.setItem("statementFilter", JSON.stringify(state));
  };
  const clearSearch = () => {
    let ini = `${moment(new Date()).format("YYYY-MM-")}01`;
    let fin = `${moment(new Date()).format("YYYY-MM-")}${new Date(
      moment(new Date()).format("YYYY"),
      moment(new Date()).format("MM"),
      0
    ).getDate()}`;

    setType([]);
    setSeller([]);
    setStatus([]);
    asignFInicio(ini);
    asignFFinal(fin);
    localStorage.removeItem("statementFilter");
    getData(ini, fin, user.id, user.id_perfil);
  };
  const formatUser = (str) => {
    let us = str.split(" ");
    return us[0];
  };
  const changeInvoicing = (row, key) => {
    setSave(false);
    row.fac_monto = FormatNumber(row.fac_monto);
    row.fac_moneda = row.fac_moneda ? row.fac_moneda : row.Moneda;
    setEdit(row);
    setModal(true);
  };
  const changeData = (e) => {
    //console.log("e.target.name :>> ", e.target.name, e.target.value);
    setEdit({ ...edit, [e.target.name]: e.target.value });
  };
  const changeStatus = async () => {
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }
    let data = {
      eventos: eventos,
      sta: cStatus,
      token: token,
    };
    if (eventos.length == 0) {
      toast.error(<span>No se han seleccionado eventos.</span>);
      return;
    }

    const response = await request.post(
      "/auth/accounting/changeMultiStatus",
      data
    );
    //console.log(response);
    if (response && !response.error) {
      if (response.result && !response.empty) {
        toast.success(<span>Datos de Factura, guardados con exito.</span>);
        setCstatus(0);
        setEventos([]);
        getData(fInicio, fFinal, user.id, user.id_perfil);
      } else {
        toast.error(
          <span>
            UPSS!!!, algo salio mal.. intentalo de nuevo, si el problema
            continua contacta a sistemas.
          </span>
        );
        setCstatus(0);
        setEventos([]);
      }
    } else {
      toast.error(
        <span>
          UPSS!!!, algo salio mal.. intentalo de nuevo, si el problema continua
          contacta a sistemas.
        </span>
      );
      setCstatus(0);
      setEventos([]);
    }
  };
  const addTotal = (array) => {
    let t = {
      cot_mxn: 0,
      cot_usd: 0,
      fac_mxn: 0,
      fac_usd: 0,
    };
    for (let i = 0; i < array.length; i++) {
      if (array[i].Moneda == "USD")
        t.cot_usd = parseFloat(t.cot_usd) + parseFloat(array[i].total || 0);
      else t.cot_mxn = parseFloat(t.cot_mxn) + parseFloat(array[i].total || 0);

      if (array[i].fac_moneda == "USD")
        t.fac_usd = parseFloat(t.fac_usd) + parseFloat(array[i].fac_monto || 0);
      else
        t.fac_mxn = parseFloat(t.fac_mxn) + parseFloat(array[i].fac_monto || 0);
    }
    setTotales(t);
  };
  const multipleEvents = (e) => {
    let a = eventos;
    let b = e.target.value;
    if (e.target.checked) {
      a.push(b);
    } else {
      a = a.filter((item) => item !== e.target.value);
    }

    console.log(a);
    setEventos(a);
  };
  const handleSubmit = async () => {
    setSave(true);
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }
    let data = {
      id: edit.id_evento,
      num: edit.num_factura,
      monto: edit.fac_monto,
      com: edit.fac_comision,
      mon: edit.fac_moneda,
      sta: edit.fac_status,
      num_ant: edit.num_ant,
      us: user.id,
      fecha: edit.fecha_fac,
      token: token,
    };

    const response = await request.post(
      "/auth/accounting/saveInfoInvoicing",
      data
    );

    if (response && !response.error) {
      if (response.result && !response.empty) {
        toast.success(<span>Datos de Factura, guardados con exito.</span>);
        getData(fInicio, fFinal, user.id, user.id_perfil);
        setSave(false);
        setModal(false);
        // response.result.type.forEach((element) => {
        //   statusDB.add(element);
        // });
      } else {
        toast.error(
          <span>
            UPSS!!!, algo salio mal.. intentalo de nuevo, si el problema
            continua contacta a sistemas.
          </span>
        );
        setSave(false);
        // this.setState({
        //   empty: true,
        //   loading: false,
        //   message: response.message
        // });
      }
    } else {
      toast.error(
        <span>
          UPSS!!!, algo salio mal.. intentalo de nuevo, si el problema continua
          contacta a sistemas.
        </span>
      );
      setSave(false);
      // this.setState({
      //   error: true,
      //   loading: false,
      //   message: response.message
      // });
    }
  };
  ////////////////////return///////////////////////
  return (
    <Layout
      title={`Facturación del ${moment(searchDate.fInicio).format(
        "DD-MMMM-YYYY"
      )} al ${moment(searchDate.fFinal).format("DD-MMMM-YYYY")}`}
      description="Página privada de perfil"
      keywords="Reactjs, SEO Ready, Ejemplo"
    >
      <Sidebar />

      <div className="content-wrapper" id="statement">
        <div className="card" style={{ margin: "1rem" }}>
          <div className="card-header">
            <div className="card-title" style={{ width: "100%" }}>
              <div className="row row-cols-2 row-cols-md-4 row-cols-lg-6">
                <div className="col text-center">
                  <TextField
                    id="fInicio"
                    name="inicio"
                    label="De"
                    type="date"
                    value={fInicio}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => handleChangeFilter(e)}
                  />
                </div>
                <div className="col text-center">
                  <TextField
                    id="fFinal"
                    name="final"
                    label="A"
                    type="date"
                    value={fFinal}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => handleChangeFilter(e)}
                  />
                </div>
                <div className="col text-center">
                  {loadType ? (
                    <i
                      className="fas fa-spinner fa-spin"
                      style={{ color: "#3f51b5" }}
                    ></i>
                  ) : (
                    <FormControl>
                      <InputLabel htmlFor="type">Hotel</InputLabel>
                      <Select
                        multiple
                        value={type}
                        onChange={(e) => handleChangeFilter(e)}
                        input={<Input id="type" />}
                        renderValue={(selected) => selected.join(", ")}
                        name="type"
                      >
                        {typeList.map((info, key) => (
                          <MenuItem key={key} value={info.nombre}>
                            <Checkbox
                              checked={type.indexOf(info.nombre) > -1}
                            />
                            <ListItemText primary={info.nombre} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </div>
                <div className="col text-center">
                  {loadSeller ? (
                    <i
                      className="fas fa-spinner fa-spin"
                      style={{ color: "#3f51b5" }}
                    ></i>
                  ) : (
                    <FormControl>
                      <InputLabel htmlFor="vendedor">Vendedor</InputLabel>
                      <Select
                        multiple
                        value={seller}
                        onChange={(e) => handleChangeFilter(e)}
                        input={<Input id="vendedor" />}
                        renderValue={(selected) => selected.join(", ")}
                        name="vendedor"
                      >
                        {sellerList.map((info, key) => (
                          <MenuItem key={key} id="vend" value={info.nombre}>
                            <Checkbox
                              checked={seller.indexOf(info.nombre) > -1}
                            />
                            <ListItemText primary={info.nombre} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </div>
                <div className="col text-center">
                  {loadStatus ? (
                    <i
                      className="fas fa-spinner fa-spin"
                      style={{ color: "#3f51b5" }}
                    ></i>
                  ) : (
                    <FormControl>
                      <InputLabel htmlFor="status">Status</InputLabel>
                      <Select
                        multiple
                        value={status}
                        onChange={(e) => handleChangeFilter(e)}
                        input={<Input id="status" />}
                        renderValue={(selected) => selected.join(", ")}
                        name="status"
                      >
                        {statusList.map((info, key) => (
                          <MenuItem key={key} value={info.label}>
                            <Checkbox
                              checked={status.indexOf(info.label) > -1}
                            />
                            <ListItemText primary={info.label} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </div>
                <div className="col text-right">
                  <button
                    className="btn btn-primary btn-sm button-filter"
                    onClick={() =>
                      getData(fInicio, fFinal, user.id, user.id_perfil)
                    }
                  >
                    <i className="fas fa-search"></i>
                  </button>
                  &nbsp;
                  <button
                    className="btn btn-secondary btn-sm button-filter"
                    onClick={() => clearSearch()}
                  >
                    <i className="fas fa-times"></i>
                  </button>
                  &nbsp;
                  {!loadRows ? (
                    <DownloadExcel
                      excelData={cotizations}
                      inicio={searchDate.fInicio}
                      final={searchDate.fFinal}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div
              className="row"
              style={{
                zoom: "80%",
                marginTop: "-1rem",
                position: "fixed",
                width: "20rem",
                backgroundColor: "white",
              }}
            >
              <div className="col-10 text-center">
                {loadStatus ? (
                  <i
                    className="fas fa-spinner fa-spin"
                    style={{ color: "#3f51b5" }}
                  ></i>
                ) : (
                  <FormControl>
                    <InputLabel htmlFor="cstatus">Status</InputLabel>
                    <Select
                      value={cStatus}
                      onChange={(e) => setCstatus(e.target.value)}
                      input={<Input id="cstatus" />}
                      //renderValue={(selected) => selected.join(", ")}
                      name="cstatus"
                    >
                      {statusList.map((info, key) => (
                        <MenuItem key={key} value={info.value}>
                          <ListItemText primary={info.label} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </div>
              <div className="col-1 text-left">
                <button
                  className="btn btn-primary btn-sm button-filter"
                  onClick={() => changeStatus()}
                  title="Cambiar Status"
                >
                  <i className="fas fa-exchange-alt"></i>
                </button>
              </div>
            </div>
            {!loadRows ? (
              <div className="table-responsive mt-5">
                <table className="table table-bordered table-striped table-hover table-sm">
                  <thead className="table-secondary">
                    <tr>
                      <td scope="col">-</td>
                      <td scope="col">GRTE.</td>
                      <td scope="col">FECHA</td>
                      <td scope="col">BODA/GRUPO</td>
                      <td scope="col">LUGAR</td>
                      <td scope="col">TIPO</td>
                      <td scope="col">MONTO COTIZACIÓN</td>
                      <td scope="col">$</td>
                      <td scope="col">% COM.</td>
                      <td scope="col">FORMA PAGO</td>
                      <td scope="col">No. FACTURA</td>
                      <td scope="col">FECHA FACTURA</td>
                      <td scope="col">MONTO FACTURA</td>
                      <td scope="col">$ FACT</td>
                      <td scope="col">%COM FACTURA</td>
                      <td scope="col">STATUS FACTURA</td>
                      <td scope="col">ACCIÓN</td>
                    </tr>
                  </thead>
                  <tbody>
                    {cotizations.map((row, key) => (
                      <tr key={key}>
                        <td
                          style={{
                            color: "black",
                            backgroundColor: edit.color_st,
                          }}
                        >
                          <input
                            type="checkbox"
                            name="id"
                            value={row.id_evento}
                            onChange={(e) => multipleEvents(e)}
                          />
                        </td>
                        <td
                          style={{
                            color: "black",
                            fontWeight: "bolder",
                            textShadow: "0px 0px 10px white",
                            backgroundColor: row.color_us,
                          }}
                        >
                          {formatUser(row.usuario)}
                        </td>
                        <td
                          style={{
                            textTransform: "capitalize",
                            maxWidth: "5rem",
                          }}
                        >
                          {moment(row.Fecha_inicio).format("DD-MMM-YYYY")}
                        </td>
                        <td style={{ maxWidth: "10rem" }}>{row.nom_evento}</td>
                        <td style={{ maxWidth: "6rem" }}>{row.hotel}</td>
                        <td style={{ maxWidth: "6rem" }}>{row.tipo_esp}</td>
                        <td className={row.Moneda}>
                          {FormatNumber(row.total)}
                        </td>
                        <td className={row.Moneda}>{row.Moneda}</td>
                        <td>{row.total_comision}</td>
                        <td>{row.Forma_pago}</td>
                        <td
                          style={
                            row.fac_mod == 1
                              ? { backgroundColor: "lightyellow" }
                              : {}
                          }
                        >
                          {row.num_factura}{" "}
                        </td>
                        <td>{row.fecha_fac} </td>
                        <td className={row.fac_moneda}>
                          {FormatNumber(row.fac_monto)}
                        </td>
                        <td className={row.fac_moneda}>{row.fac_moneda}</td>
                        <td>{row.fac_comision}</td>
                        <td>{row.fs_nombre}</td>
                        <td>
                          <button
                            className="btn btn-sm btn-info bg-gradient-info"
                            title="Editar"
                            onClick={() => changeInvoicing(row, key)}
                          >
                            <i
                              className="far fa-edit"
                              style={{ fontSize: "1.5rem" }}
                            ></i>
                          </button>
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td className="MX" colSpan={6}>
                        MONTO COTIZACION:
                      </td>
                      <td className="MX">{FormatNumber(totales.cot_mxn)}</td>
                      <td className="MX">MX</td>
                      <td className="MX" colSpan={4}>
                        FACTURAS
                      </td>
                      <td className="MX">{FormatNumber(totales.fac_mxn)}</td>
                      <td className="MX">MX</td>
                    </tr>
                    <tr>
                      <td className="USD" colSpan={6}>
                        MONTO COTIZACION:
                      </td>
                      <td className="USD">{FormatNumber(totales.cot_usd)}</td>
                      <td className="USD"> USD</td>
                      <td className="USD" colSpan={4}>
                        FACTURAS
                      </td>
                      <td className="USD">{FormatNumber(totales.fac_usd)}</td>
                      <td className="USD">USD</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="text-center pt-2">
                <i
                  className="fas fa-spinner fa-spin"
                  style={{ fontSize: "62px", color: "#3f51b5" }}
                ></i>
                <br /> Buscando Datos...
              </div>
            )}

            <Modal isOpen={modal} style={customStyles} contentLabel="Ver info">
              <div className="modal-header">
                <div className="col-12 row">
                  <div className="col-12 col-sm-6 col-lg-8 col-xl-9 text-left">
                    <h6 className="modal-title" style={{ width: 100 + "%" }}>
                      Factura para Evento:{" "}
                      <i>
                        "{edit.id_evento}-{edit.nom_evento}"
                      </i>
                    </h6>
                  </div>
                </div>

                <button
                  type="button"
                  className="close"
                  onClick={() => setModal(false)}
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body card-primary card-outline card-outline-tabs">
                <div className="card-header border-bottom-0"></div>
                <div className="card-body">
                  <div className="row">
                    <div class="col-md-12 text-center">
                      <h6>DATOS DE EVENTO</h6>
                    </div>
                    <div className="col-md-6">
                      <div className="input-group mb-2">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            Nombre Evento
                          </span>
                        </div>
                        <input
                          type="text"
                          name="nombreEv"
                          className="form-control"
                          id="nombreEv"
                          value={edit.nom_evento}
                          //onChange={(e) => changeData(e)}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-group mb-2">
                        <div className="input-group-prepend">
                          <span className="input-group-text">Gerente</span>
                        </div>
                        <input
                          type="text"
                          name="vendedor"
                          className="form-control"
                          id="vendedor"
                          value={edit.usuario}
                          //onChange={(e) => changeData(e)}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="input-group mb-2">
                        <div className="input-group-prepend">
                          <span className="input-group-text">Fecha</span>
                        </div>
                        <input
                          type="date"
                          name="fecha"
                          className="form-control"
                          id="fecha"
                          value={moment(edit.Fecha_inicio).format("YYYY-MM-DD")}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-group mb-2">
                        <div className="input-group-prepend">
                          <span className="input-group-text">Lugar/Hotel</span>
                        </div>
                        <input
                          type="text"
                          name="hotel"
                          className="form-control"
                          id="hotel"
                          value={edit.hotel}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="input-group mb-2">
                        <div className="input-group-prepend">
                          <span className="input-group-text">Tipo</span>
                        </div>
                        <input
                          type="text"
                          name="tipo"
                          className="form-control"
                          id="tipo"
                          value={edit.tipo_esp}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="input-group mb-2">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            Monto Cotización
                          </span>
                        </div>
                        <input
                          type="text"
                          name="monto_cot"
                          className={edit.Moneda + " form-control"}
                          id="monto_cot"
                          value={FormatNumber(edit.total)}
                          disabled
                        />
                        <div class="input-group-append">
                          <span class={edit.Moneda + " input-group-text"}>
                            {edit.Moneda}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="input-group mb-2">
                        <div className="input-group-prepend">
                          <span className="input-group-text">% Comisión</span>
                        </div>
                        <input
                          type="text"
                          name="com_coti"
                          className="form-control"
                          id="com_coti"
                          value={edit.total_comision}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-md-5">
                      <div className="input-group mb-2">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            Forma de Pago
                          </span>
                        </div>
                        <input
                          type="text"
                          name="pago"
                          className="form-control"
                          id="pago"
                          value={edit.Forma_pago}
                          disabled
                        />
                      </div>
                    </div>
                    <div class="col-md-12 text-center mt-3">
                      <hr className="mb-2" />
                      <h6>DATOS DE FACTURA</h6>
                    </div>
                    <div className="col-md-4">
                      <div className="input-group mb-2">
                        <div className="input-group-prepend">
                          <span className="input-group-text">No. Factura</span>
                        </div>
                        <input
                          type="text"
                          name="num_factura"
                          className="form-control"
                          id="num_factura"
                          value={edit.num_factura}
                          onChange={(e) => changeData(e)}
                          required=""
                          aria-required="true"
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="input-group mb-2">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            Fecha Factura
                          </span>
                        </div>
                        <input
                          type="date"
                          name="fecha_fac"
                          className="form-control"
                          id="fecha_fac"
                          value={edit.fecha_fac}
                          onChange={(e) => changeData(e)}
                          required=""
                          aria-required="true"
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="input-group mb-2">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            Monto Factura
                          </span>
                        </div>
                        <input
                          type="text"
                          name="fac_monto"
                          className="form-control"
                          id="fac_monto"
                          value={edit.fac_monto}
                          onChange={(e) => changeData(e)}
                          onBlur={(e) => {
                            setEdit({
                              ...edit,
                              fac_monto: FormatNumber(e.target.value),
                            });
                          }}
                          required=""
                          aria-required="true"
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="input-group mb-2">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            Moneda Factura
                          </span>
                        </div>
                        <select
                          className="form-control"
                          id="fac_moneda"
                          name="fac_moneda"
                          value={edit.fac_moneda}
                          onChange={(e) => changeData(e)}
                          required=""
                          aria-required="true"
                        >
                          <option value="MXN">Peso MX</option>
                          <option value="USD">Dollar USD</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="input-group mb-2">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            Comisión Factura
                          </span>
                        </div>
                        <input
                          type="text"
                          name="fac_comision"
                          className="form-control"
                          id="fac_comision"
                          value={edit.fac_comision}
                          onChange={(e) => changeData(e)}
                          required=""
                          aria-required="true"
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="input-group mb-2">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            Status Factura
                          </span>
                        </div>
                        <select
                          name="fac_status"
                          className="form-control"
                          id="fac_status"
                          value={edit.fac_status}
                          onChange={(e) => changeData(e)}
                          required=""
                          aria-required="true"
                        >
                          <option value={0}></option>
                          {statusList.map((info, key) => (
                            <option key={key} value={info.value}>
                              {info.label}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer justify-content-left pt-0 pb-0">
                <button
                  type="button"
                  onClick={() => handleSubmit()}
                  className="btn btn-outline-success btn-flat "
                  disabled={load}
                >
                  {load ? (
                    <i className="fas fa-spinner fa-spin"></i>
                  ) : (
                    <i className="far fa-save"></i>
                  )}
                  &nbsp; Guardar
                </button>

                <button
                  type="button"
                  onClick={() => setModal(false)}
                  className="btn btn-outline-danger btn-flat"
                  disabled={load}
                >
                  <i className="fas fa-ban"></i> Cerrar
                </button>
              </div>
            </Modal>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Consumer(InvoicingPage);
