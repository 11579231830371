import React, {useEffect, useState} from "react";
import Request from "../../../utils/http";
import MUIDataTable from "mui-datatables";

import ButtonSave from "../../inventory/tabs/buttonSave";
import validator from "./validatorVacation";


const request = new Request();

const customStyles = {
    content: {
      width: "100px"
    }
};


export default function FormBanco(props){


    const [banco, setBanco]= useState({
        tipoBanco:''
    });
    const [bancoList, setBancoList] = useState([]);
    //nuestro token para todos
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;

    if (token1) {
        token = token1.id;
    }

    useEffect(() => {
        getBank();
  }, []);

  const getBank = async () => {
    let data = {
      token: token
    }
    const response = await request.post("/employee/getBank", data);
    //validar los datos que recibimos
    if (response && !response.error) {
      if (response.result.banco && !response.empty) {
        setBancoList(response.result.banco);
      } else {
        console.log(response.result.message);
      }
    } else {
        console.log("error en la peticion");
    }
}


    const save= async () => {

        let datos = (banco); 

        let data = {
            token: token,
            datos: datos
        };
        
        const response = await request.post("/employee/saveBanco", data);
            if (response && !response.error) {
                if (response.result.ok && !response.empty) {
                  alert("Datos guardados con exito");
                  props.recargar();
                } else {
                  console.log(response.result.message);
                }
              } else {
                console.log("error en la peticion");
            }

    }




  const columns = [
    {
     name: "banco",
     label: "Nombre",
     options: {
      filter: true,
      sort: false,
     }
    },
    
   ];
   

   
   const options = {
    selectableRows: "none",
    filter: false,
    viewColumns: false,
    textLabels: {
      body: {
        noMatch: "No se encontraron resultados.",
        toolTip: "Sort"
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Número de filas:",
        displayRows: "de"
      },

      toolbar: {
        search: "Buscar",
        downloadCsv: "Descargar Excel",
        print: "Imprimir"
      },

      filter: {
        all: "TODOS",
        title: "FILTROS",
        reset: "LIMPIAR"
      },
      viewColumns: {
        title: "Mostar Columnas",
        titleAria: "Mostrar/Ocultar Columnas de la Tabla"
      },
      selectedRows: {
        text: "Fila(s) Seleccionadas",
        delete: "Eliminar",
        deleteAria: "Eliminar Filar Seleccionadas"
      }
    },
  };

    return(
        <div className="tab-content">
            <div className="row mt-2">
                <div className="col-md-12">
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Nombre Bancon</span>
                        </div>
                        <input type="text" id="tipoBanco" name="tipoBanco" className="form-control"
                            value={banco.tipoBanco} 
                            onChange={e => setBanco({...banco,tipoBanco:e.target.value.toUpperCase()})}
                          />
                    </div>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-md-4">
                    <ButtonSave  save={save}/>
                </div> 
            </div>

            <div className="row mt-2">
                <div className="col-md-12">
                    <MUIDataTable
                        data={bancoList}
                        columns={columns}
                        options={options}
                    />  
                </div>
            </div>

        </div>
    );
}