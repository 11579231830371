import React, { Component } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import EditIcon from "@material-ui/icons/Edit";
import Modal from "react-modal";

import ModalAgregarAuxiliares from "./agregarAuxiliares";


Modal.setAppElement(document.getElementById("root"));
const customStyles = {
  content: {
    top: "50%",
    left: "80%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    overflow: "scroll"
  }
};

class NewHorasExtras extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
      idArt: 0,
      eddit: this.props.eddit,
      saved: 0
    };
  }
  closeModal() {
    this.openModal();
  }
  reload() {
    this.props.reload();
  }

  render() {
    return (
      <>
        <button
          className="btn btn-sm btn-success bg-gradient-success"
          title="Agregar Auxiliares"
          onClick={this.openModal.bind(this)}
        >
          <i className="fas fa-user-alt"></i> Agregar Aux.
        </button>
        {
          <Modal
            isOpen={this.state.openModal}
            style={customStyles.content}
            contentLabel="EditarArticulo"
          >
            <div className="modal-header">
              <h4 className="modal-title">Agregar Auxiliares</h4>
              <button
                type="button"
                className="close"
                onClick={this.openModal.bind(this)}
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body card-primary card-outline card-outline-tabs">
              <div className="card-body">
              <ModalAgregarAuxiliares  
                datos={this.props.datos}
                closeModal={this.closeModal.bind(this)}

              />
              </div>
            </div>
          </Modal>
        }
      </>
    );
  }
  openModal() {
    this.setState({
      openModal: !this.state.openModal
    });
  }
}
export default NewHorasExtras;
