import React, { Component } from "react";
import Modal from "react-modal";
import { Consumer } from "../../context/index";
import { toast } from "react-toastify";
import Select from "react-select";
import Request from "../../utils/http";
import moment from "moment";
import "./modal.css";
import QRScanner from "./qrScanner";

const request = new Request();
Modal.setAppElement(document.getElementById("root"));
const customStyles = {
  content: {
    position: "absolute",
    top: "5%",
    left: "15%",
    right: "15%",
    bottom: "30%",
    border: "1px solid rgb(204, 204, 204)",
    background: "rgb(255, 255, 255)",
    overflow: "auto",
    outline: "none",
    padding: "20px",
  },
};
const customStylesSelect = {
  control: (styles) => {
    return {
      ...styles,
      marginTop: -7 + "px",
      borderRadius: "0.25rem",
      background: "none",
      borderStyle: "none",
    };
  },
};

class newEntry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
      user: {
        idUsuario: 0,
        idPerfil: 0,
        nombre: "",
      },
      sellers: [],
      types: [],
      articles: [],
      events: [],
      seller: 0,
      type: 1,
      locations: [],
      location1: 0,
      location2: 0,
      article: { value: null, label: null },
      fecha: `${moment(new Date()).format("YYYY-MM-DD")}`,
      cantidad: 1,
      event: { value: null, label: null },
      concepto: "",
      load: 0,
      isOpen: false,
    };
  }

  render() {
    return (
      <>
        <Modal
          isOpen={this.props.openModal}
          style={customStyles}
          contentLabel="EditarArticulo"
        >
          <div className="modal-header">
            <h6 className="modal-title">
              Agregar Entradas/Salidas de Artículo
            </h6>
            <button
              type="button"
              className="close"
              onClick={this.closeModal.bind(this)}
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body card-primary card-outline card-outline-tabs">
            <div className="card-body">
              <div className="row mt-2">
                <div className="col-md-1">
                  <button
                    type="button"
                    class="btn btn-info bg-gradient-info"
                    onClick={this.openHello.bind(this)}
                  >
                    <i class="fas fa-qrcode"></i>
                  </button>
                </div>
                <div className="col-md-5">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">ID Artículo:</span>
                    </div>
                    <Select
                      key="0"
                      className="pl-0 form-control"
                      name="id_art"
                      id="id_art"
                      options={this.state.articles}
                      //value={this.state.seller}
                      value={this.state.article}
                      styles={customStylesSelect}
                      onChange={this.changeArt.bind(this)}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">Tipo:</span>
                    </div>
                    <select
                      name="tipo"
                      value={this.state.type}
                      className="form-control"
                      id="tipo"
                      onChange={this.changeType.bind(this)}
                    >
                      {this.state.types.map((t, key) => (
                        <option key={key} value={t.id_tipo}>
                          {t.tdesc}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-6">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">Cantidad:</span>
                    </div>
                    <input
                      type="number"
                      className="form-control"
                      aria-label=""
                      value={this.state.cantidad}
                      name="cantidad"
                      id="cantidad"
                      onChange={this.changeCantidad.bind(this)}
                    ></input>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        Fecha Movimiento:
                      </span>
                    </div>
                    <input
                      type="date"
                      className="form-control"
                      aria-label=""
                      value={this.state.fecha}
                      name="fecha"
                      id="fecha"
                      onChange={this.changeFecha.bind(this)}
                    ></input>
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                {this.state.type == 1 || this.state.type == 2 ? (
                  <div className="col-md-6">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">Evento:</span>
                      </div>
                      <Select
                        key="1"
                        className="pl-0 form-control"
                        name="evento"
                        id="evento"
                        options={this.state.events}
                        //value={this.state.seller}
                        value={this.state.event}
                        styles={customStylesSelect}
                        onChange={this.changeEvent.bind(this)}
                      />
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="col-md-6">
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            Ubicación Origen:
                          </span>
                        </div>
                        <Select
                          key="3"
                          className="pl-0 form-control"
                          name="ubi_envia"
                          id="ubi_envia"
                          options={this.state.locations}
                          //value={this.state.seller}
                          defaulValue={this.state.location1}
                          styles={customStylesSelect}
                          onChange={this.changeUbi1.bind(this)}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            Ubicación Entrega:
                          </span>
                        </div>
                        <Select
                          key="4"
                          className="pl-0 form-control"
                          name="ubi_recibe"
                          id="ubi_recibe"
                          options={this.state.locations}
                          //value={this.state.seller}
                          defaulValue={this.state.location2}
                          styles={customStylesSelect}
                          onChange={this.changeUbi2.bind(this)}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 mt-2">
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">Empleado:</span>
                        </div>
                        <Select
                          key="2"
                          className="pl-0 form-control"
                          name="vendedor"
                          id="vendedor"
                          options={this.state.sellers}
                          //value={this.state.seller}
                          defaulValue={this.state.seller}
                          styles={customStylesSelect}
                          onChange={this.changeSeller.bind(this)}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 mt-2">
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">Concepto:</span>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          aria-label=""
                          value={this.state.concepto}
                          name="concepto"
                          id="concepto"
                          onChange={this.changeConcepto.bind(this)}
                        ></input>
                      </div>
                    </div>
                    {/*<QrReader
                    onResult={(result, error) => {
                      if (!!result) {
                        this.setState({ data: result?.text });
                      }

                      if (!!error) {
                        console.info(error);
                      }
                    }}
                    style={{ width: "100%" }}
                  />
                  <p>{this.state.data}</p>*/}
                  </>
                )}
              </div>
              <div className="modal-footer justify-content-left mt-3">
                <button
                  type="button"
                  className="btn btn-outline-success btn-flat "
                  onClick={this.handleSubmit.bind(this)}
                >
                  <i className="far fa-save"></i>&nbsp; Guardar
                </button>
                <button
                  type="button"
                  className="btn btn-outline-danger btn-flat"
                  onClick={this.closeModal.bind(this)}
                >
                  <i className="fas fa-ban"></i> Cerrar
                </button>
              </div>
            </div>
          </div>
        </Modal>
        <Modal isOpen={this.state.isOpen}>
          <div className="modal-header">
            <h6 className="modal-title">Scan QR Code</h6>
            <button
              type="button"
              className="close"
              onClick={this.isClose.bind(this)}
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <QRScanner changeData={(e) => this.changeData(e)} />
        </Modal>
      </>
    );
  }
  
  componentDidMount() {
    this.load();
    this.getArticles();
    this.getEmploye();
    this.getType();
    this.getLocations();
    this.getEvents(this.state.fecha);
  }

  async load() {
    const user = await this.props.context.loadUser();

    if (user) {
      this.setState({
        user: user,
      });
    }
  }
  closeModal() {
    this.props.setOpenModal({
      id: 0,
      nom: "",
    });
  }
  reload(data) {
    this.props.reload(data);
  }
  async getEmploye() {
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }
    let data = {
      token: token,
    };

    const response = await request.post("/auth/rh/getEmploye", data);

    if (response && !response.error) {
      if (response.result && !response.empty) {
        let sellers = [];
        response.result.map((info, key) =>
          sellers.push({
            value: info.id,
            label: (info.name + " " + info.last_name).toUpperCase(),
          })
        );
        this.setState({ sellers: sellers });
        //setLoadSeller(false);
        // response.result.vendedores.forEach((element) => {
        //   sellerDB.add(element);
        // });
      } else {
        // this.setState({
        //   empty: true,
        //   loading: false,
        //   message: response.message
        // });
      }
    } else {
      // this.setState({
      //   error: true,
      //   loading: false,
      //   message: response.message
      // });
    }
  }
  async getType() {
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }
    let data = {
      token: token,
    };

    const response = await request.post("/auth/inventory/getType", data);

    if (response && !response.error) {
      if (response.result && !response.empty) {
        this.setState({ types: response.result.type });
      } else {
        // this.setState({
        //   empty: true,
        //   loading: false,
        //   message: response.message
        // });
      }
    } else {
      // this.setState({
      //   error: true,
      //   loading: false,
      //   message: response.message
      // });
    }
  }
  async getArticles() {
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }
    let data = {
      token: token,
      band: 1,
      idPerfil: this.state.user.idPerfil,
    };

    const response = await request.post("/auth/inventory/getArticles", data);

    if (response && !response.error) {
      if (response.result && !response.empty) {
        let articles = [];
        response.result.map((info, key) =>
          articles.push({
            value: info.id_art,
            label: info.id_art + " - " + info.nomE,
          })
        );
        this.setState({ articles: articles });
      } else {
        this.setState({
          empty: true,
          loading: false,
          message: response.message,
        });
      }
    } else {
      this.setState({
        error: true,
        loading: false,
        message: response.message,
      });
    }
  }
  async getEvents(fecha) {
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }
    let data = {
      token: token,
      fecha: fecha,
    };

    const response = await request.post("/auth/inventory/getEvents", data);

    if (response && !response.error) {
      if (response.result && !response.empty) {
        let events = [];
        response.result.map((info, key) =>
          events.push({
            value: info.id_evento,
            label: info.nombre + " - " + info.id_evento,
          })
        );
        this.setState({ events: response.result });
        //setLoadSeller(false);
        // response.result.vendedores.forEach((element) => {
        //   sellerDB.add(element);
        // });
      } else {
        // this.setState({
        //   empty: true,
        //   loading: false,
        //   message: response.message
        // });
      }
    } else {
      // this.setState({
      //   error: true,
      //   loading: false,
      //   message: response.message
      // });
    }
  }
  async getLocations() {
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }
    let data = {
      token: token,
    };

    const response = await request.post("/auth/sales/getubicaciones", data);

    if (response && !response.error) {
      if (response.result && !response.empty) {
        let l = response.result.ubicaciones;
        let loc = [];
        for (let i = 0; i < l.length; i++) {
          loc.push({ label: l[i].lugar, value: l[i].id_ubicacion });
        }
        this.setState({ locations: loc });
      } else {
        // this.setState({
        //   empty: true,
        //   loading: false,
        //   message: response.message
        // });
      }
    } else {
      // this.setState({
      //   error: true,
      //   loading: false,
      //   message: response.message
      // });
    }
  }
  changeArt(e) {
    this.setState({ article: e });
  }
  changeType(e) {
    if (e.target.value == 1 || e.target.value == 1) {
      this.setState({ seller: 0, concepto: "" });
    } else if (e.target.value == 3 || e.target.value == 4) {
      this.setState({ event: "" });
    } else {
      this.setState({ seller: 0, concepto: "", event: "" });
    }
    this.setState({ type: e.target.value });
  }
  changeCantidad(e) {
    this.setState({ cantidad: e.target.value });
  }
  changeFecha(e) {
    this.setState({ fecha: e.target.value });
    this.getEvents(e.target.value);
  }
  changeEvent(e) {
    this.setState({ event: e });
  }
  changeSeller(e) {
    this.setState({ seller: e.value });
  }
  changeUbi1(e) {
    this.setState({ location1: e.value });
  }
  changeUbi2(e) {
    this.setState({ location2: e.value });
  }
  changeConcepto(e) {
    this.setState({ concepto: e.target.value });
  }
  async handleSubmit() {
    //this.setState({ load: true });
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }
    let data = {
      token: token,
      idTipo: this.state.type,
      idArt: this.state.article.value,
      idEvent: this.state.event.value
        ? this.state.event.value.toString()
        : "0000",
      cantidad: this.state.cantidad,
      fecha: this.state.fecha,
      idUsuario: this.state.seller,
      concepto: this.state.concepto.toString(),
      ubi_envia: this.state.location1,
      ubi_recibe: this.state.location2,
      id_user: this.state.user.id
    };
    let e = data.idEvent.length;
    let c = data.concepto.length;

    if (data.idArt <= 0) {
      document.getElementById("id_art").classList.add("is-invalid");
      return;
    }
    if (data.idTipo == 1 || data.idTipo == 2) {
      if (e <= 0) {
        document.getElementById("evento").classList.add("is-invalid");
        return;
      }
    }
    if (data.idTipo == 3 || data.idTipo == 4) {
      if (data.idUsuario <= 0) {
        document.getElementById("vendedor").classList.add("is-invalid");
        return;
      }

      if (c <= 0) {
        document.getElementById("concepto").classList.add("is-invalid");
        return;
      }
    }

    const response = await request.post(
      "/auth/inventory/saveInventoryMovements",
      data
    );

    if (response && !response.error) {
      if (response.result && !response.empty) {
        //this.setState({ load: false });
        toast.success(<span>Movimiento registrado con exito.</span>, {
          position: toast.POSITION.TOP_RIGHT,
          theme: "colored",
        });
        //this.closeModal();
        this.setState({
          seller: 0,
          type: 1,
          location1: 0,
          location2: 0,
          article: { value: null, label: null },
          fecha: `${moment(new Date()).format("YYYY-MM-DD")}`,
          cantidad: 1,
          event: { value: null, label: null },
          concepto: "",
        });
        this.props.reload();
      } else {
        /* this.setState({
          empty: true,
          loading: false,
          message: response.message,
        });*/
        toast.error(
          <span>
            ¡¡¡UPSS!!!, algo salio mal.. intentalo de nuevo, si el problema
            continua contacta a sistemas.
          </span>,
          {
            position: toast.POSITION.TOP_RIGHT,
            theme: "colored",
          }
        );
      }
    } else {
      this.setState({
        error: true,
        loading: false,
        message: response.message,
      });
    }
  }
  openHello = () => {
    this.setState({ isOpen: true });
  };
  isClose = () => {
    this.setState({ isOpen: false });
  };
  changeData(e) {
    let data = e.split(",");
    this.setState({
      event: { value: Number(data[0]), label: Number(data[0]) },
      article: { value: Number(data[2]), label: Number(data[2]) },
      cantidad: data[3],
      isOpen: false,
    });
  }
}

export default Consumer(newEntry);
