import React, { useEffect, useState } from "react";
import Request from "../../../utils/http";
import MUIDataTable from "mui-datatables";

import ButtonSave from "../../inventory/tabs/buttonSave";
import validator from "./validatorVacation";


const request = new Request();


export default function FormAlergies(props) {

  const [alergias, setAlergias] = useState({
    alergies: ''
  });
  const [alergiasList, setAlergiasList] = useState([]);
  //nuestro token para todos
  let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
  let token;

  if (token1) {
    token = token1.id;
  }

  useEffect(() => {
    getAllergies();
  }, []);

  const getAllergies = async () => {
    let data = {
      token: token
    }
    const response = await request.post("/employee/getAllergies", data);
    //validar los datos que recibimos
    if (response && !response.error) {
      if (response.result.alergia && !response.empty) {
        setAlergiasList(response.result.alergia);
      } else {
        console.log(response.result.message);
      }
    } else {
      console.log("error en la peticion");
    }
  }


  const save = async () => {

    let datos = (alergias);

    let data = {
      token: token,
      datos: datos
    };

    const response = await request.post("/employee/saveAlergias", data);
    if (response && !response.error) {
      if (response.result.ok && !response.empty) {
        alert("Datos guardados con exito");
        props.recargar();
      } else {
        console.log(response.result.message);
      }
    } else {
      console.log("error en la peticion");
    }

  }




  const columns = [
    {
      name: "allergie",
      label: "nombre",
      options: {
        filter: true,
        sort: false,
      }
    },

  ];



  const options = {
    selectableRows: "none",
    filter: false,
    viewColumns: false,
    textLabels: {
      body: {
        noMatch: "No se encontraron resultados.",
        toolTip: "Sort"
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Número de filas:",
        displayRows: "de"
      },

      toolbar: {
        search: "Buscar",
        downloadCsv: "Descargar Excel",
        print: "Imprimir"
      },

      filter: {
        all: "TODOS",
        title: "FILTROS",
        reset: "LIMPIAR"
      },
      viewColumns: {
        title: "Mostar Columnas",
        titleAria: "Mostrar/Ocultar Columnas de la Tabla"
      },
      selectedRows: {
        text: "Fila(s) Seleccionadas",
        delete: "Eliminar",
        deleteAria: "Eliminar Filar Seleccionadas"
      }
    },
  };

  return (
    <div className="tab-content">
      <div className="row mt-2">
        <div className="col-md-12">
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text">Nombre Alergia</span>
            </div>
            <input type="text" id="documents" name="alergies" className="form-control"
              value={alergias.alergies}
              onChange={e => setAlergias({ ...alergias, alergies: e.target.value.toUpperCase()})}
            />
          </div>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-md-4">
          <ButtonSave save={save} />
        </div>
      </div>

      <div className="row mt-2">
        <div className="col-md-12">
          <MUIDataTable
            data={alergiasList}
            columns={columns}
            options={options}
          />
        </div>
      </div>

    </div>
  );
}