/**
 *  users.js
 *  @version: 1.0.0
 *  @author: Sergio
 *  @description: Manejador de ejecuciones del Context para Usuarios
 */

export async function loadUser() {
  let user = JSON.parse(window.sessionStorage.getItem("decoAdmin"));
  //console.log('user :>> ', user);
  if (user) {
    this.setState({ user: user.user });
    return user.user;
  }
}

export function login(data) {
  window.sessionStorage.setItem("decoAdmin", JSON.stringify(data));
  this.setState({ user: data.user });
}

export function logout() {
  window.sessionStorage.setItem("decoAdmin", '{"auth": false}');
  this.setState({ user: { auth: false } });
  window.localStorage.clear();
  window.sessionStorage.clear();

  window.sessionStorage.removeItem("decoAdmin");
  window.sessionStorage.removeItem("Menus");
  window.sessionStorage.removeItem("Productos");
}

// function parseJwt(token) {
//   var base64Url = token.split(".")[1];
//   var base64 = base64Url.replace("-", "+").replace("_", "/");
//   return JSON.parse(window.atob(base64));
// }
