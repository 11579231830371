import React from "react";
import Layout from "../layout";
import Sidebar from "../../components/header/sidebar";
import TablePermisos from "../../components/rh/permisos/tablePermisos";


const customStyles = {
  content: {
    width: "7700px"
  }
};

const Permisos = () => (

  <Layout>
    <Sidebar />
    <div className="white-space-32"></div>
      <div className="container_fluid d-flex justify-content-center">
        <div className="card_empleado">
          <div className="card-body">
            <TablePermisos/>
          </div>
        </div>
      </div>
    <div className="white-space-32"></div>
  </Layout>
);

export default Permisos;
