import React from "react";
import Layout from "./layout";
import Sidebar from "../components/header/sidebar";
import Provider from "../components/purchases/provider";
import FilterProvider from "../components/purchases/filterProvider";

const Provider_page = () => (
  <Layout
    title="Proveedores de productos y servicios"
    description="Página privada de perfil"
    keywords="Reactjs, SEO Ready, Ejemplo"
  >
    <Sidebar />
    <div className="content-wrapper">
      <div className="card">
        <div className="card-body">
          <Provider />
        </div>
      </div>
    </div>
  </Layout>
);

export default Provider_page;
