import React, {useEffect, useState} from "react";
import MUIDataTable from "mui-datatables";
import Request from "../../../utils/http";
import ButtonAgregarAuxiliar from "./buttonAgregarAuxiliar";
import ButtonPaseLista from "./buttonPaseLista";
import moment from "moment";

const customStyles = {
  content: {
     width: "260px"
  }
};

function TableHorasExtras(){

  const request = new Request();

  const [horasExtras, setHorasExtras]=useState([]);
  //nuestro token para todos
  let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
  let token=token1.id;

  useEffect(() => {
    getEventosConfirmados();
  }, []);

const getEventosConfirmados = async () => {

    let data = {
      token: token
    };
    const response = await request.post("/horasExtras/getEventosConfirmados", data);
    console.log(response);
    
    if (response && !response.error) {
      if (response.result.eventosConfirmados && !response.empty) {
        setHorasExtras(response.result.eventosConfirmados);
      } else {
          console.log(response.result.message);
      }
  } else {
      console.log("error en la peticion");
  }

}



const columns = [
  {
      name: "nombreEvento",
      label: "Nombre de Evento",
      options: {
          filter: true,
          sort: false,
      }
  },
  {
      name: "nombreHotel",
      label: "Nombre del Hotel",
      options: {
          filter: true,
          sort: true,
      }
  },
  {
      name: "Fecha_inicio",
      label: "Fecha de Evento",
      options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            let fecha=moment(value).format("DD-MM-YYYY hh:mm:ss"); 
            return <p>{fecha=="Invalid date"? '':`${fecha}`}</p>
        }

      }
  },
  {
      name: "",
      label: "Acciones",
      options: {
          filter: true,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
                <div style={customStyles.content}>
                    <ButtonAgregarAuxiliar datos={horasExtras[tableMeta.rowIndex]}></ButtonAgregarAuxiliar>
                    <ButtonPaseLista datos={horasExtras[tableMeta.rowIndex]}></ButtonPaseLista>
                    
                </div>
            )
        }
      }
  }

];


const options = {
    selectableRows: "none",
    filter: false,
    viewColumns: false,
    responsive: "standard",
    textLabels: {
        body: {
            noMatch: "No se encontraron resultados.",
            toolTip: "Sort"
        },
        pagination: {
            next: "Siguiente",
            previous: "Anterior",
            rowsPerPage: "Número de filas:",
            displayRows: "de"
        },

        toolbar: {
            search: "Buscar",
            downloadCsv: "Descargar Excel",
            print: "Imprimir"
        },

        filter: {
            all: "TODOS",
            title: "FILTROS",
            reset: "LIMPIAR"
        },
        viewColumns: {
            title: "Mostar Columnas",
            titleAria: "Mostrar/Ocultar Columnas de la Tabla"
        },
        selectedRows: {
            text: "Fila(s) Seleccionadas",
            delete: "Eliminar",
            deleteAria: "Eliminar Filar Seleccionadas"
        }

    },
};



  return ( 
    <MUIDataTable
      title={"Eventos Confirmados"}
      data={horasExtras}
      columns={columns}
      options={options}
    />
  )

}
export default TableHorasExtras;

