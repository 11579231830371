/**
 *  @name: ListWorksPage.jsx
 *  @version: 1.0.0
 *  @author: Anahi Aranda
 *  @description: Página privada
 */

import React, { useState, useEffect } from "react";
import { Consumer } from "../../context/index";
import Layout from "./../layout";
import Sidebar from "../../components/header/sidebar";
import Request from "../../utils/http";
import Works from "../../components/desing/works";
const request = new Request();

function ListWorks(props) {
  const [modalM, showModalM] = useState(false);
  const [idOds] = useState(0);
  const [search, setSearch] = useState(false);
  const [user, setUser] = useState({
    idUsuario: 0,
    idPerfil: 0,
    nombre: "",
    idUbicacion: 0,
  });
  const [newCot, setNewCot] = useState(false);

  useEffect(() => {
    loadUser();
  }, []);

  const loadUser = async () => {
    const user = await props.context.loadUser();

    if (user) {
      setUser(user);
    }
  };

  return (
    <Layout
      title="Lista de Trabajos de Diseño"
      description="Página privada de perfil"
      keywords="Reactjs, SEO Ready, Ejemplo"
    >
      <Sidebar />
      <div className="content-wrapper">
        <div className="card">
          <div className="card-body">
            <Works />
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Consumer(ListWorks);
