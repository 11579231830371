import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import Request from "../../utils/http";
import NewWork from "./newWork";

const request = new Request();

function Works() {
  //set de valores
  const [Works, setWorks] = useState([]);
  //obteneos token
  let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
  let token;
  if (token1) {
    token = token1.id;
  }
  useEffect(() => {
    getWorks();
  }, []);

  const getWorks = async () => {
    let data = {
      token: token,
      ubi: -1,
    };
    const response = await request.post("/auth/design/getWorks", data);

    //validar los datos que recibimos
    if (response && !response.error) {
      if (response.result && !response.empty) {
        setWorks(response.result);
      } else {
        console.log(response.result.message);
      }
    } else {
      console.log("error en la peticion");
    }
  };
  const FormatNumber = (number) => {
    let val = new Intl.NumberFormat("ES-MX", {
      style: "currency",
      currency: "MXN",
      useGrouping: true,
    }).format(number);
    if (val.length === 8) {
      val = val.substring(0, 2) + "," + val.substring(2, 8);
    }
    return val;
  };
  const columns = [
    {
      name: "id_trabajo",
      label: "ACCIÓN",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
              <NewWork
                key={tableMeta.rowIndex}
                eddit={true}
                reload={getWorks}
                id={value}
              ></NewWork>
            </div>
          );
        },
      },
    },
    {
      name: "imagen",
      label: "IMAGEN",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <img
              src={`${request.publicURL()}/diseno/${value}`}
              style={{ maxHeight: "70px", maxWidth: "100px" }}
              alt="Imagen Data"
              onError={(e) => {
                e.target.src = "./assets/uploads/no-image.jpg";
              }}
            />
          );
        },
      },
    },
    {
      name: "codigo",
      label: "ID",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "t_trabajo",
      label: "DESCRIPCIÓN",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "lugar",
      label: "UBICACIÓN",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "ancho",
      label: "ANCHO (Mt)",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "alto",
      label: "ALTO (Mt)",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "costo_prod",
      label: "COSTO PRODUCCIÓN",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return FormatNumber(value);
        },
      },
    },

    {
      name: "costo",
      label: "COSTO",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return FormatNumber(value);
        },
      },
    },
  ];

  const options = {
    selectableRows: "none",
    filter: false,
    viewColumns: false,
    textLabels: {
      body: {
        noMatch: "No se encontraron resultados.",
        toolTip: "Sort",
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Número de filas:",
        displayRows: "de",
      },
      toolbar: {
        search: "Buscar",
        downloadCsv: "Descargar Excel",
        print: "Imprimir",
      },
      filter: {
        all: "TODOS",
        title: "FILTROS",
        reset: "LIMPIAR",
      },
      viewColumns: {
        title: "Mostar Columnas",
        titleAria: "Mostrar/Ocultar Columnas de la Tabla",
      },
      selectedRows: {
        text: "Fila(s) Seleccionadas",
        delete: "Eliminar",
        deleteAria: "Eliminar Filar Seleccionadas",
      },
    },
    customToolbar: () => {
      return <NewWork eddit={false} reload={getWorks} id={0} />;
    },
  };

  return (
    <MUIDataTable
      title={"LISTA DE TRABAJOS"}
      data={Works}
      columns={columns}
      options={options}
    />
  );
}
export default Works;
