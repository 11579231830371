import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from '@material-ui/icons/Delete';
import Request from "../../utils/http";
import {Consumer} from "../../context/index";

import ButtonNewEmployee from "./buttonEmployee";
import ButtonEditarEmpleado from "./forms/btnEditarEmpleado";
import ButtonEliminarEmpleado from "./forms/btnEliminarEmpleado";
import ButtonDetalles from "./forms/bottonDetalles";
import ButtonVacation from "./forms/buttonVacations";
import moment from "moment";


function TableEmpleados(props) {

    const request = new Request();
    const [employee, setEmployee] = useState([]);

    
    //obteneos token
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
        token = token1.id;
    }
    useEffect(() => {
        getUser();
    }, []);

    //con este metodo nos permite ver usuario que inicio session
    const getUser = async () => {
        const user = await props.context.loadUser();
        //nos permite enviar datos quien inicio sesion
        if(user.id!=null && user.id_perfil!=null && user.ubicacion!=null){
            getEmpleados(user.id,user.id_perfil,user.ubicacion);
        }
    }

    const getEmpleados = async (id_users,perfil,ubicacion) => {

        let data = {
            token: token,
            baja:0,
            id_users:id_users,
            perfil:perfil,
            ubicacion:ubicacion
        }
        const response = await request.post("/employee/getEmployee", data);
        //validar los datos que recibimos
        if (response && !response.error) {
            if (response.result.empleados && !response.empty) {
                setEmployee(response.result.empleados);
            } else {
                console.log(response.result.message);
            }
        } else {
            console.log("error en la peticion");
        }
    }

    // Refrescamos la tabla de empleados
    // function refresTable(id_empleado) {
    //     let new_employee = Object.assign([],employee);
    //     if (new_employee.length>0 || Object.keys(new_employee).length>0){
    //         for(let i in new_employee){
    //             let empleado = new_employee[i];
    //             if (empleado.id == id_empleado){
    //                 new_employee.splice(i, 1);
    //                 break;
    //             }
    //         }
    //         setEmployee([]);
    //         setEmployee(new_employee);
    //     }
    // }

    // const eliminar= async(datosId)=>{     

    //     let data = {
    //         id: datosId,
    //         token: token
    //     };

    //     const response = await request.post("/employee/deleteEmployee", data);

    //     //Validar los datos que recibimos
    //     if (response && !response.error) {
    //         let status = (response['result'].hasOwnProperty('status')) ? response['result']['status'] : 0; // 0 = Eliminado
    //         if (response.result.ok) {
    //             alert("Empleado Eliminado");
    //             refresTable(datosId);
                
    //         } else {
    //             console.log(response.result.message);
    //         }
    //     } else {
    //         console.log("error en la peticion");
    //     }

    // }

    const columns = [

        {
            name: "id",
            label: "Num-Emp",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "avatar",
            label: "Foto",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    let foto=employee[tableMeta.rowIndex].avatar;   
                    return <img  id="foto" src={foto=='' ? `./assets/uploads/empleados/no-image.jpg`:`./assets/uploads/empleados/${foto}`}  
                    alt=""/>
                }
            }
        },
        {
            name: "name",
            label: "Nombre",
            options: {
                filter: true,
                sort: true,
            }
        },

        {
            name: "last_name",
            label: "Apellido",
            options: {
                filter: true,
                sort: false,
            }
        },
        {
            name :"salary_daily",
            label: "Salario Diario",
            options: {
                filter: true,
                sort: false,
            }
        },

        {
            name: "puesto",
            label: "Puesto",
            options: {
                filter: true,
                sort: false,
            }
        },

        {
            name: "nombre_area",
            label: "Departamento",
            options: {
                filter: true,
                sort: false,
            }
        },

        {
            name: "sucursal",
            label: "Sucursal",
            options: {
                filter: true,
                sort: false,
            }
        },

        {
            name: "date_hire",
            label: "Fecha Ingreso",
            options: {
                filter: true,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    let fecha=moment(value).format("DD-MM-YYYY"); 
                    return <p>{fecha=="Invalid date"? '':`${fecha}`}</p>
                }
            }
        },

        {
            name: "nss",
            label: "Alta del Seguro",
            options: {
                filter: true,
                sort: false,
            }
        },

        {
            name: "banco",
            label: "Tarjeta de Nomina",
            options: {
                filter: true,
                sort: false,
            }
        },

        {
            name: "hours_ex",
            label: "Horas Extras",
            options: {
                filter: true,
                sort: false,
            }
        },
        {
            name: "num_lockers",
            label: "Num locker",
            options: {
                filter: true,
                sort: false,
            }
        },
        {
            name: "state",
            label: "Aciones",
            options: {
                filter: true,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    //console.log(employee[tableMeta.rowIndex].id);
                    return (
                        <div className="configuracion">
                            <ButtonEditarEmpleado datos={employee[tableMeta.rowIndex]} updateEmpleado={getEmpleados}></ButtonEditarEmpleado>
                            <ButtonDetalles datos={employee[tableMeta.rowIndex]}></ButtonDetalles>
                            <ButtonVacation datos={employee[tableMeta.rowIndex]}></ButtonVacation>
                            <ButtonEliminarEmpleado datos={employee[tableMeta.rowIndex]} updateEmpleado={getEmpleados}/>
                        </div>
                    )
                }
            }
        }
    ];

    const options = {
        selectableRows: 'none',
        filter: false,
        viewColumns: false,
        responsive: "standard",
        textLabels: {
            body: {
                noMatch: "No se encontraron resultados.",
                toolTip: "Sort"
            },
            pagination: {
                next: "Siguiente",
                previous: "Anterior",
                rowsPerPage: "Número de filas:",
                displayRows: "de"
            },

            toolbar: {
                search: "Buscar",
                downloadCsv: "Descargar Excel",
                print: "Imprimir"
            },

            filter: {
                all: "TODOS",
                title: "FILTROS",
                reset: "LIMPIAR"
            },
            viewColumns: {
                title: "Mostar Columnas",
                titleAria: "Mostrar/Ocultar Columnas de la Tabla"
            },
            selectedRows: {
                text: "Fila(s) Seleccionadas",
                delete: "Eliminar",
                deleteAria: "Eliminar Filar Seleccionadas"
            }

        },
        customToolbar: () => {
            return <ButtonNewEmployee recargar={getEmpleados} />;
        }

    };

    return (
        <MUIDataTable
            title={"Empleados"}
            data={employee}
            columns={columns}
            options={options}
        />
    );

}
export default Consumer(TableEmpleados);
