import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import SesionClose from "../login/sesionClose";
import Request from "../../utils/http";
import { Consumer } from "../../context/index";
import { Link } from "react-router-dom";
import "./menu.css";
const request = new Request();

class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: [],
      user: {
        idUsuario: 0,
        idPerfil: 0,
        nombre: "",
        foto: "",
      },
    };
  }
  render() {
    return (
      <>
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h3>Bienvenido {this.state.user.name}</h3>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item active">
                    <SesionClose />
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <section className="content">
                      <div className="container-fluid">
                        <h5 className="mb-2">Menú</h5>
                        <div className="white-space-32"></div>
                        <div className="row">
                          {this.state.menu.map((menu, key) => (
                            <div className="col-md-3 col-sm-6 col-12" key={key}>
                              <Link to={menu.url}>
                                <div className="info-box">
                                  <span className="info-box-icon">
                                    <img
                                      src={menu.img_menu}
                                      alt="Menu imagen"
                                    />
                                  </span>
                                  <div className="info-box-content">
                                    <span className="info-box-text">
                                      {menu.modulo.toUpperCase()}
                                    </span>
                                    <span className="info-box-number">
                                      {menu.descripcion}
                                    </span>
                                    <br />
                                  </div>
                                </div>
                              </Link>
                            </div>
                          ))}
                        </div>
                      </div>
                    </section>
                  </div>
                  <div className="card-footer text-sm text-muted">
                    <div className="float-right d-none d-sm-block">
                      <b>Version</b> 4.0.0
                    </div>
                    <strong>
                      Copyright © 2016-2024 <b>ZDE Bodas y Eventos</b>.
                    </strong>{" "}
                    All rights reserved.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
  componentDidMount() {
    this.load();
  }

  async load() {
    const user = await this.props.context.loadUser();

    if (user) {
      this.setState({
        user: user,
      });
      this.getMenus();
    }
  }

  // componentDidMount() {
  //   this.load();
  // }

  // async load() {
  //   let token = JSON.parse(window.sessionStorage.getItem("decoAdmin"));

  // if (token) {
  //   await request.get('/auth/user',{token: token.id}).then((response) => {
  //   if (response.statusCode === 200) {
  //     let user= {
  //       id : response.result.id,
  //       perfil : response.result.id_perfil,
  //       nombre : decodeURIComponent(escape(response.result.name)),
  //       depto : response.result.depto,
  //       ubicacion : response.result.ubicacion,
  //       foto : response.result.foto
  //     }
  //     this.setState({user:user});

  //     } else {
  //       let user= {id:{ id: 0, tipo: 0 }};
  //     this.setState({ user: user });

  //   }
  // })
  //   .catch((err) => {
  //     console.log("LoginBox -> handleSubmit -> err", err);
  //   });
  //     this.getMenus();
  //   }
  // }

  async getMenus() {
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }
    let data = {
      user: this.state.user.id,
      token: token,
    };

    const response = await request.post("/auth/menu/modules", data);
    console.log(response);
    if (response && !response.error) {
      if (response.result.modulos && !response.empty) {
        this.setState({ menu: response.result.modulos });
        window.sessionStorage.setItem(
          "Menus",
          JSON.stringify(response.result.modulos)
        );
      } else {
        this.setState({
          empty: true,
          loading: false,
          message: response.message,
        });
      }
    } else {
      this.setState({
        error: true,
        loading: false,
        message: response.message,
      });
    }
  }
}

export default withRouter(Consumer(Menu));
