import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { Consumer } from "../../context/index";
import Request from "../../utils/http";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";
import MUIDataTable from "mui-datatables";
import NewProduct from "./newProduct";


const request = new Request();
const customStyles = {
  content: {
    top: "50%",
    left: "80%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    overflow: "scroll",
  },
};
function AddProducts(props) {
  const [modal, setModal] = useState(false);
  const [products, setProducts] = useState([]);

  const getProducts = async () => {
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }
    let data = {
      token: token,
      idProv: props.idProv.value,
    };
    const response = await request.post("/auth/purchases/getProviderProducts", data);
    console.log('addprod->getProd->response',response);

    if (response && !response.error) {
      if (response.result && !response.empty) {
        setProducts(response.result);
      } else {
        //console.log(response.result.message);
      }
    } else {
      //console.log(response.result);
    }
  };
  useEffect(() => {
    if (props.idProv != null) getProducts();
  }, [props.idProv]);

  const columns = [
    {
      name: "id_lista",
      label: "ACCIÓN",
      options: {
        filter: false,
        sort: false,
        searchable: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <button className="btn btn-sm btn-success bg-gradient-success" onClick={(e) => props.addRow(tableMeta.rowData)}>
              <i className="fas fa-plus"></i>
            </button>
          );
        },
      },
    },
    {
      name: "id_prod",
      label: "ID",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "nombre",
      label: "DESCRIPCIÓN",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "caracteristicas",
      label: "CARACTERISTICAS",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "cod_alt",
      label: "CÓDIGO",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "unidad",
      label: "UNIDAD",
      options: {
        filter: false,
        sort: true,
      },
    },
  ];

  const options = {
    selectableRows: "none",
    responsive: "standard",
    pagination: true,
    search: true,
    print: false,
    download: false,
    filter: false,
    viewColumns: false,
    textLabels: {
      body: {
        noMatch: "No se encontraron resultados.",
        toolTip: "Sort",
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Número de filas:",
        displayRows: "de",
      },
      toolbar: {
        search: "Buscar",
        downloadCsv: "Descargar Excel",
        print: "Imprimir",
      },
      filter: {
        all: "TODOS",
        title: "FILTROS",
        reset: "LIMPIAR",
      },
      viewColumns: {
        title: "Mostar Columnas",
        titleAria: "Mostrar/Ocultar Columnas de la Tabla",
      },
      selectedRows: {
        text: "Fila(s) Seleccionadas",
        delete: "Eliminar",
        deleteAria: "Eliminar Filar Seleccionadas",
      },
    },
    customToolbar: () => {
      return (
        <NewProduct
          edit={false}
          reload={getProducts}
          id_prov={props.idProv.value}
        />
      );
    },
  };
  return (
    <>
      {" "}
      <Tooltip title={"Agregar Producto"}>
          <Fab
            color={props.idProv == null ? "disabled" : "primary"}
            size={"small"}
            aria-label="add"
            className="MuiButtonBase-root MuiIconButton-root MUIDataTableToolbar-icon-50"
            onClick={(e) => setModal(true)}
          >
            <AddIcon className="MuiSvgIcon-root" />
          </Fab>
      </Tooltip>
      <Modal
        isOpen={modal}
        style={customStyles.content}
        contentLabel="Nuevo Proveedor"
      >
        <div className="modal-header">
          <h4 className="modal-tile"> Lista de Productos</h4>
          <button
            type="button"
            className="close"
            onClick={(e) => setModal(false)}
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div className="modal-body card-primary card-outline card-outline-tabs">
          <div className="card-body">
            <MUIDataTable
              title={props.idProv == null ? "" : props.idProv.label}
              data={products}
              columns={columns}
              options={options}
            />
          </div>
        </div>
      </Modal>
    </>
  );
}
export default Consumer(AddProducts);
