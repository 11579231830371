/**
 *  @name: http.jsx
 *  @version: 1.0.0
 *  @author: alexis duarte
 *  @description: Cliente HTTP para todas las peticiones Web basada en superagent: GET, POST, DELETE, PUT, PATCH
 *  @param: {string} url: "/EndPoint"
 *  @param: {Object} data: Payload
 */

//import request from "superagent";
import axios from "axios";

// Web API URL
//const baseUrl = "http://localhost:8000/api";
//const baseUrl = "http://192.168.100.21:3001";
const baseUrl = "https://sys.decocancun.com/laravel_api/public/api";
//const baseUrl = "https://api.decocancun.com/api";
// let token1 = JSON.parse(localStorage.getItem("decoAdmin"));
// let token;
// if (token1) {
//   token = token1.id;
// }
class Request {
  get(url, data) {
    const options = {
      url: url,
      method: "get",
      baseURL: baseUrl,
      headers: {
        Authorization: "Bearer " + data.token,
      },
    };
    const result = axios
      .request(options)
      .then((response) => {
        return { result: response.data, statusCode: response.status };
      })
      .catch((error) => {
        console.log(error.response);
        return error.response;
      });
    return result;
  }
  getImg(url, data) {
    const options = {
      url: url,
      method: "get",
      headers: {
        Authorization: "Bearer " + data.token,
      },
    };
    const result = axios
      .request(options)
      .then((response) => {
        return { result: response.data, statusCode: response.status };
      })
      .catch((error) => {
        console.log(error.response);
        return error.response;
      });
    return result;
  }
  post(url, data) {
    const options = {
      url: url,
      method: "post",
      baseURL: baseUrl,
      data: data,
      headers: {
        Authorization: "Bearer " + data.token,
      },
    };

    const result = axios
      .request(options)
      .then((response) => {
        return { result: response.data, statusCode: response.status };
      })
      .catch((error) => {
        console.log(error.response);
        return error.response;
      });
    return result;
  }
  login(url, data) {
    const result = axios
      .post(baseUrl + url, data)
      .then((response) => {
        return { result: response.data, statusCode: response.status };
      })
      .catch((error) => {
        return {
          message: error.response.data.message,
          statusCode: error.response.status,
        };
      });
    return result;
  }
  upload(url, data, token) {
    const options = {
      url: url,
      method: "post",
      baseURL: baseUrl,
      data: data,
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    const result = axios
      .request(options)
      .then((response) => {
        return { result: response.data, statusCode: response.status };
      })
      .catch((error) => {
        console.log(error.response);
        return error.response;
      });
    return result;
  }
  publicURL() {
   //return "http://localhost:8000"; 
   return "https://sys.decocancun.com/laravel_api/public";
   //return "https://api.decocancun.com/api"
  }
  odsTitles(idioma) {
    const titles = {
      esp: {
        enc: "COTIZACIÓN DEL EVENTO",
        infoe: "INFORMACIÓN DEL EVENTO",
        event: "Evento",
        type: "Tipo de Evento",
        fi: "Fecha de Inicio",
        site: "Hotel/Lugar",
        loc: "Salón/Locación",
        pax: "Invitados",
        theme: "Tema",
        contact: "Contacto/Coordinador(a)",
        infoz: "INFORMACIÓN ZUÑIGA",
        fc: "Fecha Cotización",
        ven: "Asesor de Ventas",
        tel: "Teléfono",
        mail: "Correo Electrónico",
        photo: "FOTO",
        item: "ARTÍCULO",
        pieces: "PIEZAS",
        unit: "UNIDAD",
        days: "DÍAS",
        price: "PRECIO",
        amount: "IMPORTE",
        desc: "DESCUENTO",
        op: "OPERACIÓN (LABOR/TRANSPORTACIÓN)",
        iva: "IVA 16%",
        mon: "MX",
        client: "Cliente",
        comCli: "NOTAS PARA EL CLIENTE",
        terms: "TERMINOS Y CONDICIONES",
        sub1:"SUBTOTAL ARTÍCULOS",
        sub2:"SUBTOTAL"
      },
      ing: {
        enc: "PROPOSAL OF THE EVENT",
        infoe: "EVENT INFORMATION",
        event: "Event",
        type: "Event Type",
        fi: "Date of Event",
        site: "Site",
        loc: "Location",
        pax: "Guests",
        theme: "Theme",
        contact: "Contact/Planner",
        infoz: "ZUÑIGA INFORMATION",
        fc: "Proposal Date",
        ven: "Sales Manager",
        tel: "Cell Phone",
        mail: "E-mail",
        photo: "PHOTO",
        item: "ITEM",
        pieces: "PIECES",
        unit: "UNIT",
        days: "DAYS",
        price: "UNIT PRICE",
        amount: "AMOUNT",
        desc: "DISCOUNT",
        op: "OPERATIONS (LABOR STAFF/TRANSPORTATION)",
        iva: "TAX 16%",
        mon: "USD",
        client: "Cliente",
        comCli: "NOTES FOR THE CLIENT",
        terms: "TERMS AND CONDITIONS",
        sub1:"ITEMS SUBTOTAL",
        sub2:"SUBTOTAL"
      },
    };
    if (idioma != "English US") return titles.esp;
    else return titles.ing;
  }
}

export default Request;
