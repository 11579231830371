import React from "react";
import Layout from "../layout";
import Sidebar from "../../components/header/sidebar";
import TableEventos from "../../components/rh/horasExtras/tableHorasExtras"


const Eventos = () => (
  <Layout>
    <Sidebar />
    <div className="private column">
      <div className="white-space-32"></div>
      <div className="justify-center">
        <div className="container">
          <div className="justify-center full">
            <div className="card">
               <div className="card-body">
                    <TableEventos/>
               </div>
            </div>
          </div>
        </div>
      </div>
      <div className="white-space-32"></div>
    </div>
  </Layout>
);

export default Eventos;