let validator = data => {
  let error = 0;

  if (data.rfc.length < 13 || data.rfc.length > 13) {
    document.getElementById("rfc").classList.add("is-invalid");
    error = error + 1;
  }

  if (data.razonS.length === 0) {
    document.getElementById("razonS").classList.add("is-invalid");
    error = error + 1;
  }
  if (data.contacto.length === 0) {
    document.getElementById("contacto").classList.add("is-invalid");
    error = error + 1;
  }

  if (data.tel1.length === 0 || isNaN(data.tel1)) {
    document.getElementById("tel1").classList.add("is-invalid");
    error = error + 1;
  }
  if (
    !/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(
      data.email1
    )
  ) {
    document.getElementById("email1").classList.add("is-invalid");
    error = error + 1;
  }
  return error;
};
export default validator;
