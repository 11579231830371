import React, { useState } from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Request from "../../utils/http";

const request = new Request();

function FilesList(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [mouseX, setMouseX] = useState(null);
  const [mouseY, setMouseY] = useState(null);
  const [files, setFiles] = useState([]);
  const [load, setLoad] = useState(true);

  const handleClose = () => {
    setLoad(true);
    setAnchorEl(null);
    getFiles(props.idReq);
  };
  const handleClick = (event) => {
    setLoad(true);
    getFiles(props.idReq);
    setMouseX(event.clientX - 2);
    setMouseY(event.clientY - 4);
    setAnchorEl(event.currentTarget);
  };
  const getFiles = async (id) => {
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }

    let data = {
      token: token,
      idReq: id,
    };
    const response = await request.post("/auth/purchases/getAttachment", data);
    console.log("response :>> ", response);
    //validar los datos que recibimos
    if (response && !response.error) {
      if (response.result && !response.empty) {
        setLoad(false);
        setFiles(response.result);
      } else {
        console.log(response.message);
      }
    } else {
      console.log("error en la peticion");
    }
  };
  const splitName = (ruta) => {
    let name = ruta.split(".");
    let i = name.length - 1;
    let ext = name[i].toLowerCase();
    let far = "fas fa-file";
    switch (ext) {
      case "pdf":
        far = "fas fa-file-pdf text-red";
        break;
      case "jpeg":
      case "jpg":
      case "png":
        far = "fas fa-file-image";
        break;
      case "xml":
        far = "fas fa-file-code text-yellow";
        break;
      case "doc":
      case "docx":
        far = "fas fa-file-word text-blue";
        break;
      case "xls":
      case "xlsx":
      case "ods":
        far = "fas fa-file-excel text-green";
        break;
      case "ppt":
      case "pptx":
        far = "fas fa-file-powerpoint text-orange";
        break;
      case "zip":
      case "rar":
        far = "fas fa-file-archive text-purple";
        break;
      default:
        far = "fas fa-file";
        break;
    }
    return far;
  };

  return (
    <>
      <button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        className="btn btn-sm btn-info bg-gradient-info"
      >
        <ExpandMoreIcon style={{ color: "#eceff1" }} />
      </button>
      <Menu
        id="filesList"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          mouseY !== null && mouseX !== null
            ? { top: mouseY, left: mouseX }
            : undefined
        }
      >
        {!load ? (
          files.length != 0 ? (
            files.map((row, key) => (
              <MenuItem key={key}>
                <a
                  href={`${request.publicURL()}/compras/${row.ruta}`}
                  target="_blank"
                >
                  <i className={splitName(row.ruta)}></i>
                  &nbsp;
                  {row.descrip}
                </a>
              </MenuItem>
            ))
          ) : (
            <MenuItem onClick={handleClose}>
              <i className="fas fa-info-circle"></i>&nbsp;No hay archivos.
            </MenuItem>
          )
        ) : (
          <MenuItem>
            <i className="fas fa-spinner fa-spin"></i>
          </MenuItem>
        )}
      </Menu>
    </>
  );
}
export default FilesList;
