import React from "react";
import Layout from "../layout";
import Sidebar from "../../components/header/sidebar";
import ListarProductos from "../../components/almacen/listarProductos";


const customStyles = {
  content: {
    width: "7700px"
  }
};

const Almacen = () => (

  <Layout
    title="Perfil"
    description="Página privada de perfil"
    keywords="Reactjs, SEO Ready, Ejemplo"
  >
    <Sidebar />
      <div className="white-space-32"></div>
        <div className="container_fluid d-flex justify-content-center">
            <div className="card_empleado">
              <div className="card">
                <div className="card-body">
                  <ListarProductos/>
              </div>
              </div>
            </div>
        </div>
      <div className="white-space-32"></div>
  </Layout>
);

export default Almacen;
