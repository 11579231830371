import React, {useEffect, useState} from "react";
import Request from "../../../utils/http";
import { Consumer } from "../../../context/index";

import ButtonSave from "../../inventory/tabs/buttonSave";
import validatorEmployee from "./validatorEmployee";
import BottonDocuments from "./bottonsDocuments";
import BottonAlergies from "./bottonAlergies";
import BottonTipoBanco from"./bottonTipoBanco";
import BottonNuevoPuesto from"./bottonNuevoPuesto";
import BottonNuevaUbicacion from "./bottonNuevaUbicacion";
import BottonNuevaSucursal from "./bottonNuevaSucursal";

// import BottonNuevaArea from "./bottonNuevaArea";
import moment from "moment";
const request = new Request();

 function NewFormEmployee(props){

    const [employee, setEmployee]= useState({
        id_ubicacion:props.context.user.ubicacion,
        id_users:props.context.user.id,
        id_perfil:props.context.user.id_perfil,
        id_employee:'',
        name:'',
        last_name:'',
        sex:'',
        date_birth:'',
        civil_status:'',
        blood_type:'',
        address:'',
        curp:'',
        nss:'',
        rfc:'',
        email:'',
        cel_empresa:'',
        cel_personal:'',
        destination:'',
        branch:'',
        areas:'',
        puesto:'',
        contract_type:'',
        estudio:'',
        salary_daily:'',
        hours_ex:'',
        num_locker:'',
        pin_almacen:'',
        date_hire:'',
        tipo_banco:'',
        number_cuenta:'',
        clabe_banco:'',
        avatar:'',       
        cont_name:'',
        cont_last_name:'',
        cont_sex:'',
        cont_parentes:'',
        cont_address:'',
        cont_ocupation:'',
        cont_num_phone1:'',
        cont_num_phone2:'',
        documentos:[],
        alergias:[]
    });
    const [showModal, setModal] = useState(false);
    const [ubicacion, setUbicacion] = useState([]);
    const [sucursal, setSucursal] = useState([]);
    const [areas, setAreas] = useState([]);
    const [puesto, setPuesto] = useState([]);
    const [documento, setDocumento] = useState([]);
    const [alergia, setAlergia] = useState([]);
    const [banco, setBanco] = useState([]);
    const [nivelEstudio, setNivelEstudio] = useState([]);
    const [tipoSangre, setTipoSangre] = useState([]);
    // const [checked, setChecked] = useState([false]);
    
    const documentos = [];
    const arrAlergias = [];

    //Nos permite los  checkear los documentos y alergias al editar un empleado
    let documentos_antes=[];
    let alergias_antes=[];

    //nuestro token para todos
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token=token1.id;

    //funcion de traer la ubicacion 
    useEffect(() => {
        getUbicacion();
        getDocument();
        getAllergies();
        getBank();
        getNivelEstudio();
        getSucursal();
        getTipoSangre();

        //obtener asignar valores en los campos editar
        if(props.datos){
            //cambiar el formato de la fecha que viene de la base de datos
            let date_bir =(props.datos.date_birth); 
            let date_birth= moment(date_bir).format("YYYY-MM-DD");

            let date =(props.datos.date_hire);           
            let date_hire = moment(date).format("YYYY-MM-DD");

            let Ubicación  =(props.datos.id_ubicacion); 
            let Sucursal=(props.datos.id_sucursal ); 
            let Areas=(props.datos.id_areas);
            // verificamos si no esta vacio y luego ejecutar las funciones
            if(Ubicación=='' || Sucursal=='' || Areas==''){

            }else{
                getSucursal(Ubicación);
                getAreas(Sucursal);
                getPuesto(Areas);
            }
            documentos_antes= props.datos.documentos;
            alergias_antes=props.datos.alergias;

            setEmployee(
                {
                    id_ubicacion:props.context.user.ubicacion,
                    id_users:props.context.user.id,
                    id_employee:props.datos.id,
                    name:props.datos.name,
                    last_name:props.datos.last_name,
                    sex:props.datos.sex,
                    date_birth:date_birth,
                    civil_status:props.datos.civil_status,
                    blood_type:props.datos.blood_type,
                    address:props.datos.address,
                    curp:props.datos.curp,
                    nss:props.datos.nss,
                    rfc:props.datos.rfc,
                    email:props.datos.email,
                    cel_empresa:props.datos.cel_empresa,
                    cel_personal:props.datos.cel_personal,
                    date_hire:date_hire,
                    destination:props.datos.id_ubicacion,
                    branch:props.datos.id_sucursal, 
                    areas:props.datos.id_areas,
                    puesto:props.datos.id_puesto,
                    contract_type:props.datos.contract_type,
                    estudio:props.datos.id_studies_levels,
                    salary_daily:props.datos.salary_daily,
                    hours_ex:props.datos.hours_ex,
                    pin_almacen:props.datos.pin_almacen,
                    num_locker:props.datos.num_lockers,
                    tipo_banco:props.datos.tipo_banco,
                    number_cuenta:props.datos.num_cuenta,
                    clabe_banco:props.datos.clabe_banco,
                    avatar:props.datos.avatar,
                    cont_name:props.datos.nombre_contacto,
                    cont_last_name:props.datos.apellido_contacto,
                    cont_sex:props.datos.sexo_contacto,
                    cont_parentes:props.datos.parentesco,
                    cont_address:props.datos.direccion_contacto,
                    cont_ocupation:props.datos.ocupation,
                    cont_num_phone1:props.datos.numTelefono,
                    cont_num_phone2:props.datos.numTelefono2,
                    documentos:props.datos.documentos,
                    alergias:props.datos.alergias
                }
            ) 
        }


    }, []);

    //calcular edad
    var nacimiento=moment(employee.date_birth);
    var hoy=moment();
    var anios=hoy.diff(nacimiento,"years");
    //calcular salario mensual
    let total_salario='';
    let salario_diario=employee.salary_daily;
    if(salario_diario!=0 ||salario_diario!=''){
        let total=salario_diario*30;
        total_salario = Math.round(total);
    }else{
        total_salario=0;
    }

    const getTipoSangre = async () => {
        let data = {
          token: token
        };
        const response = await request.post("/employee/getTipoSangre", data);

        console.log(response);
        
        if (response && !response.error) {
          if (response.result.tipoSangre && !response.empty) {
            setTipoSangre(response.result.tipoSangre);

          } else {
            console.log(response.result.message);
          }
        } else {
            console.log("error en la peticion");
        }
    }

    const domentos = async(status,id_document,posicion)=>{   
        //permite borrar lo checkbox al guardar un empleado
        let docs=documento.slice()
        docs[posicion].checked=status
        setDocumento(docs);
        //FIN
        if(status==true){    
               let boolean = false;

            for (const indice in documentos) {
                if(documentos[indice]==id_document){
                    boolean=true;
                    break;
                }
            }
            if(boolean!=true){

                documentos.push(id_document);
                
            }
        }else{

            for (const i in documentos) {
                if(documentos[i]==id_document){
                    documentos.splice(i, 1);
                    break;
                }
            }

        }
        
    }

    function checkAlergies(status,id_alergies,posicion){
        //permite borrar lo checkbox al guardar un empleado
        let aler=alergia.slice()
        aler[posicion].checked=status
        setAlergia(aler);
        //FIN
        if(status==true){
            let boolean=false;           
            for (let indice in arrAlergias) {
                if(arrAlergias[indice]==id_alergies){
                    boolean=true;
                    break;
                }
            }
            if(boolean!=true){

                arrAlergias.push(id_alergies);
                
            }
        }else{
            for (let i in arrAlergias) {
                if(arrAlergias[i]==id_alergies){
                    arrAlergias.splice(i, 1);;
                    break;
                }
            }
        }

    }

    const getUbicacion = async () => {
        let data = {
          token: token
        };
        const response = await request.post("/employee/getUbicacion", data);
        //validar los datos que recibimos
        if (response && !response.error) {
          if (response.result.ubicacion && !response.empty) {
            setUbicacion(response.result.ubicacion);
            if(response.result.ubicacion==[]){
                getSucursal();
              }
          } else {
            console.log(response.result.message);
          }
        } else {
            console.log("error en la peticion");
        }
    }
   
    let getSucursal = async(id) => {

        setEmployee({...employee,destination:id});
        let data = {
          token: token,
          id_ubicacion:id
        };
        const response = await request.post("/employee/getSucursal", data);       
        if (response && !response.error) {
            if (response.result.sucursal && !response.empty) {
              setSucursal(response.result.sucursal);
            } else {
              console.log(response.result.message);
            }
        } else {
              console.log("error en la peticion");
          }
        
    }

    const subirFoto=(event)=>{

        let imagen=event.target.files[0];
        console.log(imagen);
        if(imagen["type"]!=="image/jpeg" && imagen["type"]!=="image/png"){
            alert("La imagen debe estar en formato JPG O PNG !");
            event.target.value = '';
            return;
 
        }else{
            let datosArchivo = new FileReader;
            datosArchivo.readAsDataURL(imagen);
            
            datosArchivo.onload=(event)=>{
              let rutaArchivo= event.target.result;

              document.getElementById("resultado").src =rutaArchivo;
            };

            setEmployee({...employee,avatar:imagen.name});
        }
        
    }

    let getAreas = async(id) => {
        setEmployee({...employee,branch:id});
        let data = {
          token: token,
          id_sucursal:id
        }
        const response = await request.post("/employee/getAreas", data);
        
        if (response && !response.error) {

            if (response.result.areas && !response.empty) {
                setAreas(response.result.areas);
            } else {
              console.log(response.result.message);
            }
        } else {
              console.log("error en la peticion");
          }
        
    }
    
    let getPuesto = async(id) => {
        setEmployee({...employee,areas:id});
        let data = {
          token: token,
          id_areas:id
        }
       const response = await request.post("/employee/getPuesto", data);
        if (response && !response.error) {
            if (response.result.puesto && !response.empty) {
                setPuesto(response.result.puesto);
            } else {
              console.log(response.result.message);
            }
        } else {
              console.log("error en la peticion");
          }
        
    }

    const getDocument = async () => {
        let data = {
          token: token
        }
        const response = await request.post("/employee/getDocument", data);
        //validar los datos que recibimos
        if (response && !response.error) {
          if (response.result.documento && !response.empty) {
              let result = response['result']['documento'];

              if ( documentos_antes.length>0){
                  result.map(function (ob,index) {
                      ob['checked']=false;

                      for (let old of documentos_antes){
                          if (old == ob['id_document']){
                              ob['checked']=true;
                          }
                      }

                  });
              }

            setDocumento(result);

          } else {
            console.log(response.result.message);
          }
        } else {
            console.log("error en la peticion");
        }
    }

    const getAllergies = async () => {
        let data = {
          token: token
        }
        const response = await request.post("/employee/getAllergies", data);
        //validar los datos que recibimos
        if (response && !response.error) {
          if (response.result.alergia && !response.empty) {

              let result = response['result']['alergia'];

              if ( alergias_antes.length>0){
                  result.map(function (ob,index) {
                      ob['checked']=false;

                      for (let old of alergias_antes){
                          if (old == ob['id_allergies']){
                              ob['checked']=true;
                          }
                      }

                  });
              }

            setAlergia(result);

          } else {
            console.log(response.result.message);
          }
        } else {
            console.log("error en la peticion");
        }
    }

    const getBank = async () => {
        let data = {
          token: token
        }
        const response = await request.post("/employee/getBank", data);
        //validar los datos que recibimos
        if (response && !response.error) {
          if (response.result.banco && !response.empty) {
            setBanco(response.result.banco);
          } else {
            console.log(response.result.message);
          }
        } else {
            console.log("error en la peticion");
        }
    }

    const getNivelEstudio = async () => {
        let data = {
          token: token
        }
        const response = await request.post("/employee/getNivelEstudio", data);
        //validar los datos que recibimos
        if (response && !response.error) {
          if (response.result.N_level && !response.empty) {
            setNivelEstudio(response.result.N_level);
          } else {
            console.log(response.result.message);
          }
        } else {
            console.log("error en la peticion");
        }
    }
    //funcion de envio de informacio en la base de datos
    const save = async () => {
        
        //ejecutamos la fucion validato(para realizar la validacion de formulario).
        let datos = validatorEmployee(employee); 
        let year = document.getElementById("year").value;

        let id_empleado=datos.id_employee;
        let perfil=props.context.user.id_perfil;

        if (datos === 1) {
            alert("favor de llenar todos los campos");
        }else if(year=='NaN'){
            alert("No hay edad,Fecha Nacimiento Incorrecto");
            document.getElementById("date_birth").classList.add("is-invalid");
        }else {

            //obtenemos las documentos y las alergias los que estan chequeados
            let arrDocumentos = [];
            let arrAlergias = [];
            let htmlDocs = document.getElementsByClassName('docs');
            for (let input of htmlDocs){
                if (input.checked==true){
                    let id = input.getAttribute('data-id');
                    arrDocumentos.push(id);
                }
            }
            let htmlAlergias = document.getElementsByClassName('alergias');
            for (let input of htmlAlergias){
                if (input.checked==true){
                    let id = input.getAttribute('data-id');
                    arrAlergias.push(id);
                }
            }
            //Nos permite integrar mas datos a JSON STRING
            datos["edad"]=year;
            datos["salario"]=total_salario;
            //Son array
            datos['documentos'] = arrDocumentos;
            datos['alergias']   = arrAlergias;
            //obtenemos la imagen en el input
            let avatar = document.getElementById('avatar').files[0];
            
            let formData = new FormData();
            formData.append( "avatar", avatar );
            formData.append( "token", token);
            formData.append( "datos", JSON.stringify(datos));
            
            const headers = {
                cache: false,
                contentType: false,
                processData: false
            };

            const response = await request.post("/employee/saveEmployee", formData,headers);
            //Respuesta de nuestra peticion
            if (response && !response.error) {
                if (response.result.ok && !response.empty) {
                    if(id_empleado==''){
                        alert("Datos guardados con exito");
                        props.recargar(datos.id_users,datos.id_perfil,datos.id_ubicacion);
                        setEmployee({
                            id_employee:'',
                            name:'',
                            last_name:'',
                            sex:'',
                            date_birth:'',
                            year:'',
                            civil_status:'',
                            blood_type:'',
                            address:'',
                            curp:'',
                            nss:'',
                            rfc:'',
                            email:'',
                            cel_empresa:'',
                            cel_personal:'',
                            date_hire:'',
                            destination:'',
                            branch:'',
                            areas:'',
                            puesto:'',
                            contract_type:'',
                            estudio:'',
                            salary_daily:'',
                            salary_mensual:'',
                            hours_ex:'',
                            pin_almacen:'',
                            num_locker:'',
                            tipo_banco:'',
                            number_cuenta:'',
                            clabe_banco:'',
                            avatar:'',       
                            cont_name:'',
                            cont_last_name:'',
                            cont_sex:'',
                            cont_parentes:'',
                            cont_address:'',
                            cont_ocupation:'',
                            cont_num_phone1:'',
                            cont_num_phone2:'',

                        });
                        //nos pasan una copia luego pasarle en checked (false) y tambien las alergias
                        let docs=documento.slice()
                        for (let i = 0; i < docs.length; i++) {
                          docs[i].checked=false
                        }
                        // setDocumento(docs);
                        let aler=alergia.slice()
                        for (let i = 0; i < aler.length; i++) {
                          aler[i].checked=false
                            
                        }
                        // setDocumento(aler);
                        //fin
                    }else{
                        alert("Se actulizo correctamente");
                        props.recargar(datos.id_users,perfil,datos.id_ubicacion);
                        props.closeModal();

                    }
                } else {
                  console.log(response.result.message);
                }
              } else {
                console.log("error en la peticion");
            }

        } 

    }



    return ( 
        <div className="tab-content" id="custom-tabs-three-tabContent">
            <form encType="multipart/form-data" id="formulario">
            <h3 className="text-center mb-4"><span>DATOS PERSONALES</span></h3>

            <div className="row mt-2">
                <div className="col-md-6">
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Nombre</span>
                        </div>
                        <input type="text" id="name" name="name" className="form-control text-uppercase"
                         value={employee.name} 
                         onChange={e => setEmployee({...employee,name:e.target.value.toUpperCase()})} />
                    </div>
                </div>

                <div className="col-md-6">
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Apellido</span>
                        </div>
                        <input type="text" id="last_name" name="last_name" className="form-control text-uppercase"
                        value={employee.last_name} 
                        onChange={e=> setEmployee({...employee,last_name:e.target.value.toUpperCase()})}/>
                    </div>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-md-4">
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Sexo</span>
                        </div>
                        <select className="form-control text-uppercase"  id="sex" name="sex"
                            value={employee.sex} 
                            onChange={e=> setEmployee({...employee,sex:e.target.value})}>
                            <option></option>
                            <option value="1">M</option>
                            <option value="2">F</option>
                        </select>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Fecha_Nacimiento</span>
                        </div>
                        <input type="date" className="form-control" 
                            id="date_birth"
                            name="date_birth" 
                            value={employee.date_birth} 
                            onChange={e => setEmployee({...employee,date_birth:e.target.value})} 
                            aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default"
                        />
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Edad</span>
                        </div>
                        <input type="text"  className="form-control" 
                            id="year"
                            name="year" 
                            value={ anios==''?employee.year:anios}
                            disabled
                            />
                    </div>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-md-4">
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Estado Civil</span>
                        </div>
                        <input type="text" className="form-control text-uppercase"
                            id="civil_status"
                            name="civil_status" value={employee.civil_status}  
                            onChange={e => setEmployee({...employee,civil_status:e.target.value.toUpperCase()})}/>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Tipo de Sangre</span>
                        </div>

                        <select className="form-control text-uppercase" 
                            id="blood_type"
                            name="blood_type" 
                            value={employee.blood_type}  
                            onChange={e => setEmployee({...employee,blood_type:e.target.value})}
                            >
                            <option></option>
                            {tipoSangre.map((tipoSangreVal, key) => (
                                <option key={key} 
                                    value={tipoSangreVal.id_tipo_sangre}
                                    selected={employee.blood_type == tipoSangreVal.id_tipo_sangre ? true : false}>
                                    {tipoSangreVal.name}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Dirección</span>
                        </div>
                        <input type="text" className="form-control text-uppercase" 
                            id="address"
                            name="address" value={employee.address} 
                            onChange={e => setEmployee({...employee,address:e.target.value.toUpperCase()})}/>
                    </div>
                </div>
            </div>
            
            <div className="row mt-2">
                <div className="col-md-4">
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text">CURP</span>
                        </div>
                        <input type="text" className="form-control text-uppercase" 
                            id="curp"
                            name="curp" value={employee.curp} value={employee.curp} 
                            onChange={e => setEmployee({...employee,curp:e.target.value.toUpperCase()})}/>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text">NSS</span>
                        </div>
                        <input type="text" className="form-control text-uppercase" 
                            id="nss"
                            name="nss" value={employee.nss} value={employee.nss} 
                            onChange={e => setEmployee({...employee,nss:e.target.value.toUpperCase()})}/>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text">RFC</span>
                        </div>
                        <input type="text" className="form-control text-uppercase" 
                            id="rfc"
                            name="rfc" value={employee.rfc} value={employee.rfc} 
                            onChange={e => setEmployee({...employee,rfc:e.target.value.toUpperCase()})}/>
                    </div>
                </div>
            </div>


            <div className="row mt-2">
                <div className="col-md-4">
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Correo</span>
                        </div>
                        <input type="email" className="form-control" 
                            id="email"
                            name="email" value={employee.email} value={employee.email} 
                            onChange={e => setEmployee({...employee,email:e.target.value})}/>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Num.Cel.Empresa</span>
                        </div>
                        <input type="number" className="form-control" 
                            id="cel_empresa"
                            name="cel_empresa" 
                            value={employee.cel_empresa} 
                            onChange={e => setEmployee({...employee,cel_empresa:e.target.value})}/>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Num.Cel.Personal</span>
                        </div>
                        <input type="number" className="form-control" 
                            id="cel_personal"
                            name="cel_personal" 
                            value={employee.cel_personal} 
                            onChange={e => setEmployee({...employee,cel_personal:e.target.value})}/>
                    </div>
                </div>
            </div>

            <div className="row mt-2">
                <div className="col-md-4">
                    <div className="row">
                        <div className="col-12">
                            <BottonNuevaUbicacion recargar={getUbicacion}/>
                        </div>
                    </div>
                    <div className="input-group mb-3 mt-2">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Ubicación</span>
                        </div>                      
                        <select className="form-control text-uppercase" 
                            id="destination"
                            name="destination" 
                            value={employee.destination}  
                            onChange={e =>getSucursal(e.target.value)}>
                            <option></option>
                            {ubicacion.map((ubicacion, key) => (
                                <option key={key} 
                                    value={ubicacion.id_ubicacion}
                                    selected={employee.destination == ubicacion.id_ubicacion ? true : false}>
                                    {ubicacion.lugar}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="row">
                        <div className="col-12">
                            <BottonNuevaSucursal/>
                        </div>
                    </div>
                    <div className="input-group mb-3 mt-2">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Sucursal</span>
                        </div>
                        <select className="form-control text-uppercase" 
                            id="branch"
                            name="branch" 
                            value={employee.branch} 
                            onChange={e => getAreas(e.target.value)}>
                            <option></option>
                            {
                            
                            sucursal.map((sucursal, key) => (
                                <option key={key} 
                                    value={sucursal.id_sucursal}
                                    selected={employee.branch == sucursal.id_sucursal ? true : false}>
                                    {sucursal.sucursal}
                                </option>
                            ))
                            
                            }
                        </select>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="row mb-5 mt-2">
                        <div className="col-12">
                        </div>
                    </div>
                    <div className="input-group mb-3 mt-2">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Departamento</span>
                        </div>
                        <select className="form-control text-uppercase" 
                            id="areas"
                            name="areas" 
                            value={employee.areas} 
                            onChange={e => getPuesto(e.target.value)}>
                            <option></option>
                            {areas.map((sucursal, key) => (
                                <option key={key} 
                                    value={sucursal.id_areas}
                                    selected={employee.areas == sucursal.id_areas ? true : false}>
                                    {sucursal.name}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>
            <BottonNuevoPuesto recargar={getPuesto}/>
            <div className="row mt-2">
                <div className="col-md-4">
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Puesto</span>
                        </div>
                        <select className="form-control text-uppercase" 
                            id="puesto"
                            name="puesto" 
                            value={employee.puesto} 
                            onChange={e => setEmployee({...employee,puesto:e.target.value})} >
                            <option></option>
                            {puesto.map((valPuesto, key) => (
                                <option key={key} 
                                    value={valPuesto.id_puesto}
                                    selected={employee.puesto == valPuesto.id_puesto ? true : false}>
                                    {valPuesto.puesto}
                                </option>
                            ))}
                        </select>
                        
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Tipo de Contrato</span>
                        </div>
                        <select className="form-control text-uppercase" 
                            id="contract_type"
                            name="contract_type" 
                            value={employee.contract_type} 
                            onChange={e => setEmployee({...employee,contract_type:e.target.value})}>
                            <option></option>
                            <option value="1">Indeterminado</option>
                            <option value="2">Determinado</option>
                        </select>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Nivel de Estudio</span>
                        </div>
                        <select className="form-control text-uppercase" 
                            id="estudio"
                            name="estudio" 
                            value={employee.estudio} 
                            onChange={e => setEmployee({...employee,estudio:e.target.value})}>
                            <option></option>
                            {nivelEstudio.map((valBanco, key)=>(
                                <option key={key}
                                 value={valBanco.id_studies_levels}
                                 selected={employee.estudio == valBanco.id_studies_levels ? true : false}>
                                 {valBanco.level}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>

            <div className="row mt-2">
                <div className="col-md-4">
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Salario Diario</span>
                            <span className="input-group-text">$</span>
                        </div>
                        <input type="number" className="form-control" 
                            id="salary_daily"
                            name="salary_daily" 
                            value={employee.salary_daily} 
                            onChange={e => setEmployee({...employee,salary_daily:e.target.value})}
                            aria-label="Amount (to the nearest dollar)"
                            />
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Salario Mensual</span>
                            <span className="input-group-text">$</span>
                        </div>
                        <input type="number" className="form-control" 
                            id="salary_mensual"
                            name="salary_mensual" 
                            value={total_salario}
                            disabled
                            />
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Horas Extras</span>
                            <span className="input-group-text">$</span>
                        </div>
                        <input type="number" className="form-control" 
                            id="hours_ex"
                            name="hours_ex" 
                            value={employee.hours_ex} 
                            onChange={e => setEmployee({...employee,hours_ex:e.target.value})} aria-label="Amount (to the nearest dollar)"/>

                    </div>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-md-4">
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Num Locker</span>
                        </div>
                        <input type="number"  className="form-control"  
                            id="num_locker" 
                            name="num_locker" 
                            value={employee.num_locker} 
                            onChange={e => setEmployee({...employee,num_locker:e.target.value})}/>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Pin Almacen</span>
                        </div>
                        <input type="number" className="form-control"
                        id="pin_almacen" 
                        name="pin_almacen" 
                        value={employee.pin_almacen} 
                        onChange={e => setEmployee({...employee,pin_almacen:e.target.value})} />
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Fecha de Alta</span>
                        </div>
                        <input type="date" className="form-control" 
                            id="date_hire" 
                            name="date_hire" 
                            value={employee.date_hire} 
                            onChange={e=> setEmployee({...employee,date_hire:e.target.value})} 
                        />
                    </div>
                </div>
            </div>
            
            <div className="row mb-2 d-flex flex-column">
                <div className="col-md-3 mb-1">
                    <div className="caja-imagen">
                        <img  id="resultado" 
                            alt=""
                            src={
                                employee.avatar ==null
                                ? "./assets/uploads/no-image.jpg"
                                : `./assets/uploads/empleados/${employee.avatar}`
                            }
                        /> 
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="input-file">
                        <div className="input-group-text name_imagen">
                            <span>Subir Foto: </span>
                        </div>
                        <input type="file" 
                            id="avatar" 
                            name="avatar"
                            onChange={e => subirFoto(e)}
                        />
                    </div>
                </div>
            </div>


            <BottonTipoBanco recargar={getBank}/>
            <div className="row mt-4">
                <div className="col-md-12">
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Tipo de Banco</span>
                        </div>
                        <select className="form-control text-uppercase" 
                            id="tipo_banco"
                            name="tipo_banco" 
                            value={employee.tipo_banco} 
                            onChange={e => setEmployee({...employee,tipo_banco:e.target.value})}>
                            <option></option>  
                            {banco.map((valBanco, key)=>(
                                <option key={key}
                                 value={valBanco.id_banco}
                                 selected={employee.tipo_banco == valBanco.id_banco ? true : false}>
                                 {valBanco.banco}
                                </option>
                            ))}
                        </select>
                        <div className="input-group-prepend">
                            <span className="input-group-text">Num.Cuenta</span>
                        </div>
                        <input type="number" className="form-control" placeholder="Numero de cuenta"
                            id="number_cuenta" 
                            name="number_cuenta"
                            value={employee.number_cuenta} 
                            onChange={e => setEmployee({...employee,number_cuenta:e.target.value})}
                        />
                        <div className="input-group-prepend">
                            <span className="input-group-text">Clabe Inter.</span>
                        </div>
                        <input type="number" className="form-control" placeholder="Clabe Interbancaria" 
                            id="clabe_banco"
                            name="clabe_banco"
                            value={employee.clabe_banco} 
                            onChange={e => setEmployee({...employee,clabe_banco:e.target.value})}
                        />
                    </div>
                </div>
            </div>
 
            <h3 className="text-center mb-3 mt-3"><span>DOCUMENTOS</span></h3>
            
            <hr></hr>
            <BottonDocuments recargar={getDocument}/>
            <div className="row mt-2">
                {documento.map((valDocument, key) => (

                <div className="col-md-3">
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text">{valDocument.name}</span>
                        </div>
                        <span className="input-group-text">
                            <input
                            type="checkbox"
                            className="docs"
                            data-id={valDocument.id_document}
                            onClick={(e) => {
                                    domentos(
                                        e.target.checked,
                                        valDocument.id_document, key                             
                                    );
                                }}
                               checked={valDocument.checked}
                            />
                        </span>
                    </div>
                </div>
                ))}
            </div>

            <h3 className="text-center mb-3 mt-3"><span>ALERGIAS</span></h3>
            <hr></hr>
            <BottonAlergies recargar={getAllergies}/>

            <div className="row mt-2">
                {alergia.map((valAlergia, key) =>(
                <div className="col-md-3">
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text">{valAlergia.allergie}</span>
                        </div>
                        <span className="input-group-text">
                            <input
                            type="checkbox"
                            className="alergias"
                            data-id={valAlergia.id_allergies}
                                onChange={(e) => {
                                    checkAlergies(
                                        e.target.checked,
                                        valAlergia.id_allergies,key
                                    );
                                }}
                                checked={valAlergia.checked}
                            />
                        </span>
                    </div>
                </div>
                ))}
            </div>

            <h3 className="text-center mb-3 mt-3"><span>REFERENCIA PERSONAL</span></h3>

            <div className="row mt-2">
                <div className="col-md-6">
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Nombre</span>
                        </div>
                        <input type="text" className="form-control text-uppercase"
                            id="cont_name"
                            name="cont_name" 
                            value={employee.cont_name} 
                            onChange={e => setEmployee({...employee,cont_name:e.target.value.toUpperCase()})} />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Apellido</span>
                        </div>
                        <input type="text" className="form-control text-uppercase"
                            id="cont_last_name"
                            name="cont_last_name" 
                            value={employee.cont_last_name} 
                            onChange={e => setEmployee({...employee,cont_last_name:e.target.value.toUpperCase()})} />
                    </div>
                </div>
            </div>

            <div className="row mt-2">
                <div className="col-md-4">
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Sexo</span>
                        </div>
                        <select className="form-control text-uppercase" 
                            id="cont_sex"
                            name="cont_sex" 
                            value={employee.cont_sex} 
                            onChange={e=> setEmployee({...employee,cont_sex:e.target.value})}>
                            <option></option>
                            <option value="1">M</option>
                            <option value="2">F</option>
                        </select>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Parentesco</span>
                        </div>
                        <input type="text" className="form-control text-uppercase"
                            id="cont_parentes"
                            name="cont_parentes" 
                            value={employee.cont_parentes} 
                            onChange={e => setEmployee({...employee,cont_parentes:e.target.value.toUpperCase()})}  />
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Dirección</span>
                        </div>
                        <input type="text" className="form-control text-uppercase"
                            id="cont_address"
                            name="cont_address" 
                            value={employee.cont_address} 
                            onChange={e => setEmployee({...employee,cont_address:e.target.value.toUpperCase()})} />
                    </div>
                </div>
            </div> 
            <div className="row mt-2">
                <div className="col-md-4">
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Ocupación</span>
                        </div>
                        <input type="text" className="form-control text-uppercase"
                            id="cont_ocupation"
                            name="cont_ocupation" 
                            value={employee.cont_ocupation} 
                            onChange={e => setEmployee({...employee,cont_ocupation:e.target.value.toUpperCase()})} />
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Numero Celular</span>
                        </div>
                        <input type="number" 
                            id="cont_num_phone1"
                            name="cont_num_phone1" 
                            value={employee.cont_num_phone1} 
                            onChange={e => setEmployee({...employee,cont_num_phone1:e.target.value})} className="form-control"/>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Numero Telefono</span>
                        </div>
                        <input type="number" 
                            id="cont_num_phone2"
                            name="cont_num_phone2" 
                            value={employee.cont_num_phone2} 
                            onChange={e => setEmployee({...employee,cont_num_phone2:e.target.value})} className="form-control"/>
                    </div>
                </div>
            </div> 
          
            <ButtonSave  
                save={save} 
                closeModal={props.closeModal}
            />
        </form>
        </div>
    );

}
export default Consumer(NewFormEmployee);






 
