import React, { useEffect, useState, forwardRef } from "react";
import moment from "moment-timezone";
import { toast } from "react-toastify";
import "./tableProducts.css";
import validator from "./validatorStep2";
import NewItem from "./components/newItem";
import TableProductsExistences from "./tableProductsExistences";
import Request from "../../utils/http";
const request = new Request();

function TableProducts(props, ref) {
  const [productos, setProductos] = useState([]);
  //const [productosList, setProdList] = useState([]);

  const [nombre, setNombre] = useState("");
  const [locacion_sub, setLocacion] = useState("");
  const [fecha_montaje, setMontaje] = useState("");
  const [fecha_entrega, setEntrega] = useState("");
  const [ready, setReady] = useState(false);
  // const [reload]= useState();
  // const [mensaje]= useState();
  // const [save]= useState();

  useEffect(() => {
    setProductos(props.products);
    setReady(true);
    if (props.products.length !== 0) {
      setNombre(props.products[0].nombre);
      setLocacion(props.products[0].locacion_sub);
      setMontaje(
        moment(props.products[0].fecha_montaje, "DD-MM-YYYY HH:mm").format(
          "YYYY-MM-DDTHH:mm"
        )
      );
      setEntrega(
        moment(props.products[0].fecha_entrega, "DD-MM-YYYY HH:mm").format(
          "YYYY-MM-DDTHH:mm"
        )
      );
    } else {
      setEntrega(
        moment(props.fechaI, "DD-MM-YYYY HH:mm").format("YYYY-MM-DDTHH:mm")
      );

      let fMontaje = moment(props.fechaM, "DD-MM-YYYY HH:mm").isValid();
      if (fMontaje) {
        setMontaje(
          moment(props.fechaM, "DD-MM-YYYY HH:mm").format("YYYY-MM-DDTHH:mm")
        );
      } else {
        setMontaje(
          moment(props.fechaI, "DD-MM-YYYY HH:mm").format("YYYY-MM-DDTHH:mm")
        );
      }
    }
  }, [props.products]);

  const handleChange = (posicion, valor, propiedad) => {
    switch (propiedad) {
      case "cantidad":
      case "produccion":
        productos[posicion][propiedad] = parseInt(valor);
        break;

      case "preciouni":
      case "dias":
        productos[posicion][propiedad] = parseFloat(valor);
        break;

      case "oculto_cot":
        let ods = productos[posicion]["oculto_ods"];
        productos[posicion][propiedad] = parseInt(valor);
        if (ods === 1 && valor === 1) productos[posicion]["oculto_ods"] = 0;
        break;

      case "oculto_ods":
        let cot = productos[posicion]["oculto_cot"];
        productos[posicion][propiedad] = parseInt(valor);
        if (cot === 1 && valor === 1) productos[posicion]["oculto_cot"] = 0;
        break;

      default:
        productos[posicion][propiedad] = valor;
        break;
    }
    //events[i]=evento;
    setProductos([...productos]);
    //setProductos(copia);

    // console.log(copia[posicion].bod);
  };
  const deleting = async (indice) => {
    let copia = productos.slice();
    let borrar = window.confirm(
      `Estas seguro de eliminar el articulo: ${copia[indice].nomE}  de la cotizacion?. No podra deshacer esta acción.`
    );
    if (borrar) {
      let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
      let token;
      if (token1) {
        token = token1.id;
      }
      let data = {
        idEvent: props.idEvent,
        idSub: props.num + 1,
        idArt: copia[indice].id_art.replace("T-", ""),
        idBod: copia[indice].tabla,
        token: token,
      };

      const response = await request.post("/auth/sales/deleteArt", data);
      //console.log("delete art :>> ", response);

      if (response && !response.error) {
        if (response.result && !response.empty) {
          copia.splice(indice, 1);
          props.deleteArt(props.num, copia);
          setProductos(copia);
          toast.success('Artículo "' + data.idArt + '" borrado con exito.', {
            position: toast.POSITION.TOP_RIGHT,
            theme: "colored",
          });
        } else {
          toast.error(
            'ERROR: Artículo "' + data.idArt + '" no se pudo borrar.',
            {
              position: toast.POSITION.TOP_RIGHT,
              theme: "colored",
            }
          );
        }
      } else {
        toast.error('ERROR: Artículo "' + data.idArt + '" no se pudo borrar.', {
          position: toast.POSITION.TOP_RIGHT,
          theme: "colored",
        });
      }
    }
  };
  const getProducts = async (info) => {
    let copia = productos.slice();
    info.id_evento = Number(props.idEvent);
    info.id_subevento = props.num + 1;
    info.id_subevento_ant = props.num + 1;
    info.orden = copia.length;
    let error = false;
    for (let i = 0; i < copia.length; i++) {
      if (copia[i].id_art == info.id_art) {
        error = true;
        break;
      }
    }
    if (!error) {
      copia.push(info);
      console.log("copia :>> ", copia, info);
      setProductos(copia);
      toast.success('Artículo "' + info.nomE + '" agregado con exito.', {
        position: toast.POSITION.TOP_RIGHT,
        theme: "colored",
      });
    } else {
      toast.error(
        'ERROR: ¡El artículo "' +
          info.nomE +
          '" ya se encuentra en este subevento!'
      );
    }
  };
  const deleteSub = async () => {
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }

    let data = {
      token: token,
      idEvent: props.idEvent,
      idSub: props.num + 1,
    };

    const response = await request.post("/auth/sales/deleteSubEvent", data);

    if (response && !response.error) {
      if (response.result && !response.empty) {
        toast.success(
          'Subevento "' +
            data.idSub +
            '" eliminado con exito. Se ajustaron los subeventos restantes.',
          {
            position: toast.POSITION.TOP_RIGHT,
            theme: "colored",
          }
        );
      } else {
        toast.error(
          'ERROR: ¡El subevento "' + data.idSub + '" no pudo ser borrado!',
          {
            position: toast.POSITION.TOP_RIGHT,
            theme: "colored",
          }
        );
      }
    } else {
      toast.error(
        'ERROR: ¡El subevento "' + data.idSub + '" no pudo ser borrado!'
      );
    }
    props.deleteSub(props.num);
  };
  const send = async () => {
    setReady(false);
    let copia = productos.slice();
    let fechaInicio;
    let fechaFin;
    let datos = validator(copia);

    if (datos === 1) {
      toast.error(
        <span>
          !Por favor asegurate de llenar todos los campos marcados en rojo, son
          obligatorios!.
        </span>
      );
      setReady(true);
      props.save();
      return;
    } else {
      if (fecha_montaje) {
        fechaInicio = moment(fecha_montaje, "YYYY-MM-DDTHH:mm").format(
          "YYYY-MM-DD HH:mm:ss"
        );
      } else {
        fechaInicio = "0000-00-00 00:00:00";
      }
      if (fecha_entrega) {
        fechaFin = moment(fecha_entrega, "YYYY-MM-DDTHH:mm").format(
          "YYYY-MM-DD HH:mm:ss"
        );
      } else {
        fechaFin = "0000-00-00 00:00:00";
      }

      // console.log("send -> fechaInicio", fechaInicio);
      // console.log("send -> fechaFin", fechaFin);

      for (let i = 0; i < copia.length; i++) {
        copia[i].nombre = nombre;
        copia[i].locacion_sub = locacion_sub;
        copia[i].fecha_montaje = fechaInicio;
        copia[i].fecha_entrega = fechaFin;
        copia[i].orden = i;
      }
      let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
      let token;
      if (token1) {
        token = token1.id;
      }
      let data = {
        token: token,
        idUsuario: props.idUsuario,
        idEvento: props.idEvent,
        sub: props.num + 1,
        fechaInicio: fechaInicio,
        fechaFin: fechaFin,
        subEventos: props.subs,
        products: copia,
      };

      //setState({ ready: false });
      if (copia.length !== 0) {
        const response = await request.post("/auth/sales/saveodsArts", data);
        console.log(
          "🚀 ~ file: tableProducts.jsx ~ line 214 ~ send ~ response",
          response
        );

        if (response && !response.error) {
          if (response.result && !response.empty) {
            toast.success(
              <span>
                Datos del subevento {props.num + 1} guardados con exito.
              </span>,
              {
                position: toast.POSITION.TOP_RIGHT,
                theme: "colored",
              }
            );
            props.save();
            setReady(true);
            // alert("guardado");
            // props.save();
            // props.cont(props.subEv);

            // getProductsSubEvent();
          } else {
            toast.error(
              <span>
                Datos del subevento {props.num + 1}: no se guardo este
                subevento.
              </span>,
              {
                position: toast.POSITION.TOP_RIGHT,
                theme: "colored",
              }
            );
            setReady(true);
            props.save();
          }
        } else {
          toast.error(
            <span>
              Datos del subevento {props.num + 1}: no se guardo este subevento.
            </span>,
            {
              position: toast.POSITION.TOP_RIGHT,
              theme: "colored",
            }
          );
          setReady(true);
          props.save();
          // recargar(true);
        }
      } else {
        toast.warning(
          <span>
            <i className="fas fa-exclamation-circle"></i>&nbsp;Datos del
            subevento {props.num + 1}:, No tiene artículos.
          </span>,
          {
            position: toast.POSITION.TOP_RIGHT,
            theme: "colored",
          }
        );
        setReady(true);
        props.save();
      }
    }
  };

  return (
    <>
      {ready ? (
        <div
          className="card-body"
          style={{
            width: "98.5%",
          }}
        >
          <div
            style={{
              backgroundColor: "#F2F5F7",
              padding: 5 + "px",
              zoom: "85%",
            }}
          >
            <div className="row" style={{ zoom: "90%" }}>
              <div className="col-md-4 mt-1">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">{`Nom. Subevento ${
                      props.num + 1
                    }`}</span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    value={nombre}
                    name="nombre"
                    onChange={(e) => setNombre(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-md-4 mt-1">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">Locacion</span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    value={locacion_sub}
                    name="locacion_sub"
                    onChange={(e) => setLocacion(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-1"></div>
              <div className="col-md-3 text-right">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">Sub-Evento</span>
                  </div>
                  <div
                    className="form-control"
                    style={{
                      zoom: "115%",
                      padding: "2.5px",
                      backgroundColor: "rgb(242, 245, 247)",
                    }}
                  >
                    <button
                      className="btn btn-sm btn-success btn-gradient-success"
                      onClick={(e) => props.addSub()}
                    >
                      <i className="fas fa-plus-circle"></i>&nbsp;Agregar
                    </button>
                    {props.subs > 1 ? (
                      <button
                        className="btn btn-sm btn-danger bg-gradient-danger ml-5"
                        onClick={(e) => deleteSub()}
                      >
                        <i className="fa fa-eraser"></i>&nbsp;Borrar
                      </button>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="col-md-4 mt-2">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">Fecha Montaje</span>
                  </div>
                  <input
                    type="datetime-local"
                    className="form-control"
                    value={fecha_montaje}
                    name="fecha_montaje"
                    onChange={(e) => setMontaje(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-md-4 mt-2">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">Fecha entrega</span>
                  </div>
                  <input
                    type="datetime-local"
                    className="form-control"
                    value={fecha_entrega}
                    name="fecha_entrega"
                    onChange={(e) => setEntrega(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-md-4 mt-2 text-left" style={{ zoom: "115%" }}>
                <NewItem
                  funcion={(e) => getProducts(e)}
                  boton={productos}
                  idUbicacion={props.idUbicacion}
                  fechaI={props.fechaI}
                  fechaF={props.fechaF}
                  fechaM={props.fechaM}
                  fechaD={props.fechaD}
                  subEvents={props.subs}
                />
              </div>
            </div>
          </div>
          {productos.length !== 0 ? (
            <table className="table table-sm table-hover" id="ArticulosCotizar">
              <thead>
                <tr>
                  <th className="text-sm p-0" style={{ width: "80px" }}></th>
                  <th className="text-sm p-0">FOTO</th>
                  <th className="text-sm p-0">ID</th>
                  <th className="text-sm p-0">ARTÍCULO/DIM</th>
                  <th className="text-sm p-0">STOCK</th>
                  <th className="text-sm p-0">UNID</th>
                  <th className="text-sm p-0" colSpan={2}>
                    COTIZAR
                  </th>
                  <th className="text-sm p-0">PRECIOUNI</th>
                  <th className="text-sm p-0">DÍAS</th>
                  <th className="text-sm p-0" colSpan={2}>
                    INDICACIONES
                  </th>
                </tr>
              </thead>
              <tbody>
                {productos.map((datos, key) => (
                  <tr key={key}>
                    <td className="p-0" style={{ zoom: "80%" }}>
                      <p
                        className="btn btn-sm btn-danger bg-gradient-danger p-1"
                        onClick={(e) => deleting(key, datos)}
                        style={{ width: "100%" }}
                      >
                        <i className="fas fa-trash"></i>&nbsp;Eliminar
                      </p>
                      {datos.oculto_cot === 0 ? (
                        <p
                          className="btn btn-sm  bg-info bg-gradient-info p-1"
                          onClick={(e) => handleChange(key, 1, "oculto_cot")}
                          style={{ width: "100%" }}
                        >
                          <i className="fas fa-eye"></i>&nbsp;Cotización
                        </p>
                      ) : (
                        <p
                          className="btn btn-sm  bg-info bg-gradient-info p-1 disabled"
                          onClick={(e) => handleChange(key, 0, "oculto_cot")}
                          style={{ width: "100%" }}
                        >
                          <i className="fas fa-eye-slash"></i>&nbsp;Cotización
                        </p>
                      )}
                      {datos.oculto_ods === 0 ? (
                        <p
                          className="btn btn-sm  bg-info bg-gradient-info p-1"
                          onClick={(e) => handleChange(key, 1, "oculto_ods")}
                          style={{ width: "100%" }}
                        >
                          <i className="fas fa-eye"></i>&nbsp;ODS
                        </p>
                      ) : (
                        <p
                          className="btn btn-sm bg-info bg-gradient-info p-1 disabled"
                          onClick={(e) => handleChange(key, 0, "oculto_ods")}
                          style={{ width: "100%" }}
                        >
                          <i className="fas fa-eye-slash"></i>&nbsp;ODS
                        </p>
                      )}
                    </td>
                    <td>
                      <img
                        className="card-img-top"
                        style={{
                          maxHeight: 80 + "px",
                          width: "auto",
                          maxWidth: 90 + "px",
                        }}
                        src={
                          datos.img_char === "http"
                            ? datos.img_pred
                            : `${request.publicURL()}/inventario/${
                                datos.img_pred
                              }`
                        }
                        alt="Foto Artículo"
                        onError={(e) => {
                          e.target.src = "./assets/uploads/no-image.jpg";
                        }}
                      />
                    </td>
                    <td>{datos.id_art}</td>
                    <td>
                      {datos.nomE}
                      <hr />
                      {datos.nomI}
                      <br />
                      <span className="text-sm">{datos.dim}</span>
                    </td>
                    <td className="Stock" style={{zoom:'75%'}}>
                      {parseInt(datos.tabla) !== 1 ? (
                        <TableProductsExistences
                          stock={datos.stock}
                          idEvent={props.idEvent}
                          idSub={props.num + 1}
                          idArt={datos.id_art}
                          idUbicacion={props.idUbicacion}
                          fechaI={props.fechaI}
                          fechaF={props.fechaF}
                          fechaM={props.fechaM}
                          fechaD={props.fechaD}
                          subEvents={props.subs}
                        />
                      ) : (
                        <i className="fas fa-question"></i>
                      )}
                    </td>
                    <td>{datos.unidad}</td>
                    <td>
                      <div className="form-group">
                        <label className="text-sm">BODEGA</label>
                        <input
                          type="number"
                          name="cantidad"
                          id={`cantidad${key}`}
                          step="1"
                          min="0"
                          className="form-control"
                          value={datos.cantidad}
                          aria-required="true"
                          onChange={(e) => {
                            document
                              .getElementById(e.target.id)
                              .classList.remove("is-invalid");
                            handleChange(key, e.target.value, e.target.name);
                          }}
                          disabled={parseInt(datos.tabla) !== 1 ? false : true}
                        />
                      </div>
                    </td>
                    <td>
                      <div className="form-group">
                        <label className="text-sm">PRODUCCIÓN</label>
                        <input
                          type="number"
                          name="produccion"
                          id={`produccion${key}`}
                          step="1"
                          min="0"
                          className="form-control"
                          value={datos.produccion}
                          aria-required="true"
                          onChange={(e) => {
                            document
                              .getElementById(e.target.id)
                              .classList.remove("is-invalid");
                            handleChange(key, e.target.value, e.target.name);
                          }}
                        />
                      </div>
                    </td>
                    <td>
                      <div className="form-group">
                        <label>&nbsp;</label>

                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text pl-1 pr-1">
                              $
                            </span>
                          </div>
                          <input
                            type="number"
                            name="preciouni"
                            id={`preciouni${key}`}
                            step="any"
                            min="0"
                            className="form-control"
                            value={datos.preciouni}
                            aria-required="true"
                            onChange={(e) => {
                              document
                                .getElementById(e.target.id)
                                .classList.remove("is-invalid");
                              handleChange(key, e.target.value, e.target.name);
                            }}
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="form-group">
                        <label>&nbsp;</label>
                        <input
                          type="number"
                          name="dias"
                          id={`dias${key}`}
                          step="any"
                          min="0"
                          className="form-control"
                          value={datos.dias}
                          aria-required="true"
                          onChange={(e) => {
                            document
                              .getElementById(e.target.id)
                              .classList.remove("is-invalid");
                            handleChange(key, e.target.value, e.target.name);
                          }}
                        />
                      </div>
                    </td>
                    <td className="p-0" style={{ zoom: "90%" }}>
                      <div className="form-group mb-0">
                        <label className="text-sm mb-0">CLIENTE</label>
                        <textarea
                          className="form-control"
                          cols="30"
                          rows="1"
                          name="comentarios"
                          placeholder="Comentarios para el Cliente"
                          onChange={(e) =>
                            handleChange(key, e.target.value, e.target.name)
                          }
                          value={datos.comentarios}
                        ></textarea>
                      </div>
                      <div className="form-group mb-0">
                        <label className="text-sm mb-0">OPERACIÓN</label>
                        <textarea
                          className="form-control"
                          cols="30"
                          rows="1"
                          name="indicaciones"
                          placeholder="Indicaciones para Operaciones"
                          onChange={(e) =>
                            handleChange(key, e.target.value, e.target.name)
                          }
                          value={datos.indicaciones}
                        ></textarea>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p className="pt-4">No se han agregado artículos.</p>
          )}
        </div>
      ) : (
        <div className="card-body">
          <div className="overlay text-center" style={{ color: "#007bff" }}>
            <i className="fas fa-2x fa-spinner fa-spin"></i>
            <br />
            Guardando Subevento {props.num + 1}...
          </div>
          <hr className="mb-3 mt-3" />
        </div>
      )}
      <input ref={ref} type="hidden" onClick={() => send()} />
    </>
  );
}
export default forwardRef(TableProducts);
