import React, {useEffect, useState} from "react";
import Request from "../../../utils/http";
import MUIDataTable from "mui-datatables";
import ButtonSave from "../../inventory/tabs/buttonSave";

const request = new Request();


export default function FormUbicaion(props){

    const [ubicacion, setUbicacion] = useState({
        name_ubicacion:'',
        description:''
    });

    //nuestro token para todos
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token = token1.id;

    useEffect(() => {

    }, []);

    let validarUbicacion=data=>{
      let error = 1;
        if (data.name_ubicacion === null || data.name_ubicacion.length === 0 || data.name_ubicacion === "") {
            document.getElementById("name_ubicacion").classList.add("is-invalid");
            return error;
          }
        if (data.description === null || data.description.length === 0 || data.description === "") {
          document.getElementById("description").classList.add("is-invalid");
          return error;
        }
      return data;
    }

    const save = async () => {
        let datos=validarUbicacion(ubicacion);
        if(datos===1){
          alert("favor de llenar los campos");
        }else{
          let data = {
            token: token,
            datos: datos
          }
  
          const response = await request.post("/employee/saveUbicacion", data);
          if (response && !response.error) {
              if (response.result.ok && !response.empty) {
                  alert("Ubicacion guardado");
                  props.recargar();
                  props.closeModal();
              } else {
                console.log(response.result.message);
              }
            } else {
              console.log("error en la peticion");
          }
        }
    }

    return(
        <div className="tab-content">
            <div className="row mt-2">
                <div className="col-md-6">
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Ubicación</span>
                        </div>
                        <input type="text"  className="form-control text-uppercase" 
                            id="name_ubicacion" 
                            name="name_ubicacion" 
                            onChange={e => setUbicacion({...ubicacion,name_ubicacion:e.target.value.toUpperCase()})} 
                         />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Descripción</span>
                        </div>
                        <input type="text" className="form-control text-uppercase" 
                            id="description" 
                            name="description" 
                            onChange={e => setUbicacion({...ubicacion,description:e.target.value.toUpperCase()})}
                          />
                    </div>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-md-12">
                    <ButtonSave  
                    save={save}
                    closeModal={props.closeModal}
                    />
                </div> 
            </div>
        </div>
    );
}