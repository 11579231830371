/**
 *  @name: private.jsx
 *  @version: 1.0.0
 *  @author: Trejocode - Sergio
 *  @description: Página privada
 */

import React from "react";
import Layout from "../layout";
import Sidebar from "../../components/header/sidebar";
import TableArticlesTemp from "../../components/inventory/temporary/tableArticlesTemp";

const InventoryTemp = () => (
  <Layout
    title="Artículos Temporales"
    description="Página privada de perfil"
    keywords="Reactjs, SEO Ready, Ejemplo"
  >
    <Sidebar />
    <TableArticlesTemp></TableArticlesTemp>
  </Layout>
);

export default InventoryTemp;
