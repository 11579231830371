import React, { useState, useEffect } from "react";
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from "@material-ui/core/MenuItem";
import Button from '@material-ui/core/Button';


function DeleteEvent(props) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
        props.handleClose();
    };

    const handleClose = () => {
        setOpen(false);
    };
    const deleteEvent = async (id) => {
        handleClose();
        props.deleteCalendarEvent(id);
    };
    return (
        <>
            <MenuItem onClick={() => handleClickOpen()}>
                <DeleteIcon style={{ color: "#b71515" }} />
          Eliminar
        </MenuItem>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title"><i className="fas fa-exclamation-triangle"></i> {"¿Seguro que desea eliminar este evento?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Se eliminara el evento de forma permanente. Esta acción no se puede deshacer!.
          </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <button className="btn btn-outline-success btn-flat" onClick={() => deleteEvent(props.idEv)} autoFocus>
                        Aceptar
          </button>
                    <button className="btn btn-outline-danger btn-flat" onClick={handleClose}>
                        Cerrar
          </button>
                </DialogActions>
            </Dialog>

        </>
    )
}
export default DeleteEvent;