import React, { useState, useEffect } from "react";
import Request from "../../utils/http";
import { Zoom, toast } from "react-toastify";

const request = new Request();

function OdsStep4(props, ref) {
  const [artInfo, setArtInfo] = useState([]);
  const [loadArt, setLoadArt] = useState(false);

  // const [disabledOrder, setDisabledOrder] = useState(false);
  const [block, setBlock] = useState(false);
  const [load, setLoad] = useState(false);

  useEffect(() => {
    if (props.sub !== 0) {
      getEventArt(props.sub);
    }
  }, [props.sub]);

  useEffect(() => {
    if (props.reload === true && props.sub !== 0) {
      console.log("props", props);
      getEventArt(props.sub);
    }
  }, [props.reload]);

  const getEventArt = async (sub) => {
    setLoadArt(false);
    localStorage.removeItem("orderArt");
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }

    let data = {
      token: token,
      id_event: props.id,
      events: sub,
    };

    const response = await request.post("/auth/calendar/getEventArt", data);
    console.log(
      "🚀 ~ file: odsStep4.jsx ~ line 49 ~ getEventArt ~ response",
      data,
      response
    );

    if (response && !response.error) {
      if (response.result && !response.empty) {
        setLoadArt(true);
        window.localStorage.setItem(
          "orderArt",
          JSON.stringify(response.result)
        );
        setArtInfo(response.result);
        // window.sessionStorage.setItem(
        //   "Productos",
        //   JSON.stringify(response.result)
        // );
      } else {
        setLoadArt(true);
        // this.setState({
        //   empty: true,
        //   loading: false,
        //   message: response.message
        // });
      }
    } else {
      setLoadArt(true);
      // this.setState({
      //   error: true,
      //   loading: false,
      //   message: response.message
      // });
    }
  };
  const eventDown = (e, key, info) => {
    key = key - 1;
    let art = JSON.parse(localStorage.getItem("orderArt"));
    let down = art[key + 1];
    art[key + 1] = info;
    art[key] = down;
    changeOrderEvent(art);
  };
  const eventUp = (e, key, info) => {
    key = key - 1;
    let art = JSON.parse(localStorage.getItem("orderArt"));
    let down = art[key - 1];
    art[key - 1] = info;
    art[key] = down;
    changeOrderEvent(art);
  };
  const changeOrderEvent = async (art) => {
    for (let i = 0; i < art.length; i++) {
      for (let x = 0; x < art[i].length; x++) {
        art[i][x].id_subevento = i + 1;
      }
    }
    window.localStorage.setItem("orderArt", JSON.stringify(art));
    let productos = JSON.parse(localStorage.getItem("orderArt"));
    setArtInfo(productos);
  };
  const rowDown = (e, info, index, art) => {
    let down = art[index + 1];
    art[index + 1] = info;
    art[index] = down;
    let id = info.id_subevento - 1;
    changeOrderArt(art, id);
  };
  const rowUp = (e, info, index, art) => {
    let up = art[index - 1];
    art[index - 1] = info;
    art[index] = up;
    let id = info.id_subevento - 1;
    changeOrderArt(art, id);
  };
  const changeOrderArt = async (art, id) => {
    window.localStorage.setItem("orderArt", JSON.stringify(artInfo));
    let productos = JSON.parse(localStorage.getItem("orderArt"));
    productos[id] = art;
    window.localStorage.setItem("orderArt", JSON.stringify(productos));
    productos = JSON.parse(localStorage.getItem("orderArt"));
    setArtInfo(productos);
  };
  const clean = () => {
    //localStorage.removeItem(props.id);
  };
  const save = async () => {
    setLoad(true);
    setBlock(true);
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }
    if (artInfo.length !== 0) {
      let data = {
        token: token,
        products: JSON.parse(localStorage.getItem("orderArt")),
      };
      // console.log(data);

      const response = await request.post("/auth/sales/artUpDown", data);

      if (response && !response.error) {
        if (response.result && !response.empty) {
          getEventArt(props.sub);
          props.setReload();

          setLoad(false);
          setBlock(false);
          toast.success(
            <span>
              Orden de artículos
              cambiado con exito.
            </span>,
            {
              position: toast.POSITION.TOP_RIGHT,
          theme: "colored",
            }
          );

          // props.reload(true);
          // props.recargarInfo()
        } else {
          setLoad(false);
          setBlock(false);
          toast.error(
            <span>
              UPSS!!!, algo salio
              mal.. intentalo de nuevo, si el problema continua contacta a
              sistemas.
            </span>,
            {
              position: toast.POSITION.TOP_RIGHT,
          theme: "colored",
            }
          );
          // this.setState({
          //   empty: true,
          //   loading: false,
          //   message: response.message
          // });
        }
      } else {
        setLoad(false);
        setBlock(false);
        toast.error(
          <span>
            UPSS!!!, algo salio
            mal.. intentalo de nuevo, si el problema continua contacta a
            sistemas.
          </span>
        );
        // this.setState({
        //   error: true,
        //   loading: false,
        //   message: response.message
        // });
      }
    }
  };
  const reload = () => {
    setLoadArt(false);
    getEventArt(props.sub);
  };
  return (
    <>
      <div className="form-step2" style={{zoom:"85%"}}>
        <div className="table-responsive p-0" style={{ textAlign: "center" }}>
          {loadArt ? (
            <>
              {artInfo.length !== 0 ? (
                <table
                  className="table table-sm table-hover"
                  id="ArticulosOrdenar"
                >
                  {artInfo.map((key, index) => (
                    <tbody key={index}>
                      {key.length > 0 ? (
                        <>
                          <tr>
                            <td colSpan="2" className="card-header">
                              {artInfo.length > 1 ? (
                                <>
                                  <button
                                    className="btn btn-sm btn-info bg-gradient-info"
                                    onClick={(e) =>
                                      eventUp(e, key[0].id_subevento, key)
                                    }
                                    disabled={index === 0 ? true : false}
                                  >
                                    <i className="fas fa-arrow-up"></i>
                                  </button>
                                  &nbsp;
                                  <button
                                    className="btn btn-sm btn-info bg-gradient-info"
                                    onClick={(e) =>
                                      eventDown(e, key[0].id_subevento, key)
                                    }
                                    disabled={
                                      index === artInfo.length - 1
                                        ? true
                                        : false
                                    }
                                  >
                                    <i className="fas fa-arrow-down"></i>
                                  </button>
                                </>
                              ) : null}
                            </td>
                            <td colSpan="7" className="card-header">
                              <div className="row">
                                <div className="col-4">
                                  SUBEVENTO {key[0].id_subevento}:
                                  {key[0].nombre}
                                </div>
                                <div className="col-4">
                                  MONTAJE: {key[0].fecha_montaje}h
                                </div>
                                <div className="col-4">
                                  ENTREGA: {key[0].fecha_entrega}h
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td></td>
                            <td>FOTO</td>
                            <td>ID</td>
                            <td>ARTÍCULO/DIM</td>
                            <td>COTIZAR</td>
                            <td>INDICACIÓN</td>
                            <td>COMENTARIOS</td>
                          </tr>
                          {key.map((info, id) => (
                            <tr key={id}>
                              <td>
                                {key.length > 1 ? (
                                  <>
                                    <button
                                      className="btn btn-sm btn-info bg-gradient-info"
                                      onClick={(e) => rowUp(e, info, id, key)}
                                      disabled={id === 0 ? true : false}
                                    >
                                      <i className="fas fa-arrow-up"></i>
                                    </button>
                                    <br />
                                    <button
                                      className="btn btn-sm btn-info bg-gradient-info"
                                      onClick={(e) => rowDown(e, info, id, key)}
                                      disabled={
                                        id === key.length - 1 ? true : false
                                      }
                                    >
                                      <i className="fas fa-arrow-down"></i>
                                    </button>
                                  </>
                                ) : null}
                              </td>
                              <td>
                                <img
                                  className="card-img-top"
                                  style={{
                                    maxHeight: 100 + "px",
                                    width: "auto",
                                    maxWidth: 120 + "px",
                                  }}
                                  src={
                                    info.img_char === "http"
                                      ? info.img_pred
                                      : `${request.publicURL()}/inventario/${info.img_pred}`
                                  }
                                  alt="Foto Artículo"
                                  onError={(e) => {
                                    e.target.src =
                                      "./assets/uploads/no-image.jpg";
                                  }}
                                />
                              </td>
                              <td>{info.id_art}</td>
                              <td>
                                {info.nomE}
                                <br />
                                <small>{info.dim}</small>
                              </td>
                              <td>
                                {info.total}&nbsp;{info.unidad}
                              </td>
                              <td>
                                <small>{info.indicaciones}</small>
                              </td>
                              <td>
                                <small>{info.comentarios}</small>
                              </td>
                            </tr>
                          ))}
                        </>
                      ) : null}
                    </tbody>
                  ))}
                </table>
              ) : (
                <div className="card-body">
                  No se han agregado artículos a esta cotización. &nbsp;
                </div>
              )}
            </>
          ) : (
            <div className="overlay text-center" style={{ color: "#007bff" }}>
              <i className="fas fa-2x fa-spinner fa-spin"></i>
              <br />
              Cargando...
            </div>
          )}
        </div>
      </div>
      <div className="modal-footer justify-content-left " style={{zoom:"85%"}}>
        <button
          className="btn btn-outline-secondary"
          onClick={() => {
            setLoadArt(false);
            getEventArt(props.sub);
          }}
          disabled={!loadArt}
        >
          <i className="fas fa-sync"></i> Recargar
        </button>
        &nbsp;
        {loadArt && artInfo.length !== 0 ? (
          <>
            <button
              type="button"
              onClick={() => save()}
              className="btn btn-outline-success btn-flat "
              disabled={block}
            >
              {block ? (
                <i className="fas fa-spinner fa-spin"></i>
              ) : (
                <i className="far fa-save"></i>
              )}
              &nbsp; Guardar
            </button>
            &nbsp;
            <button
              type="button"
              onClick={(e) => props.closeModal(e)}
              className="btn btn-outline-danger btn-flat"
              disabled={block}
            >
              <i className="fas fa-ban"></i> Cerrar
            </button>
          </>
        ) : null}
      </div>
      <input ref={ref} type="hidden" name="reload" onClick={() => reload()} />
    </>
  );
}
export default React.forwardRef(OdsStep4);
