
let validator = data => {

    let error = 1;

    if (data.id_tipo.length === 0 || data.id_tipo === "") {
        document.getElementById("id_tipo").classList.add("is-invalid");
      return error;
    }
    // if (data.fecha.length === 0 || data.fecha === "") {
    //     document.getElementById("fecha").classList.add("is-invalid");
    //   return error;
    // }
    // if (data.hora.length === 0 || data.hora === "") {
    //     document.getElementById("hora").classList.add("is-invalid");
    //   return error;
    // }

    return data;
}

export default validator;