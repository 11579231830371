import React, { useState, useEffect } from "react";
import Request from "../../../utils/http";
import ButtonSave from "./buttonSave";
import { Consumer } from "../../../context/index";
import { toast } from "react-toastify";

const request = new Request();
function TempBlock(props) {


  return (
    <div></div>
  )
}  

export default Consumer(TempBlock);
