export const DBConfig = {
    name: 'zde_data',
    version: 4,
    objectStoresMeta: [
      {
        store: 'status',
        storeConfig: { keyPath: 'id_status', autoIncrement: true },
        storeSchema: [
          { name: 'status', keypath: 'status', options: { unique: false } },
          { name: 'color', keypath: 'color', options: { unique: false } },
          { name: 'colorRA', keypath: 'colorRA', options: { unique: false } },
          { name: 'colorRN', keypath: 'colorRN', options: { unique: false } },
          { name: 'activo', keypath: 'activo', options: { unique: false } }
        ]
      },
      {
        store: 'sellers',
        storeConfig: { keyPath: 'id_usuario', autoIncrement: true },
        storeSchema: [
          { name: 'nombre', keypath: 'nombre', options: { unique: false } },
        ]
      },
      {
        store: 'hotel',
        storeConfig: { keyPath: 'id_hotel', autoIncrement: true },
        storeSchema: [
          { name: 'nombre', keypath: 'nombre', options: { unique: false } },
          { name: 'comision_bn', keypath: 'comision_bn', options: { unique: false } },
          { name: 'comision_bi', keypath: 'comision_bi', options: { unique: false } },
          { name: 'comision_gn', keypath: 'comision_gn', options: { unique: false } },
          { name: 'comision_gi', keypath: 'comision_gi', options: { unique: false } },
          { name: 'activar', keypath: 'activar', options: { unique: false } },
          { name: 'abreviar', keypath: 'abreviar', options: { unique: false } },
          { name: 'id_ubicacion', keypath: 'id_ubicacion', options: { unique: false } },
          { name: 'lugar', keypath: 'lugar', options: { unique: false } }
        ]
      },
      {
        store: 'locations',
        storeConfig: { keyPath: 'id_ubicacion', autoIncrement: true },
        storeSchema: [
          { name: 'abr', keypath: 'abr', options: { unique: true } },
          { name: 'lugar', keypath: 'descrip', options: { unique: false } },
        ]
      },
      {
        store: 'type',
        storeConfig: { keyPath: 'id_tipo', autoIncrement: true },
        storeSchema: [
          { name: 'tipo_esp', keypath: 'tipo_esp', options: { unique: false } },
          { name: 'tipo_ing', keypath: 'tipo_ing', options: { unique: false } },
          { name: 'activo', keypath: 'activo', options: { unique: false } }
        ]
      } ,
      {
        store: 'category',
        storeConfig: { keyPath: 'id_tema', autoIncrement: true },
        storeSchema: [
          { name: 'Tema', keypath: 'Tema', options: { unique: false } },
        ]
      },
      {
        store: "events",
        storeConfig: { keyPath: "id", autoIncrement: true },
        storeSchema: [
          { name: "title", keypath: "title", options: { unique: false } },
          { name: "start", keypath: "start", options: { unique: false } },
          { name: "color", keypath: "color", options: { unique: false } },
          { name: "textColor", keypath: "textColor", options: { unique: false } },
          { name: "status", keypath: "status", options: { unique: false } },
          { name: "id_status", keypath: "id_status", options: { unique: false } },
          { name: "id_hotel", keypath: "id_hotel", options: { unique: false } },
          { name: "nom_hotel", keypath: "nom_hotel", options: { unique: false } },
          { name: "id_usuario", keypath: "id_usuario", options: { unique: false } },
          { name: "nom_vend", keypath: "nom_vend", options: { unique: false } },
          { name: "id_tipo", keypath: "id_tipo", options: { unique: false } },
          { name: "tipo", keypath: "tipo", options: { unique: false } },
          { name: "lugar", keypath: "lugar", options: { unique: false } },
        ],
      },
      {
        store: "cot_cliente",
        storeConfig: { keyPath: "id_cliente", autoIncrement: true },
        storeSchema: [
          { name: "nombre", keypath: "nombre", options: { unique: false } },
        ],
      },
      {
        store: "cot_cdfi",
        storeConfig: { keyPath: "id_uso_CFDI", autoIncrement: true },
        storeSchema: [
          { name: "descripcion", keypath: "descripcion", options: { unique: false } },
        ],
      },
      {
        store: "cot_fpago",
        storeConfig: { keyPath: "id_forma_pago", autoIncrement: true },
        storeSchema: [
          { name: "descripcion", keypath: "descripcion", options: { unique: false } },
        ],
      },
      {
        store: "cot_mpago",
        storeConfig: { keyPath: "id_metodo_pago", autoIncrement: true },
        storeSchema: [
          { name: "descripcion", keypath: "descripcion", options: { unique: false } },
        ],
      },
      {
        store: "cot_moneda",
        storeConfig: { keyPath: "id_moneda", autoIncrement: true },
        storeSchema: [
          { name: "descripcion", keypath: "descripcion", options: { unique: false } },
        ],
      },
    ]
  };
