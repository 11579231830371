/**
 *  @name: private.jsx
 *  @version: 1.0.0
 *  @author: Trejocode - Sergio
 *  @description: Página privada
 */

import React, { useState, useEffect } from "react";
import { Consumer } from "../../context/index";
import Layout from "../layout";
import Sidebar from "../../components/header/sidebar";
import SupList from "../../components/operation/supList";

function Operation(props) {
  const [user, setUser] = useState({
    idUsuario: 0,
    idPerfil: 0,
    nombre: "",
    idUbicacion: 0,
  });

  useEffect(() => {
    loadUser();
  }, []);

  const loadUser = async () => {
    const user = await props.context.loadUser();

    if (user) {
      setUser(user);
    }
  };

  return (
    <Layout
      title="Lista de Supervisores / Encargados de Eventos"
      description="Página privada de perfil"
      keywords="Reactjs, SEO Ready, Ejemplo"
    >
      <Sidebar />
      <SupList id_modulo={1} user={user} />
    </Layout>
  );
}
export default Consumer(Operation);
