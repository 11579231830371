import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Consumer } from "../../context/index";
//import "./login.css";
import Request from "../../utils/http";
import CheckVersion from "../general/checkVersion";

const request = new Request();

class LoginBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      usuario: "",
      password: "",
    };
  }
  render() {
    return (
      <div className="hold-transition login-page">
        <div className="login-box">
          <div className="login-logo">
            <img
              src="./assets/home/logo_ods.png"
              alt="Zuñiga Productions"
              style={{ width: "90%" }}
            />
          </div>
          <div className="card">
            <div className="card-body login-card-body">
              <p className="login-box-msg">
                <b>Iniciar Sesión</b>
                <CheckVersion />
              </p>

              <form onSubmit={this.handleSubmit.bind(this)}>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Usuario"
                    name="user"
                    value={this.state.usuario}
                    autoComplete="on"
                    onChange={(e) => this.setState({ usuario: e.target.value })}
                  />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fas fa-envelope"></span>
                    </div>
                  </div>
                </div>
                <div className="input-group mb-3">
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Password"
                    name="password"
                    value={this.state.password}
                    autoComplete="on"
                    onChange={(e) =>
                      this.setState({ password: e.target.value })
                    }
                  />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fas fa-lock"></span>
                    </div>
                  </div>
                </div>
                <div className="white-space-48">{this.state.message}</div>
                <div className="row">
                  <div className="col-12">
                    <button
                      type="submit"
                      className="btn btn-primary btn-block"
                      disabled={this.state.loading}
                    >
                      {this.state.loading ? (
                        <i className="fas fa-spinner fa-spin"></i>
                      ) : (
                        <i className="fas fa-sign-in-alt"></i>
                      )}
                      &nbsp; Iniciar sesíon
                    </button>
                  </div>
                </div>
              </form>
              <p className="mt-4">
                ¿Olvido su contraseña? Contacte al soporte técnico para
                recuperarla.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  async handleSubmit(e) {
    e.preventDefault();
    let form = e.target;
    let data = {
      user: form.user.value,
      password: form.password.value,
    };
    // console.log(data);
    this.setState({ loading: true });
    //const response = await request.login("/login/login", data).;
    await request
      .login("/auth/login", data)
      .then((response) => {
        //console.log("🚀 ~ file: index.jsx ~ line 114 ~ LoginBox ~ .then ~ response", response)
        
        switch (response.statusCode) {
          case 200:
            this.props.context.login({
              id: response.result.token,
              expires_at: response.result.expires_at,
              auth: true,
              user: response.result.user,
            });
            console.log("🚀 ~ file: index.jsx ~ line 114 ~ LoginBox ", response.result.user)
            /*this.props.history.push("/inicio");*/
            switch (response.result.user.id_perfil) {
              case 1:
                this.props.history.push("/inicio");
                break;
              case 2:
              case 3:
                this.props.history.push("/ventas");
              break;
              case 14:
                this.props.history.push("/diseno");
                break;
              case 20:
              case 21:
                this.props.history.push("/compras");
              break;
              case 22:
                this.props.history.push("/inventario");
                break;
              case 23:
                this.props.history.push("/contabilidad");
                break;
              default:
                this.props.history.push("/operaciones");
              break;
            }
            
            break;

          default:
            this.setState({
              error: true,
              loading: false,
              message: "ERROR " + response.statusCode + ". " + response.message,
            });
            break;
        }
      })
      .catch((err) => {
        console.log("LoginBox -> handleSubmit -> err", err);
      });
  }
}

export default withRouter(Consumer(LoginBox));
