import React, { useState, useEffect } from "react";
import { Consumer } from "../../context/index";
import Modal from "react-modal";
import EdditOds from "../sales/edditOds";
Modal.setAppElement("#root");


function ModalEddit(props) {
  const [user, setUser] = useState({
    idUsuario: 0,
    idPerfil: 0,
    nombre: "",
    ubicacion: 0,
  });


  const customStyles = {
    content: {
      position: "absolute",
      top: "10px",
      left: "50px",
      right: "50px",
      bottom: "10px",
      border: "1px solid rgb(204, 204, 204)",
      background: "rgb(255, 255, 255)",
      overflow: "auto",
      outline: "none",
      padding: "10px",
    },
  };

  useEffect(() => {
    loadUser();
  }, []);

  const loadUser = async () => {
    const user = await props.context.loadUser();

    if (user) {
      setUser(user);
    }
  };

  return (
    <Modal
      isOpen={props.modalEddit}
      contentLabel="Editar/Agregar Evento"
      style={customStyles}
    >
      <div className="modal-header pt-2 pb-1" >
        <div className="row col-12">
          <div className="col-12 col-md-12">
            <h6 className="modal-title">
              {props.infoEvent.id_event}-{props.infoEvent.title}
            </h6>
          </div>
        </div>
        <button
          type="button"
          className="close"
          onClick={(e) => {
            props.setModalEddit(false);
            props.setNewCot(false);
          }}
          aria-label="Close"
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <EdditOds
        idEvent={props.infoEvent.id_event}
        eddit={props.infoEvent.id_event === 0 ? 0 : 1}
        closeModal={(e) => props.setModalEddit(false)}
        updateEvent={(e) => props.updateEvent(e)}
        changeEvent={(e) => props.changeEvent(e)}
        setRecargaOds={(e) => props.setRecargaOds(e)}
      />
    </Modal>
  );
}
export default Consumer(ModalEddit);
