import React, {useEffect, useState} from "react";
import Request from "../../../utils/http";
import MUIDataTable from "mui-datatables";
import moment from "moment";



export default function FormVacation(props){
    const request = new Request();
    const [verUsuarios, setVerUsuarios] = useState([]);

    //nuestro token para todos
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
        token = token1.id;
    }
    
    useEffect(() => {
        listarAsistencia();
        
    }, []);


    const listarAsistencia= async() => {
        let data = {
            token: token,
            id_asistencia:props.datos
        };

        const response = await request.post("/horasExtras/listarAsistencia", data);
        
        if (response && !response.error) {
            if (response.result.Litar_asistencia && !response.empty) {
                setVerUsuarios(response.result.Litar_asistencia);
            } else {
                console.log(response.result.message);
            }
        } else {
            console.log("error en la peticion");
        }
    };



let nombre='';
    return(

        <div className=" d-flex justify-content-center mt-3">
            <div className="col-md-8 card">
                <table className="table">
                    <thead>
                        <tr>
                        <th scope="col">Nombres</th>
                        <th scope="col">Nombre de Evento</th>
                        <th scope="col">Tipo</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            verUsuarios.map((val, key)=>(

                                <tr>
                                    <td>{val.name +' '+ val.last_name}</td>
                                    <td>{val.nombre}</td>
                                    <td>{val.type==1?'Inicio/Montaje':'Inicio/Desmontaje'}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
}