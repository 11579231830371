/**
 *  @name: private.jsx
 *  @version: 1.0.0
 *  @author: Trejocode - Sergio
 *  @description: Página privada
 */

import React from "react";
import Layout from "./layout";
import Sidebar from "../components/header/sidebar";
import UsersTable from "../components/users/usersTable"

const Users = () => (
  <Layout
    title="Perfil"
    description="Página privada de perfil"
    keywords="Reactjs, SEO Ready, Ejemplo"
  >
    <Sidebar />
    <div className="private column">
          <div className="justify-center">
            <div className="container">
            <div className="justify-center full">
              <div className="card" style={{width:'100%'}}>
              <div className="col-xl-12 mb-5 mb-xl-0">
            <div style={{ margin: 10 + "px" }}>
              <UsersTable />
            </div>
          </div>
                </div>
                </div>
            </div>
          </div>
          <div className="white-space-32"></div>
        </div>
  </Layout>
);

export default Users;