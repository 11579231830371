import React, {useEffect, useState} from "react";
import MUIDataTable from "mui-datatables";
import DeleteIcon from '@material-ui/icons/Delete';
import Request from "../../../utils/http";
import moment from "moment";
import FormPermisos from "./newPermisos";



function NewPermisos(){

  const request = new Request();
  const [Permisos, setPermisos]=useState([]);


  let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
  let token;
  if (token1) {
      token = token1.id;
  }

  useEffect(() => {
    getPermisos();
  }, []);

const getPermisos = async () => {
  let data = {
      token: token
  };
  const response = await request.post("/permiso/getPermisos", data);

  //validar los datos que recibimos
  if (response && !response.error) {
      if (response.result.permiso && !response.empty) {
        setPermisos(response.result.permiso);
      } else {
          console.log(response.result.message);
      }
  } else {
      console.log("error en la peticion");
  }
}

const eliminar= async(datosId)=>{     

  let data = {
      id: datosId,
      token: token
  };

  
  const response = await request.post("/permiso/deletePermiso", data);       
  if (response && !response.error) {
      let status = (response['result'].hasOwnProperty('status')) ? response['result']['status'] : 0; // 0 = Eliminado
      if (response.result.ok) {
          alert("Permiso Eliminado");
          refresTable(datosId);
          
      } else {
          console.log(response.result.message);
      }
  } else {
      console.log("error en la peticion");
  }

}

function refresTable(id_permiso) {
  let new_permiso = Object.assign([],Permisos);
  if (new_permiso.length>0 || Object.keys(new_permiso).length>0){
      for(let i in new_permiso){
          let empleado = new_permiso[i];
          if (empleado.id_permiso == id_permiso){
              new_permiso.splice(i, 1);
              break;
          }
      }
      setPermisos([]);
      setPermisos(new_permiso);
      getPermisos();
  }
}

  const columns = [

    {
      name: "name",
      label: "Nombre",
      options: {
       filter: true,
       sort: false,
      }
     },
    {
     name: "last_name",
     label: "Apellido",
     options: {
      filter: true,
      sort: false,
     }
    },
    {
     name: "date",
     label: "Fecha de Registro",
     options: {
      filter: true,
      sort: false,
      customBodyRender: (value, tableMeta, updateValue) => {
        let fecha=moment(value).format("DD-MM-YYYY");                   
        if (fecha=="Invalid date") {
        return <p>{''}</p> 
        }else{
            return <p>{fecha}</p>
        }
      }

     }
    },
    {
     name: "descripcion",
     label: "Motivo",
     options: {
      filter: true,
      sort: false,
     }
    },
    {
      name: "state",
      label: "accion",
      options: {
       filter: true,
       sort: true,
         customBodyRender: (value, tableMeta, updateValue) => {
           // console.log(employee[tableMeta.rowIndex].id_vacations);
           return (
               <div>
                   <div className="btn-group">
                       <button
                           className="btn btn-sm btn-danger"
                           title="Eliminar"
                           onClick={()=>{if(window.confirm('Esta seguro de Elimiar?')){eliminar(Permisos[tableMeta.rowIndex].id_permiso)}}} >
                           <DeleteIcon />
                       </button>
                   </div>
               </div>
           )
         }
      }
     }
   ];
   

   const options = {
    selectableRows: "none",
    filter: false,
    viewColumns: false,
    textLabels: {
      body: {
        noMatch: "No se encontraron resultados.",
        toolTip: "Sort"
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Número de filas:",
        displayRows: "de"
      },

      toolbar: {
        search: "Buscar",
        downloadCsv: "Descargar Excel",
        print: "Imprimir"
      },

      filter: {
        all: "TODOS",
        title: "FILTROS",
        reset: "LIMPIAR"
      },
      viewColumns: {
        title: "Mostar Columnas",
        titleAria: "Mostrar/Ocultar Columnas de la Tabla"
      },
      selectedRows: {
        text: "Fila(s) Seleccionadas",
        delete: "Eliminar",
        deleteAria: "Eliminar Filar Seleccionadas"
      }
    },
    customToolbar: () => {
      return <FormPermisos loadTable={recargarTabla}/>;
    }
  };

  const recargarTabla=()=>{
    getPermisos();
  }

    return ( 
        <MUIDataTable
        title={"PERMISOS"}
        data={Permisos}
        columns={columns}
        options={options}
      />
    )

}
export default NewPermisos;

