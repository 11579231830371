import React, { Component } from "react";
import ReactToPrint from "react-to-print";
import Request from "../../../utils/http";
import "./ods.css";

const request = new Request();
const titles = {
  esp: {
    infoe: "INFORMACIÓN DEL EVENTO",
    event: "Evento",
    type: "Tipo de Evento",
    fi: "Fecha de Inicio",
    site: "Hotel/Lugar",
    loc: "Salón/Locación",
    pax: "Invitados",
    theme: "Tema",
    contact: "Contacto/Coordinador(a)",
    infoz: "INFORMACIÓN ZUÑIGA",
    fc: "Fecha Cotización",
    ven: "Asesor de Ventas",
    tel: "Teléfono",
    mail: "Correo Electrónico",
    photo: "FOTO",
    item: "ARTÍCULO",
    pieces: "PIEZAS",
    unit: "UNIDAD",
    days: "DÍAS",
    price: "PRECIO",
    amount: "IMPORTE",
    desc: "DESCUENTO",
    op: "OPERACIÓN (LABOR/TRANSPORTACIÓN)",
    iva: "IVA 16%",
    mon: "MX",
    client: "Client",
  },
  ing: {
    infoe: "EVENT INFORMATION",
    event: "Event",
    type: "Event Type",
    fi: "Date of Event",
    site: "Site",
    loc: "Location",
    pax: "Guests",
    theme: "Theme",
    contact: "Contact/Planner",
    infoz: "ZUÑIGA INFORMATION",
    fc: "Proposal Date",
    ven: "Sales Manager",
    tel: "Cell Phone",
    mail: "E-mail",
    photo: "PHOTO",
    item: "ITEM",
    pieces: "PIECES",
    unit: "UNIT",
    days: "DAYS",
    price: "UNIT PRICE",
    amount: "AMOUNT",
    desc: "DISCOUNT",
    op: "OPERATIONS (LABOR STAFF/TRANSPORTATION)",
    iva: "TAX 16%",
    mon: "USD",
    client: "Cliente",
  },
};
class Ods extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id_event: this.props.idEvent,
      eventHeader: [],
      artInfo: [],
      events: 0,
      ods: this.props.ods,
    };
  }
  render() {
    return (
      <>
        {/* <ReactToPrint
          trigger={() => <a href="#">Print this out!</a>}
          content={() => this.componentRef}
        /> */}
        <div ref={(el) => (this.componentRef = el)}>
          <div className="row">
            <div className="col-md-6">
              <div className="card">
                <div className="card-header">
                  {this.props.ods ||
                  this.state.eventHeader.Idioma !== "English US"
                    ? titles.esp.infoe
                    : titles.ing.infoe}
                </div>
                <div className="card-body row">
                  <div className="col-12 data">
                    {this.props.ods ||
                    this.state.eventHeader.Idioma !== "English US"
                      ? titles.esp.event
                      : titles.ing.event}
                    :{" "}
                    {this.props.ods
                      ? this.state.eventHeader.id_evento + "-"
                      : ""}
                    <b>{this.state.eventHeader.nombre}</b>
                  </div>
                  <div className="col-12 data">
                    {this.props.ods ||
                    this.state.eventHeader.Idioma !== "English US" ? (
                      <>
                        {titles.esp.type}:{" "}
                        <b>{this.state.eventHeader.tipo_esp}</b>
                      </>
                    ) : (
                      <>
                        {titles.ing.type}:{" "}
                        <b>{this.state.eventHeader.tipo_ing}</b>
                      </>
                    )}
                  </div>
                  <div className="col-12 data">
                    {this.props.ods ||
                    this.state.eventHeader.Idioma !== "English US"
                      ? titles.esp.fi
                      : titles.ing.fi}{" "}
                    <b>{this.state.eventHeader.Fecha_inicio}h</b>
                  </div>
                  {this.props.ods ? (
                    <div className="col-12 data">
                      Fecha de Termino:{" "}
                      <b>{this.state.eventHeader.Fecha_fin}h</b>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="col-12 data">
                    {this.props.ods ||
                    this.state.eventHeader.Idioma !== "English US"
                      ? titles.esp.site
                      : titles.ing.site}
                    : <b>{this.state.eventHeader.nombre_h}</b>
                  </div>
                  <div className="col-12 data">
                    {this.props.ods ||
                    this.state.eventHeader.Idioma !== "English US"
                      ? titles.esp.loc
                      : titles.ing.loc}
                    : <b>{this.state.eventHeader.Salon}</b>
                  </div>
                  {this.props.ods ? (
                    <div className="col-12 data">
                      Backup: <b>{this.state.eventHeader.Bakup}</b>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="col-12 data">
                    {this.props.ods ||
                    this.state.eventHeader.Idioma !== "English US"
                      ? titles.esp.pax
                      : titles.ing.pax}
                    : <b>{this.state.eventHeader.Paxes}</b>
                  </div>
                  <div className="col-12 data">
                    {this.props.ods ||
                    this.state.eventHeader.Idioma !== "English US" ? (
                      <>
                        {titles.esp.theme}:{" "}
                        <b>
                          {this.state.eventHeader.Tema}-
                          {this.state.eventHeader.T_especial}
                        </b>
                      </>
                    ) : (
                      <>
                        {titles.ing.theme}:{" "}
                        <b>{this.state.eventHeader.Tema_ing}</b>
                      </>
                    )}
                  </div>
                  {this.props.ods ? (
                    <>
                      {" "}
                      <div className="col-12 data">
                        Fecha de Montaje:{" "}
                        <b>{this.state.eventHeader.Fecha_montaje}h</b>
                      </div>
                      <div className="col-12 data">
                        Fecha de Desmontaje:{" "}
                        <b>{this.state.eventHeader.Fecha_desmontaje}h</b>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  <div className="col-12 data">
                    {this.props.ods ||
                    this.state.eventHeader.Idioma !== "English US"
                      ? titles.esp.contact
                      : titles.ing.contact}
                    : <b>{this.state.eventHeader.Contacto_Hotel}</b>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card-img" style={{ textAlign: "center" }}>
                <img
                  src="./assets/home/logo_ods.png"
                  style={{ maxHeight: 45 + "px" }}
                  alt="Logo Zuñiga"
                />
                <div className="white-space-16"></div>
              </div>
              <div className="card">
                <div className="card-header">
                  {this.props.ods ||
                  this.state.eventHeader.Idioma !== "English US"
                    ? titles.esp.infoz
                    : titles.ing.infoz}
                </div>
                <div className="card-body row">
                  {/* <div className="col-12 data">Status: <b style={{color:this.state.eventHeader.color}}>{this.state.eventHeader.status}</b></div> */}
                  {!this.props.ods ? (
                    <div className="col-12 data">
                      {this.state.eventHeader.Idioma !== "English US"
                        ? titles.esp.fc
                        : titles.ing.fc}
                      : {this.state.eventHeader.fecha_ods}
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="col-12 data">
                    {this.props.ods ||
                    this.state.eventHeader.Idioma !== "English US"
                      ? titles.esp.ven
                      : titles.ing.ven}
                    : <b>{this.state.eventHeader.nombre_us}</b>
                  </div>
                  <div className="col-12 data">
                    {this.props.ods ||
                    this.state.eventHeader.Idioma !== "English US"
                      ? titles.esp.tel
                      : titles.ing.tel}
                    : <b>{this.state.eventHeader.telefono}</b>
                  </div>
                  <div className="col-12 data">
                    {this.props.ods ||
                    this.state.eventHeader.Idioma !== "English US"
                      ? titles.esp.mail
                      : titles.ing.mail}
                    : <b>{this.state.eventHeader.correo}</b>
                  </div>
                  {this.props.ods ? (
                    <div className="ribbon-wrapper ribbon-xl">
                      <div
                        className="ribbon text-lg"
                        style={{
                          color: "white",
                          backgroundColor: this.state.eventHeader.color,
                        }}
                      >
                        {this.state.eventHeader.status}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              {this.props.ods ? (
                <>
                  <div className="card no-print">
                    <div className="card-header">INFORMACIÓN CONTABLE</div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-12 data">
                          Cliente a Facturar:{" "}
                          <b>{this.state.eventHeader.Cliente_factura}</b>
                        </div>
                        <div className="col-12 data">
                          Forma de Pago:{" "}
                          <b>{this.state.eventHeader.Forma_pago}</b>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="card col-12">
            <div className="table-responsive p-0">
              {this.state.artInfo.length !== 0 ? (
                <table
                  className="table table-sm table-hover"
                  style={{ width: 99 + "%" }}
                >
                  {this.state.artInfo.map((key,i) => (
                    <tbody key={i}>
                      {key.length > 0 ? (
                        <>
                          <tr>
                            <td colSpan="8" className="card-header">
                              <div className="row">
                                {this.props.ods ? (
                                  <>
                                    <div className="col-4">
                                      SUBEVENTO {key[0].id_subevento}:{" "}
                                      {key[0].nombre}
                                    </div>
                                    <div className="col-4">
                                      MONTAJE: {key[0].fecha_montaje}h
                                    </div>
                                    <div className="col-4">
                                      ENTREGA: {key[0].fecha_entrega}h
                                    </div>
                                  </>
                                ) : (
                                  <div className="col-12">{key[0].nombre}</div>
                                )}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {this.props.ods ||
                              this.state.eventHeader.Idioma !== "English US"
                                ? titles.esp.photo
                                : titles.ing.photo}
                            </td>
                            <td>
                              {this.props.ods ||
                              this.state.eventHeader.Idioma !== "English US"
                                ? titles.esp.item
                                : titles.ing.item}
                            </td>
                            {this.props.ods ? <td>DIMENSIÓN</td> : null}
                            <td>
                              {" "}
                              {this.props.ods ||
                              this.state.eventHeader.Idioma !== "English US"
                                ? titles.esp.pieces
                                : titles.ing.pieces}
                            </td>
                            <td>
                              {" "}
                              {this.props.ods ||
                              this.state.eventHeader.Idioma !== "English US"
                                ? titles.esp.unit
                                : titles.ing.unit}
                            </td>
                            {!this.props.ods ? (
                              this.state.eventHeader.Idioma !== "English US" ? (
                                <td>{titles.esp.days}</td>
                              ) : (
                                <td>{titles.ing.days}</td>
                              )
                            ) : (
                              <>
                                <td>INDICACIÓN</td>
                                <td>BOD</td>
                                <td>OTRO</td>
                              </>
                            )}
                            {!this.props.ods ? (
                              this.state.eventHeader.Idioma !==
                                "English US" ? (
                                  <>
                                    <td>{titles.esp.price}</td>
                                    <td>{titles.esp.amount}</td>
                                  </>
                                ) : (
                                  <>
                                    <td>{titles.ing.price}</td>
                                    <td>{titles.ing.amount}</td>
                                  </>
                                )
                            ) : null}
                          </tr>
                          {key.map((info, key) =>
                            (info.oculto_ods == 0 && this.props.ods) ||
                            (info.oculto_cot == 0 && !this.props.ods) ? (
                              <tr key={key}>
                                <td>
                                  <img
                                    className="card-img-top"
                                    style={{
                                      maxHeight: 100 + "px",
                                      width: "auto",
                                      maxWidth: 120 + "px",
                                    }}
                                    src={
                                      info.img_char == "http"
                                        ? info.img_pred
                                        : `./assets/uploads/articlesPhotos/${info.img_pred}`
                                    }
                                    alt="Foto Artículo"
                                    onError={(e) => {
                                      e.target.src =
                                        "./assets/uploads/no-image.jpg";
                                    }}
                                  />
                                </td>
                                <td style={{ maxWidth: 150 + "px" }}>
                                  {this.props.ods ? (
                                    <>
                                      ({info.id_art}) {info.nomE}
                                    </>
                                  ) : (
                                    <>
                                      {this.state.eventHeader.Idioma !==
                                      "English US"
                                        ? info.nomE
                                        : info.nomI}
                                      <br />
                                      <small>{info.comentarios}</small>
                                    </>
                                  )}
                                </td>
                                {this.props.ods ? (
                                  <td>
                                    <small>{info.dim}</small>
                                  </td>
                                ) : null}
                                <td>{info.total}</td>
                                <td>{info.unidad}</td>
                                {this.props.ods ? (
                                  <td>
                                    <small>{info.indicaciones}</small>
                                  </td>
                                ) : (
                                  <td>{info.dias}</td>
                                )}
                                {!this.props.ods ? (
                                  <>
                                    <td className="currency">
                                      {this.FormatNumber(info.preciouni)}
                                    </td>
                                    <td className="currency">
                                      {this.FormatNumber(info.importe)}
                                    </td>
                                  </>
                                ) : (
                                  <>
                                    <td>{info.cantidad}</td>
                                    <td>{info.produccion}</td>
                                  </>
                                )}
                              </tr>
                            ) : null
                          )}
                        </>
                      ) : null}
                    </tbody>
                  ))}
                </table>
              ) : (
                <div className="card-body">
                  No se han agregado artículos a esta{" "}
                  {this.props.ods ? "orden de servicio" : "cotizacón"}.
                </div>
              )}
            </div>
          </div>
          <div className="card col-12">
            {!this.props.ods ? null : (
              <>
                <div className="card-header">COMENTARIOS PARA OPERACIÓN</div>
                <div className="card-body text-justify">
                  {this.state.eventHeader.Comentarios_int}
                </div>
              </>
            )}
          </div>
        </div>
      </>
    );
  }
  componentDidMount() {
    this.getEventHeader();
  }
  async getEventHeader() {
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }
    let data = {
      token: token,
      id_event: this.props.idEvent,
    };

    const response = await request.post("/auth/sales/getCollectionHead", data);

    if (response && !response.error) {
      if (response.result && !response.empty) {
        this.setState({
          eventHeader: response.result[0],
          events: response.result[0].eventos,
        });
        let dataDB = {
          id: 0,
          title: response.result[0].nombre,
          start: "",
          color: response.result[0].color,
          textColor: "white",
          status: response.result[0].status,
          id_status: response.result[0].id_status,
          id_hotel: response.result[0].id_hotel,
          nom_hotel: response.result[0].nombre_h,
          id_usuario: response.result[0].id_usuario,
          nom_vend: response.result[0].nombre_us,
          id_tipo: response.result[0].id_tipo,
          tipo: response.result[0].tipo_esp,
          lugar: "",
        };
        this.props.asignData(dataDB);
        if (response.result[0].eventos >= 1) {
          this.getEventArt();
        }
      } else {
        this.setState({
          empty: true,
          loading: false,
          message: response.message,
        });
      }
    } else {
      this.setState({
        error: true,
        loading: false,
        message: response.message,
      });
    }
  }
  async getEventArt() {
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }

    let data = {
      token: token,
      id_event: this.props.idEvent,
      events: this.state.events,
    };

    const response = await request.post("/auth/sales/getCollectionArt", data);
    console.log("🚀 ~ file: ods.jsx ~ line 552 ~ Ods ~ getEventArt ~ response", response)

    if (response && !response.error) {
      if (response.result && !response.empty) {
        this.setState({ artInfo: response.result });
      } else {
        this.setState({
          empty: true,
          loading: false,
          message: response.message,
        });
      }
    } else {
      this.setState({
        error: true,
        loading: false,
        message: response.message,
      });
    }
  }
  FormatNumber(number) {
    let val = new Intl.NumberFormat("ES-MX", {
      style: "currency",
      currency: "MXN",
      useGrouping: true,
    }).format(number);
    return val;
  }
}

export default Ods;
