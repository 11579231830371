import React, { Component } from "react";
import ClearCache from "react-clear-cache";

class CheckVersion extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <ClearCache>
        {({ isLatestVersion, emptyCacheStorage }) => (
          <>
            {!isLatestVersion && (
              <div className="alert alert-info" role="alert">
                <p>Hay una nueva version del sistema.&nbsp;
                <a
                  className="btn btn-info btn-block btn-flat"
                  style={{color:"white"}}
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    emptyCacheStorage();
                  }}
                >
                  <i className="fas fa-sync-alt"></i>&nbsp;Actualizar
                </a></p>
              </div>
            )}
          </>
        )}
      </ClearCache>
    );
  }
}

export default CheckVersion;
