/**
 *  @name: app.jsx
 *  @version: 1.0.0
 *  @description: Punto de Entrada de la aplicación
 * 	@process: 2
 */

import React from "react";
import Routes from "./routes";
import { DBConfig } from "./DBConfig";
import { initDB } from "react-indexed-db";


initDB(DBConfig);
const App = () => <Routes />;

export default App;
