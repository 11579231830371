/**
 *  @name: private.jsx
 *  @version: 1.0.0
 *  @author: Trejocode - Sergio
 *  @description: Página privada
 */

import React from "react";
import Layout from "../layout";
import Sidebar from "../../components/header/sidebar";
import TableArticles from "../../components/inventory/tableArticles";

const Inventory = () => (
  <Layout
    title="Inventario"
    description="Página privada de perfil"
    keywords="Reactjs, SEO Ready, Ejemplo"
  >
    <Sidebar />
    <TableArticles></TableArticles>
  </Layout>
);

export default Inventory;
