import React, { Component } from "react";
import Modal from "react-modal";
import NewPuesto from "./newFormPuesto";



Modal.setAppElement(document.getElementById("root"));

const customStyles = {
  content: {
    top: "50%",
    left: "52%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    overflow: "scroll"
  }
};

class buttonActions extends Component {

    constructor(props) {
        super(props);
        this.state = {
            openModal: false,     
        };
    }
    openModal() {
        this.setState({
        openModal: !this.state.openModal
        });
    }
    closeModal() {
      this.openModal();
    
    }



  render() {
    return (
      <div className="btn-group">
            <button
                type="button"
                className="btn btn-md btn-info bg-gradient-info mt-2"
                onClick={this.openModal.bind(this)}
            >
                <i className="fas fa-plus"></i>
            </button>
        
          <Modal
            isOpen={this.state.openModal}
            style={customStyles.content}
            contentLabel=""
          >
            <div className="modal-header">
              <button
                type="button"
                className="close"
                onClick={this.openModal.bind(this)}
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body card-primary card-outline card-outline-tabs">
              <div className="card-body">
                <NewPuesto
                 closeModal={this.closeModal.bind(this)}
                 recargar={this.props.recargar} 
                />
              </div>
            </div>
          </Modal>
        
      </div>
    );
  }




}
export default buttonActions;
