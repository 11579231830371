let validator = (data) => {
  let error = 0;

  if (data.nombreEv.length === 0 || data.nombreEv === "") {
    if (error !== 1) {
      document.getElementById("nombreEv").focus();
    }
    document.getElementById("nombreEv").classList.add("is-invalid");
    error = 1;
  }
  if (
    data.VendedorId === 0 ||
    data.VendedorId === "0" ||
    data.VendedorId === ""
  ) {
    if (error !== 1) {
      document.getElementById("VendedorId").focus();
    }
    document.getElementById("VendedorId").classList.add("is-invalid");
    error = 1;
  }
  if (data.status === 0) {
    if (error !== 1) {
      document.getElementById("status").focus();
    }
    document.getElementById("status").classList.add("is-invalid");
    error = 1;
  }
  if (data.fInicio === "") {
    if (error !== 1) {
      document.getElementById("fInicio").focus();
    }
    document.getElementById("fInicio").classList.add("is-invalid");
    error = 1;
  }
  if (data.Hinicio === "") {
    if (error !== 1) {
      document.getElementById("Hinicio").focus();
    }
    document.getElementById("Hinicio").classList.add("is-invalid");
    error = 1;
  }
  if ((data.status === "3") & (data.fMontaje === "")) {
    if (error !== 1) {
      document.getElementById("fMontaje").focus();
    }
    document.getElementById("fMontaje").classList.add("is-invalid");
    error = 1;
  }

  if ((data.status === "3") & (data.hMontaje === "")) {
    if (error !== 1) {
      document.getElementById("hMontaje").focus();
    }
    document.getElementById("hMontaje").classList.add("is-invalid");
    error = 1;
  }

  if ((data.status === "3") & (data.fDesmontaje === "")) {
    if (error !== 1) {
      document.getElementById("fDesmontaje").focus();
    }
    document.getElementById("fDesmontaje").classList.add("is-invalid");
    error = 1;
  }

  if ((data.status === "3") & (data.hDesmontaje === "")) {
    if (error !== 1) {
      document.getElementById("hDesmontaje").focus();
    }
    document.getElementById("hDesmontaje").classList.add("is-invalid");
    error = 1;
  }

  if (data.fTermino === "") {
    if (error !== 1) {
      document.getElementById("fTermino").focus();
    }
    document.getElementById("fTermino").classList.add("is-invalid");
    error = 1;
  }
  if (data.hTermino === "") {
    if (error !== 1) {
      document.getElementById("hTermino").focus();
    }
    document.getElementById("hTermino").classList.add("is-invalid");
    error = 1;
  }
  if (data.subEv === "0" || data.subEv === "") {
    if (error !== 1) {
      document.getElementById("subEv").focus();
    }
    document.getElementById("subEv").classList.add("is-invalid");
    error = 1;
  }
  if (data.pax === "0") {
    if (error !== 1) {
      document.getElementById("pax").focus();
    }
    document.getElementById("pax").classList.add("is-invalid");
    error = 1;
  }
  if (data.salon === "") {
    if (error !== 1) {
      document.getElementById("salon").focus();
    }
    document.getElementById("salon").classList.add("is-invalid");
    error = 1;
  }
  if (data.backup === "") {
    if (error !== 1) {
      document.getElementById("backup").focus();
    }
    document.getElementById("backup").classList.add("is-invalid");
    error = 1;
  }
  if (data.planner === "") {
    if (error !== 1) {
      document.getElementById("planner").focus();
    }
    document.getElementById("planner").classList.add("is-invalid");
    error = 1;
  }
  if (data.agencia === "") {
    if (error !== 1) {
      document.getElementById("agencia").focus();
    }
    document.getElementById("agencia").classList.add("is-invalid");
    error = 1;
  }
  if (data.contAge === "") {
    if (error !== 1) {
      document.getElementById("contAge").focus();
    }
    document.getElementById("contAge").classList.add("is-invalid");
    error = 1;
  }
  if (data.finalContact === "") {
    if (error !== 1) {
      document.getElementById("finalContact").focus();
    }
    document.getElementById("finalContact").classList.add("is-invalid");
    error = 1;
  }
  if (data.comision === "") {
    if (error !== 1) {
      document.getElementById("comision_hotel").focus();
    }
    document.getElementById("comision_hotel").classList.add("is-invalid");
    error = 1;
  }
  if (data.desc === "") {
    if (error !== 1) {
      document.getElementById("desc").focus();
    }
    document.getElementById("desc").classList.add("is-invalid");
    error = 1;
  }
  return error;
};
export default validator;
