import React, { Component } from "react";
class ButtonSave extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: false
    };
  }
  render() {
    return (
      <div className="modal-footer justify-content-left ">
        <button
          type="button"
          onClick={this.handleSubmit.bind(this)}
          className="btn btn-outline-danger btn-flat "
          readOnly={this.props.block}
        >
          {this.props.load ? (
            <i className="fas fa-spinner fa-spin"></i>
          ) : (
            <i className="far fa-save"></i>
          )}
          &nbsp; Eliminar Empleado
        </button>
      </div>
    );
  }

  handleSubmit() {
      this.props.save();
  }

  handleCancel() {
    this.props.closeModal();

  }
  
}

export default ButtonSave;
