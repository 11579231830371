/**
 *  @name: private.jsx
 *  @version: 1.0.0
 *  @author: Trejocode - Sergio
 *  @description: Página privada
 */

import React from "react";
import Layout from "./layout";
import Sidebar from "../components/header/sidebar";

const Dashboard = () => (
  <Layout
    title="Perfil"
    description="Página privada de perfil"
    keywords="Reactjs, SEO Ready, Ejemplo"
  >
    <Sidebar />
    <div className="content-wrapper test">
      <div className="white-space-32"></div>
      <div className="justify-center">
        <div className="container">
          <div className="justify-center full">
            <h1> Private Page </h1>
          </div>
        </div>
      </div>
      <div className="white-space-32"></div>
    </div>
  </Layout>
);

export default Dashboard;
