/**
 *  @name: private.jsx
 *  @version: 1.0.0
 *  @author: Trejocode - Sergio
 *  @description: Página privada
 */

import React, { useState, useEffect } from "react";
import { Consumer } from "../../context/index";
import { toast } from "react-toastify";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Input from "@material-ui/core/Input";
import Select from "@material-ui/core/Select";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import moment from "moment-timezone";
import Request from "../../utils/http";
import ModalOds from "../calendar/modalOds";
const request = new Request();

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    //overflow: "scroll",
  },
};
function Daily(props) {
  const [user, setUser] = useState({
    idUsuario: 0,
    idPerfil: 0,
    nombre: "",
    idUbicacion: 0,
  });
  const [fecha, setFecha] = useState({
    fi: moment().format("YYYY-MM-DD") + " 00:00:00",
    ff: moment().format("YYYY-MM-DD") + " 23:59:59",
  });
  const [list, setList] = useState([]);
  const [sup, setSup] = useState([]);
  const [load, setLoad] = useState(true);
  const [modalOds, setModalOds] = useState(false);
  const [recargaOds, setRecargaOds] = useState(false);
  const [infoEvent, setInfoEvent] = useState({
    id_event: 0,
    title: "",
    idUser: 0,
  });

  useEffect(() => {
    loadUser();
    getInfo();
    getSupervisores();
  }, []);

  const loadUser = async () => {
    const user = await props.context.loadUser();

    if (user) {
      setUser(user);
    }
  };

  const getInfo = async () => {
    setLoad(true);
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }

    let data = {
      token: token,
      fi: fecha.fi,
      ff: fecha.ff,
    };

    const response = await request.post("/auth/operations/getEvents", data);
    console.log(response);
    if (response && !response.error) {
      if (response.result && !response.empty) {
        setList(response.result);
        setLoad(false);
      } else {
        alert(response.result.message);
      }
    } else {
      console.log("algo salio mal");
    }
  };

  const saveSup = async (e, evento, sub) => {
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }

    let datos = {
      token: token,
      evento: evento,
      sub: sub,
      id: e.target.value,
      user: user.id,
    };

    const response = await request.post("/auth/operations/saveEveSup", datos);

    if (response && !response.error) {
      if (response.result && !response.empty) {
        /*setTemas([...temas, { label: esp, value: response.result[0].new_id }]);*/
        toast.success(
          <span>
            Supervisor asignado con
            exito.
          </span>
        );
      }
    }
  };

  const changeDate = async (e) => {
    setFecha({
      fi: e.target.value + " 00:00:00",
      ff: e.target.value + " 23:59:59",
    });
  };

  const changeEvent = (event) => {
    setRecargaOds(false);
    setInfoEvent({
      id_event: Number(event.id_evento),
      title: event.nom_Evento,
      idUser: 0,
    });
    setModalOds(true);
    setRecargaOds(true);
  };

  const getSupervisores = async () => {
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }

    let data = {
      token: token,
    };

    const response = await request.post(
      "/auth/operations/getSupervisores",
      data
    );
    console.log(response);
    if (response && !response.error) {
      if (response.result.sup && !response.empty) {
        setSup(response.result.sup);
      } else {
        alert(response.result.message);
      }
    } else {
      console.log("algo salio mal");
    }
  };

  return (
      <div className="content-wrapper" id="tableDaily">
        <div className="card" style={{ margin: "1rem" }}>
          <div className="card-header">
            <div
              className="card-title row card-title row row-cols-2 row-cols-md-4 row-cols-lg-5"
              style={{ width: "100%" }}
            >
              <div className="col text-center">
                <TextField
                  label="Buscar Fecha"
                  name="fecha"
                  type="date"
                  value={fecha.fi.slice(0, -9)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => changeDate(e)}
                />
              </div>
              <div className="col text-left">
                <button
                  className="btn btn-primary btn-sm button-filter"
                  onClick={() => getInfo()}
                >
                  <i className="fas fa-search"></i>
                </button>
              </div>
            </div>
          </div>
          <div className="card-body">
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center"></TableCell>
                    <TableCell align="center">ID/SUB.</TableCell>
                    <TableCell align="center">VENDEDOR</TableCell>
                    <TableCell align="center">
                      NOMBRE DEL EVENTO/SUBEVENTO
                    </TableCell>
                    <TableCell align="center">HOTEL</TableCell>
                    <TableCell align="center">LOCACION</TableCell>
                    <TableCell align="center">UBICACIÓN</TableCell>
                    <TableCell align="center">HORA MONTAJE</TableCell>
                    <TableCell align="center">HORA ENTREGA</TableCell>
                    <TableCell align="center">
                      TIPO
                      <br />
                      EVENTO
                    </TableCell>
                    <TableCell align="center">ENCARGADO ZDE</TableCell>
                    <TableCell align="center">CONTACTO HOTEL</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!load ? (
                    <>
                      {list.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell align="center">{index + 1}</TableCell>
                          <TableCell align="center">
                            {row.id_evento}
                            <br />
                            Sub.{row.id_subevento}
                          </TableCell>
                          <TableCell align="center">{row.nombre_us}</TableCell>
                          <TableCell align="center">
                            <a onClick={(e) => changeEvent(row)}>
                              {row.nom_Evento}&nbsp;{row.nombre_sub}
                            </a>
                          </TableCell>
                          <TableCell align="center">{row.nombre_h}</TableCell>
                          <TableCell align="center">{row.Salon}</TableCell>
                          <TableCell align="center">{row.lugar_h}</TableCell>
                          <TableCell align="center">
                            {row.fecha_montaje_sub.slice(11)}Hrs.
                          </TableCell>
                          <TableCell align="center">
                            {row.fecha_entrega.slice(11)}Hrs.
                          </TableCell>
                          <TableCell align="center">{row.tipo_esp}</TableCell>
                          <TableCell align="center">
                            {user.id === 19 ||
                            user.id === 20 ||
                            user.id === 30 ||
                            user.id === 59 ||
                            user.id === 64 ||
                            user.id === 124 ||
                            user.id_perfil === 1 ? (
                              <Select
                                input={<Input id={"encargado" + index} />}
                                name={"encargado" + index}
                                defaultValue={row.id_supervisor}
                                onChange={(e) =>
                                  saveSup(e, row.id_evento, row.id_subevento)
                                }
                              >
                                {sup.map((info, key) => (
                                  <MenuItem
                                    key={key}
                                    value={info.id_supervisor}
                                  >
                                    {info.nombre}
                                  </MenuItem>
                                ))}
                              </Select>
                            ) : (
                              <>
                                {row.nombre_sup}
                                <br />
                                {row.tel}
                              </>
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {row.Contacto_Hotel}
                          </TableCell>
                        </TableRow>
                      ))}
                    </>
                  ) : (
                    <TableRow>
                      <TableCell colSpan={12} align="center">
                        <i
                          className="fas fa-spinner fa-spin"
                          style={{ fontSize: "62px", color: "#3f51b5" }}
                        ></i>
                        <br /> Buscando Datos...
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
        <ModalOds
          modalOds={modalOds}
          infoEvent={infoEvent}
          id_modulo={props.id_modulo}
          setModalOds={(e) => setModalOds(e)}
          setModalEddit={false}
          changeEvent={(e) => changeEvent(e)}
          recargaOds={recargaOds}
          setRecargaOds={(e) => setRecargaOds(e)}
          deleteCalendarEvent={(e) => {}}
        />
      </div>
  );
}
export default Consumer(Daily);