
import React, {useEffect, useState} from "react";
import Request from "../../../utils/http";
import MUIDataTable from "mui-datatables";
import moment from "moment";
import {Consumer} from "../../../context/index";

import { render } from 'react-dom';
import { Multiselect } from 'react-widgets';
import 'react-widgets/dist/css/react-widgets.css';
import DropdownList from 'react-widgets/lib/DropdownList';
import ButtonSerch from "../../general/buttonSerch";


const request = new Request();

function HorasUsers(props){

    const [employee, setEmployee] = useState([]);
    const [reporteHorasExtras, setReporteHorasExtras]= useState({
        fecha_inicio:'',
        fecha_fin:'',
        id_users:[],

        fecha_evento:'',
        id_evento:[]
    });
    //consulta los eventos al selecionar una fecha(reporte por evento)
    const [eventConsulta, setEventConsulta]= useState([]);
    //respuesta de reportes
    const [HorasUsers, setHorasUsers]= useState([]);
    const [HorasUsersPorEvento, setHorasUsersPorEvento]= useState([]);


    //con este metodo nos permite ver usuario que inicio session
    const getUser = async () => {
        const user = await props.context.loadUser();
        //nos permite enviar datos quien inicio sesion
        if(user.id!=null && user.id_perfil!=null && user.ubicacion!=null){
            getUsersEspecifico(user.id,user.id_perfil,user.ubicacion);
        }
    }

    //nos permite calcular los subtotales y totales de horas extras
    let resultadoReporte = (Object.keys(HorasUsers).length>0)?HorasUsers:HorasUsersPorEvento

    

    let resultadoOrdenado =[];
    let sumaSubTotalHoras=0;
    
    if(resultadoReporte["ok"]!=false){
        for(let id_event in resultadoReporte){
            
            sumaSubTotalHoras +=resultadoReporte[id_event]['total'];
            let tempUser={}
            tempUser['nombre']=resultadoReporte[id_event]["usuario"];
            tempUser['totalHoras']=resultadoReporte[id_event]["horas"];
            tempUser['SubtotalHoras']=resultadoReporte[id_event]["total"];
            resultadoOrdenado.push(tempUser);
        }
    }else{
        let message= resultadoReporte["message"];
        resultadoOrdenado.push(message);
    }
 
    
    //nuestro token para todos
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token=token1.id;


    useEffect(() => {
        getUser();
    }, []);


    const getUsersEspecifico = async (id_users,perfil,id_ubicacion) => {

        let data = {
            token: token,
            id_ubicacion:id_ubicacion
        };
        const response = await request.post("/horasExtras/getUsers", data);
        if (response && !response.error) {
            if (response.result.users && !response.empty) {
                setEmployee(response.result.users);
            } else {
                console.log(response.result.message);
            }
        } else {
            console.log("error en la peticion");
        }
    }

    const alamcenar=(value)=>{
        setReporteHorasExtras({...reporteHorasExtras,id_users:value});
    }

    const alamcenarEvento=(value)=>{
        setReporteHorasExtras({...reporteHorasExtras,id_evento:value});
    }
   

    let getEventos = async(id) => {
        setReporteHorasExtras({...reporteHorasExtras,fecha_evento:id});

        let data = {
          token: token,
          fecha_evento:id
        };
        const response = await request.post("/horasExtras/getEvent", data);   
        if (response && !response.error) {
            if (response.result.events  && !response.empty) {
                setEventConsulta(response.result.events );
            } else {
              console.log(response.result.message);
            }
        } else {
              console.log("error en la peticion");
        }
        
    }

    const ReporteHorasExtras = async () => {

        //validamos la fecha que no este ningun vacio
        if(reporteHorasExtras.fecha_inicio==''){
            document.getElementById("fecha_inicio").classList.add("is-invalid");
        }else if(reporteHorasExtras.fecha_fin==''){
            document.getElementById("fecha_fin").classList.add("is-invalid");
        }else{

            setHorasUsersPorEvento([]);
            let id_users=(reporteHorasExtras.id_users);
            let ids_usuario=[];
            for(let i in id_users){
                ids_usuario.push(id_users[i].id);
            }
    
            let data = {
                token: token,
                date_inicio: reporteHorasExtras.fecha_inicio,
                date_fin: reporteHorasExtras.fecha_fin,
                ids_users:ids_usuario,
                id_event:'',
            };
    
            const response = await request.post("/horasExtras/getHorasTotal", data);
    
            if (response && !response.error) {
                if (response.result  && !response.empty) {
                    setHorasUsers(response.result);
                } else {
                    console.log(response.result.message);
                }
            } else {
                console.log("error en la peticion");
            }

        }

    }
    
    const ReporteHorasExtrasPorEvento = async () => {
        setHorasUsers([]);
        let ids_evento=reporteHorasExtras.id_evento;
        // let fecha =reporteHorasExtras.fecha_evento;
       
        let id_events=[];
        for(let i in ids_evento){
            id_events.push(ids_evento[i].id_evento);
        }       

        if(reporteHorasExtras.fecha_evento==''){
            document.getElementById("fecha_evento").classList.add("is-invalid");
        }
        if (id_events == null || id_events==[] || id_events == "") {
            alert("No has seleccionado ningun evento para generar reporte");
        }else{

            let data = {
                token: token,     
                date_inicio: '',
                date_fin: '',  
                id_event:id_events,
                ids_users:''
            };
    
            const response = await request.post("/horasExtras/getHorasTotal", data);
    
            if (response && !response.error) {
                if (response.result  && !response.empty) {
                    setHorasUsersPorEvento(response.result);
                } else {
                    console.log(response.result.message);
                }
            } else {
                console.log("error en la peticion");
            }  

            
        }      
    }

    
    return(
        <div className="p-5">
            <div className="col-12 col-sm-12 col-lg-12">
                <div className="card card-primary card-outline card-outline-tabs">
                    <div className="card-header p-0 border-bottom-0">
                        <ul className="nav nav-tabs" id="custom-tabs-three-tab" role="tablist">
                            <li className="nav-item">
                                <a className="nav-link active" 
                                id="custom-tabs-three-home-tab" data-toggle="pill" 
                                href="#custom-tabs-three-home" 
                                role="tab" aria-controls="custom-tabs-three-home" 
                                aria-selected="true">Reporte de Horas Extras</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" 
                                id="custom-tabs-three-profile-tab" 
                                data-toggle="pill" href="#custom-tabs-three-profile" 
                                role="tab" aria-controls="custom-tabs-three-profile" 
                                aria-selected="false">Reporte de Horas Extras por Evento</a>
                            </li>
                        </ul>
                    </div>
                    <div className="card-body">
                        <div className="tab-content" id="custom-tabs-three-tabContent">
                            <div className="tab-pane fade active show" 
                                id="custom-tabs-three-home" role="tabpanel" aria-labelledby="custom-tabs-three-home-tab">

                                <div className="row mt-1 p-3">
                                    <div className="col-md-6">
                                        <div className="input-group ">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">Fecha Inicio</span>
                                            </div>
                                            <input type="date" className="form-control" 
                                                id="fecha_inicio" 
                                                name="fecha_inicio" 
                                                value={reporteHorasExtras.fecha_inicio} 
                                                onChange={e => setReporteHorasExtras({...reporteHorasExtras,fecha_inicio:e.target.value})} 
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">Fecha Fin</span>
                                            </div>
                                            <input type="date" className="form-control" 
                                                id="fecha_fin" 
                                                name="fecha_fin" 
                                                value={reporteHorasExtras.fecha_fin} 
                                                onChange={e => setReporteHorasExtras({...reporteHorasExtras,fecha_fin:e.target.value})} 
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="row  p-3 mb-3">
                                    <div className="col-md-12 d-flex">
                                        <div className="col-4 col-md-3 p-0 m-0 ">
                                            <span className="input-group-text style-ml_select">Nombres Aux.</span>
                                        </div>
                                        <div className="col-8 col-md-9 p-0 m-0">
                                            <Multiselect
                                                data={employee}
                                                textField={item => item.name+ ' ' + item.last_name}
                                                onChange={value => alamcenar(value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <ButtonSerch
                                    serch={ReporteHorasExtras} 
                                />

                                <div className="row">
                                    <div className="col-12">
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Nombres de Auxiliares</th>
                                                    <th scope="col">Horas</th>
                                                    <th scope="col">Sub Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    
                                                    resultadoReporte["ok"]!=false  && Object.keys(HorasUsers)!='' && resultadoReporte!=''?
                                                    resultadoOrdenado.map((val_horas, index)=>
                                                        <tr key={index}>
                                                            <td>{val_horas.nombre}</td>
                                                            <td>{val_horas.totalHoras}</td>
                                                            <td>{"$" +val_horas.SubtotalHoras}</td>
                                                        </tr>
                                                    )
                                                    :
                                                    Object.keys(HorasUsers)!=''?
                                                    resultadoOrdenado.map((val_horas, index)=>
                                                        <tr key={index}>
                                                            <td className="text-center" colSpan="3">{val_horas}</td>
                                                        </tr>
                                                    )
                                                    :''

                                                }
                                                <tr className="table-sm">
                                                    <th scope="col" colSpan="2" className="text-right">Total:</th>
                                                    <th scope="col" className="text-left">{HorasUsers!=''?"$"+sumaSubTotalHoras:''}</th>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="custom-tabs-three-profile" role="tabpanel" aria-labelledby="custom-tabs-three-profile-tab">

                                <div className="row mb-4 p-3 mt-1">
                                    <div className="col-md-6">
                                        <div className="input-group mb-2">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">Fecha Evento</span>
                                            </div>
                                            <input type="date" className="form-control" 
                                                id="fecha_evento" 
                                                name="fecha_evento" 
                                                value={reporteHorasExtras.fecha_evento} 
                                                onChange={e =>getEventos(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6 d-flex ">
                                        <div className="col-4 col-md-3 p-0 m-0 ">
                                            <span className="input-group-text style-ml_select">NombreEvento</span>
                                        </div>
                                        <div className="col-8 col-md-9 p-0 m-0">
                                            <Multiselect
                                                data={eventConsulta}
                                                textField={item => item.nombreEvento}
                                                onChange={value => alamcenarEvento(value)}
                                            />
                                            
                                        </div>
                                    </div>
                                </div>
                                <ButtonSerch
                                    serch={ReporteHorasExtrasPorEvento} 
                                />
                                <div className="row">
                                    <div className="col-12">
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Nombres de Auxiliares</th>
                                                    <th scope="col">Horas</th>
                                                    <th scope="col">Sub Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                     Object.keys(HorasUsersPorEvento)!='' && resultadoReporte!='' &&   resultadoReporte["ok"]!=false?
                                                    resultadoOrdenado.map((val_horas_event, index_event)=>
                                                        <tr key={index_event}>
                                                            
                                                            <td>{val_horas_event.nombre}</td>
                                                            <td>{val_horas_event.totalHoras}</td>
                                                            <td>{"$" +val_horas_event.SubtotalHoras}</td>
                                                        </tr>
                                                    )
                                                    :
                                                    Object.keys(HorasUsersPorEvento)!=''?
                                                    resultadoOrdenado.map((val_horas_event, index_event)=>
                                                        <tr key={index_event}>
                                                            <td className="text-center" colSpan="3">{val_horas_event}</td>
                                                        </tr>
                                                    )
                                                    :''
                                                }
                                                <tr className="table-sm">
                                                    <th scope="col" colSpan="2" className="text-right">Total:</th>
                                                    <th scope="col" className="text-left">{HorasUsersPorEvento!=''?"$"+sumaSubTotalHoras:''}</th>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Consumer(HorasUsers);