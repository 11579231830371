import React, { Component } from "react";
import Modal from "react-modal";
import { Consumer } from "../../context/index";
import "./modal.css";
import ActivateMenu from "./actions";
Modal.setAppElement(document.getElementById("root"));
const customStyles = {
  content: {
    top: "50%",
    left: "52%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    overflow: "scroll",
  },
};
class buttonActions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
      idArt: this.props.id,
      user: {
        idUsuario: 0,
        idPerfil: 0,
        nombre: "",
      },
    };
  }
  closeModal() {
    this.props.openModal({
      id: 0,
      nom: "",
    });
  }
  reload() {
    this.props.reload();
  }
  render() {
    return (
      <div className="btn-group">
        <button
          className="btn btn-info bg-gradient-info"
          title="Editar"
          onClick={this.openModal.bind(this)}
        >
          <i className="fas fa-edit"></i>
        </button>
        {this.props.idPerfil === 1 || this.props.idPerfil === 2 ? (
          <ActivateMenu
            value={this.props.id}
            user={this.state.user.id}
            reload={() => this.reload()}
          />
        ) : null}
      </div>
    );
  }
  componentDidMount() {
    this.load();
  }
  async load() {
    const user = await this.props.context.loadUser();

    if (user) {
      this.setState({
        user: user,
      });
    }
  }
  openModal() {
    this.props.openModal({
      id: this.props.id,
      nom: this.props.nom,
    });
  }
  handleChange(e) {
    console.log(e.target);
  }
}
export default Consumer(buttonActions);
