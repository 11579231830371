import React, { useState, useEffect, useRef } from "react";
import Request from "../../utils/http";
import FullCalendar from "@fullcalendar/react/";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import esLocale from "@fullcalendar/core/locales/es";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";

import "@fullcalendar/common/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import "@fullcalendar/list/main.css";
import "bootstrap/dist/css/bootstrap.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "./calendarEvents.css";

import ModalOds from "./modalOds";
import ModalEddit from "./modalEddit";
import ModalColection from "./modalColection";

const request = new Request();

function CalendarEvents(props) {
  /**
   * referencia para el api de calendario
   */
  const calendarRef = useRef(null);
  const mountedRef = useRef(true);
  /**
   * modales
   */
  const [modalOds, setModalOds] = useState(false);
  const [modalEddit, setModalEddit] = useState(false);
  const [modalColection, setModalColection] = useState(false);
  /**
   * loading de los componentes
   */
  const [loadCalendar, setLoadCalendar] = useState(true);
  const [loadType, setLoadType] = useState(true);
  const [loadSeller, setLoadSeller] = useState(true);
  const [loadLocation, setLoadLocation] = useState(true);
  const [loadHotel, setLoadHotel] = useState(true);
  const [loadStatus, setLoadStatus] = useState(true);

  /**
   * guardar resultados de las consultas
   */
  const [typeList, setTypeList] = useState([]);
  const [sellerList, setSellerList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [hotelList, setHotelList] = useState([]);
  const [hotelBack, setHotelBack] = useState([]);
  const [statusList, setStatusList] = useState([]);

  /**
   * valores predeterminados de busqueda
   */
  //const [text, setText] = useState([]);
  const [type, setType] = useState([]);
  const [seller, setSeller] = useState([]);
  const [location, setLocation] = useState([]);
  const [hotel, setHotel] = useState([]);
  const [status, setStatus] = useState([]);

  const [search, setSearch] = useState(false);
  const [newCot, setNewCot] = useState(false);
  const [recargaOds, setRecargaOds] = useState(false);
  const [events, setEvents] = useState([]);
  const [infoEvent, setInfoEvent] = useState({
    id_event: 0,
    title: "",
    idUser: 0,
  });

  useEffect(() => {
    let filter = JSON.parse(localStorage.getItem("calendarFilter"));
    if (filter) {
      setType(filter.type);
      setSeller(filter.seller);
      setLocation(filter.location);
      setHotel(filter.hotel);
      setStatus(filter.status);
    }

    getTypes();
    getSellers();
    getLocations();
    getHotels();
    getStatus();
    getSearchEvents();
  }, []);
  useEffect(() => {
    const reload = setInterval(function () {
      if (!mountedRef.current) {
        clearInterval(reload);
        mountedRef.current = true;
        return;
      }
      getSearchEvents();
      console.log("Recargando Calendario...");
      // getHotels();
      // getCategory();
    }, 300000);
    return () => {
      console.log("cleaned up Calendar");
      mountedRef.current = false;
    };
  }, []);
  useEffect(() => {
    if (!loadCalendar) {
      let calendarApi = calendarRef.current.getApi();
      let date = localStorage.getItem("calendarDate");
      let view = localStorage.getItem("calendarView");

      if (!date) {
        saveCalendarDate(calendarApi.getDate().toISOString());
      } else {
        calendarApi.gotoDate(date);
      }

      if (!view) {
        saveCalendarView("dayGridMonth");
      } else {
        calendarApi.changeView(view);
      }

      let reload = document.getElementsByClassName("fc-reload");
    }
  }, [loadCalendar]);
  useEffect(() => {
    if (!modalOds) {
      setInfoEvent({
        id_event: 0,
        title: "",
        idUser: 0,
      });
    }
  }, [modalOds]);

  /**
   * Traer lista de eventos del calendario
   */
  const getEvents = async () => {
    setLoadCalendar(true);
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }

    let data = {
      token: token,
      id_modulo: props.id_modulo,
    };

    const response = await request.post("/auth/calendar/getEventsMonth", data);

    if (response && !response.error) {
      if (response.result && !response.empty) {
        setEvents(response.result);
        setLoadCalendar(false);

        //setEvents(response.result);
        //setEventsSearch(response.result);
        // window.sessionStorage.setItem(
        //   "eventsList",
        //   JSON.stringify(response.result)
        // );
        // let event = { target: { name: "", value: [] } };
        // handleChangeFilter(event, response.result);
      }
    }
  };
  const getSearchEvents = async () => {
    setLoadCalendar(true);
    let filter = JSON.parse(localStorage.getItem("calendarFilter"));

    if (filter) {
      if (
        filter.type.length === 0 &&
        filter.seller.length === 0 &&
        filter.location.length === 0 &&
        filter.hotel.length === 0 &&
        filter.status.length === 0
      ) {
        setType([]);
        setSeller([]);
        setLocation([]);
        setHotel([]);
        setStatus([]);
        setSearch(false);
        getEvents();
        return;
      }
      let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
      let token;
      if (token1) {
        token = token1.id;
      }

      let data = {
        token: token,
        id_modulo: props.id_modulo,
        type: filter.type,
        seller: filter.seller,
        location: filter.location,
        hotel: filter.hotel,
        status: filter.status,
      };

      const response = await request.post(
        "/auth/calendar/getEventsSearch",
        data
      );

      if (response && !response.error) {
        if (response.result && !response.empty) {
          setEvents(response.result);
          setLoadCalendar(false);
          setSearch(true);
        }
      }
    } else {
      getEvents();
    }
  };
  const clearSearch = () => {
    localStorage.removeItem("calendarFilter");
    setType([]);
    setSeller([]);
    setLocation([]);
    setHotel([]);
    setStatus([]);
    getEvents();
  };

  const resizeCalendar = () => {
    calendarRef.current.getApi().updateSize();
  };
  /**
   * traer listado de filtros
   */
  const getTypes = async () => {
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }
    let data = {
      token: token,
    };

    const response = await request.post("/auth/sales/getType", data);

    if (response && !response.error) {
      if (response.result && !response.empty) {
        setTypeList(response.result.types);
        setLoadType(false);
        // response.result.types.forEach((element) => {
        //   typeDB.add(element);
        // });
      } else {
        // this.setState({
        //   empty: true,
        //   loading: false,
        //   message: response.message
        // });
      }
    } else {
      // this.setState({
      //   error: true,
      //   loading: false,
      //   message: response.message
      // });
    }
  };
  const getSellers = async () => {
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }
    let data = {
      token: token,
    };

    const response = await request.post("/auth/sales/getSellers", data);

    if (response && !response.error) {
      if (response.result && !response.empty) {
        setSellerList(response.result.vendedores);
        setLoadSeller(false);
        // response.result.vendedores.forEach((element) => {
        //   sellerDB.add(element);
        // });
      } else {
        // this.setState({
        //   empty: true,
        //   loading: false,
        //   message: response.message
        // });
      }
    } else {
      // this.setState({
      //   error: true,
      //   loading: false,
      //   message: response.message
      // });
    }
  };
  const getLocations = async () => {
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }
    let data = {
      token: token,
    };

    const response = await request.post("/auth/sales/getubicaciones", data);

    if (response && !response.error) {
      if (response.result && !response.empty) {
        setLocationList(response.result.ubicaciones);
        setLoadLocation(false);
      } else {
        // this.setState({
        //   empty: true,
        //   loading: false,
        //   message: response.message
        // });
      }
    } else {
      // this.setState({
      //   error: true,
      //   loading: false,
      //   message: response.message
      // });
    }
  };
  const getHotels = async () => {
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }
    let data = {
      token: token,
    };

    const response = await request.post("/auth/sales/getHotel", data);

    if (response && !response.error) {
      if (response.result && !response.empty) {
        setHotelList(response.result.hotels);
        setHotelBack(response.result.hotels);
        setLoadHotel(false);
        //response.result.hotels.forEach((element) => {
        //   hotelDB.add(element).then(
        //     (id) => {},
        //     (error) => {
        //       if (error.target.error.code == 0) {
        //         hotelDB.update(element);
        //       }
        //     }
        //   );
        //});
        //AdminIndexDB('hotel', {}, 'getAll');
        // window.localStorage.setItem(
        //   "hotelList",
        //   JSON.stringify(response.result.hotels)
        // );
      } else {
        // this.setState({
        //   empty: true,
        //   loading: false,
        //   message: response.message
        // });
      }
    } else {
      // this.setState({
      //   error: true,
      //   loading: false,
      //   message: response.message
      // });
    }
  };
  const getStatus = async () => {
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }
    let data = {
      token: token,
    };

    const response = await request.post("/auth/sales/getStatus", data);

    if (response && !response.error) {
      if (response.result && !response.empty) {
        setStatusList(response.result.type);
        setLoadStatus(false);
        // response.result.type.forEach((element) => {
        //   statusDB.add(element);
        // });
      } else {
        // this.setState({
        //   empty: true,
        //   loading: false,
        //   message: response.message
        // });
      }
    } else {
      // this.setState({
      //   error: true,
      //   loading: false,
      //   message: response.message
      // });
    }
  };
  /**
   * guardar datos recurrentes en local storage
   */
  const saveCalendarDate = (calendarDate) => {
    window.localStorage.setItem("calendarDate", calendarDate);
  };
  const saveCalendarView = (view) => {
    window.localStorage.setItem("calendarView", view);
  };
  const changeEvent = (event) => {
    setRecargaOds(false);

    setInfoEvent({
      id_event: Number(event.id),
      title: event.title,
      idUser: event.id_usuario,
    });
    setModalOds(true);
    setRecargaOds(true);
    setModalEddit(true);
    calendarRef.current.getApi().addEvent(event);
    calendarRef.current.getApi().gotoDate(event.start);
  };
  const updateEvent = (event) => {
    setRecargaOds(false);

    setInfoEvent({
      id_event: Number(event.id),
      title: event.title,
      idUser: event.id_usuario,
    });

    setRecargaOds(true);
    calendarRef.current.getApi().getEventById(event.id).remove();
    calendarRef.current.getApi().addEvent(event);

    // setModalEddit(true);
    // calendarRef.current.getApi().gotoDate(event.start);
  };
  const deleteCalendarEvent = async (id) => {
    setModalOds(false);
    calendarRef.current.getApi().getEventById(id).remove();
  };
  const handleChangeFilter = async (event, list) => {
    let filter = JSON.parse(localStorage.getItem("calendarFilter"));
    let state = [];
    if (filter === null) {
      state = {
        //text: text,
        type: type,
        seller: seller,
        location: location,
        hotel: hotel,
        status: status,
      };
    } else {
      state = {
        //text: filter.text,
        type: filter.type,
        seller: filter.seller,
        location: filter.location,
        hotel: filter.hotel,
        status: filter.status,
      };
    }
    switch (event.target.name) {
      // case "texto":
      //   setText(event.target.value);
      //   state.text = event.target.value;
      //   break;
      case "type":
        state.type = event.target.value;
        setType(event.target.value);
        break;
      case "vendedor":
        state.seller = event.target.value;
        setSeller(event.target.value);
        break;
      case "ubicacion":
        state.location = event.target.value;
        setLocation(event.target.value);
        filterHotel(state.location);
        break;
      case "hotel":
        state.hotel = event.target.value;
        setHotel(event.target.value);
        break;
      case "status":
        state.status = event.target.value;
        setStatus(event.target.value);
        break;
      default:
        // setText(state.text);
        setType(state.type);
        setSeller(state.seller);
        setLocation(state.location);
        setHotel(state.hotel);
        setStatus(state.status);
        break;
    }

    window.localStorage.setItem("calendarFilter", JSON.stringify(state));
  };
  const handleEventClick = async (info) => {
    setInfoEvent({
      id_event: Number(info.event.id),
      title: info.event.title,
      idUser: info.event.extendedProps.id_usuario,
    });
    // setNumAdj(0);
    setModalOds(true);
  };

  const filterHotel = (ubi) => {
    let list = hotelBack;
    let new_list = [];

    if (ubi.length === 0) {
      setHotelList(list);
      return;
    }

    if (list.length !== 0) {
      list.forEach((hotel) => {
        if (ubi.indexOf(hotel.lugar) !== -1) {
          new_list.push(hotel);
        }
      });
      setHotelList(new_list);
    }
  };
  return (
    <div className="content-wrapper" id="calendar">
      <div className="card" style={{ margin: "1rem" }}>
        {search ? (
          <div className="card-header">
            <div
              className="card-title row card-title row row-cols-2 row-cols-md-4 row-cols-lg-6"
              style={{ width: "100%" }}
            >
              {/* <div className="col text-center">
            <TextField
              label="Texto"
              name="texto"
              value={text}
              onChange={(e) => handleChangeFilter(e)}
            />
          </div> */}
              <div className="col text-center">
                {loadType ? (
                  <i
                    className="fas fa-spinner fa-spin"
                    style={{ color: "#3f51b5" }}
                  ></i>
                ) : (
                  <FormControl>
                    <InputLabel htmlFor="type">Tipo</InputLabel>
                    <Select
                      multiple
                      value={type}
                      onChange={(e) => handleChangeFilter(e)}
                      input={<Input id="type" />}
                      renderValue={(selected) => selected.join(", ")}
                      name="type"
                    >
                      {typeList.map((info, key) => (
                        <MenuItem key={key} value={info.tipo_esp}>
                          <Checkbox
                            checked={type.indexOf(info.tipo_esp) > -1}
                          />
                          <ListItemText primary={info.tipo_esp} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </div>
              <div className="col text-center">
                {loadSeller ? (
                  <i
                    className="fas fa-spinner fa-spin"
                    style={{ color: "#3f51b5" }}
                  ></i>
                ) : (
                  <FormControl>
                    <InputLabel htmlFor="vendedor">Vendedor</InputLabel>
                    <Select
                      multiple
                      value={seller}
                      onChange={(e) => handleChangeFilter(e)}
                      input={<Input id="vendedor" />}
                      renderValue={(selected) => selected.join(", ")}
                      name="vendedor"
                    >
                      {sellerList.map((info, key) => (
                        <MenuItem key={key} id="vend" value={info.nombre}>
                          <Checkbox
                            checked={seller.indexOf(info.nombre) > -1}
                          />
                          <ListItemText primary={info.nombre} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </div>
              <div className="col text-center">
                {loadLocation ? (
                  <i
                    className="fas fa-spinner fa-spin"
                    style={{ color: "#3f51b5" }}
                  ></i>
                ) : (
                  <FormControl>
                    <InputLabel htmlFor="ubicacion">Ubicación</InputLabel>
                    <Select
                      multiple
                      value={location}
                      onChange={(e) => handleChangeFilter(e)}
                      input={<Input id="ubicacion" />}
                      renderValue={(selected) => selected.join(", ")}
                      name="ubicacion"
                    >
                      {locationList.map((info, key) => (
                        <MenuItem key={key} value={info.lugar}>
                          <Checkbox
                            checked={location.indexOf(info.lugar) > -1}
                          />
                          <ListItemText
                            primary={info.lugar}
                            style={{ textTransform: "capitalize" }}
                          />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </div>
              <div className="col text-center">
                {loadHotel ? (
                  <i
                    className="fas fa-spinner fa-spin"
                    style={{ color: "#3f51b5" }}
                  ></i>
                ) : (
                  <FormControl>
                    <InputLabel htmlFor="hotel">Hotel</InputLabel>
                    <Select
                      multiple
                      value={hotel}
                      onChange={(e) => handleChangeFilter(e)}
                      input={<Input id="hotel" />}
                      renderValue={(selected) => selected.join(", ")}
                      name="hotel"
                    >
                      {hotelList.map((info, key) => (
                        <MenuItem key={key} value={info.nombre}>
                          <Checkbox checked={hotel.indexOf(info.nombre) > -1} />
                          <ListItemText primary={info.nombre} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </div>
              <div className="col text-center">
                {loadStatus ? (
                  <i
                    className="fas fa-spinner fa-spin"
                    style={{ color: "#3f51b5" }}
                  ></i>
                ) : (
                  <FormControl>
                    <InputLabel htmlFor="status">Status</InputLabel>
                    <Select
                      multiple
                      value={status}
                      onChange={(e) => handleChangeFilter(e)}
                      input={<Input id="status" />}
                      renderValue={(selected) => selected.join(", ")}
                      name="status"
                    >
                      {statusList.map((info, key) => (
                        <MenuItem key={key} value={info.status}>
                          <Checkbox
                            checked={status.indexOf(info.status) > -1}
                          />
                          <ListItemText primary={info.status} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </div>
              <div className="col text-right">
                <button
                  className="btn btn-primary btn-sm button-filter"
                  onClick={() => getSearchEvents()}
                >
                  <i className="fas fa-search"></i>
                </button>
                &nbsp;
                <button
                  className="btn btn-secondary btn-sm button-filter"
                  onClick={() => {
                    clearSearch();
                  }}
                >
                  <i className="fas fa-times"></i>
                </button>
              </div>
            </div>
            <div className="card-tools">
              <button
                type="button"
                className="btn btn-tool"
                onClick={() => setSearch(false)}
              >
                <i className="fas fa-times"></i>
              </button>
            </div>
          </div>
        ) : null}
        <div
          className="card-body"
          style={{ zIndex: 0, padding: "0rem .25rem .25rem" }}
        >
          {loadCalendar ? (
            <div className="text-center pt-2">
              <i
                className="fas fa-spinner fa-spin"
                style={{ fontSize: "62px", color: "#3f51b5" }}
              ></i>
              <br /> {search ? "Buscando Eventos del" : "Cargando Eventos del"}{" "}
              Calendario
            </div>
          ) : (
            <FullCalendar
              ref={calendarRef}
              plugins={[
                bootstrapPlugin,
                dayGridPlugin,
                timeGridPlugin,
                interactionPlugin,
                listPlugin,
              ]}
              themeSystem="bootstrap"
              initialView="dayGridMonth"
              dayMaxEventRows={true}
              contentHeight="95vh"
              //aspectRatio={1}
              locale={esLocale}
              weekends={true}
              events={events}
              firstDay={0}
              eventClick={(e) => handleEventClick(e)}
              eventOrder={["status", "title"]}
              headerToolbar={{
                left: `${
                  props.id_modulo === 2 ? "add_custom colection_custom " : ""
                }search_custom reload_custom`,
                center: "title",
                right:
                  "prev_custom,today_custom,next_custom month_custom,week_custom,day_custom",
              }}
              customButtons={{
                prev_custom: {
                  click: function () {
                    let calendarApi = calendarRef.current.getApi();
                    calendarApi.prev();
                    let date = calendarApi.getDate().toISOString();
                    saveCalendarDate(date);
                  },
                },
                next_custom: {
                  click: function () {
                    let calendarApi = calendarRef.current.getApi();
                    calendarApi.next();
                    let date = calendarApi.getDate().toISOString();
                    saveCalendarDate(date);
                  },
                },
                today_custom: {
                  text: "Hoy",
                  click: function () {
                    let calendarApi = calendarRef.current.getApi();
                    calendarApi.today();
                    let date = calendarApi.getDate().toISOString();
                    saveCalendarDate(date);
                  },
                },
                month_custom: {
                  text: "Mes",
                  click: function () {
                    let calendarApi = calendarRef.current.getApi();
                    calendarApi.changeView("dayGridMonth");
                    saveCalendarView("dayGridMonth");
                  },
                },
                week_custom: {
                  text: "Semana",
                  click: function () {
                    let calendarApi = calendarRef.current.getApi();
                    calendarApi.changeView("listWeek");
                    saveCalendarView("listWeek");
                  },
                },
                day_custom: {
                  text: "Día",
                  click: function () {
                    let calendarApi = calendarRef.current.getApi();
                    calendarApi.changeView("listDay");
                    saveCalendarView("listDay");
                  },
                },
                reload_custom: {
                  click: () => getSearchEvents(),
                },
                search_custom: {
                  click: () => setSearch(!search),
                },
                add_custom: {
                  click: () => {
                    setModalEddit(true);
                    setInfoEvent({
                      id_event: 0,
                      title: "Evento Nuevo",
                      idUser: props.user.id,
                    });
                  },
                },
                colection_custom: {
                  text: "Hoy",
                  click: () => setModalColection(true),
                },
                Excel: {
                  text: "Excel",
                  click: function () {
                    alert("clicked the custom button!");
                  },
                },
              }}
              bootstrapFontAwesome={{
                prev_custom: "fas fa-chevron-left",
                next_custom: "fas fa-chevron-right",
                search_custom: "fas fa-filter",
                reload_custom: "fas fa-sync-alt",
                add_custom: "fas fa-plus",
                colection_custom: "fas fa-swatchbook",
              }}
              views={{
                listWeek: { buttonText: "Semana" },
                listDay: { buttonText: "Día" },
              }}
            />
          )}
        </div>
      </div>
      {/** Modal de ODS*/}
      <ModalOds
        modalOds={modalOds}
        infoEvent={infoEvent}
        id_modulo={props.id_modulo}
        setModalOds={(e) => setModalOds(e)}
        setModalEddit={(e) => setModalEddit(e)}
        changeEvent={(e) => changeEvent(e)}
        recargaOds={recargaOds}
        setRecargaOds={(e) => setRecargaOds(e)}
        deleteCalendarEvent={(e) => deleteCalendarEvent(e)}
      />
      {/**
       * Modal Editar Cotización/ODS
       */}
      <ModalEddit
        modalEddit={modalEddit}
        infoEvent={infoEvent}
        id_modulo={props.id_modulo}
        setModalEddit={(e) => setModalEddit(e)}
        setNewCot={(e) => setNewCot(e)}
        updateEvent={(e) => updateEvent(e)}
        changeEvent={(e) => changeEvent(e)}
        setRecargaOds={(e) => setRecargaOds(e)}
      />
      {/**
       * Modal Lista de Colecciones
       */}
      <ModalColection
        modalColection={modalColection}
        setModalColection={(e) => setModalColection(e)}
        changeEvent={(e) => changeEvent(e)}
      />
    </div>
  );
}
export default CalendarEvents;
