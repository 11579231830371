import React, { useState, useEffect } from "react";
import { Consumer } from "../../context/index";
import Modal from "react-modal";
import Request from "../../utils/http";

import Machotes from "../../pages/sales/collentionQuote";

const request = new Request();

function ModalColection(props) {
  const customStyles = {
    content: {
      position: "absolute",
      top: "5vh",
      left: "15vw",
      right: "15vw",
      bottom: "5vh",
      border: "1px solid rgb(204, 204, 204)",
      background: "rgb(255, 255, 255)",
      overflow: "auto",
      outline: "none",
      padding: "10px",
    },
  };

  return (
    <Modal
      isOpen={props.modalColection}
      contentLabel="Editar/Agregar Evento"
      style={customStyles}
    >
      <Machotes
        setModalColection={(e) => props.setModalColection(e)}
        changeEvent={(e) =>{ props.changeEvent(e);
        console.log("🚀 ~ file: modalColection.jsx ~ line 35 ~ ModalColection ~ e", e)}}
      />
    </Modal>
  );
}
export default Consumer(ModalColection);
