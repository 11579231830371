import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import ButtonActions from "../inventory/buttonsActions";
import EditIcon from "@material-ui/icons/Edit";
import Request from "../../utils/http";
import NewProvider from "./newProvider";

const request = new Request();

function Provider() {
  //set de valores
  const [providers, setProviders] = useState([]);
  //obteneos token
  let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
  let token;
  if (token1) {
    token = token1.id;
  }
  useEffect(() => {
    getProvider();
  }, []);

  const getProvider = async () => {
    let data = {
      token: token
    };
    const response = await request.post("/auth/purchases/getProviders", data);

    //validar los datos que recibimos
    if (response && !response.error) {
      if (response.result && !response.empty) {
        //console.log(response.result);
        setProviders(response.result);
      } else {
        console.log(response.result.message);
      }
    } else {
      console.log("error en la peticion");
    }
  };

  const columns = [
    {
      name: "actions",
      label: "ACCIONES",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <NewProvider
              reload={getProvider}
              edit={true}
              idProv={tableMeta.rowData[1]}
            />
          );
        }
      }
    },
    {
      name: "id_prov",
      label: "ID",
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: "den_com",
      label: "DENOMINACIÓN COMERCIAL",
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: "razon",
      label: "RAZON SOCIAL",
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: "RFC",
      label: "RFC",
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: "vendedor",
      label: "CONTACTO",
      options: {
        filter: true,
        sort: true
      }
    }
  ];

  const options = {
    selectableRows: "none",
    filter: false,
    viewColumns: false,
    textLabels: {
      body: {
        noMatch: "No se encontraron resultados.",
        toolTip: "Sort"
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Número de filas:",
        displayRows: "de"
      },
      toolbar: {
        search: "Buscar",
        downloadCsv: "Descargar Excel",
        print: "Imprimir"
      },
      filter: {
        all: "TODOS",
        title: "FILTROS",
        reset: "LIMPIAR"
      },
      viewColumns: {
        title: "Mostar Columnas",
        titleAria: "Mostrar/Ocultar Columnas de la Tabla"
      },
      selectedRows: {
        text: "Fila(s) Seleccionadas",
        delete: "Eliminar",
        deleteAria: "Eliminar Filar Seleccionadas"
      }
    },
    customToolbar: () => {
      return <NewProvider reload={getProvider} edit={false} idProv={0} />;
    }
  };

  return (
    <MUIDataTable
      title={"LISTA DE PROVEEDORES"}
      data={providers}
      columns={columns}
      options={options}
    />
  );
}
export default Provider;
