import React, {useEffect, useState} from "react";
import Request from "../../../utils/http";
import MUIDataTable from "mui-datatables";
import ButtonSave from "../../inventory/tabs/buttonSave";

const request = new Request();




export default function FormPuesto(props){

    const [areasG, setAreasG] = useState([]);
    const [listPuest, SetListPuest] = useState([]);
    const [puesto, setPuesto] = useState({
        area:'',
        name_puesto:''
    });


    //nuestro token para todos
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token = token1.id;

    useEffect(() => {
        getAreasGeneral();
        getPuestoGeneral();
    }, []);

  const getAreasGeneral = async () => {
    let data = {
      token: token
    }
    const response = await request.post("/employee/getAreasGeneral", data);

    console.log(response);
    if (response && !response.error) {
        if (response.result.areasG && !response.empty) {
            setAreasG(response.result.areasG);
        } else {
          console.log(response.result.message);
        }
      } else {
          console.log("error en la peticion");
      }

    }

    const getPuestoGeneral = async () => {
        let data = {
          token: token
        }
        const response = await request.post("/employee/getPuestoGeneral", data);

        if (response && !response.error) {
            if (response.result.puestoGeneral && !response.empty) {
                SetListPuest(response.result.puestoGeneral);
            } else {
              console.log(response.result.message);
            }
          } else {
              console.log("error en la peticion");
          }
    
    }

    

    const save = async () => {
        let datos=puesto;
        let data = {
          token: token,
          datos: datos
        }
        const response = await request.post("/employee/savePuesto", data);
        if (response && !response.error) {
          if (response.result.ok && !response.empty) {
              alert("Puesto Guardado Con Exito");
              getPuestoGeneral();
              props.recargar();
          } else {
            console.log(response.result.message);
          }
        } else {
          console.log("error en la peticion");
        }
    }


  const columns = [
    {
        name: "puesto",
        label: "Puesto",
            options: {
            filter: true,
        sort: false,
        }
    },
    {
        name: "nombreArea",
        label: "Area",
            options: {
            filter: true,
        sort: false,
        }
    },
   ];
   

   
   const options = {
    selectableRows: "none",
    filter: false,
    viewColumns: false,
    textLabels: {
      body: {
        noMatch: "No se encontraron resultados.",
        toolTip: "Sort"
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Número de filas:",
        displayRows: "de"
      },

      toolbar: {
        search: "Buscar",
        downloadCsv: "Descargar Excel",
        print: "Imprimir"
      },

      filter: {
        all: "TODOS",
        title: "FILTROS",
        reset: "LIMPIAR"
      },
      viewColumns: {
        title: "Mostar Columnas",
        titleAria: "Mostrar/Ocultar Columnas de la Tabla"
      },
      selectedRows: {
        text: "Fila(s) Seleccionadas",
        delete: "Eliminar",
        deleteAria: "Eliminar Filar Seleccionadas"
      }
    },
  };

    return(
        <div className="tab-content">
            <div className="row mt-2">
                <div className="col-md-6">
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Areas</span>
                        </div>
                        <select className="form-control" 
                            id="area"
                            name="area" 
                            value={puesto.area} 
                            onChange={e => setPuesto({...puesto,area:e.target.value})} >
                            <option></option>
                            {areasG.map((valArea, key) => (
                                <option key={key} 
                                    value={valArea.id_areas}
                                    selected={puesto.area == valArea.id_areas ? true : false}>
                                    {valArea.name}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Puesto</span>
                        </div>
                        <input type="text" id="name_puesto" name="name_puesto" className="form-control text-uppercase"
                         value={puesto.name_puesto} 
                         onChange={e => setPuesto({...puesto,name_puesto:e.target.value})} />
                    </div>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-md-12">
                    <ButtonSave  
                    save={save}
                    closeModal={props.closeModal}
                    />
                </div> 
            </div>

            <div className="row mt-2">
                <div className="col-md-12">
                    <MUIDataTable
                        data={listPuest}
                        columns={columns}
                        options={options}
                    />  
                </div>
            </div>

        </div>
    );
}