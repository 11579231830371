import React, { Component } from "react";
class ButtonSerch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: false
    };
  }
  render() {
    return (
        <div className=" modal-footer justify-content-left ">
          <button
            type="button"
            onClick={this.handleSubmit.bind(this)}
            className="btn btn-outline-success btn-flat"
          >
            &nbsp; Buscar
          </button>
        </div>
    );
  }

  handleSubmit() {
    this.props.serch();
  }
  
}

export default ButtonSerch;