let validator = (data) => {
  console.log("🚀 ~ file: validatorStep2.js ~ line 2 ~ validator ~ data", data);
  let error = 0;
  for (let i = 0; i < data.length; i++) {
    if (isNaN(data[i]["cantidad"]) || data[i]["cantidad"]===null || data[i]["cantidad"]==="" || data[i]["cantidad"]<0) {
      if (error !== 1) {
        document.getElementById(`cantidad${i}`).focus();
      }
      document.getElementById(`cantidad${i}`).classList.add("is-invalid");
      error = 1;
    }
    if (isNaN(data[i]["produccion"]) || data[i]["produccion"]===null || data[i]["produccion"]==="" || data[i]["produccion"]<0) {
      if (error !== 1) {
        document.getElementById(`produccion${i}`).focus();
      }
      document.getElementById(`produccion${i}`).classList.add("is-invalid");
      error = 1;
    }

    if (isNaN(data[i]["preciouni"]) || data[i]["preciouni"]===null ||data[i]["preciouni"]==="" || data[i]["preciouni"]<0) {
      if (error !== 1) {
        document.getElementById(`preciouni${i}`).focus();
      }
      document.getElementById(`preciouni${i}`).classList.add("is-invalid");
      error = 1;
    }

    if (isNaN(data[i]["dias"]) || data[i]["dias"]===null ||data[i]["dias"]==="" || data[i]["dias"]<=0) {
      if (error !== 1) {
        document.getElementById(`dias${i}`).focus();
      }
      document.getElementById(`dias${i}`).classList.add("is-invalid");
      error = 1;
    }
  }
  return error;
};
export default validator;
