
let validarNuevoProducto = data => {

    let error = 1;

    if (data.categoria==="") {
        alert("llenar campos de categoria");
      return error;
    }
    if (data.name.length === 0 || data.name === "") {
      document.getElementById("name").classList.add("is-invalid");
      return error;
    }
    if (data.codigo.length === 0 || data.codigo === "") {
      document.getElementById("codigo").classList.add("is-invalid");
      return error;
    }
    if (data.unidad_medida==="") {
        alert("llenar campos de Unidad Medida");
      return error;
    }
    if (data.marca.length === 0 || data.marca === "") {
        document.getElementById("marca").classList.add("is-invalid");
        return error;
    }
    if (data.color.length === 0 || data.color === "") {
        document.getElementById("color").classList.add("is-invalid");
        return error;
    }
    if (data.dimension.length === 0 || data.dimension === "") {
        document.getElementById("dimension").classList.add("is-invalid");
        return error;
    }
    if (data.descripcion.length === 0 || data.descripcion === "") {
        document.getElementById("descripcion").classList.add("is-invalid");
        return error;
    }
    if (data.stock.length === 0 || data.stock === "") {
        document.getElementById("stock").classList.add("is-invalid");
        return error;
    }
    if (data.precio_compra.length === 0 || data.precio_compra === "") {
        document.getElementById("precio_compra").classList.add("is-invalid");
        return error;
    }
    if (data.precio_almacen.length === 0 || data.precio_almacen === "") {
        document.getElementById("precio_almacen").classList.add("is-invalid");
        return error;
    }
    if (data.tipo_material.length === 0 || data.tipo_material === "") {
        document.getElementById("tipo_material").classList.add("is-invalid");
        return error;
    }

      return data;
}

export default validarNuevoProducto;
  