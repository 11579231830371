import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Request from "../../../utils/http";
import { Consumer } from "../../../context/index";
const request = new Request();
function NotifyOds(props) {
  const [id_event] = useState(props.idEvent);
  const [motivo, setMotivo] = useState("ODS CONFIRMADA");
  const [coment, setComent] = useState("");
  const [load, setLoad] = useState(false);
  const [labelInput, setLabelInput] = useState("Adjuntar Archivo");
  const [user, setUser] = useState({
    idUsuario: 0,
    idPerfil: 0,
    nombre: "",
  });

  useEffect(() => {
    loadUser();
    //getEventFiles();
  }, []);
  const loadUser = async () => {
    const user = await props.context.loadUser();

    if (user) {
      setUser(user);
    }
  };

  const sendMail = async (e) => {
    setLoad(true);
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }
    let data = {
      id_event: props.idEvent,
      motivo: motivo,
      coment: coment,
      token: token,
    };

    const response = await request.post("/auth/calendar/mailOds", data);
    console.log(response);
    if (response && !response.error) {
      if (response.result && !response.empty) {
        setLoad(false);
        toast.success(<span>Archivo cargado con exito.</span>);
      } else {
        setLoad(false);
        toast.error(<span>¡No se pudo cargar el archivo!.</span>);
      }
    } else {
      setLoad(false);
      toast.error(<span>¡No se pudo cargar el archivo!.</span>);
    }
  };

  return (
    <div className="card">
      <div className="card-header pt-1 pb-1 pl-1 pr-1">
        NOTIFICAR A OPERACIONES
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-md-7">
            <div className="input-group mb-2">
              <div className="input-group-prepend">
                <span className="input-group-text">Motivo</span>
              </div>
              <select
                name="motivo"
                id="motivo"
                className="form-control"
                value={motivo}
                onChange={(e) => setMotivo(e.target.value)}
              >
                <option>ODS CONFIRMADA</option>
                <option>CAMBIOS EN ODS</option>
                <option>ODS POSPUESTA</option>
                <option>ODS CANCELADA</option>
              </select>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-7">
            <div className="input-group mb-2">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  Comentarios
                  <br />
                  Adicionales
                </span>
              </div>
              <textarea
                name="comentarios"
                id="comentarios"
                className="form-control"
                value={coment}
                onChange={(e) => setComent(e.target.value)}
              ></textarea>
            </div>
          </div>
        </div>
        *La vista previa esta en la pestaña ODS
      </div>
      <div className="card-footer">
        <button
          type="button"
          onClick={() => props.closeModal()}
          className="btn btn-outline-danger btn-flat float-right ml-3"
        >
          <i className="fas fa-ban"></i> Cerrar
        </button>
        <button
          type="submit"
          className="btn btn-outline-success btn-flat float-right"
          title="Boton para enviar."
          disabled={load}
          onClick={() => sendMail()}
        >
          {load ? (
            <i className="fas fa-spinner fa-spin"></i>
          ) : (
            <i className="fas fa-paper-plane"></i>
          )}
          &nbsp; Enviar
        </button>
      </div>
    </div>
  );
}
export default Consumer(NotifyOds);
