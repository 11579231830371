import React, { useEffect, useState, Component } from "react";
import Request from "../../utils/http";
import { toast } from "react-toastify";
const request = new Request();

class OdsStep3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      condiciones: [],
      terminos: [],
      termAssigned: [],
      load: false,
      block: false,
      termAll: false,
    };
  }
  render() {
    return (
      <>
        {this.state.load ? (
          <>
            <div className="row">
              <div className="col-lg-12">
                <div className="input-group mb-2">
                  <div className="input-group-prepend">
                    <button
                      className="btn btn-block btn-outline-info"
                      onClick={this.asignTermAll.bind(this)}
                    >
                      {this.state.termAll ? (
                        <i className="fas fa-check-square"></i>
                      ) : (
                        <i className="far fa-square"></i>
                      )}
                    </button>
                  </div>
                  <div
                    className="form-control"
                    style={{ display: "inline-table" }}
                  >
                    {this.state.termAll ? "Desmarcar" : "Marcar"} Todo
                  </div>
                </div>
              </div>
            </div>
            <div className="row form-step3" style={{zoom:"85%"}}>
              {this.state.condiciones.map((term, key) => (
                <div className="col-lg-12" key={key}>
                  <div className="input-group mb-2">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <input
                          type="checkbox"
                          onChange={(e) => this.asignTerm(term, e, key)}
                          checked={
                            this.state.terminos.indexOf(term.id_pie) === -1
                              ? false
                              : true
                          }
                        />
                      </span>
                    </div>
                    <div
                      className="form-control"
                      style={{ display: "inline-table" }}
                    >
                      {term.texto}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </>
        ) : (
          <div className="overlay text-center" style={{ color: "#007bff" }}>
            <i className="fas fa-2x fa-spinner fa-spin"></i>
            <br />
            Cargando...
          </div>
        )}
        <div className="modal-footer justify-content-left pt-0 pb-0" style={{zoom:"85%"}}>
          <button
            type="button"
            onClick={() => {
              // this.setState({block:true})
              this.getInfo.bind(this);
            }}
            className="btn btn-outline-secondary btn-flat"
            //  disabled={!this.state.load}
          >
            <i className="fas fa-sync"></i> Recargar
          </button>
          &nbsp;
          {this.state.load ? (
            <>
              <button
                type="button"
                onClick={this.handleSubmit.bind(this)}
                className="btn btn-outline-success btn-flat "
                disabled={this.state.block}
              >
                {this.state.block ? (
                  <i className="fas fa-spinner fa-spin"></i>
                ) : (
                  <i className="far fa-save"></i>
                )}
                &nbsp; Guardar
              </button>
              &nbsp;
              <button
                type="button"
                onClick={this.props.closeModal.bind(this)}
                className="btn btn-outline-danger btn-flat"
                disabled={this.state.block}
              >
                <i className="fas fa-ban"></i> Cerrar
              </button>
            </>
          ) : null}
        </div>
      </>
    );
  }

  componentDidMount() {
    if (this.props.idioma) {
      this.getInfo();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.idioma !== prevProps.idioma) {
      this.setState({ termAssigned: [], terminos: [], termAll: false });
      this.getInfo();
    }
  }

  getInfo() {
    this.getTerminos();
    if (this.props.eddit === 1) {
      this.getData();
    }
  }

  async getTerminos() {
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }
    let data = { token: token, idioma: this.props.idioma };
    const response = await request.post("/auth/sales/getTermn", data);

    if (response && !response.error) {
      if (response.result.termn && response.result.termn.length > 0) {
        this.setState({ condiciones: response.result.termn, load: true });
      } else {
        console.log(response.result.message);
      }
    } else {
      console.log("error en la peticion");
    }
  }
  async getData() {
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }
    let data = {
      token: token,
      id_evento: this.props.id,
    };

    const response = await request.post("/auth/sales/getTermnOds", data);

    if (response && !response.error) {
      if (response.result.termino && response.result.termino.length > 0) {
        let elemento = [];
        let condicion = [];
        let termino = JSON.parse(response.result.termino[0].id_pie);
        for (let i = 0; i < termino.length; i++) {
          if (termino[i].idioma === this.props.idioma) {
            elemento.push(termino[i].id_pie);
            condicion.push(termino[i]);
          }
        }
        // console.log(elemento);
        this.setState({ termAssigned: condicion, terminos: elemento });
        if (elemento.length === this.state.condiciones.length) {
          this.setState({ termAll: true });
        }
      } else {
        console.log(response.result.message);
      }
    } else {
      console.log("error en la peticion");
    }
  }

  asignTerm(termino, e, key) {
    let condiciones = this.state.termAssigned.slice();
    let added = this.state.terminos.slice();

    if (e.target.checked) {
      condiciones.push(termino);

      this.setState({ termAssigned: condiciones });
      added.push(termino.id_pie);
      this.setState({ terminos: added });
    } else {
      for (let i = 0; i < condiciones.length; i++) {
        if (condiciones[i].id_pie === termino.id_pie) {
          condiciones.splice(i, 1);
          this.setState({ termAssigned: condiciones });
        }
      }
      for (let i = 0; i < added.length; i++) {
        if (added[i] === termino.id_pie) {
          added.splice(i, 1);
          this.setState({ terminos: added });
        }
      }
    }
    if (added.length !== this.state.condiciones.length) {
      this.setState({ termAll: false });
    } else {
      this.setState({ termAll: true });
    }
  }

  asignTermAll() {
    let condiciones = this.state.condiciones.slice();
    let cond = [];
    let term = [];
    if (this.state.termAll) {
      this.setState({
        terminos: term,
        termAssigned: cond,
        termAll: false,
      });
    } else {
      for (let i = 0; i < condiciones.length; i++) {
        cond.push(condiciones[i]);
        term.push(condiciones[i].id_pie);
      }
      this.setState({
        terminos: term,
        termAssigned: cond,
        termAll: true,
      });
    }
  }
  async handleSubmit() {
    this.setState({ block: true });
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }

    let data = {
      token: token,
      id_evento: this.props.id,
      terminos: this.state.termAssigned,
    };
    console.log("🚀 ~ file: odsStep3.jsx ~ line 268 ~ OdsStep3 ~ handleSubmit ~ data", data)
    if (data.terminos.length === 0) {
      toast.warning(
        <span>
          <i className="fas fa-exclamation-circle"></i>&nbsp;No se seleccionaron
          terminos o condiciones. Aun así se guardara.
        </span>
      );
    }
      const response = await request.post("/auth/sales/saveTerms", data);

      if (response && !response.error) {
        if (response.result) {
          if (response.result[0].affected_rows > 0) {
            toast.success(
              <span>
                Terminos y
                condiciones guardados con exito.
              </span>,
              {
                position: toast.POSITION.TOP_RIGHT,
          theme: "colored",
              }
            );
            this.setState({ block: false });
            this.props.recargarInfo(true);
          }
        } else {
          toast.error(
            <span>
              !!!Error!!!, algo
              salio mal.. intentalo de nuevo, si el problema continua contacta a
              sistemas.
            </span>,
            {
              position: toast.POSITION.TOP_RIGHT,
          theme: "colored",
            }
          );
          this.setState({ block: false });
          console.log(response.message);
        }
      }
  }
}

export default OdsStep3;
