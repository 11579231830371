import React, { useEffect, useState } from "react";
import Request from "../../utils/http";
import MUIDataTable from "mui-datatables";
import ButtonSave from "../general/buttonSave";
import {Consumer} from "../../context/index";
import moment from "moment";

import { render } from 'react-dom';
import { Multiselect } from 'react-widgets';
import 'react-widgets/dist/css/react-widgets.css';
import DropdownList from 'react-widgets/lib/DropdownList';


const request = new Request();
const customStyles = {
    content: {
        width: "100px"
    }
};

 function PrestamosAlmacen(props) {


    const [employee, setEmployee] = useState([]);
    const [categoriasAlmacen, setListarCategorias] = useState([]);
    const [productos, setProductos] = useState([]);
    const [prestamos, setPrestamos] = useState({
        nameEmployee: '',
        categoria: '',
        producto:''
    });

    //nuestro token para todos
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token = token1.id;
    useEffect(() => {
        getEmpleadosGeneral();
        getCategoriasAlmacen();
        // getUser();
    }, []);

    //con este metodo nos permite ver usuario que inicio session
    // const getUser = async () => {
    //     const user = await props.context.loadUser();
    //     //nos permite enviar datos quien inicio sesion
    //     if(user.id!=null && user.id_perfil!=null && user.ubicacion!=null){
    //         getProductos(user.id,user.id_perfil,user.ubicacion);
    //     }
    // }



    let calculaDiasTrannscurridos = data => {

        // var date1 = new Date('2020/08/17 00:00:00');
        // var date2 = new Date('2020/08/17 22:00:00'); //less than 1
        // var start = Math.floor(date1.getTime() / (3600 * 24 * 1000)); //days as integer from..
        // var end = Math.floor(date2.getTime() / (3600 * 24 * 1000)); //days as integer from..
        // var daysDiff = end - start; // exact dates
        // console.log(daysDiff);

        // var sdt = new Date('2020-08-16');
        // var difdt = new Date(new Date() - sdt);
        // alert(difdt.getDate() + "D");
    }

    const getEmpleadosGeneral = async () => {
        let data = {
            token: token
        };
        const response = await request.post("/employee/getEmpleadosGeneral", data);
        if (response && !response.error) {
            if (response.result.employeeGeneral && !response.empty) {
                setEmployee(response.result.employeeGeneral);
            } else {
                console.log(response.result.message);
            }
        } else {
            console.log("error en la peticion");
        }
    }
    const obtenerDatos = async (value) => {

    }



    const getCategoriasAlmacen = async () => {
        let data = {
            token: token
        };
        const response = await request.post("/almacen/getCategoriasAlmacen", data);
        if (response && !response.error) {
            if (response.result.categoriasAlmacen && !response.empty) {
                setListarCategorias(response.result.categoriasAlmacen);
            } else {
                console.log(response.result.message);
            }
        } else {
            console.log("error en la peticion");
        }
    }
    const obtenerDatosCategoria = async (value) => {
        // setNewProductos({ ...productos, categoria:value.id_categoria});

    }


    let getProductos = async(id) => {
        let id_ubicacion=props.context.user.ubicacion;
        setPrestamos({...prestamos,categoria:id});
        let data = {
          token: token,
          id_categoria:id,
          id_ubicacion:id_ubicacion
        };
        const response = await request.post("/prestamo/getProductosPorCategoria", data);  

        if (response && !response.error) {
            if (response.result.productos && !response.empty) {
                setProductos(response.result.productos);
            } else {
              console.log(response.result.message);
            }
        } else {
              console.log("error en la peticion");
          }
        
    }
  

    return (
        <div className="p-4">
            <div className="card">

                <div className="container-fluid">
                    <div className="row mt-5">
                        <div className="col-12 col-md-6">
                            <div className="row">
                                <div className="col-sm-12 mb-5">
                                    <div className="conte_name">
                                        <div className="select_name">
                                            <span className="input-group-text border_name">Nombre</span>
                                        </div>
                                        <div className="select_name1">
                                            <DropdownList filter
                                                data={employee}
                                                id={item => item.id}
                                                defaultValue={employee[0]}
                                                allowCreate="onFilter"
                                                onChange={value => obtenerDatos(value)}
                                                textField="full_name"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">Fecha de Devolucion</span>
                                </div>
                                <input type="date" className="form-control" name=""
                                    aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="row mt-2">
                        <div className="col-md-4">
                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">Categoria:</span>
                                </div>
                                <select className="form-control text-uppercase"
                                    id="categoria"
                                    name="categoria"
                                    value={prestamos.categoria}
                                    onChange={e => getProductos(e.target.value)}
                                >
                                    <option></option>
                                    {categoriasAlmacen.map((valBanco, key) => (
                                        <option key={key}
                                            value={valBanco.id_categoria}
                                            selected={prestamos.categoria == valBanco.id_categoria ? true : false}
                                        >
                                            {valBanco.nombre}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>




                        <div className="col-md-4">
                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">Producto:</span>
                                </div>
                                <select className="form-control text-uppercase"
                                    id="producto"
                                    name="producto"
                                    value={prestamos.producto}
                                    onChange={e => setPrestamos({...prestamos,producto:e.target.value.toUpperCase()})} 
                                >
                                    <option></option>
                                    {productos.map((valProducto, key) => (
                                        <option key={key}
                                            value={valProducto.id_producto}
                                            selected={prestamos.producto == valProducto.id_producto ? true : false}
                                        >
                                            {valProducto.nombre_producto}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>


                        {/* <div className="col-md-4">
                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">Producto</span>
                                </div>
                                <select className="custom-select" id="inputGroupSelect01">
                                    <option value="1">Pinza</option>
                                </select>
                            </div>
                        </div> */}
                        <div className="col-md-4">
                            <button type="button" className="btn btn-info">Agregar Prestamo</button>
                        </div>
                    </div>

                    <div className="row mt-2">
                        <div className="col-md-12">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">Producto</th>
                                        <th scope="col">Cantidad</th>
                                        <th scope="col">Nota</th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Martillo</td>
                                        <td style={customStyles.content}>
                                            <input type="number" name="last_name" className="form-control" />
                                        </td>
                                        <td>
                                            <input type="text" name="last_name" className="form-control" />
                                        </td>
                                        <td>
                                            <button type="button" className="btn btn-danger">x</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">Descripción</span>
                                </div>
                                <textarea className="form-control" aria-label="With textarea"></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-4 mb-4 d-flex justify-content-end align-items-end">
                        <div className="col-md-4  ">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">Introducir Pin</span>
                                </div>
                                <input type="text" name="last_name" className="form-control" />
                            </div>
                        </div>
                    </div>
                    <ButtonSave />
                </div>
            </div>
        </div>

        // <div className="tab-content">
        //     <form id="formulario-unidad">
        //     <div className="row mt-2">
        //         <div className="col-md-6">
        //             <div className="input-group mb-3">
        //                 <div className="input-group-prepend">
        //                     <span className="input-group-text">UnidadMedida:</span>
        //                 </div>
        //                 <input type="text"  
        //                     className="form-control text-uppercase" 
        //                     id="name_unidad" 
        //                     name="name_unidad" 
        //                     onChange={e => setUnidadMedida({...unidadMedida,name_unidad:e.target.value.toUpperCase()})} 
        //                  />
        //             </div>
        //         </div>
        //     </div>
        //     <div className="row mt-2">
        //         <div className="col-md-12">
        //             <ButtonSave  
        //             save={save}
        //             closeModal={props.closeModal}
        //             />
        //         </div> 
        //     </div>
        //     </form>
        // </div>
    );
}

export default Consumer(PrestamosAlmacen);