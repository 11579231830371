/**
 *  @name: private.jsx
 *  @version: 1.0.0
 *  @author: Anahi Aranda
 *  @description: Página privada
 */

import React, { useState, useEffect } from "react";
import { Consumer } from "../../context/index";
import moment from "moment";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import Layout from "../layout";
import Sidebar from "../../components/header/sidebar";
import Request from "../../utils/http";
import DownloadExcel from "../../components/sales/statement/downloadExcel";
import "moment/locale/es.js";
import "./Statement.css";
const request = new Request();

function Statement(props) {
  // ///////////////hooks//////////////////
  const [cotizations, setCotizations] = useState([]);
  const [user, setUser] = useState({
    id: 0,
    id_perfil: 0,
    name: "",
  });
  const [fInicio, asignFInicio] = useState(
    `${moment(new Date()).format("YYYY-MM-")}01`
  );
  const [fFinal, asignFFinal] = useState(
    `${moment(new Date()).format("YYYY-MM-")}${new Date(
      moment(new Date()).format("YYYY"),
      moment(new Date()).format("MM"),
      0
    ).getDate()}`
  );
  const [searchDate, setSDate] = useState({
    fInicio: `${moment(new Date()).format("YYYY-MM-")}01`,
    fFinal: `${moment(new Date()).format("YYYY-MM-")}${new Date(
      moment(new Date()).format("YYYY"),
      moment(new Date()).format("MM"),
      0
    ).getDate()}`,
  });
  const [loadRows, setLoad] = useState(true);
  const [loadType, setLoadType] = useState(true);
  const [loadSeller, setLoadSeller] = useState(true);
  const [loadStatus, setLoadStatus] = useState(true);

  /**
   * guardar resultados de las consultas
   */
  const [typeList, setTypeList] = useState([]);
  const [sellerList, setSellerList] = useState([]);
  const [statusList, setStatusList] = useState([]);

  /**
   * valores predeterminados de busqueda
   */
  //const [text, setText] = useState([]);
  const [type, setType] = useState([]);
  const [seller, setSeller] = useState([]);
  const [status, setStatus] = useState([]);

  useEffect(() => {
    loadUser();
    getTypes();
    getSellers();
    getStatus();
  }, []);

  // const downloadExcel = () => {
  //   let FS = JSON.parse(sessionStorage.getItem("FilterStatemet"));
  //   if (FS === null) {
  //     FS = JSON.parse(
  //       "[[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]]"
  //     );
  //   }
  //   let filter = JSON.stringify({
  //     idPerfil: user.id_perfil,
  //     idUser: user.id,
  //     fi: dateInicio.fInicio,
  //     ff: dateFinal.fFinal,
  //     vend: FS[0].length !== 0 ? FS[0] : [],
  //     status: FS[1].length !== 0 ? FS[1] : [],
  //     tipo: FS[9].length !== 0 ? FS[9] : [],
  //   })
  //     .normalize("NFD")
  //     .replace(/[\u0300-\u036f]/g, "");
  //   window.open(
  //     `http://decocancun.com/download/excel_cons.php?filter=${btoa(filter)}`,
  //     "_blank"
  //   );
  // };

  ////////////funciones///////////////////////

  const getData = async (inicio, fin, idUsuario, idPerfil) => {
    setLoad(true);
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }

    let data = {
      inicio: inicio,
      fin: fin,
      perfil: idPerfil,
      usuario: idUsuario,
      type: type,
      seller: seller,
      status: status,
      status_fac: [],
      hotel: [],
      token: token,
    };
    setSDate({ fInicio: inicio, fFinal: fin });
    const response = await request.post("/auth/reports/salesStatement", data);
    console.log(response);

    if (response.result) {
      if (response.result.movement) {
        setCotizations(response.result.movement);
        setLoad(false);
      } else {
        setCotizations([]);
        setLoad(false);
        console.log(response.result.message);
      }
    } else {
      setCotizations([]);
      setLoad(false);
      console.log(response);
    }
  };
  const loadUser = async () => {
    const user = await props.context.loadUser();

    if (user) {
      setUser(user);
    }
    getData(fInicio, fFinal, user.id, user.id_perfil);
  };
  const getTypes = async () => {
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }
    let data = {
      token: token,
    };

    const response = await request.post("/auth/sales/getType", data);

    if (response && !response.error) {
      if (response.result && !response.empty) {
        setTypeList(response.result.types);
        setLoadType(false);
        // response.result.types.forEach((element) => {
        //   typeDB.add(element);
        // });
      } else {
        // this.setState({
        //   empty: true,
        //   loading: false,
        //   message: response.message
        // });
      }
    } else {
      // this.setState({
      //   error: true,
      //   loading: false,
      //   message: response.message
      // });
    }
  };
  const getSellers = async () => {
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }
    let data = {
      token: token,
    };

    const response = await request.post("/auth/sales/getSellers", data);

    if (response && !response.error) {
      if (response.result && !response.empty) {
        setSellerList(response.result.vendedores);
        setLoadSeller(false);
        // response.result.vendedores.forEach((element) => {
        //   sellerDB.add(element);
        // });
      } else {
        // this.setState({
        //   empty: true,
        //   loading: false,
        //   message: response.message
        // });
      }
    } else {
      // this.setState({
      //   error: true,
      //   loading: false,
      //   message: response.message
      // });
    }
  };
  const getStatus = async () => {
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }
    let data = {
      token: token,
    };

    const response = await request.post("/auth/sales/getStatus", data);

    if (response && !response.error) {
      if (response.result && !response.empty) {
        setStatusList(response.result.type);
        setLoadStatus(false);
        // response.result.type.forEach((element) => {
        //   statusDB.add(element);
        // });
      } else {
        // this.setState({
        //   empty: true,
        //   loading: false,
        //   message: response.message
        // });
      }
    } else {
      // this.setState({
      //   error: true,
      //   loading: false,
      //   message: response.message
      // });
    }
  };
  const FormatNumber = (number) => {
    let val = new Intl.NumberFormat("ES-MX", {
      style: "currency",
      currency: "MXN",
      useGrouping: true,
    }).format(number);
    if (val.length === 8) {
      val = val.substring(0, 2) + "," + val.substring(2, 8);
    }
    return val;
  };
  const handleChangeFilter = async (event) => {
    let filter = JSON.parse(localStorage.getItem("statementFilter"));
    let state = [];
    if (!filter) {
      state = {
        type: type,
        seller: seller,
        status: status,
        inicio: fInicio,
        final: fFinal,
      };
    } else {
      state = {
        type: filter.type,
        seller: filter.seller,
        status: filter.status,
        inicio: filter.inicio,
        final: filter.final,
      };
    }
    switch (event.target.name) {
      case "type":
        state.type = event.target.value;
        setType(event.target.value);
        break;
      case "vendedor":
        state.seller = event.target.value;
        setSeller(event.target.value);
        break;
      case "status":
        state.status = event.target.value;
        setStatus(event.target.value);
        break;
      case "inicio":
        state.inicio = event.target.value;
        asignFInicio(event.target.value);
        break;
      case "final":
        state.final = event.target.value;
        asignFFinal(event.target.value);
        break;
      default:
        setType(state.type);
        setSeller(state.seller);
        setStatus(state.status);
        asignFInicio(state.inicio);
        asignFFinal(state.final);
        break;
    }

    window.localStorage.setItem("statementFilter", JSON.stringify(state));
  };
  const clearSearch = () => {
    let ini = `${moment(new Date()).format("YYYY-MM-")}01`;
    let fin = `${moment(new Date()).format("YYYY-MM-")}${new Date(
      moment(new Date()).format("YYYY"),
      moment(new Date()).format("MM"),
      0
    ).getDate()}`;

    setType([]);
    setSeller([]);
    setStatus([]);
    asignFInicio(ini);
    asignFFinal(fin);
    localStorage.removeItem("statementFilter");
    getData(ini, fin, user.id, user.id_perfil);
  };
  const formatUser = (str) => {
    let us = str.split(" ");
    return us[0];
  };
  ////////////////////return///////////////////////
  return (
    <Layout
      title={`Concentrado de ventas del ${moment(searchDate.fInicio).format(
        "DD-MMMM-YYYY"
      )} al ${moment(searchDate.fFinal).format("DD-MMMM-YYYY")}`}
      description="Página privada de perfil"
      keywords="Reactjs, SEO Ready, Ejemplo"
    >
      <Sidebar />

      <div className="content-wrapper" id="statement">
        <div className="card" style={{ margin: "1rem" }}>
          <div className="card-header">
            <div
              className="card-title row card-title row row-cols-2 row-cols-md-4 row-cols-lg-6"
              style={{ width: "100%" }}
            >
              <div className="col text-center">
                <TextField
                  id="fInicio"
                  name="inicio"
                  label="De"
                  type="date"
                  value={fInicio}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => handleChangeFilter(e)}
                />
              </div>
              <div className="col text-center">
                <TextField
                  id="fFinal"
                  name="final"
                  label="A"
                  type="date"
                  value={fFinal}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => handleChangeFilter(e)}
                />
              </div>
              <div className="col text-center">
                {loadType ? (
                  <i
                    className="fas fa-spinner fa-spin"
                    style={{ color: "#3f51b5" }}
                  ></i>
                ) : (
                  <FormControl>
                    <InputLabel htmlFor="type">Tipo</InputLabel>
                    <Select
                      multiple
                      value={type}
                      onChange={(e) => handleChangeFilter(e)}
                      input={<Input id="type" />}
                      renderValue={(selected) => selected.join(", ")}
                      name="type"
                    >
                      {typeList.map((info, key) => (
                        <MenuItem key={key} value={info.tipo_esp}>
                          <Checkbox
                            checked={type.indexOf(info.tipo_esp) > -1}
                          />
                          <ListItemText primary={info.tipo_esp} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </div>
              {user.id_perfil == 3 ? null : (
                <div className="col text-center">
                  {loadSeller ? (
                    <i
                      className="fas fa-spinner fa-spin"
                      style={{ color: "#3f51b5" }}
                    ></i>
                  ) : (
                    <FormControl>
                      <InputLabel htmlFor="vendedor">Vendedor</InputLabel>
                      <Select
                        multiple
                        value={seller}
                        onChange={(e) => handleChangeFilter(e)}
                        input={<Input id="vendedor" />}
                        renderValue={(selected) => selected.join(", ")}
                        name="vendedor"
                      >
                        {sellerList.map((info, key) => (
                          <MenuItem key={key} id="vend" value={info.nombre}>
                            <Checkbox
                              checked={seller.indexOf(info.nombre) > -1}
                            />
                            <ListItemText primary={info.nombre} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </div>
              )}
              <div className="col text-center">
                {loadStatus ? (
                  <i
                    className="fas fa-spinner fa-spin"
                    style={{ color: "#3f51b5" }}
                  ></i>
                ) : (
                  <FormControl>
                    <InputLabel htmlFor="status">Status</InputLabel>
                    <Select
                      multiple
                      value={status}
                      onChange={(e) => handleChangeFilter(e)}
                      input={<Input id="status" />}
                      renderValue={(selected) => selected.join(", ")}
                      name="status"
                    >
                      {statusList.map((info, key) => (
                        <MenuItem key={key} value={info.status}>
                          <Checkbox
                            checked={status.indexOf(info.status) > -1}
                          />
                          <ListItemText primary={info.status} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </div>
              <div className="col text-right">
                <button
                  className="btn btn-primary btn-sm button-filter"
                  onClick={() =>
                    getData(fInicio, fFinal, user.id, user.id_perfil)
                  }
                >
                  <i className="fas fa-search"></i>
                </button>
                &nbsp;
                <button
                  className="btn btn-secondary btn-sm button-filter"
                  onClick={() => clearSearch()}
                >
                  <i className="fas fa-times"></i>
                </button>
                &nbsp;
                {!loadRows ? (
                  <DownloadExcel
                    excelData={cotizations}
                    inicio={searchDate.fInicio}
                    final={searchDate.fFinal}
                  />
                ) : null}
              </div>
            </div>
          </div>
          <div className="card-body">
            {!loadRows ? (
              <div className="table-responsive">
                <table className="table table-bordered table-striped table-hover table-sm">
                  <thead className="table-secondary">
                    <tr>
                      <td scope="col">GRTE.</td>
                      <td scope="col">STS</td>
                      <td scope="col">MES</td>
                      <td scope="col">BODA/GRUPO</td>
                      <td scope="col">#SUB</td>
                      <td scope="col">LUGAR</td>
                      <td scope="col">PAX</td>
                      <td scope="col">TIPO</td>
                      <td scope="col">MONTO ARTÍCULOS</td>
                      <td scope="col">DESCUENTO</td>
                      <td scope="col">MONTO OPERACIÓN</td>
                      <td scope="col">IVA</td>
                      <td scope="col">FACTURA</td>
                      <td scope="col">$</td>
                      <td scope="col">FORMA PAGO</td>
                      <td scope="col">RECOMENDADO</td>
                      <td scope="col">% COM.</td>
                      <td scope="col">CONTACTO</td>
                      <td scope="col">STATUS FACTURA</td>
                      <td scope="col">No. FACTURA</td>
                      <td scope="col">FECHA FACTURA</td>
                      <td scope="col">MONTO FACTURA</td>
                      <td scope="col">$ FACT</td>
                      <td scope="col">%COM FACTURA</td>
                    </tr>
                  </thead>
                  <tbody>
                    {cotizations.map((row, key) => (
                      <tr key={key}>
                        <td
                          style={{
                            color: "black",
                            fontWeight: "bolder",
                            textShadow: "0px 0px 10px white",
                            backgroundColor: row.color_us,
                          }}
                        >
                          {formatUser(row.usuario)}
                        </td>
                        <td
                          style={{
                            color: "black",
                            fontWeight: "bolder",
                            textShadow: "0px 0px 10px white",
                            backgroundColor: row.color_st,
                          }}
                        >
                          {row.status.substr(0, 3)}
                        </td>
                        <td style={{ textTransform: "capitalize" }}>
                          {moment(row.Fecha_inicio).format("MMM")}
                        </td>
                        <td>
                          {row.nom_evento}
                          <br />
                          <i style={{ textTransform: "capitalize" }}>
                            {moment(row.Fecha_fin).diff(
                              moment(row.Fecha_inicio),
                              "years"
                            ) > 0
                              ? `${moment(row.Fecha_inicio).format(
                                  "DD-MMM-YYYY"
                                )} al ${moment(row.Fecha_fin).format(
                                  "DD-MMM-YYYY"
                                )}`
                              : moment(row.Fecha_fin).diff(
                                  moment(row.Fecha_inicio),
                                  "months"
                                ) > 0
                              ? `${moment(row.Fecha_inicio).format(
                                  "DD-MMM"
                                )} al ${moment(row.Fecha_fin).format(
                                  "DD-MMM-YYYY"
                                )}`
                              : moment(row.Fecha_fin).diff(
                                  moment(row.Fecha_inicio),
                                  "days"
                                ) > 0
                              ? `${moment(row.Fecha_inicio).format(
                                  "DD"
                                )} al ${moment(row.Fecha_fin).format(
                                  "DD-MMM-YYYY"
                                )}`
                              : moment(row.Fecha_inicio).format("DD-MMM-YYYY")}
                          </i>
                        </td>
                        <td>{row.Eventos}</td>
                        <td>{row.hotel}</td>
                        <td>{row.Paxes}</td>
                        <td>{row.tipo_esp}</td>
                        <td className={row.Moneda}>
                          {FormatNumber(row.importe)}
                        </td>
                        <td className={row.Moneda}>
                          {FormatNumber(row.total_descuento)}
                        </td>
                        <td className={row.Moneda}>
                          {FormatNumber(row.operacion)}
                        </td>
                        <td className={row.Moneda}>
                          {FormatNumber(row.total_iva)}
                        </td>
                        <td className={row.Moneda}>
                          {FormatNumber(row.total)}
                        </td>
                        <td className={row.Moneda}>{row.Moneda}</td>
                        <td>{row.Forma_pago}</td>
                        <td>
                          {row.Empresa.replace("@", " @")
                            .replace(".com", " .com")
                            .replace("mailto:", " ")}
                        </td>
                        <td>{row.total_comision}</td>
                        <td>
                          {row.Contacto_Hotel.replace("@", " @")
                            .replace(".com", " .com")
                            .replace("mailto:", " ")}
                        </td>
                        <td>{row.fs_nombre}</td>
                        <td>{row.num_factura}</td>
                        <td>{row.fecha_fac}</td>
                        <td className={row.fac_moneda}>
                          {FormatNumber(row.fac_monto)}
                        </td>
                        <td className={row.fac_moneda}>{row.fac_moneda}</td>
                        <td>{row.fac_comision}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="text-center pt-2">
                <i
                  className="fas fa-spinner fa-spin"
                  style={{ fontSize: "62px", color: "#3f51b5" }}
                ></i>
                <br /> Buscando Datos...
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Consumer(Statement);
