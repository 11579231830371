import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Request from "../../utils/http";
import TableProducts from "./tableProducts";

const request = new Request();

function OdsStep2(props, ref) {
  const [block, setBlock] = useState(false);
  // const [modalBodega, setModalBodega] = useState(false);
  // const [modalTemporal, setModalTemporal] = useState(false);
  const [artInfo, setArtInfo] = useState([]);
  const [loadArt, setLoadArt] = useState(false);
  const [cont, setCont] = useState([]);
  const [saveP, setSaveP] = useState([]);
  const [costo, setCosto] = useState(0);
  const [operacion, setOperacion] = useState("");
  // const [toast, setToast] = useState(false);
  // const BodegaDB = useIndexedDB("inventarioBodega");
  // const TemporalDB = useIndexedDB("inventarioTemporal");
  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    if (props.sub !== 0) {
      getEventArt(props.sub);
      let subs = [];
      for (let i = 0; i < props.sub; i++) {
        subs.push(React.createRef());
      }
      setSaveP(subs);
    }
  }, [props.sub]);
  useEffect(() => {
    if (cont.length === props.sub) {
      setBlock(false);
      props.setReload();
      setCont([]);
    }
  }, [cont]);
  const getEventArt = async (sub) => {
    console.log("OdsStep2 -> sub", sub);
    //setLoadArt(false);
    localStorage.removeItem("orderArt");
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }

    let data = {
      token: token,
      id_event: props.id,
      events: sub,
    };
    let subs = [];
    for (let i = 0; i < sub; i++) {
      subs.push([]);
    }

    const response = await request.post("/auth/calendar/getEventArt", data);
    console.log(
      "🚀 ~ file: odsStep2.jsx ~ line 66 ~ getEventArt ~ response",
      response
    );

    if (response && !response.error) {
      if (response.result && !response.empty) {
        setLoadArt(true);
        response.result.map((array, key) => {
          subs[key] ? (subs[key] = array) : subs.push(array);
        });
        // window.sessionStorage.setItem(
        //   "Productos",
        //   JSON.stringify(response.result)
        // );
      } else {
        setLoadArt(true);
        // setState({
        //   empty: true,
        //   loading: false,
        //   message: response.message
        // });
      }
    } else {
      setLoadArt(true);
      // setState({
      //   error: true,
      //   loading: false,
      //   message: response.message
      // });
    }
    setArtInfo(subs);
  };
  const deleteSub = (idSub) => {
    //setLoadArt(false);
    let newData = [];
    artInfo.map((data, key) => (key !== idSub ? newData.push(data) : null));
    newData.map((data, key) =>
      data.map((prod) => (
        <>
          {(prod.id_subevento = key + 1)}
          {(prod.id_subevento_ant = key + 1)}
        </>
      ))
    );
    props.deleteSub(newData.length);

    setArtInfo(newData);
    //setLoadArt(true);
  };
  const addSub = async () => {
    props.addSub(props.sub + 1);
    let newdata = artInfo;
    newdata.push([]);
    setArtInfo(newdata);
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }

    let data = {
      token: token,
      idEvent: props.id,
    };
    const response = await request.post("/auth/sales/addSubEvent", data);
    if (response && !response.error) {
      if (response.result && !response.empty) {
        toast.success(
          'Subevento "' + (props.sub + 1) + '" agregado con exito.',
          {
            position: toast.POSITION.TOP_RIGHT,
            theme: "colored",
          }
        );
      } else {
        toast.error(
          'ERROR: Subevento "' + (props.sub + 1) + '" no se pudo agregar.',
          {
            position: toast.POSITION.TOP_RIGHT,
            theme: "colored",
          }
        );
      }
    } else {
      toast.error(
        'ERROR: Subevento "' + (props.sub + 1) + '" no se pudo agregar.'
      );
    }
  };
  const save = async () => {
    setBlock(true);
    if (saveP.length !== 0) {
      for (let i = 0; i < saveP.length; i++) {
        saveP[i].current.click();
      }
    }
    saveCost();
  };
  const reload = () => {
    setLoadArt(false);
    getEventArt(props.sub);
  };
  const getData = async () => {
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }
    let data = {
      token: token,
      id_event: props.id,
    };
    const response = await request.post("/auth/sales/getCostos", data);
    /*/console.log(
      "🚀 ~ file: odsStep2.jsx ~ line 159 ~ getData ~ response",
      response
    );*/

    if (response && !response.error) {
      if (response.result.costos && !response.empty) {
        if (response.result.costos.length !== 0) {
          setCosto(response.result.costos[0].preciouni);
          setOperacion(response.result.costos[0].indicaciones);
        }
      } else {
        console.log(response.result.message);
      }
    } else {
      console.log("error en la peticion");
    }
  };
  const saveCost = async () => {
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }
    let data = {
      token: token,
      id_event: props.id,
      costo: costo,
      operaciones: operacion,
      idUser: props.idUser,
    };
    if (isNaN(costo) || costo === "" || costo === null) {
      document.getElementById("costo").focus();
      document.getElementById("costo").classList.add("is-invalid");
      toast.error(
        <span>
          !Por favor asegurate de
          poner el costo de operación!.
        </span>
      );
    } else {
      const response = await request.post("/auth/sales/saveCostos", data);
      if (response && !response.error) {
        if (response.result && !response.empty) {
          toast.success(
            <span>
              Datos de operación
              guardados con exito.
            </span>,
            {
              position: toast.POSITION.TOP_RIGHT,
              theme: "colored",
            }
          );
        }
      }
    }
  };
  const deleteArt = (num, art) => {
    //console.log("deleteArt -> num,art", num, art);
    let copia = artInfo.slice();
    copia[num] = art;
    setArtInfo(copia);
    props.setReload();
    //console.log("deleteArt -> artInfo", artInfo);
  };

  //const clean = () => {};
  return (
    <>
      <div
        className="table-responsive form-step2 p-0"
        style={{ textAlign: "center", zoom: "90%" }}
      >
        {loadArt ? (
          artInfo.map((datos, key) => (
            <TableProducts
              key={key}
              ref={saveP[key]}
              idEvent={props.id}
              products={datos}
              num={key}
              subs={props.sub}
              fechaI={props.fechaInicio}
              fechaF={props.fechaFin}
              fechaM={props.fechaMontaje}
              fechaD={props.fechaDesmontaje}
              idUbicacion={props.hotel}
              idUsuario={props.idUser}
              deleteSub={(e) => deleteSub(e)}
              addSub={(e) => addSub()}
              save={() => setCont((cont) => [...cont, 1])}
              deleteArt={(num, art) => deleteArt(num, art)}
            />
          ))
        ) : (
          <i className="fas fa-spinner fa-spin"></i>
        )}
        {loadArt && !block ? (
          <>
            <div className="white-space-16" />
            <div
              style={{
                border: "1px solid #dee2e6",
                padding: 10 + "px",
                backgroundColor: "rgb(242, 245, 247)",
                zoom:'85%',
                width:'98.5%'
              }}
            >
              <h5>Costos e indicaciones de Operación/Transportación</h5>
              <div className="white-space-16" />
              <div className="row">
                <div className="col-md-4">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">$</span>
                    </div>
                    <input
                      type="number"
                      className="form-control"
                      name="costo"
                      id="costo"
                      min="0"
                      value={costo}
                      onChange={(e) => {
                        setCosto(e.target.value);
                        document
                          .getElementById(e.target.name)
                          .classList.remove("is-invalid");
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-8">
                  {" "}
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        Comentarios
                        <br />
                        Operación
                      </span>
                    </div>
                    <textarea
                      name="textarea"
                      rows="2"
                      className="form-control"
                      value={operacion}
                      onChange={(e) => setOperacion(e.target.value)}
                    ></textarea>
                  </div>
                </div>
              </div>
              <div className="white-space-16" />
            </div>
          </>
        ) : null}
      </div>

      <div className="modal-footer justify-content-left p-0" style={{zoom:'80%'}}>
        <button
          type="button"
          onClick={(e) => {
            setLoadArt(false);
            getEventArt(props.sub);
          }}
          className="btn btn-outline-secondary btn-flat"
          disabled={!loadArt}
        >
          <i className="fas fa-sync"></i> Recargar
        </button>
        &nbsp;
        {loadArt ? (
          <>
            <button
              type="button"
              onClick={(e) => save()}
              className="btn btn-outline-success btn-flat "
              disabled={block}
            >
              {block ? (
                <i className="fas fa-spinner fa-spin"></i>
              ) : (
                <i className="far fa-save"></i>
              )}
              &nbsp; Guardar
            </button>
            &nbsp;
            <button
              type="button"
              onClick={(e) => props.closeModal(e)}
              className="btn btn-outline-danger btn-flat"
              disabled={block}
            >
              <i className="fas fa-ban"></i> Cerrar
            </button>
          </>
        ) : null}
      </div>
      <input ref={ref} type="hidden" name="reload" onClick={() => reload()} />
    </>
  );
}
export default React.forwardRef(OdsStep2);
