import React, { useState, useEffect } from "react";
import { Consumer } from "../../context/index";
import Modal from "react-modal";
import { toast } from "react-toastify";
import Request from "../../utils/http";
const request = new Request();
function ModalEdit(props) {
  const [sup, setSup] = useState({
    id_supervisor: 0,
    nombre: "",
    tipoSupervisor: "Otro",
    id_ubicacion: 0,
    activo: 1,
    tel: "",
  });
  const [load, setLoad] = useState(true);
  const [locationList, setLocationList] = useState([]);

  useEffect(() => {
    if (props.sup.id !== 0) getData(props.sup.id);
    else {
      setSup({
        id_supervisor: 0,
        nombre: '',
        id_ubicacion: 1,
        activo: 1,
        tel: '0000000000',
      });
      setLoad(false);
    }
  }, [props.sup.id]);
  useEffect(() => {
    setLocationList(props.location);
  }, [props.location]);

  const getData = async (id) => {
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }

    let data = {
      token: token,
      idSup: id,
    };

    const response = await request.post("/auth/operations/getSupervisor", data);
    console.log(response);
    if (response && !response.error) {
      if (response.result.sup && !response.empty) {
        setSup(response.result.sup[0]);
        setLoad(false);
      } else {
        alert(response.result.message);
      }
    } else {
      console.log("algo salio mal");
    }
  };
  const saveSup = async () => {
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }
    let data = {
      token: token,
      id_sup: sup.id_supervisor,
      nombre: sup.nombre,
      id_ubi: sup.id_ubicacion,
      activo: sup.activo,
      tel: sup.tel,
    };

    const response = await request.post(
      "/auth/operations/saveSupervisor",
      data
    );
    console.log(response);
    if (response && !response.error) {
      if (response.result && !response.empty) {
        props.reload({
          id: response.result[0].id_supervisor,
          nom: response.result[0].nombre,
        });
        toast.success(
          <span>
            Datos generales del
            artículo, guardados con exito.
          </span>
        );
      } else {
        toast.error(
          <span>
            ¡¡¡UPSS!!!, algo salio
            mal.. intentalo de nuevo, si el problema continua contacta a
            sistemas.
          </span>
        );
      }
    } else {
    }
  };
  return (
    <Modal isOpen={props.openModal} contentLabel="EditarSupervisor">
      <div className="modal-header">
        <h6 className="modal-title">
          Editar Supervisor <i>"{props.sup.id + "-" + props.sup.nom}"</i>
        </h6>
        <button
          type="button"
          className="close"
          aria-label="Close"
          onClick={() => props.setOpenModal({ id: 0, nom: "Nuevo Supervisor" })}
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
      {!load ? (
        <>
          <div className="modal-body card-primary card-outline card-outline-tabs">
            <div className="card-header border-bottom-0"></div>
            <div className="card-body">
              <div className="tab-content" id="custom-tabs-three-tabContent">
                <div className="white-space-16"></div>
                <div className="row">
                  <div className="col-12 col-md-2 mt-3">
                    <div
                      className="form-control custom-control custom-switch custom-switch-off-danger custom-switch-on-success"
                      style={{ textAlign: "center" }}
                    >
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="activo"
                        defaultChecked={sup.activo}
                        name="activo"
                        style={{ width: "100%", zIndex: 99 }}
                        onChange={(e) =>
                          setSup({ ...sup, activo: Number(e.target.checked) })
                        }
                      />
                      <label className="custom-control-label" htmlFor="activo">
                        {sup.activo === 1 ? "Activo" : "Inactivo"}
                      </label>
                    </div>
                  </div>
                  <div className="col-md-2 mt-3">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">ID:</span>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        aria-label=""
                        value={sup.id_supervisor}
                        readOnly
                      ></input>
                    </div>
                  </div>
                  <div className="col-md-8 mt-3">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">NOMBRE:</span>
                      </div>
                      <input
                        type="text"
                        name="nombre"
                        className="form-control"
                        aria-label=""
                        value={sup.nombre}
                        onChange={(e) =>
                          setSup({ ...sup, nombre: e.target.value })
                        }
                      ></input>
                    </div>
                  </div>
                  <div className="col-md-6 mt-3">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">UBICACIÓN:</span>
                      </div>
                      <select
                        name="id_ubicacion"
                        value={sup.id_ubicacion}
                        className="form-control"
                        id=""
                        onChange={(e) =>
                          setSup({ ...sup, id_ubicacion: e.target.value })
                        }
                      >
                        {locationList.map((ubi, key) => (
                          <option key={key} value={ubi.id_ubicacion}>
                            {ubi.lugar}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6 mt-3">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">TELÉFONO:</span>
                      </div>
                      <input
                        type="text"
                        name="tel"
                        className="form-control"
                        aria-label=""
                        maxLength={10}
                        value={sup.tel}
                        onChange={(e) =>
                          setSup({ ...sup, tel: e.target.value })
                        }
                      ></input>
                    </div>
                  </div>
                  <div className="white-space-16"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer justify-content-left ">
            <button
              type="button"
              className="btn btn-outline-success btn-flat "
              onClick={() => saveSup()}
            >
              <i className="far fa-save"></i>&nbsp; Guardar
            </button>
            <button
              type="button"
              className="btn btn-outline-danger btn-flat"
              onClick={() =>
                props.setOpenModal({ id: 0, nom: "Nuevo Supervisor" })
              }
            >
              <i className="fas fa-ban"></i> Cerrar
            </button>
          </div>
        </>
      ) : (
        <div className="modal-body card-primary card-outline card-outline-tabs text-center">
          <i
            className="fas fa-spinner fa-spin mt-3"
            style={{ fontSize: "62px", color: "#3f51b5" }}
          ></i>
          <br /> Buscando Datos...
        </div>
      )}
    </Modal>
  );
}

export default Consumer(ModalEdit);
