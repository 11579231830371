import React, {Component} from 'react';
import Menu from "../components/menu/menu";

class InicioPage extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return ( 
            <>
            <Menu></Menu>
            </>
         );
    }
}
 
export default InicioPage;
