import React, { useState, useEffect } from "react";
import { Consumer } from "../../context/index";
import { toast } from "react-toastify";
import Request from "../../utils/http";
import MUIDataTable from "mui-datatables";
import moment from "moment";
import AddDesign from "./addDesign";

const request = new Request();

function ModalPriceDesing(props) {
  const [user, setUser] = useState({
    idUsuario: 0,
    idPerfil: 0,
    nombre: "",
    depto: "",
    ubicacion: 1,
  });
  const [location, setLocation] = useState([]);
  const [infoHeader, setHeader] = useState({
    us_nombre: "",
    id_ubicacion: 1,
  });
  const [infoProd, setProducts] = useState([]);
  const [totales, setTotales] = useState({
    subtotal: 0,
    iva: 0,
    total: 0,
  });

  useEffect(() => {
    loadUser();
    getHeader();
    getLocations();
  }, [props.idEvent]);

  const loadUser = async () => {
    const user = await props.context.loadUser();

    if (user) {
      setUser(user);
      let info = infoHeader;
      info.us_nombre = user.name;
      info.depto = user.depto;
    }
  };
  const obtenerToken = () => {
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }
    return token;
  };
  const getHeader = async () => {
    let token = obtenerToken();
    let data = {
      token: token,
      id: props.idEvent,
    };
    const response = await request.post("/auth/design/getHeader", data);

    if (response && !response.error) {
      if (response.result && !response.empty) {
        let info = response.result[0];
        console.log("info :>> ", info);
        info.fecha = info.fecha ? info.fecha : moment().format("YYYY-MM-DD");
        info.fecha_produccion = info.fecha_produccion
          ? info.fecha_produccion
          : moment().format("YYYY-MM-DD");
        info.fecha_instalacion = info.fecha_instalacion
          ? info.fecha_instalacion
          : moment().format("YYYY-MM-DD");
        info.id_ubicacion = info.id_ubicacion
          ? info.id_ubicacion
          : info.id_ubicacion_h;
        setHeader(info);
        getProducts();
      } else {
        //console.log(response.result);
      }
    } else {
      //console.log(response.result);
    }
  };
  const getProducts = async () => {
    let token = obtenerToken();
    let data = {
      token: token,
      id: props.idEvent,
    };
    const response = await request.post("/auth/design/getPriceDesign", data);
    console.log("getProd->response :>> ", response);
    if (response && !response.error) {
      if (response.result && !response.empty) {
        changeTotales(response.result);
        setProducts(response.result);
      } else {
        //console.log(response.result.message);
      }
    } else {
      //console.log(response.result);
    }
  };
  const getLocations = async () => {
    let token = obtenerToken();
    let data = {
      token: token,
    };

    const response = await request.post("/auth/sales/getubicaciones", data);

    if (response && !response.error) {
      if (response.result && !response.empty) {
        setLocation(response.result.ubicaciones);
      } else {
        // this.setState({
        //   empty: true,
        //   loading: false,
        //   message: response.message
        // });
      }
    } else {
      // this.setState({
      //   error: true,
      //   loading: false,
      //   message: response.message
      // });
    }
  };
  const addRow = (e) => {
    console.log(e);
    let m = e[3] * e[4];

    if (m < 1) m = 1;

    let c = 0;
    if (
      e[5].includes("(Pz)") ||
      e[5].includes("(Min)") ||
      e[5].includes("(Hr)")
    )
      c = 1 * e[7];
    else c = m * 1 * e[7];

    let newP = {
      id_lista: 0,
      id_evento: props.idEvent,
      archivo: "",
      id_trabajo: e[0],
      codigo: e[1],
      t_trabajo: e[2],
      ancho: e[3],
      alto: e[4],
      medida: m,
      unidad: e[5],
      cantidad: 1,
      costo_uni: e[7],
      costo: c,
    };
    setProducts([...infoProd, newP]);
    changeTotales(infoProd);
    toast.success(
      <span>
        
        {newP.codigo + "-" + newP.t_trabajo} agregado con exito.
      </span>,
      {
        position: toast.POSITION.TOP_RIGHT,
        theme: "colored",
      }
    );
  };
  const deleteRow = async (indice) => {
    let copia = infoProd.slice();
    let borrar = window.confirm(
      `Estas seguro de eliminar el archivo: "${copia[indice].archivo}"  de la cotización. No podra deshacer esta acción.`
    );
    if (borrar) {
      if (copia[indice].id_lista === 0) {
        copia.splice(indice, 1);
        setProducts(copia);
      } else {
        let token = obtenerToken();
        let data = {
          token: token,
          id_lista: copia[indice].id_lista,
        };

        const response = await request.post(
          "/auth/design/deleteDesignProd",
          data
        );
        console.log("deleteRow->response :>> ", response);
        if (response && !response.error) {
          if (response.result && !response.empty) {
            copia.splice(indice, 1);
            setProducts(copia);
          }
        }
      }
    }
  };
  const changeData = (posicion, name, valor) => {
    let v = 0;
    let m = 0;
    switch (name) {
      case "ancho":
        v = parseFloat(valor);
        m = v * infoProd[posicion]["alto"];
        if (m < 1) m = 1;
        infoProd[posicion]["medida"] = m;
        infoProd[posicion][name] = v;

        if (
          !infoProd[posicion]["unidad"].includes("(Pz)") ||
          !infoProd[posicion]["unidad"].includes("(Min)") ||
          !infoProd[posicion]["unidad"].includes("(Hr)")
        )
          infoProd[posicion]["costo"] =
            m *
            infoProd[posicion]["cantidad"] *
            infoProd[posicion]["costo_uni"];
        changeTotales(infoProd);
        break;
      case "alto":
        v = parseFloat(valor);
        m = v * infoProd[posicion]["ancho"];
        if (m < 1) m = 1;
        infoProd[posicion]["medida"] = m;
        infoProd[posicion][name] = v;

        if (
          !infoProd[posicion]["unidad"].includes("(Pz)") ||
          !infoProd[posicion]["unidad"].includes("(Min)") ||
          !infoProd[posicion]["unidad"].includes("(Hr)")
        )
          infoProd[posicion]["costo"] =
            m *
            infoProd[posicion]["cantidad"] *
            infoProd[posicion]["costo_uni"];
        changeTotales(infoProd);
        break;
      case "cantidad":
        v = parseFloat(valor);
        if (
          !infoProd[posicion]["unidad"].includes("(Pz)") ||
          !infoProd[posicion]["unidad"].includes("(Min)") ||
          !infoProd[posicion]["unidad"].includes("(Hr)")
        )
          m =
            infoProd[posicion]["medida"] * v * infoProd[posicion]["costo_uni"];
        else m = v * infoProd[posicion]["costo_uni"];
        infoProd[posicion]["costo"] = m;
        infoProd[posicion][name] = v;
        changeTotales(infoProd);
        break;
      case "costo_uni":
        v = parseFloat(valor);
        if (
          !infoProd[posicion]["unidad"].includes("(Pz)") ||
          !infoProd[posicion]["unidad"].includes("(Min)") ||
          !infoProd[posicion]["unidad"].includes("(Hr)")
        )
          m = infoProd[posicion]["medida"] * infoProd[posicion]["cantidad"] * v;
        else m = infoProd[posicion]["cantidad"] * v;
        infoProd[posicion]["costo"] = m;
        infoProd[posicion][name] = v;
        changeTotales(infoProd);
        break;
      /*case "costo":
        infoProd[posicion][name] = parseFloat(valor);
        break;*/
      default:
        infoProd[posicion][name] = valor;
        break;
    }
    /*if (
      !infoProd[posicion]["unidad"].includes("(Pz)") ||
      !infoProd[posicion]["unidad"].includes("(Min)") ||
      !infoProd[posicion]["unidad"].includes("(Hr)")
    )
      infoProd[posicion]["costo"] =
        infoProd[posicion]["cantidad"] * infoProd[posicion]["costo_uni"];
    else
      infoProd[posicion]["costo"] =
        m * infoProd[posicion]["cantidad"] * infoProd[posicion]["costo_uni"];
*/
    setProducts([...infoProd]);
  };
  const changeTotales = (prod) => {
    let costo = 0;
    for (let i = 0; i < prod.length; i++) {
      costo = costo + prod[i].costo;
    }
    let iva = costo * 0.16;
    let total = costo + iva;

    setTotales({
      subtotal: costo,
      iva: iva,
      total: total,
    });
  };
  const saveHeader = async () => {
    let token = obtenerToken();
    let data = {
      token: token,
      user: user,
      info: infoHeader,
    };
    // alert('guardar encabezado')
    const response = await request.post("/auth/design/saveHeader", data);
    console.log("saveHeader -> response", response);

    if (response && !response.error) {
      if (response.result && !response.empty) {
        saveProds();
        toast.success("Datos del encabezado", {
          position: toast.POSITION.TOP_RIGHT,
          theme: "colored",
          theme: "colored",
        });
      } else {
        console.log(response.result);

        toast.error(
          "UPSS!!!, algo salio mal. Intentalo de nuevo, si el problema continua contacta a sistemas.",
          {
            position: toast.POSITION.TOP_RIGHT,
            theme: "colored",
            theme: "colored",
          }
        );
      }
    } else {
      console.log(response.result);

      toast.error(
        "UPSS!!!, algo salio mal. Intentalo de nuevo, si el problema continua contacta a sistemas.",
        {
          position: toast.POSITION.TOP_RIGHT,
          theme: "colored",
          theme: "colored",
        }
      );
    }
  };
  const saveProds = async (id) => {
    let copia = infoProd.slice();
    if (copia.length != 0) {
      for (let i = 0; i < copia.length; i++) {}

      let token = obtenerToken();
      let data = {
        token: token,
        info: copia,
      };
      // alert('guardar encabezado')
      const response = await request.post("/auth/design/saveWorksProd", data);
      console.log("saveProds -> response", response);

      if (response && !response.error) {
        if (response.result && !response.empty) {
          toast.success("Trabajos guardados con exito!", {
            position: toast.POSITION.TOP_RIGHT,
            theme: "colored",
            theme: "colored",
          });
          getProducts();
        } else {
          console.log(response.result);

          toast.error(
            "UPSS!!!, algo salio mal. Intentalo de nuevo, si el problema continua contacta a sistemas.",
            {
              position: toast.POSITION.TOP_RIGHT,
              theme: "colored",
              theme: "colored",
            }
          );
        }
      } else {
        console.log(response.result);

        toast.error(
          "UPSS!!!, algo salio mal. Intentalo de nuevo, si el problema continua contacta a sistemas.",
          {
            position: toast.POSITION.TOP_RIGHT,
            theme: "colored",
            theme: "colored",
          }
        );
      }
    }
  };
  const columns = [
    {
      name: "id_lista",
      label: "ACCIÓN",
      options: {
        filter: false,
        sort: false,
        searchable: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <button
              className="btn btn-danger bg-gradient-danger"
              onClick={(e) => deleteRow(tableMeta.rowIndex)}
            >
              <i className="fas fa-trash-alt"></i>
            </button>
          );
        },
      },
    },
    {
      name: "archivo",
      label: "ARCHIVO",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <input
              type="text"
              name="archivo"
              className="form-control"
              style={{ minWidth: "15em" }}
              value={value}
              onChange={(e) =>
                changeData(tableMeta.rowIndex, e.target.name, e.target.value)
              }
            />
          );
        },
      },
    },
    {
      name: "t_trabajo",
      label: "TIPO DE TRABAJO",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return tableMeta.tableData[tableMeta.rowIndex].codigo + " - " + value;
        },
      },
    },
    {
      name: "ancho",
      label: "ANCHO (Mts.)",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <input
              className="form-control"
              type="number"
              name="ancho"
              step="any"
              min={1}
              defaultValue={value}
              onChange={(e) =>
                changeData(tableMeta.rowIndex, e.target.name, e.target.value)
              }
            />
          );
        },
      },
    },
    {
      name: "alto",
      label: "ALTO (Mts.)",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <input
              className="form-control"
              type="number"
              name="alto"
              step="any"
              min={1}
              defaultValue={value}
              onChange={(e) =>
                changeData(tableMeta.rowIndex, e.target.name, e.target.value)
              }
            />
          );
        },
      },
    },
    {
      name: "medida",
      label: "MEDIDA TOTAL (Mts.)",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "unidad",
      label: "UNIDAD",
      options: {
        filter: false,
        sort: false,
        searchable: false,
      },
    },
    {
      name: "cantidad",
      label: "CANTIDAD",
      options: {
        filter: false,
        sort: false,
        searchable: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <input
              className="form-control"
              type="number"
              name="cantidad"
              step="any"
              min={1}
              defaultValue={value}
              onChange={(e) =>
                changeData(tableMeta.rowIndex, e.target.name, e.target.value)
              }
            />
          );
        },
      },
    },
    {
      name: "costo_uni",
      label: "COSTO DE IMPRESIÓN O CORTE",
      options: {
        filter: false,
        sort: false,
        searchable: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <input
              className="form-control"
              type="number"
              name="costo_uni"
              step="any"
              min={1}
              defaultValue={value}
              onChange={(e) =>
                changeData(tableMeta.rowIndex, e.target.name, e.target.value)
              }
            />
          );
        },
      },
    },
    {
      name: "costo",
      label: "COSTO",
      options: {
        filter: false,
        sort: false,
        searchable: false,
        /*customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <input
              className="form-control"
              type="number"
              name="costo"
              step="any"
              min={1}
              defaultValue={value}
              onChange={(e) =>
                changeData(tableMeta.rowIndex, e.target.name, e.target.value)
              }
            />
          );
        },*/
      },
    },
  ];

  const options = {
    selectableRows: "none",
    responsive: "standard",
    pagination: false,
    search: false,
    print: false,
    download: false,
    filter: false,
    viewColumns: false,
    textLabels: {
      body: {
        noMatch: "No se encontraron resultados.",
        toolTip: "Sort",
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Número de filas:",
        displayRows: "de",
      },
      toolbar: {
        search: "Buscar",
        downloadCsv: "Descargar Excel",
        print: "Imprimir",
      },
      filter: {
        all: "TODOS",
        title: "FILTROS",
        reset: "LIMPIAR",
      },
      viewColumns: {
        title: "Mostar Columnas",
        titleAria: "Mostrar/Ocultar Columnas de la Tabla",
      },
      selectedRows: {
        text: "Fila(s) Seleccionadas",
        delete: "Eliminar",
        deleteAria: "Eliminar Filar Seleccionadas",
      },
    },
    customToolbar: () => {
      return (
        <AddDesign id_ubi={infoHeader.id_ubicacion} addRow={(e) => addRow(e)} />
      );
    },
  };

  const FormatNumber = (number) => {
    let val = new Intl.NumberFormat("ES-MX", {
      style: "currency",
      currency: "MXN",
      useGrouping: true,
    }).format(number);
    if (val.length === 8) {
      val = val.substring(0, 2) + "," + val.substring(2, 8);
    }
    return val;
  };
  return (
    <div
      className="tab-content"
      id="custom-tabs-three-tabContent"
      style={{ zoom: "95%" }}
    >
      <div className="row">
        <div className="col-md-12">
          <h5>Datos del Cliente</h5>
        </div>
        <div className="col-md-3">
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text text-sm">ID:</span>
            </div>
            <input
              type="text"
              name="idReq"
              className="form-control"
              aria-label=""
              value={props.idEvent}
              disabled={true}
            />
          </div>
        </div>
        <div className="col-md-3">
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text text-sm">FECHA SOLICITUD:</span>
            </div>
            <input
              type="date"
              name="fSolicitud"
              className="form-control"
              aria-label=""
              value={infoHeader.fecha}
              disabled={true}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text text-sm">ELABORADO POR:</span>
            </div>
            <input
              type="text"
              name="solicita"
              className="form-control"
              aria-label=""
              value={infoHeader.creado ? infoHeader.creado : user.name}
              disabled={true}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text text-sm">CAMPAÑA:</span>
            </div>
            <input
              type="text"
              name="idReq"
              className="form-control"
              aria-label=""
              value={infoHeader.campana}
              disabled={true}
            />
          </div>
        </div>
        <div className="col-md-3">
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text text-sm">VENDEDOR:</span>
            </div>
            <input
              type="text"
              name="depto"
              className="form-control"
              aria-label=""
              value={infoHeader.vendedor}
              disabled={true}
            ></input>
          </div>
        </div>
        <div className="col-md-3">
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text text-sm">LUGAR:</span>
            </div>
            <select
              className="form-control"
              name="location"
              disabled={!props.eddit}
              value={infoHeader.id_ubicacion}
              onChange={(e) =>
                setHeader({
                  ...infoHeader,
                  id_ubicacion: parseInt(e.target.value),
                })
              }
            >
              {location.map((lugar, key) => (
                <option key={key} value={lugar.id_ubicacion}>
                  {lugar.lugar}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="col-md-3">
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text text-sm">
                FECHA DE PRODUCCIÓN:
              </span>
            </div>
            <input
              type="date"
              name="fProd"
              className="form-control"
              aria-label=""
              disabled={!props.eddit}
              value={moment(infoHeader.fecha_produccion).format("YYYY-MM-DD")}
              onChange={(e) =>
                setHeader({ ...infoHeader, fecha_produccion: e.target.value })
              }
            />
          </div>
        </div>
        <div className="col-md-3">
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text text-sm">
                FECHA DE INSTALACIÓN:
              </span>
            </div>
            <input
              type="date"
              name="fInst"
              className="form-control"
              aria-label=""
              disabled={!props.eddit}
              value={moment(infoHeader.fecha_instalacion).format("YYYY-MM-DD")}
              onChange={(e) =>
                setHeader({ ...infoHeader, fecha_instalacion: e.target.value })
              }
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text text-sm">OBSERVACIONES:</span>
            </div>
            <textarea
              name="obs"
              className="form-control"
              disabled={!props.eddit}
              defaultValue={infoHeader.obs}
              onChange={(e) =>
                setHeader({ ...infoHeader, obs: e.target.value })
              }
            ></textarea>
          </div>
        </div>
        <div className="col-md-4">
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text text-sm">
                OBSERVACIONES PREPRENSA:
              </span>
            </div>
            <textarea
              name="obs_preprensa"
              className="form-control"
              disabled={!props.eddit}
              defaultValue={infoHeader.obs_preprensa}
              onChange={(e) =>
                setHeader({ ...infoHeader, obs_preprensa: e.target.value })
              }
            ></textarea>
          </div>
        </div>
        <div className="col-md-4">
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text text-sm">
                OBSERVACIONES IMPRESIÓN:
              </span>
            </div>
            <textarea
              name="obs_impresion"
              className="form-control"
              disabled={!props.eddit}
              defaultValue={infoHeader.obs_impresion}
              onChange={(e) =>
                setHeader({ ...infoHeader, obs_impresion: e.target.value })
              }
            ></textarea>
          </div>
        </div>
        <div className="col-md-4">
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text text-sm">
                OBSERVACIONES ACABADO:
              </span>
            </div>
            <textarea
              name="obs_acabado"
              className="form-control"
              disabled={!props.eddit}
              defaultValue={infoHeader.obs_acabado}
              onChange={(e) =>
                setHeader({ ...infoHeader, obs_acabado: e.target.value })
              }
            ></textarea>
          </div>
        </div>
      </div>

      {!props.eddit ? (
        <div className="row">
          <div className="col-md-12">
            <div className="table-responsive">
              <table className="table table-bordered table-striped table-hover table-sm">
                <thead className="table-secondary">
                  <tr>
                    <th scope="col">ARCHIVO</th>
                    <th scope="col">TIPO DE TRABAJO</th>
                    <th scope="col">ANCHO (MTS.)</th>
                    <th scope="col">ALTO (MTS.)</th>
                    <th scope="col">MEDIDA TOTAL (Mts.)</th>
                    <th scope="col">UNIDAD</th>
                    <th scope="col">CANTIDAD</th>
                    <th scope="col">COSTO DE IMPRESIÓN O CORTE</th>
                    <th scope="col">COSTO</th>
                  </tr>
                </thead>
                <tbody>
                  {infoProd.map((prod, key) => (
                    <tr key={key}>
                      <td role="cell" scope="row">
                        {prod.archivo}
                      </td>
                      <td role="cell" scope="row">
                        {prod.codigo + " - " + prod.t_trabajo}
                      </td>
                      <td role="cell" scope="row">
                        {prod.ancho}
                      </td>
                      <td role="cell" scope="row">
                        {prod.alto}
                      </td>
                      <td role="cell" scope="row">
                        {prod.medida}
                      </td>
                      <td role="cell" scope="row">
                        {prod.unidad}
                      </td>
                      <td role="cell" scope="row">
                        {prod.cantidad}
                      </td>
                      <td role="cell" scope="row">
                        {FormatNumber(prod.costo_uni)}
                      </td>
                      <td role="cell" scope="row">
                        {FormatNumber(prod.costo)}
                      </td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <th className="text-right" colSpan="8">
                      COSTO PRODUCCIÓN
                    </th>
                    <td>{FormatNumber(totales.subtotal)}</td>
                  </tr>
                  <tr>
                    <th className="text-right" colSpan="8">
                      IVA 16%
                    </th>
                    <td>{FormatNumber(totales.iva)}</td>
                  </tr>
                  <tr>
                    <th className="text-right" colSpan="8">
                      TOTAL
                    </th>
                    <td>{FormatNumber(totales.total)}</td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col-md-12">
            <MUIDataTable
              title={"Datos de Archivos"}
              data={infoProd}
              columns={columns}
              options={options}
            />
            <div className="row mt-3">
              <div className="col-md-9"></div>
              <div className="col-md-3">
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text text-sm">
                      Costo Producción: $
                    </span>
                  </div>
                  <input
                    type="text"
                    name="costo"
                    className="form-control text-right"
                    aria-label=""
                    value={FormatNumber(totales.subtotal)}
                    readOnly
                  />
                </div>
              </div>
              <div className="col-md-9"></div>
              <div className="col-md-3">
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text text-sm">IVA 16%:</span>
                  </div>
                  <input
                    type="text"
                    name="iva"
                    className="form-control text-right"
                    aria-label=""
                    value={FormatNumber(totales.iva)}
                    readOnly
                  />
                </div>
              </div>
              <div className="col-md-9"></div>
              <div className="col-md-3">
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text text-sm">
                      Precio de venta: $
                    </span>
                  </div>
                  <input
                    type="text"
                    name="precio"
                    className="form-control text-right"
                    aria-label=""
                    value={FormatNumber(totales.total)}
                    readOnly
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="modal-footer justify-content-left ">
        {!props.eddit ? null : (
          <button
            type="button"
            className="btn btn-outline-success btn-flat"
            onClick={(e) => saveHeader()}
          >
            <i className="far fa-save"></i>&nbsp; Guardar
          </button>
        )}
      </div>
    </div>
  );
}
export default Consumer(ModalPriceDesing);
