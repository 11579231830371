import React, { Component } from "react";
import Request from "../../../utils/http";
import { Consumer } from "../../../context/index";
import { toast } from "react-toastify";
import "./tabs.css";

const request = new Request();
class GeneralData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      block: false,
      eddit: this.props.eddit,
      idArt: this.props.id,
      load: false,
      loading: false,
      articleData: {
        id_art: 0,
        activo: 1,
        tags: "",
        precio_venta: 0,
        dias_mto: 0.5,
        id_coleccion: 0,
      },
      listUnit: [],
      listArea: [],
      listCollection: [],
      cantAnt: 0,
      user: {
        id: 0,
        id_perfil: 0,
        name: "",
      },
      labelInput: "Cambiar foto",
      formData: [],
    };
    this.refPhoto = React.createRef();
  }
  reloadImg() {
    this.getArticleInfo();
  }
  reload(data) {
    this.props.reload(data);
  }
  render() {
    return this.state.loading ? (
      <div className="card-body">
        <div className="form-group">
          <form method="post" id="uploadForm" encType="multipart/form-data">
            <div className="row">
              <div className="col-12 col-md-8 ">
                <div className="row">
                  <div className="col-12 col-md-4">
                    <div
                      className="form-control custom-control custom-switch custom-switch-off-danger custom-switch-on-success"
                      style={{ textAlign: "center" }}
                    >
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="activo"
                        defaultChecked={this.state.articleData.activo}
                        name="activo"
                        onChange={this.handleChange.bind(this)}
                        style={{ width: "100%", zIndex: 99 }}
                      />
                      <label className="custom-control-label" htmlFor="activo">
                        {this.state.articleData.activo == 1
                          ? "Activo"
                          : "Inactivo"}
                      </label>
                    </div>
                  </div>
                  <div className="col-12 col-md-8">
                    <div className="input-group mb-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text">Foto</span>
                      </div>
                      <div className="custom-file">
                        <input
                          type="file"
                          name="uploadFile"
                          className="custom-file-input"
                          id="uploadFile"
                          accept="image/jpg,image/jpeg,image/png"
                          onChange={this.handleChange.bind(this)}
                        />
                        <label
                          className="custom-file-label"
                          htmlFor="uploadFile"
                        >
                          {this.state.labelInput}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="input-group mb-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text">Nombre</span>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Español"
                        defaultValue={this.state.articleData.nomE}
                        name="nomE"
                        id="nomE"
                        onChange={this.handleChange.bind(this)}
                      />

                      <input
                        type="text"
                        className="form-control"
                        placeholder="Ingles"
                        defaultValue={this.state.articleData.nomI}
                        name="nomI"
                        id="nomI"
                        onChange={this.handleChange.bind(this)}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-lg-6">
                    <div className="input-group mb-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text">Almacén</span>
                      </div>
                      <select
                        className="form-control select2"
                        value={this.state.articleData.id_area}
                        name="id_area"
                        id="id_area"
                        onChange={this.handleChange.bind(this)}
                        required
                      >
                        <option></option>
                        {this.state.listArea.map((listArea, key) => (
                          <option value={listArea.id_area} key={key}>
                            {listArea.descrip}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-12 col-lg-6">
                    <div className="input-group mb-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text">Colección</span>
                      </div>
                      <select
                        className="form-control select2"
                        value={this.state.articleData.id_coleccion}
                        name="id_coleccion"
                        id="id_coleccion"
                        onChange={this.handleChange.bind(this)}
                      >
                        <option value="0">No es de colección</option>
                        {this.state.listCollection.map(
                          (listCollection, key) => (
                            <option
                              value={listCollection.id_coleccion}
                              key={key}
                            >
                              {listCollection.nombre}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                  </div>
                  <div className="col-12 col-lg-6">
                    <div className="input-group mb-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text">Material</span>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Madera, Plastico, Tipo de Tela, etc..."
                        defaultValue={this.state.articleData.caract}
                        name="caract"
                        id="caract"
                        onChange={this.handleChange.bind(this)}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-lg-6">
                    <div className="input-group mb-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text">Dimensión</span>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Ancho X Alto X Profundidad / Diametro"
                        defaultValue={this.state.articleData.dim}
                        name="dim"
                        id="dim"
                        onChange={this.handleChange.bind(this)}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="input-group mb-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text">Cantidad total</span>
                      </div>
                      <input
                        type="number"
                        min="1"
                        className="form-control"
                        placeholder="1"
                        defaultValue={this.state.articleData.stock_virtual}
                        name="stock_virtual"
                        id="stock_virtual"
                        onChange={this.handleChange.bind(this)}
                      />
                    </div>
                  </div>

                  <div className="col-12 col-md-6">
                    <div className="input-group mb-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text">Unidad:</span>
                      </div>
                      <select
                        className="form-control select2"
                        value={this.state.articleData.id_uni}
                        name="id_uni"
                        id="id_uni"
                        onChange={this.handleChange.bind(this)}
                      >
                        <option></option>
                        {this.state.listUnit.map((listUnit, key) => (
                          <option value={listUnit.id} key={key}>
                            {listUnit.descripcion}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div
                    className="col-12 col-lg-6"
                    title="Días de mantenimiento aproximados."
                  >
                    <div className="input-group mb-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text">Días de manto:</span>
                      </div>
                      <input
                        type="number"
                        step="0.5"
                        min="0.5"
                        className="form-control"
                        placeholder="0.5"
                        defaultValue={this.state.articleData.dias_mto}
                        name="dias_mto"
                        id="dias_mto"
                        onChange={this.handleChange.bind(this)}
                      />
                    </div>
                  </div>
                  <div
                    className="col-12 col-lg-6"
                    title="Precio minimo para su renta."
                  >
                    <div className="input-group mb-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          Precio min renta:$
                        </span>
                      </div>
                      <input
                        type="number"
                        step="any"
                        min="0"
                        className="form-control"
                        placeholder="0"
                        defaultValue={this.state.articleData.precio_venta}
                        name="precio_venta"
                        id="precio_venta"
                        onChange={this.handleChange.bind(this)}
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="input-group mb-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text">Tags:</span>
                      </div>
                      <textarea
                        className="form-control"
                        defaultValue={this.state.articleData.tags}
                        name="tags"
                        id="tags"
                        onChange={this.handleChange.bind(this)}
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4 ">
                <div className="card">
                  <img
                    className="card-img-top"
                    src={
                      this.state.articleData.img_char === "http"
                        ? this.state.articleData.img_pred
                        : `${request.publicURL()}/inventario/${
                            this.state.articleData.img_pred
                          }`
                    }
                    alt="Foto Artículo"
                    onError={(e) => {
                      e.target.src = "./assets/uploads/no-image.jpg";
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="white-space-32"></div>
            {this.state.user.id_perfil === 1 ||
            this.state.user.id_perfil === 2 ? (
              <div className="modal-footer justify-content-left pt-0 pb-0">
                <button
                  ref="uploadButton"
                  type="submit"
                  onClick={this.handleSubmit.bind(this)}
                  id="uploadPhoto"
                  className="btn btn-outline-success btn-flat "
                  //readOnly={this.props.block}
                  disabled={this.props.load}
                >
                  {this.props.load ? (
                    <i className="fas fa-spinner fa-spin"></i>
                  ) : (
                    <i className="far fa-save"></i>
                  )}
                  &nbsp; Guardar
                </button>

                <button
                  type="button"
                  onClick={this.props.closeModal}
                  className="btn btn-outline-danger btn-flat"
                  //  readOnly={this.props.block}
                  disabled={this.state.load}
                >
                  <i className="fas fa-ban"></i> Cerrar
                </button>
              </div>
            ) : null}
          </form>
        </div>
      </div>
    ) : (
      <div className="text-center">
        <i
          className="fas fa-spinner fa-spin"
          style={{ fontSize: "62px", color: "#3f51b5" }}
        ></i>
        <br /> Cargando Datos del Artículo...
      </div>
    );
  }
  componentDidMount() {
    this.getArticleUnit();
    this.getArticleArea();
    this.getArticleCollection();
    this.load();
    if (this.state.eddit) {
      this.getArticleInfo();
    } else {
      this.setState({ loading: true });
    }
  }
  async load() {
    const user = await this.props.context.loadUser();

    if (user) {
      this.setState({
        user: user,
      });
    }
  }
  save() {
    let error = false;
    if (document.getElementById("id_area").value <= 0) {
      error = true;
      document.getElementById("id_area").classList.add("is-invalid");
    }
    if (
      document.getElementById("nomE").value === "" ||
      document.getElementById("nomE").value == null ||
      document.getElementById("nomE").value == 0
    ) {
      error = true;
      document.getElementById("nomE").classList.add("is-invalid");
    }
    if (
      document.getElementById("nomI").value === "" ||
      document.getElementById("nomI").value == null ||
      document.getElementById("nomI").value == 0
    ) {
      error = true;
      document.getElementById("nomI").classList.add("is-invalid");
    }
    if (
      document.getElementById("caract").value === "" ||
      document.getElementById("caract").value == null ||
      document.getElementById("caract").value == 0
    ) {
      error = true;
      document.getElementById("caract").classList.add("is-invalid");
    }
    if (
      document.getElementById("dim").value === "" ||
      document.getElementById("dim").value == null ||
      document.getElementById("dim").value == 0
    ) {
      error = true;
      document.getElementById("dim").classList.add("is-invalid");
    }
    if (
      document.getElementById("stock_virtual").value <= 0 ||
      document.getElementById("stock_virtual").value == null
    ) {
      error = true;
      document.getElementById("stock_virtual").classList.add("is-invalid");
    }
    if (
      document.getElementById("id_uni").value <= 0 ||
      document.getElementById("id_uni").value == null
    ) {
      error = true;
      document.getElementById("id_uni").classList.add("is-invalid");
    }
    if (
      document.getElementById("dias_mto").value < 0.5 ||
      document.getElementById("dias_mto").value == null
    ) {
      error = true;
      document.getElementById("dias_mto").classList.add("is-invalid");
    }
    if (
      document.getElementById("precio_venta").value < 0 ||
      document.getElementById("precio_venta").value == null
    ) {
      error = true;
      document.getElementById("precio_venta").classList.add("is-invalid");
    }
    if (error) {
      this.setState({
        load: false,
      });
      // ToastsStore.error("UPS!, revisar los campos marcados en rojo.");
      return;
    }
    this.setState({ load: true });
    this.handleSubmit();
  }
  async getArticleInfo() {
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }
    let data = {
      idArt: this.state.idArt,
      token: token,
    };

    const response = await request.post("/auth/inventory/getArticle", data);

    if (response && !response.error) {
      if (response.result && !response.empty) {
        this.setState({
          articleData: response.result[0],
          cantAnt: response.result[0].stock_virtual,
          loading: true,
        });
        this.props.totalStock(response.result[0].stock_virtual);
      } else {
        this.setState({
          empty: true,
          loading: true,
          message: response.message,
        });
      }
    } else {
      this.setState({
        error: true,
        loading: true,
        message: response.message,
      });
    }
  }
  async getArticleArea() {
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }
    let data = {
      token: token,
    };

    const response = await request.post("/auth/inventory/getAlmacen", data);

    if (response && !response.error) {
      if (response.result && !response.empty) {
        this.setState({
          listArea: response.result.almacen,
        });
      } else {
        this.setState({
          empty: true,
          loading: false,
          message: response.message,
        });
      }
    } else {
      this.setState({
        error: true,
        loading: false,
        message: response.message,
      });
    }
  }
  async getArticleUnit() {
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }
    let data = {
      token: token,
    };

    const response = await request.post("/auth/inventory/getArticleUnit", data);

    if (response && !response.error) {
      if (response.result && !response.empty) {
        this.setState({
          listUnit: response.result,
        });
      } else {
        this.setState({
          empty: true,
          loading: false,
          message: response.message,
        });
      }
    } else {
      this.setState({
        error: true,
        loading: false,
        message: response.message,
      });
    }
  }
  async getArticleCollection() {
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }
    let data = {
      token: token,
    };

    const response = await request.post("/auth/sales/collectionQuote", data);

    if (response && !response.error) {
      if (response.result && !response.empty) {
        this.setState({
          listCollection: response.result.movement,
        });
      } else {
        this.setState({
          empty: true,
          loading: false,
          message: response.message,
        });
      }
    } else {
      this.setState({
        error: true,
        loading: false,
        message: response.message,
      });
    }
  }
  handleChange(e) {
    let valores = this.state.articleData;
    // let active = docu;
    let name = e.target.name;
    switch (e.target.type) {
      case "checkbox":
        valores[name] = Number(e.target.checked);
        break;
      case "file":
        let x = e.target.value.length;
        if (x > 0) {
          let imagen = e.target.files[0].name;

          this.setState({
            labelInput: imagen,
          });
        } else {
          this.setState({
            labelInput: "Cambiar Foto",
          });
        }
        break;
      case "text":
      case "textarea":
        valores[name] = e.target.value.trim();
        break;
      default:
        valores[name] = Number(e.target.value);
        break;
    }
    document.getElementById(name).classList.remove("is-invalid");
    this.setState({ articleData: valores });
  }
  async handleSubmit(e) {
    e.preventDefault();
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }

    let data = new FormData(document.getElementById("uploadForm"));
    data.append("id_art", this.state.articleData.id_art);
    data.append("status", this.state.articleData.activo);
    data.append("idUser", this.state.user.id);
    //data.append("dif", this.state.cantAnt);
    // let data = {
    //   img_pred: this.state.articleData.img_pred,
    // };

    const response = await request.upload(
      "/auth/upload/saveInventoryPhoto",
      data,
      token
    );
    console.log("response :>> ", response);
    if (response && !response.error) {
      if (response.result && !response.empty) {
        if (this.state.idArt == 0) {
          this.props.saved({
            id: response.result[0].id_art,
            nom: response.result[0].nomE,
          });
        }
        this.setState({
          idArt: response.result[0].id_art,
          eddit: true,
          load: false,
          block: true,
        });
        this.props.reload(response.result[0]);
        this.getArticleInfo();
        toast.success(
          <span>Datos generales del artículo, guardados con exito.</span>
        );
      } else {
        this.setState({
          empty: true,
          loading: false,
          message: response.message,
        });
        toast.error(
          <span>
            ¡¡¡UPSS!!!, algo salio mal.. intentalo de nuevo, si el problema
            continua contacta a sistemas.
          </span>
        );
      }
    } else {
      this.setState({
        error: true,
        loading: false,
        message: response.message,
      });
    }
  }
}
export default Consumer(GeneralData);
