import React, { useEffect, useState } from "react";
import { Consumer } from "../../context/index";
import { useIndexedDB } from "react-indexed-db";
import Request from "../../utils/http";
import OdsStep1 from "./odsStep1";
import OdsStep2 from "./odsStep2";
import OdsStep3 from "./odsStep3";
import OdsStep4 from "./odsStep4";
import NotifyOds from "../calendar/tabs/notifyOds";
import "./edditOds.css";

const request = new Request();

function EdditOds(props) {
  const [menu, setMenu] = useState(1);
  const [sub, setSub] = useState(0);
  const [hotel, sethotel] = useState(null);
  const [nameHotel, setNameHotel] = useState("");
  const [fechaIn, setFechaIn] = useState("0000-00-00 00:00");
  const [fechaFin, setFechaFin] = useState("0000-00-00 00:00");
  const [fechaMon, setFechaMon] = useState("0000-00-00 00:00");
  const [fechaDes, setFechaDes] = useState("0000-00-00 00:00");
  const [eventUser, setEventUser] = useState(null);
  const [idioma, setIdioma] = useState(null);
  const [reload, setReload] = useState(true);
  const paso2 = React.useRef();
  const paso4 = React.useRef();
  const locationDB = useIndexedDB("locations");
  const [location, setLocation] = useState([]);

  useEffect(() => {
    getLocationDB();
  }, []);
  const fechaIPro = (fecha) => {
    setFechaIn(fecha);
  };
  const fechaFPro = (fecha) => {
    setFechaFin(fecha);
  };
  const fechaMPro = (fecha) => {
    setFechaMon(fecha);
  };
  const fechaDPro = (fecha) => {
    setFechaDes(fecha);
  };
  const asignMenu = (sub) => {
    setMenu(menu + 1);
    setSub(sub);
  };
  const asignHotel = async (id, nombre) => {
    if (id) {
      sethotel(Number(id));
    } else {
      setNameHotel(nombre);
    }
  };
  const asignIdUser = (id) => {
    setEventUser(id);
  };
  const deleteSub = (subs) => {
    setSub(subs);
    props.setRecargaOds();
  };
  const reloadArts = (id) => {
    switch (id) {
      case 2:
        paso4.current.click();
        break;

      case 4:
        paso2.current.click();
        break;
    }
    props.setRecargaOds(true);
  };

  const getLocationDB = async () => {
    let locations = await locationDB.getAll();
    let options = [];
    locations.map((type, key) =>
      options.push({ value: type.id_ubicacion, label: type.lugar })
    );
    setLocation(options);
  };
  const saveHotel = async (id) => {
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }
    let data = {
      token: token,
      nameHotel: nameHotel,
      idUbi: id,
    };
    const response = await request.post("/auth/sales/changeUbiHotel", data);
    console.log(
      "🚀 ~ file: edditOds.jsx ~ line 102 ~ saveHotel ~ response",
      response
    );
  };

  return (
    <div className="modal-body" style={{ padding: 0 }}>
      <div className="card-primary card-outline card-outline-tabs">
        <div className="card-header p-0 pt-1 border-bottom-0">
          <ul
            className="nav nav-tabs"
            id="custom-tabs-three-tab"
            role="tablist"
            style={{zoom:'85%'}}
          >
            <li className="nav-item active" title="Información del evento">
              <a
                className="nav-link active"
                id="custom-tabs-three-home-tab"
                data-toggle="pill"
                href="#custom-tabs-three-home"
                role="tab"
                aria-controls="custom-tabs-three-home"
                aria-selected="false"
              >
                <i className="far fa-newspaper"></i>
                <b className="d-none d-md-inline">
                  &nbsp;Información del evento
                </b>
              </a>
            </li>
            {props.idEvent !== 0 ? (
              <>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="custom-tabs-three-profile-tab"
                    data-toggle="pill"
                    href="#custom-tabs-three-profile"
                    role="tab"
                    aria-controls="custom-tabs-three-profile"
                    aria-selected="false"
                  >
                    <i className="fas fa-th-list"></i>
                    <b className="d-none d-md-inline">
                      &nbsp;Administrar Artículos
                    </b>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="custom-tabs-three-order-tab"
                    data-toggle="pill"
                    href="#custom-tabs-three-order"
                    role="tab"
                    aria-controls="custom-tabs-three-order"
                    aria-selected="false"
                  >
                    <i className="fas fa-sort-amount-down"></i>
                    <b className="d-none d-md-inline">
                      &nbsp;Ordenar Artículos
                    </b>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="custom-tabs-three-termn-tab"
                    data-toggle="pill"
                    href="#custom-tabs-three-termn"
                    role="tab"
                    aria-controls="custom-tabs-three-termn"
                    aria-selected="false"
                  >
                    <i className="fas fa-tasks"></i>
                    <b className="d-none d-md-inline">
                      &nbsp;Terminos y Condiciones
                    </b>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="custom-tabs-three-n-tab"
                    data-toggle="pill"
                    href="#custom-tabs-three-n"
                    role="tab"
                    aria-controls="custom-tabs-three-n"
                    aria-selected="false"
                  >
                    <i class="fas fa-bell"></i>
                    <b className="d-none d-md-inline">&nbsp;Notificar</b>
                  </a>
                </li>
              </>
            ) : null}
          </ul>
        </div>
        <div className="edditOds card-body pb-1">
          <div className="tab-content" id="custom-tabs-three-tabContent">
            <div
              className="tab-pane fade active show"
              id="custom-tabs-three-home"
              role="tabpanel"
              aria-labelledby="custom-tabs-three-home-tab"
            >
              <OdsStep1
                eddit={props.eddit}
                idEvents={props.idEvent}
                cont={asignMenu}
                numE={asignMenu}
                asign={asignHotel}
                hotelAsignar={asignHotel}
                fechaI={fechaIPro}
                fechaF={fechaFPro}
                fechaM={fechaMPro}
                fechaD={fechaDPro}
                asignIdUser={(e) => asignIdUser(e)}
                closeModal={(e) => props.closeModal(e)}
                setIdioma={(e) => setIdioma(e)}
                updateEvent={(e) => props.updateEvent(e)}
                changeEvent={(e) => props.changeEvent(e)}
              />
            </div>
            {props.idEvent !== 0 ? (
              <>
                <div
                  className="tab-pane fade"
                  id="custom-tabs-three-profile"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-three-profile-tab"
                >
                  {props.idEvent &&
                  fechaIn !== "0000-00-00 00:00" &&
                  fechaFin !== "0000-00-00 00:00" ? (
                    <>
                      {hotel > 0 ? (
                        <OdsStep2
                          ref={paso2}
                          id={props.idEvent}
                          idUser={eventUser}
                          sub={sub}
                          eddit={1}
                          cont={asignMenu}
                          hotel={hotel}
                          fechaInicio={fechaIn}
                          fechaFin={fechaFin}
                          fechaMontaje={fechaMon}
                          fechaDesmontaje={fechaDes}
                          deleteSub={(e) => deleteSub(e)}
                          addSub={(e) => setSub(e)}
                          reload={reload}
                          setReload={() => reloadArts(2)}
                          closeModal={(e) => props.closeModal(e)}
                        />
                      ) : (
                        <div className="modal-body card-primary card-outline card-outline-tabs">
                          <div className="card-body">
                            El hotel {<b>"{nameHotel}"</b>} no tiene asignada
                            una ubicación, seleccione una de la siguiente lista
                            para poder continuar.
                            <br />
                            <select
                              name="locations"
                              id="locations"
                              onChange={(e) => (
                                asignHotel(e.target.value, nameHotel),
                                saveHotel(e.target.value)
                              )}
                            >
                              <option value={null}></option>
                              {location.map((data) => (
                                <option value={data.value}>{data.label}</option>
                              ))}
                            </select>
                            <br />
                            *NOTA: Si le aparece mas de una vez este mensaje
                            para este hotel contacte a soporte.
                          </div>
                        </div>
                      )}
                    </>
                  ) : null}
                </div>
                <div
                  className="tab-pane fade"
                  id="custom-tabs-three-order"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-three-order-tab"
                >
                  <OdsStep4
                    ref={paso4}
                    id={props.idEvent}
                    sub={sub}
                    eddit={1}
                    reload={reload}
                    setReload={() => reloadArts(4)}
                    closeModal={(e) => props.closeModal(e)}
                  />
                </div>
                <div
                  className="tab-pane fade"
                  id="custom-tabs-three-termn"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-three-termn-tab"
                >
                  <OdsStep3
                    id={props.idEvent}
                    eddit={1}
                    recargarInfo={(e) => props.setRecargaOds(e)}
                    closeModal={(e) => props.closeModal(e)}
                    idioma={idioma}
                  />
                </div>
                <div
                  className="tab-pane fade"
                  id="custom-tabs-three-n"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-three-n-tab"
                >
                  <br />
                  <NotifyOds
                    idEvent={props.idEvent}
                    closeModal={(e) => props.closeModal(e)}
                  />
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
export default Consumer(EdditOds);
