
import React from "react";
import Layout from "../layout";
import Sidebar from "../../components/header/sidebar";
import TableEmpleados from "../../components/rh/empleados";



const Empleados = () => (
  <Layout
    title="Perfil"
    description="Página privada de perfil"
    keywords="Reactjs, SEO Ready, Ejemplo"
  >
    <Sidebar />

      <div className="white-space-32"></div>
        <div className="container_fluid d-flex justify-content-center">
            <div className="card_empleado">
               <div className="card-body">
                <TableEmpleados/>
               </div>
            </div>
        </div>
      <div className="white-space-32"></div>

  </Layout>
);

export default Empleados;