import React, { useState, useEffect, useRef } from "react";
import Request from "../../../utils/http";
import Modal from "react-modal";
import "./ods.css";

const request = new Request();
function Ods(props) {
  const [titles, setTitles] = useState([]);
  const [eventHeader, setEventHeader] = useState([]);
  const [artInfo, setArtInfo] = useState([]);
  const [eventTotal, setEventTotal] = useState([]);
  const [eventTerm, setEventTerm] = useState([]);
  const [loadHead, setLoadHead] = useState(false);
  const [loadArt, setLoadArt] = useState(false);
  const [loadTotal, setLoadTotal] = useState(false);
  const [preview, setPreview] = useState(false);
  const [image, setImage] = useState([]);
  const mountedRef = useRef(true);

  useEffect(() => {
    if (!mountedRef.current) return null;
    getEventHeader();
  }, []);

  useEffect(() => {
    if (!mountedRef.current) return null;
    if (props.recargaOds === true) {
      setLoadHead(false);
      setLoadArt(false);
      setLoadTotal(false);
      getEventHeader();
      props.setRecargaOds(false);
    }
  }, [props.recargaOds]);
  useEffect(() => {
    return () => {
      console.log("cleaned up ods.jsx");
      mountedRef.current = false;
    };
  }, []);
  useEffect(() => {
    setTitles(
      request.odsTitles(!props.ods ? eventHeader.Idioma : "Español MX")
    );
  }, [eventHeader?.Idioma]);

  const getEventHeader = async () => {
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }
    let data = {
      token: token,
      id_event: props.idEvent,
    };

    const response = await request.post("/auth/calendar/getEventHead", data);
    // console.log("🚀 ~ file: ods.jsx ~ line 110 ~ getEventHeader ~ response", response)

    if (response && !response.error) {
      if (response.result && !response.empty) {
        setEventHeader(response.result[0]);
        setLoadHead(true);

        if (response.result[0].id_ubicacion_h === null) {
          response.result[0].id_ubicacion_h = 1;
        }

        if (response.result[0].eventos >= 1) {
          getEventArt(
            response.result[0].eventos,
            response.result[0].id_ubicacion_h,
            {
              siniva: response.result[0].siniva,
              tdesc: response.result[0].tdesc,
              desc: response.result[0].desc,
              comh: response.result[0].comh,
              subs: response.result[0].eventos,
            }
          );
        }
        // window.sessionStorage.setItem(
        //   "Productos",
        //   JSON.stringify(response.result)
        // );
      } else {
        //   this.setState({
        //     empty: true,
        //     loading: false,
        //     message: response.message
        //   });
      }
    } else {
      // this.setState({
      //   error: true,
      //   loading: false,
      //   message: response.message
      // });
    }
  };
  const getEventArt = async (sub, ubi, total) => {
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }

    let data = {
      token: token,
      id_modulo: props.idModulo,
      id_event: Number(props.idEvent),
      id_ubicacion: ubi,
      events: sub,
    };
    //  console.log("🚀 ~ file: ods.jsx ~ line 162 ~ getEventArt ~ data", data)

    const response = await request.post("/auth/calendar/getEventArt_Ods", data);
    //console.log("Ods -> response", response);

    if (response && !response.error) {
      if (response.result && !response.empty) {
        if (response.result[0].length > 0) {
          setArtInfo(response.result);
          if (props.idModulo !== 1 && props.idModulo !== 7) {
            getEventTotal(total);
          }
        }
      } else {
        // this.setState({
        //   empty: true,
        //   loading: false,
        //   message: response.message
        // });
      }
    } else {
      // this.setState({
      //   error: true,
      //   loading: false,
      //   message: response.message
      // });
    }
    setLoadArt(true);
  };

  const getEventTotal = async (param) => {
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }

    let data = {
      token: token,
      id_event: Number(props.idEvent),
      siva: parseInt(param.siniva),
      tdesc: parseInt(param.tdesc),
      desc: parseInt(param.desc),
      comh: parseInt(param.comh),
    };
    //console.log("🚀 ~ file: ods.jsx ~ line 228 ~ getEventTotal ~ param", param)
    const response = await request.post("/auth/calendar/getEventTotal", data);
    //console.log("🚀 ~ file: ods.jsx ~ line 213 ~ getEventTotal ~ response.result", data, response.result)

    if (response && !response.error) {
      if (response.result && !response.empty) {
        setEventTotal(response.result[0]);
        setLoadTotal(true);
        getEventTerm();
      } else {
        // this.setState({
        //   empty: true,
        //   loading: false,
        //   message: response.message
        // });
      }
    } else {
      // this.setState({
      //   error: true,
      //   loading: false,
      //   message: response.message
      // });
    }
  };

  const getEventTerm = async () => {
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }

    let data = {
      token: token,
      id_evento: props.idEvent,
    };

    const response = await request.post("/auth/sales/getTermnOds", data);
    /*console.log(
      "🚀 ~ file: ods.jsx ~ line 246 ~ getEventTerm ~ response",
      response
    );
    //console.log(data);*/

    if (response && !response.error) {
      if (response.result && !response.empty) {
        if (response.result.termino.length !== 0) {
          let termino = JSON.parse(response.result.termino[0].id_pie);
          setEventTerm(termino);
        }
      } else {
        // this.setState({
        //   empty: true,
        //   loading: false,
        //   message: response.message
        // });
      }
    } else {
      // this.setState({
      //   error: true,
      //   loading: false,
      //   message: response.message
      // });
    }
  };
  const FormatNumber = (number) => {
    let val = new Intl.NumberFormat("ES-MX", {
      style: "currency",
      currency: "MXN",
      useGrouping: true,
    }).format(number);
    if (val.length === 8) {
      val = val.substring(0, 2) + "," + val.substring(2, 8);
    }
    return val;
  };

  const previewImage = (e) => {
    setImage({
      url: e.src,
      name: e.alt,
    });
    setPreview(true);
  };
  const customStyles = {
    content: {
      position: "absolute",
      top: "10%",
      left: "10%",
      right: "10%",
      bottom: "10%",
      border: "1px solid rgb(204, 204, 204)",
      background: "rgb(255, 255, 255)",
      overflow: "auto",
      outline: "none",
      padding: "10px",
    },
  };
  return (
    <div>
      {loadHead ? (
        <div className="row">
          <div className="col-md-6">
            <div className="card">
              <div className="card-header pl-1 pr-1 pt-1 pb-1 text-center">
                {titles.infoe}
              </div>
              <div className="card-body row">
                <div className="col-12 data">
                  {titles.event}:&nbsp;
                  {props.ods ? eventHeader.id_evento + "-" : null}
                  <b>{eventHeader.nombre}</b>
                </div>
                <div className="col-12 data">
                  {titles.type}:&nbsp;
                  <b>
                    {props.ods || eventHeader.Idioma != "English US"
                      ? eventHeader.tipo_esp
                      : eventHeader.tipo_ing}
                  </b>
                </div>
                <div className="col-12 data">
                  {titles.fi}:&nbsp; <b>{eventHeader.Fecha_inicio}h</b>
                </div>
                {props.ods ? (
                  <div className="col-12 data">
                    Fecha de Termino: <b>{eventHeader.Fecha_fin}h</b>
                  </div>
                ) : null}
                <div className="col-12 data">
                  {titles.site}:&nbsp; <b>{eventHeader.nombre_h}</b>
                </div>
                <div className="col-12 data">
                  {titles.loc}:&nbsp; <b>{eventHeader.Salon}</b>
                </div>
                {props.ods ? (
                  <div className="col-12 data">
                    Backup: <b>{eventHeader.Bakup}</b>
                  </div>
                ) : null}
                <div className="col-12 data">
                  {titles.pax}:&nbsp; <b>{eventHeader.Paxes}</b>
                </div>
                <div className="col-12 data">
                  {titles.theme}:&nbsp;
                  <b>
                    {props.ods || eventHeader.Idioma != "English US"
                      ? `${eventHeader.Tema}-${eventHeader.T_especial}`
                      : eventHeader.Tema_ing}
                  </b>
                </div>
                {props.ods ? (
                  <>
                    <div className="col-12 data">
                      Fecha de Montaje: <b>{eventHeader.Fecha_montaje}h</b>
                    </div>
                    <div className="col-12 data">
                      Fecha de Desmontaje:&nbsp;
                      <b>{eventHeader.Fecha_desmontaje}h</b>
                    </div>
                    <div className="col-12 data">
                      Requiere de Diseño:&nbsp;
                      <b>
                        {eventHeader.Renders === 0 ? "No" : "Sí"}&nbsp;
                        <i
                          className="fas fa-info-circle"
                          style={{ color: "#007bff" }}
                          title="Cuando esta opción esta como NO, no se vera en el calendario del modulo para diseño."
                        ></i>
                      </b>
                    </div>
                  </>
                ) : (
                  ""
                )}
                <div className="col-12 data">
                  {titles.contact}:&nbsp;<b>{eventHeader.Contacto_Hotel}</b>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card-img pb-3" style={{ textAlign: "center" }}>
              <img
                src="./assets/home/logo_ods.png"
                style={{ maxHeight: 45 + "px" }}
                alt="Logo Zuñiga"
              />
              <div className="white-space-16"></div>
            </div>
            <div className="card">
              <div className="card-header pl-1 pr-1 pt-1 pb-1 text-center">
                {titles.infoz}
              </div>
              <div className="card-body row">
                {!props.ods ? (
                  <div className="col-12 data">
                    {titles.fc}:&nbsp;<b>{eventHeader.fecha_ods}</b>
                  </div>
                ) : null}
                <div className="col-12 data">
                  {titles.ven}:&nbsp;
                  <b>{eventHeader.nombre_us}</b>
                </div>
                <div className="col-12 data">
                  Concierge:&nbsp;
                  <b>{eventHeader.consierge}</b>
                </div>
                <div className="col-12 data">
                  {titles.tel}:&nbsp;
                  <b>{eventHeader.telefono}</b>
                </div>
                <div className="col-12 data">
                  {titles.mail}:&nbsp;
                  <b>{eventHeader.correo}</b>
                </div>
                {props.ods ? (
                  <div className="ribbon-wrapper ribbon-xl">
                    <div
                      className="ribbon text-lg"
                      style={{
                        color: "white",
                        backgroundColor: eventHeader.color,
                      }}
                    >
                      {eventHeader.status}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            {props.ods ? (
              <>
                <div className="card no-print">
                  <div className="card-header pl-1 pr-1 pt-1 pb-1 text-center">
                    INFORMACIÓN CONTABLE
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12 data">
                        Cliente a Facturar: <b>{eventHeader.clienteId}</b>
                      </div>
                      <div className="col-12 data">
                        Forma de Pago: <b>{eventHeader.formaDescripcion}</b>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      ) : (
        <i className="fas fa-spinner fa-spin"></i>
      )}
      <div className="card col-12">
        <div className="table-responsive p-0" style={{ textAlign: "center" }}>
          {loadArt ? (
            <>
              {artInfo.length != 0 ? (
                <table
                  className="table table-sm table-hover"
                  style={{ width: 99 + "%" }}
                >
                  {artInfo.map((key, i) =>
                    key.length > 0 ? (
                      <tbody key={i}>
                        <tr>
                          <th
                            colSpan="8"
                            className="card-header pl-1 pr-1 pt-1 pb-1"
                          >
                            <div className="row">
                              {props.ods ? (
                                <>
                                  <div className="col-3">
                                    SUBEVENTO {key[0].id_subevento}:&nbsp;
                                    {key[0].nombre}
                                  </div>
                                  <div className="col-3">
                                    LOCACIÓN {key[0].id_subevento}:&nbsp;
                                    {key[0].locacion_sub}
                                  </div>
                                  <div className="col-3">
                                    MONTAJE: {key[0].fecha_montaje}h
                                  </div>
                                  <div className="col-3">
                                    ENTREGA: {key[0].fecha_entrega}h
                                  </div>
                                </>
                              ) : (
                                <div className="col-12">{key[0].nombre}&nbsp;{key[0].locacion_sub}</div>
                              )}
                            </div>
                          </th>
                        </tr>
                        <tr>
                          {props.ods && props.idModulo !== 2 ? <th></th> : null}
                          <th>{titles.photo}</th>
                          <th>{titles.item}</th>
                          {props.ods ? <th>DIMENSIÓN</th> : null}
                          <th>{titles.pieces}</th>
                          <th>{titles.unit}</th>
                          {!props.ods ? (
                            <th>{titles.days}</th>
                          ) : (
                            <>
                              <th>INDICACIÓN</th>
                              <th>BOD</th>
                              <th>OTRO</th>
                            </>
                          )}
                          {!props.ods ? (
                            <>
                              <th>{titles.price}</th>
                              <th>{titles.amount}</th>
                            </>
                          ) : null}
                        </tr>
                        {key.map((info, j) =>
                          (info.oculto_ods == 0 && props.ods) ||
                          (info.oculto_cot == 0 && !props.ods) ? (
                            <tr key={j}>
                              {props.ods && props.idModulo !== 2 ? (
                                <td style={{ maxWidth: 80 + "px" }}>
                                  <img
                                    className="card-img-top"
                                    style={{
                                      maxHeight: 100 + "px",
                                      width: "auto",
                                      maxWidth: 120 + "px",
                                    }}
                                    src={`data:image/png;base64,${info.qr}`}
                                    alt="QR"
                                  />
                                </td>
                              ) : null}
                              <td>
                                <img
                                  className="card-img-top"
                                  style={{
                                    maxHeight: 100 + "px",
                                    width: "auto",
                                    maxWidth: 120 + "px",
                                    cursor: "pointer",
                                  }}
                                  src={
                                    info.img_char == "http"
                                      ? `${info.img_pred}`
                                      : `${request.publicURL()}/inventario/${
                                          info.img_pred
                                        }`
                                  }
                                  alt={`${info.id_art} "${info.nomE}"`}
                                  onClick={(e) => previewImage(e.target)}
                                  onError={(e) => {
                                    e.target.src =
                                      "./assets/uploads/no-image.jpg";
                                  }}
                                />
                              </td>
                              <td style={{ maxWidth: 150 + "px" }}>
                                {props.ods ? (
                                  <>
                                    ({info.id_art}) {info.nomE}
                                  </>
                                ) : (
                                  <>
                                    {eventHeader.Idioma != "English US"
                                      ? info.nomE
                                      : info.nomI}
                                    <br />
                                    <small>{info.comentarios}</small>
                                  </>
                                )}
                              </td>
                              {props.ods ? (
                                <td>
                                  <small>{info.dim}</small>
                                </td>
                              ) : null}
                              <td>{info.total}</td>
                              <td>{info.unidad}</td>
                              {props.ods ? (
                                <td>
                                  <small>{info.indicaciones}</small>
                                </td>
                              ) : (
                                <td>{info.dias}</td>
                              )}
                              {!props.ods ? (
                                <>
                                  <td className="currency">
                                    {FormatNumber(info.preciouni)}
                                  </td>
                                  <td className="currency">
                                    {FormatNumber(info.importe)}
                                  </td>
                                </>
                              ) : (
                                <>
                                  <td>{info.cantidad}</td>
                                  <td>{info.produccion}</td>
                                </>
                              )}
                            </tr>
                          ) : null
                        )}
                      </tbody>
                    ) : null
                  )}
                  {!props.ods ? (
                    loadTotal ? (
                      <tfoot>
                        <tr>
                          <th colSpan="6" className="currency card-footer">
                            {titles.sub1}
                            &nbsp;
                            {eventHeader.monedaDescripcion
                              ? eventHeader.monedaDescripcion.slice(0, 3)
                              : ""}
                          </th>
                          <th className="currency card-footer">
                            {FormatNumber(eventTotal.subtotal)}
                          </th>
                        </tr>
                        {eventTotal.descuento != 0 ? (
                          <>
                            <tr className="alert-danger">
                              <th colSpan="6" className="currency card-footer">
                                {titles.desc}
                                &nbsp;
                                {eventHeader.monedaDescripcion
                                  ? eventHeader.monedaDescripcion.slice(0, 3)
                                  : null}
                              </th>
                              <th className="currency card-footer">
                                -{FormatNumber(eventTotal.descuento)}
                              </th>
                            </tr>
                            <tr>
                              <th colSpan="6" className="currency card-footer">
                                {titles.sub1}
                                &nbsp;
                                {eventHeader.monedaDescripcion
                                  ? eventHeader.monedaDescripcion.slice(0, 3)
                                  : ""}
                              </th>
                              <th className="currency card-footer">
                                {FormatNumber(eventTotal.subtotal2)}
                              </th>
                            </tr>
                          </>
                        ) : null}
                        {eventTotal.operacion != 0 ? (
                          <>
                            <tr>
                              <th colSpan="6" className="currency card-footer">
                                {titles.op}
                                &nbsp;
                                {eventHeader.monedaDescripcion
                                  ? eventHeader.monedaDescripcion.slice(0, 3)
                                  : null}
                              </th>
                              <th className="currency card-footer">
                                {FormatNumber(eventTotal.operacion)}
                              </th>
                            </tr>
                            <tr className="alert-info">
                              <th colSpan="6" className="currency card-footer">
                                {titles.sub2}&nbsp;
                                {eventHeader.monedaDescripcion
                                  ? eventHeader.monedaDescripcion.slice(0, 3)
                                  : ""}
                              </th>
                              <th className="currency card-footer">
                                {FormatNumber(eventTotal.subtotal3)}
                              </th>
                            </tr>
                          </>
                        ) : null}
                        {eventTotal.iva != 0 ? (
                          <tr>
                            <th colSpan="6" className="currency card-footer">
                              {titles.iva}
                              &nbsp;
                              {eventHeader.monedaDescripcion
                                ? eventHeader.monedaDescripcion.slice(0, 3)
                                : null}
                            </th>
                            <th className="currency card-footer">
                              {FormatNumber(eventTotal.iva)}
                            </th>
                          </tr>
                        ) : null}
                        <tr className="alert-success">
                          <th colSpan="6" className="currency card-footer">
                            TOTAL&nbsp;
                            {eventHeader.monedaDescripcion
                              ? eventHeader.monedaDescripcion.slice(0, 3)
                              : ""}
                          </th>
                          <th className="currency card-footer">
                            {FormatNumber(eventTotal.total)}
                          </th>
                        </tr>
                      </tfoot>
                    ) : (
                      <tfoot>
                        <tr>
                          <th colSpan="7">
                            <i className="fas fa-spinner fa-spin"></i>
                          </th>
                        </tr>
                      </tfoot>
                    )
                  ) : null}
                </table>
              ) : (
                <div className="card-body">
                  No se han agregado artículos a esta&nbsp;
                  {props.ods ? "orden de servicio" : "cotizacón"}.&nbsp;
                  <button
                    className="btn btn-sm btn-info bg-gradient-info"
                    onClick={(e) => {
                      setLoadArt(false);
                      getEventArt(
                        eventHeader.eventos,
                        eventHeader.id_ubicacion_h,
                        {
                          siniva: eventHeader.siniva,
                          tdesc: eventHeader.tdesc,
                          desc: eventHeader.desc,
                          comh: eventHeader.comh,
                          subs: eventHeader.eventos,
                        }
                      );
                    }}
                  >
                    <i className="fas fa-sync"></i>
                  </button>
                </div>
              )}
            </>
          ) : (
            <i className="fas fa-spinner fa-spin"></i>
          )}
        </div>
      </div>
      {!props.ods && eventHeader.cCli?.length ? (
        <div className="card col-12">
          <div className="card-header">{titles.comCli}</div>
          <div className="card-body text-justify">{eventHeader.cCli}</div>
        </div>
      ) : null}
      <div className="card col-12">
        {eventTerm.length != 0 && !props.ods ? (
          <>
            <div className="card-header">{titles.terms}</div>
            <div className="card-body">
              {eventTerm.map((info, key) => (
                <p className="text-justify" key={key}>
                  * {info.texto}
                </p>
              ))}
            </div>
          </>
        ) : (
          ""
        )}
        {!props.ods ? (
          <div className="card-footer row">
            <div className="col-6 text-center">
              <span>
                <div className="white-space-64"></div>
                {eventHeader.cFinal}
                <hr />
                {titles.client}
              </span>
            </div>
            <div className="col-6 text-center">
              <span>
                <div className="white-space-64"></div>
                {eventHeader.nombre_us}
                <hr />
                {titles.ven}
                <br />
                ZDE BODAS Y EVENTOS S.A de C.V.
              </span>
            </div>
          </div>
        ) : (
          <>
            <div className="card-header">COMENTARIOS PARA OPERACIÓN</div>
            <div className="card-body text-justify">
              {eventHeader.Comentarios_int}
            </div>
          </>
        )}
      </div>
      <Modal isOpen={preview} contentLabel="Zoom Imagen" style={customStyles}>
        <div className="modal-header">
          <h4 className="modal-title">{image.name}</h4>
          <button
            type="button"
            className="close"
            onClick={(e) => {
              setPreview(false);
            }}
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="text-center">
            <img
              className="rounded mx-auto d-block"
              src={image.url}
              alt={image.name}
              style={{ maxHeight: "50vh", maxWidth: "75vw" }}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Ods;
