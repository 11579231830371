import React, { Component } from "react";
import ActivateMenu from "./actions";

class buttonActions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
      user: {
        idUsuario: 0,
        idPerfil: 0,
        nombre: "",
        ubicacion: 0,
      },
    };
  }
  closeModal() {
    this.openModal();
  }
  reload(data) {
    this.props.reload(data);
  }
  openModal() {
    this.props.openModal({
      id: this.props.id,
      nom: this.props.nom,
    });
  }
  render() {
    return (
      <div className="btn-group">
        <button
          className="btn btn-info bg-gradient-info"
          title="Editar"
          onClick={this.openModal.bind(this)}
        >
          <i className="fas fa-edit"></i>
        </button>
        {this.props.idPerfil === 1 || this.props.idPerfil === 2 ? (
          <ActivateMenu value={this.props.id} reload={(e) => this.reload(e)} />
        ) : null}
      </div>
    );
  }
}
export default buttonActions;
