import React, { Component } from "react";
import Request from "../../../utils/http";
import { Consumer } from "../../../context/index";
import { toast } from "react-toastify";
const request = new Request();
class GeneralData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
      idArt: this.props.idArt,
      articleData: [],
      listUnit: [],
      loading: false,
      labelInput: "Cambiar foto",
      formData: [],
      user: {
        id: 0,
        id_perfil: 0,
        name: "",
      },
    };
    this.refPhoto = React.createRef();
  }
  closeModal() {
    this.openModal();
  }
  reloadImg() {
    this.getArticleInfo();
  }
  reload(data) {
    this.props.reload(data);
  }
  render() {
    return this.state.loading ? (
      <div className="form-group">
        <form method="post" id="uploadForm" encType="multipart/form-data">
          <div className="row">
            <div className="col-12 col-md-8 ">
              <div className="row">
                {this.props.user.id_perfil === 1 ||
                this.props.user.id_perfil === 2 ? (
                  <div className="col-12 col-md-4">
                    <div className="form-group">
                      <div
                        className="form-control custom-control custom-switch custom-switch-off-danger custom-switch-on-success"
                        style={{ textAlign: "center" }}
                      >
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="Activo"
                          checked={this.state.articleData.Activo}
                          name="Activo"
                          onChange={this.handleChange.bind(this)}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="Activo"
                        >
                          {this.state.articleData.Activo == 1
                            ? "Activo"
                            : "Inactivo"}
                        </label>
                      </div>
                    </div>
                  </div>
                ) : (
                  <input
                    type="hidden"
                    id="Activo"
                    name="Activo"
                    value={this.state.articleData.Activo}
                  />
                )}
                <div className="col-12 col-md-8">
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text">Foto</span>
                    </div>
                    <div className="custom-file">
                      <input
                        type="file"
                        name="uploadFile"
                        className="custom-file-input"
                        id="uploadFile"
                        accept="image/jpg,image/jpeg,image/png"
                        onChange={this.handleChange.bind(this)}
                      />
                      <label className="custom-file-label" htmlFor="uploadFile">
                        {this.state.labelInput}
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text">Nombre</span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Español"
                      value={this.state.articleData.Articulo}
                      defaultValue={this.state.articleData.Articulo}
                      name="Articulo"
                      id="Articulo"
                      onChange={this.handleChange.bind(this)}
                    />

                    <input
                      type="text"
                      className="form-control"
                      placeholder="Ingles"
                      value={this.state.articleData.ArticuloI}
                      defaultValue={this.state.articleData.ArticuloI}
                      name="ArticuloI"
                      id="ArticuloI"
                      onChange={this.handleChange.bind(this)}
                    />
                  </div>
                </div>

                <div className="col-12 col-md-6">
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text">Dimensión</span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Ancho X Alto X Profundidad / Diametro"
                      value={this.state.articleData.Dimension}
                      defaultValue={this.state.articleData.Dimension}
                      name="Dimension"
                      id="Dimension"
                      onChange={this.handleChange.bind(this)}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text">Unidad:</span>
                    </div>
                    <select
                      className="form-control select2"
                      defaultValue={this.state.articleData.id_unidad}
                      value={this.state.articleData.id_unidad}
                      name="id_unidad"
                      id="id_unidad"
                      onChange={this.handleChange.bind(this)}
                    >
                      <option></option>
                      {this.state.listUnit.map((listUnit, key) => (
                        <option value={listUnit.id} key={key}>
                          {listUnit.descripcion}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-12">
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text">Tags:</span>
                    </div>
                    <textarea
                      className="form-control"
                      value={this.state.articleData.tags}
                      defaultValue={this.state.articleData.tags}
                      name="tags"
                      id="tags"
                      onChange={this.handleChange.bind(this)}
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4 ">
              <div className="card">
                <img
                  className="card-img-top"
                  src={
                    this.state.articleData.img_char == "http"
                      ? this.state.articleData.Imagen
                      : `${request.publicURL()}/inventario/${
                          this.state.articleData.Imagen
                        }`
                  }
                  alt="Foto Artículo"
                  onError={(e) => {
                    e.target.src = "./assets/uploads/no-image.jpg";
                  }}
                />
              </div>
            </div>
          </div>
          <div className="white-space-32"></div>
          <div className="modal-footer justify-content-left pt-0 pb-0">
            <button
              ref="uploadButton"
              type="submit"
              onClick={this.handleSubmit.bind(this)}
              id="uploadPhoto"
              className="btn btn-outline-success btn-flat "
              //readOnly={this.props.block}
              disabled={this.props.load}
            >
              {this.props.load ? (
                <i className="fas fa-spinner fa-spin"></i>
              ) : (
                <i className="far fa-save"></i>
              )}
              &nbsp; Guardar
            </button>

            <button
              type="button"
              onClick={this.openModal.bind(this)}
              className="btn btn-outline-danger btn-flat"
              //  readOnly={this.props.block}
              disabled={this.state.load}
            >
              <i className="fas fa-ban"></i> Cerrar
            </button>
          </div>
        </form>
      </div>
    ) : (
      <div className="text-center">
        <i
          className="fas fa-spinner fa-spin"
          style={{ fontSize: "62px", color: "#3f51b5" }}
        ></i>
        <br /> Cargando Datos del Artículo...
      </div>
    );
  }
  componentDidMount() {
    this.load();
    this.getArticleUnit();

    if (this.props.idArt !== 0) {
      this.getArticleInfo();
    } else {
      this.setState({
        articleData: { id_art: 0, Activo: 1 },
        loading: true,
      });
    }
  }
  async load() {
    const user = await this.props.context.loadUser();

    if (user) {
      this.setState({
        user: user,
      });
    }
  }
  async getArticleInfo() {
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }
    let data = {
      idArt: this.state.idArt,
      token: token,
    };

    const response = await request.post(
      "/auth/inventory/getArticleDataTemp",
      data
    );

    if (response && !response.error) {
      if (response.result && !response.empty) {
        this.setState({
          articleData: response.result[0],
          loading: true,
        });
      } else {
        this.setState({
          empty: true,
          loading: false,
          message: response.message,
          loading: true,
        });
      }
    } else {
      this.setState({
        error: true,
        loading: false,
        message: response.message,
        loading: true,
      });
    }
  }
  openModal() {
    this.props.closeModal();
  }
  handleChange(e) {
    // console.log("handleChange e :>> ", e.target.type);
    let valores = this.state.articleData;
    // let active = docu;
    let name = e.target.name;
    switch (e.target.type) {
      case "checkbox":
        valores[name] = Number(e.target.checked);
        break;
      case "file":
        let x = e.target.value.length;
        if (x > 0) {
          let imagen = e.target.files[0].name;

          this.setState({
            labelInput: imagen,
          });
        } else {
          this.setState({
            labelInput: "Cambiar Foto",
          });
        }
        break;
      case "text":
      case "textarea":
        valores[name] = e.target.value;
        break;
      default:
        valores[name] = Number(e.target.value);
        break;
    }
    document.getElementById(name).classList.remove("is-invalid");
    this.setState({ articleData: valores });
  }
  save() {
    let error = false;
    let art = document.getElementById("Articulo").value.trim();
    document.getElementById("Articulo").value = art;
    if (
      document.getElementById("Articulo").value === "" ||
      document.getElementById("Articulo").value == null ||
      document.getElementById("Articulo").value == 0
    ) {
      error = true;
      document.getElementById("Articulo").classList.add("is-invalid");
    }
    let artT = document.getElementById("ArticuloI").value.trim();
    document.getElementById("ArticuloI").value = artT;
    if (
      document.getElementById("ArticuloI").value === "" ||
      document.getElementById("ArticuloI").value == null ||
      document.getElementById("ArticuloI").value == 0
    ) {
      error = true;
      document.getElementById("ArticuloI").classList.add("is-invalid");
    }
    if (
      document.getElementById("Dimension").value === "" ||
      document.getElementById("Dimension").value == null ||
      document.getElementById("Dimension").value == 0
    ) {
      error = true;
      document.getElementById("Dimension").classList.add("is-invalid");
    }
    if (
      document.getElementById("id_unidad").value <= 0 ||
      document.getElementById("id_unidad").value == null
    ) {
      error = true;
      document.getElementById("id_unidad").classList.add("is-invalid");
    }
    if (error) {
      this.setState({
        load: false,
      });
      toast.error(<span>"Todos los campos en rojo son obligatorios."</span>);
      return;
    }
    this.setState({ load: true });
    this.handleSubmit();
  }
  async handleSubmit(e) {
    e.preventDefault();

    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }
    let data = new FormData(document.getElementById("uploadForm"));
    data.append("id_art", this.state.articleData.id_art);
    data.append("status", this.state.articleData.Activo);
    data.append(
      "idUser",
      this.state.articleData.id_art === 0
        ? this.props.user.id
        : this.state.articleData.id_usuario
    );

    const response = await request.upload(
      "/auth/upload/saveTemporaryPhoto",
      data,
      token
    );
    console.log("response :>> ", response);

    if (response && !response.error) {
      if (response.result && !response.empty) {
        this.props.reload(response.result[0]);
        this.setState({
          idArt: response.result[0].id_art,
          eddit: true,
          load: false,
        });
        this.getArticleInfo();
        //  this.refPhoto.current.refs.uploadButton.click();

        toast.success(
          <span>
            Se guardo el artículo con id:"
            {data.id_art}" correctamente.
          </span>
        );
      } else {
        this.setState({
          empty: true,
          loading: false,
          message: response.message,
        });
        toast.error(
          <span>
            "UPSS!!!, algo salio mal.. intentalo de nuevo, si el problema
            continua contacta a sistemas."
          </span>
        );
      }
    } else {
      this.setState({
        error: true,
        loading: false,
        message: response.message,
      });
      toast.error(
        <span>
          "UPSS!!!, algo salio mal.. intentalo de nuevo, si el problema continua
          contacta a sistemas."
        </span>
      );
    }
  }
  async getArticleUnit() {
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }
    let data = {
      token: token,
    };

    const response = await request.post("/auth/inventory/getArticleUnit", data);

    if (response && !response.error) {
      if (response.result && !response.empty) {
        this.setState({
          listUnit: response.result,
        });
      } else {
        this.setState({
          empty: true,
          loading: false,
          message: response.message,
        });
      }
    } else {
      this.setState({
        error: true,
        loading: false,
        message: response.message,
      });
    }
  }
}
export default GeneralData;
