import React, { Component } from "react";
import Modal from "react-modal";
import Request from "../../../utils/http";
import { Consumer } from "../../../context/index";
import ButtonActions from "./buttonsActions";
import NewArticle from "./newArticle";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import GeneralData from "./generalData";
import History from "./history";

Modal.setAppElement(document.getElementById("root"));
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    //overflow: "scroll",
  },
};
const request = new Request();
class TableArticlesTemp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productost: [],
      showModal: false,
      image: "",
      productot: "",
      load: true,
      user: {
        id: 0,
        id_perfil: 0,
        name: "",
        ubicacion: 0,
      },
      artEddit: {
        id: 0,
        nom: "",
      },
      id: "",
      text: "",
      activo: -1,
      idUser: -1,
      edditModal: false,
      page: 0,
      rowsPerPage: 25,
      sellers: [],
    };
  }
  render() {
    return (
      <>
        <div className="content-wrapper" id="tableBodega">
          <div className="card" style={{ margin: "1rem" }}>
            <div className="card-header">
              <div
                className="card-title row card-title row row-cols-2 row-cols-md-4 row-cols-xl-5"
                style={{ width: "100%" }}
              >
                <div className="col text-center">
                  <TextField
                    label="ID"
                    name="id"
                    type="number"
                    value={this.state.id}
                    onChange={(e) => this.handleChangeFilter(e)}
                  />
                </div>
                <div className="col text-center">
                  <TextField
                    label="Palabras Clave"
                    name="texto"
                    value={this.state.text}
                    onChange={(e) => this.handleChangeFilter(e)}
                  />
                </div>
                <div className="col text-center">
                  <FormControl>
                    <InputLabel htmlFor="vendedor">Vendedor</InputLabel>
                    <Select
                      value={this.state.idUser}
                      onChange={(e) => this.handleChangeFilter(e)}
                      input={<Input id="vendedor" />}
                      name="vendedor"
                    >
                      <MenuItem value={-1}>
                        <i className="fas fa-list"></i>&nbsp;Todos
                      </MenuItem>
                      {this.state.sellers.map((v, k) => (
                        <MenuItem key={k} value={v.id_usuario}>
                          <i className="fas fa-dot-circle"></i>&nbsp;{v.nombre}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                {this.state.user.id_perfil === 1 ||
                this.state.user.id_perfil === 5 ? (
                  <>
                    <div className="col text-center">
                      <FormControl>
                        <InputLabel htmlFor="activo">Activo</InputLabel>
                        <Select
                          value={this.state.activo}
                          onChange={(e) => this.handleChangeFilter(e)}
                          input={<Input id="activo" />}
                          name="activo"
                        >
                          <MenuItem value={-1}>
                            <i className="fas fa-list"></i>&nbsp;Todos
                          </MenuItem>
                          <MenuItem value={1}>
                            <i className="fas fa-eye"></i>&nbsp;Activo
                          </MenuItem>
                          <MenuItem value={0}>
                            <i className="fas fa-eye-slash"></i>&nbsp;Inactivo
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </>
                ) : null}
                <div className="col text-right">
                  <button
                    className="btn btn-primary btn-sm button-filter"
                    onClick={this.handleSearch.bind(this)}
                  >
                    <i className="fas fa-search"></i>
                  </button>
                  &nbsp;
                  <button
                    className="btn btn-secondary btn-sm button-filter"
                    onClick={this.handleClear.bind(this)}
                  >
                    <i className="fas fa-times"></i>
                  </button>
                  &nbsp;
                  <button
                    className="btn btn-info btn-sm bg-gradient-info button-filter"
                    onClick={(e) =>
                      this.openEddit({ id: 0, nom: "Nuevo Artículo" })
                    }
                  >
                    <i className="fas fa-plus"></i>
                  </button>
                  {/* &nbsp;
                {this.state.productos.length !== 0 ? (
                  <>
                    <DownloadExcel excelData={this.state.productos} />
                    &nbsp;
                    <DownloadPDF pdfData={this.state.productos} />
                  </>
                ) : null} */}
                </div>
              </div>
            </div>
            <div className="card-body">
              <TableContainer>
                <Table
                  aria-labelledby="tableTitle"
                  size="small"
                  aria-label="enhanced table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" className="AcctionsButton">
                        ACCIÓN
                      </TableCell>
                      {this.state.user.id_perfil === 1 ||
                      this.state.user.id_perfil === 5 ? (
                        <TableCell align="center">ACTIVO</TableCell>
                      ) : null}
                      <TableCell align="center">FOTO</TableCell>
                      <TableCell align="center">ID</TableCell>
                      <TableCell align="center" style={{ maxWidth: "300px" }}>
                        NOMBRE
                      </TableCell>
                      <TableCell align="center">DIMENSIÓN</TableCell>
                      <TableCell align="center">UNIDAD</TableCell>
                      <TableCell align="center">VENDEDOR</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!this.state.load ? (
                      this.state.productost
                        .slice(
                          this.state.page * this.state.rowsPerPage,
                          this.state.page * this.state.rowsPerPage +
                            this.state.rowsPerPage
                        )
                        .map((row, index) => {
                          return (
                            <TableRow key={index}>
                              <TableCell
                                align="center"
                                className="AcctionsButton"
                              >
                                {this.state.user.id_perfil === 1 ||
                                this.state.user.id_perfil === 5 ||
                                this.state.user.id === row.id_usuario ? (
                                  <ButtonActions
                                    id={row.id_art}
                                    nom={row.Articulo}
                                    reload={this.reload.bind(this)}
                                    openModal={(e) => this.openEddit(e)}
                                    idPerfil={this.state.user.id_perfil}
                                  />
                                ) : null}
                              </TableCell>
                              {this.state.user.id_perfil === 1 ||
                              this.state.user.id_perfil === 5 ? (
                                <TableCell align="center">
                                  {row.Activo === 0 ? (
                                    <>
                                      <i
                                        className="fas fa-eye-slash"
                                        style={{ color: "#b71c1c" }}
                                        title="Inactivo"
                                      ></i>
                                      &nbsp;Inactivo
                                    </>
                                  ) : (
                                    <>
                                      <i
                                        className="fas fa-eye"
                                        style={{ color: "#4caf50" }}
                                        title="Activo"
                                      ></i>
                                      &nbsp;Activo
                                    </>
                                  )}
                                </TableCell>
                              ) : null}
                              <TableCell align="center">
                                {this.customImg(row.Imagen)}
                              </TableCell>
                              <TableCell align="center">{row.id_art}</TableCell>
                              <TableCell
                                align="center"
                                style={{ maxWidth: "300px" }}
                              >
                                {row.Articulo}
                                <hr />
                                <i>{row.ArticuloI}</i>
                              </TableCell>
                              <TableCell align="center">
                                {row.Dimension}
                              </TableCell>
                              <TableCell align="center">{row.unidad}</TableCell>
                              <TableCell align="center">
                                {row.usuario}
                              </TableCell>
                            </TableRow>
                          );
                        })
                    ) : (
                      <TableRow>
                        <TableCell align="center" colSpan={11}>
                          <i
                            className="fas fa-spinner fa-spin"
                            style={{ fontSize: "62px", color: "#3f51b5" }}
                          ></i>
                          <br /> Cargando Artículos...
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[25, 50, 100, 150, 200, 250]}
                component="div"
                count={this.state.productost.length}
                rowsPerPage={this.state.rowsPerPage}
                page={this.state.page}
                onChangePage={this.handleChangePage.bind(this)}
                onChangeRowsPerPage={this.handleChangeRowsPerPage.bind(this)}
                labelRowsPerPage="Artículos por página"
              />
              {/*Modal para ver imagen*/}
              <Modal
                isOpen={this.state.showModal}
                style={customStyles}
                contentLabel="EditarArticulo"
              >
                <div className="card-body">
                  <button
                    type="button"
                    className="close"
                    onClick={(e) => this.setState({ showModal: false })}
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                  <div
                    className="tab-content"
                    id="custom-tabs-three-tabContent"
                    style={{ display: "flex" }}
                  >
                    <div>
                      <img
                        src={this.state.image}
                        alt=""
                        style={{
                          width: "auto",
                          maxWidth: 800 + "px",
                          height: "auto",
                          maxHeight: 400 + "px",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </Modal>
              {/*Modal para editar/agregar articulo*/}
              <Modal
                isOpen={this.state.edditModal}
                style={customStyles.content}
                contentLabel="EditarArticulo"
              >
                <div className="modal-header">
                  <h6 className="modal-title">
                    Editar Artículo{" "}
                    <i>
                      "{this.state.artEddit.id + "-" + this.state.artEddit.nom}"
                    </i>
                  </h6>
                  <button
                    type="button"
                    className="close"
                    onClick={() =>
                      this.setState({
                        edditModal: false,
                        artEddit: { id: 0, nom: "" },
                      })
                    }
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body card-primary card-outline card-outline-tabs">
                  <div className="card-header border-bottom-0">
                    <ul
                      className="nav nav-tabs"
                      id="custom-tabs-three-tab"
                      role="tablist"
                    >
                      <li className="nav-item active">
                        <a
                          className="nav-link active"
                          id="custom-tabs-three-home-tab"
                          data-toggle="pill"
                          href="#custom-tabs-three-home"
                          role="tab"
                          aria-controls="custom-tabs-three-home"
                          aria-selected="false"
                        >
                          Datos Generales
                        </a>
                      </li>
                      {this.state.artEddit.id != 0 ? (
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            id="custom-tabs-three-settings-tab"
                            data-toggle="pill"
                            href="#custom-tabs-three-history"
                            role="tab"
                            aria-controls="custom-tabs-three-settings"
                            aria-selected="true"
                          >
                            Historial de eventos
                          </a>
                        </li>
                      ) : null}
                    </ul>
                  </div>
                  <div className="card-body">
                    <div
                      className="tab-content"
                      id="custom-tabs-three-tabContent"
                    >
                      <div
                        className="tab-pane fade active show"
                        id="custom-tabs-three-home"
                        role="tabpanel"
                        aria-labelledby="custom-tabs-three-home-tab"
                      >
                        <GeneralData
                          idArt={this.state.artEddit.id}
                          reload={this.reload.bind(this)}
                          user={this.state.user}
                          closeModal={() =>
                            this.setState({
                              edditModal: false,
                              artEddit: { id: 0, nom: "" },
                            })
                          }
                        />
                      </div>
                      <div
                        className="tab-pane fade"
                        id="custom-tabs-three-history"
                        role="tabpanel"
                        aria-labelledby="custom-tabs-three-settings-tab"
                      >
                        {this.state.artEddit.id != 0 ? (
                          <History id={this.state.artEddit.id} />
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        </div>
      </>
    );
  }

  componentDidMount() {
    this.getSellers();
    this.load();
    // document.getElementsByClassName('btn').classList.remove(;
    // MuiButtonBase-root MuiButton-root MuiButton-text btn btn-sm btn-info bg-gradient-info MuiButton-textSizeSmall MuiButton-sizeSmall
  }
  async reload(data) {
    let prod = this.state.productost;
    let newp = true;
    this.setState({
      artEddit: {
        id: data.id_art,
        nom: data.Articulo,
      },
    });
    for (let i = 0; i < prod.length; i++) {
      if (data.id_art === prod[i].id_art) {
        prod[i] = data;
        newp = false;
        this.setState({ productost: prod });
        return;
      }
    }
    if (newp) {
      prod.unshift(data);
      this.setState({ productost: prod, page: 0 });
    }
  }
  async load() {
    const user = await this.props.context.loadUser();

    if (user) {
      this.setState({
        user: user,
      });
      let productost = JSON.parse(sessionStorage.getItem("productost"));
      if (productost === null) {
        this.getArticles();
      } else {
        this.setState({
          productost: productost,
        });
      }
    }
  }
  openEddit(data) {
    this.setState({ artEddit: data, edditModal: !this.state.edditModal });
  }
  openModal(img, datos) {
    // console.log(datos);

    this.setState({ showModal: !this.state.showModal });
    this.setState({ image: img, productot: datos });
  }
  async getArticles() {
    this.setState({ load: true });
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }
    let data = {
      token: token,
      idPerfil: this.state.user.id_perfil,
    };
    //   console.log(data);

    const response = await request.post(
      "/auth/inventory/getArticlesTemp",
      data
    );

    if (response && !response.error) {
      if (response.result && !response.empty) {
        this.setState({ productost: response.result, load: false });
      } else {
        this.setState({
          empty: true,
          loading: false,
          message: response.message,
          load: false,
        });
      }
    } else {
      this.setState({
        error: true,
        loading: false,
        message: response.message,
        load: false,
      });
    }
  }
  customImg(value) {
    let img = "";
    if (value !== null && value.slice(0, 4) === "http") {
      img = value;
    } else {
      img = `${request.publicURL()}/inventario/${value}`;
    }
    return (
      <div>
        <img
          src={
            value !== null
              ? value.slice(0, 4) === "http"
                ? value.replace("/imagenes/", "/imagenes/thumb/")
                : `${request.publicURL()}/inventario/${value}`
              : "./assets/uploads/no-image.jpg"
          }
          style={{ maxHeight: "80px", maxWidth: "120px" }}
          alt="Imagen Data"
          onError={(e) => {
            e.target.src = "./assets/uploads/no-image.jpg";
          }}
          onClick={(e) => {
            this.openModal(img);
          }}
        />
      </div>
    );
  }
  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };
  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };
  async handleChangeFilter(e) {
    let name = e.target.name;
    switch (name) {
      case "id":
        if (e.target.value.length !== 0) {
          this.setState({ id: parseInt(e.target.value, 10) });
        } else {
          this.setState({ id: "" });
        }
        break;
      case "texto":
        if (e.target.value.length !== 0) {
          this.setState({ text: e.target.value });
        } else {
          this.setState({ text: "" });
        }
        break;
      case "vendedor":
        this.setState({ idUser: e.target.value });
        break;
      case "activo":
        this.setState({ activo: e.target.value });
        break;
    }
  }
  async handleSearch() {
    this.setState({ load: true });
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }
    if (
      this.state.id.length === 0 &&
      this.state.text.length === 0 &&
      this.state.idUser === -1 &&
      this.state.activo === -1
    ) {
      this.getArticles();
      return;
    }
    let data = {
      token: token,
      band: 1,
      idPerfil: this.state.user.id_perfil,
      id: this.state.id.length !== 0 ? this.state.id : "%",
      text: this.state.text,
      activo: this.state.activo,
      idUser: this.state.idUser,
    };
    const response = await request.post(
      "/auth/inventory/getSearchArticlesTemp",
      data
    );

    if (response && !response.error) {
      if (response.result && !response.empty) {
        this.setState({ productost: response.result, load: false });
      } else {
        this.setState({
          empty: true,
          loading: false,
          message: response.message,
        });
      }
    } else {
      this.setState({
        error: true,
        loading: false,
        message: response.message,
      });
    }
  }
  async handleClear() {
    this.setState({
      id: "",
      text: "",
      idUser: -1,
      activo: -1,
      load: true,
    });
    this.getArticles();
  }
  async getSellers() {
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }
    let data = {
      token: token,
    };

    const response = await request.post("/auth/sales/getSellers", data);

    if (response && !response.error) {
      if (response.result && !response.empty) {
        this.setState({ sellers: response.result.vendedores });
      } else {
        // this.setState({
        //   empty: true,
        //   loading: false,
        //   message: response.message
        // });
      }
    } else {
      // this.setState({
      //   error: true,
      //   loading: false,
      //   message: response.message
      // });
    }
  }
}

export default Consumer(TableArticlesTemp);
