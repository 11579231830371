import React, { Component } from "react";
import ButtonSave from "./buttonSave";
import { Consumer } from "../../../context/index";
import { toast } from "react-toastify";
import Request from "../../../utils/http";

const request = new Request();

class Transfers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idArt: this.props.id,
      user: {
        idUsuario: 0,
        idPerfil: 0,
        nombre: "",
        idUbi: 1,
      },
      locations: [],
      envia: 0,
      cantE: 0,
      recibe: 0,
      cantR: 0,
      loading: false,
    };
  }
  save() {
    this.setState({ load: true });
    this.handleSubmit();
  }
  render() {
    return this.state.loading ? (
      <div className="card-body">
        <div className="row">
          <div className="input-group col-md-6 mb-5">
            <div className="input-group-prepend">
              <span className="input-group-text">Envia</span>
            </div>
            <select
              id="envia"
              className="form-control select2"
              onChange={this.handleChange.bind(this)}
              value={this.state.envia}
            >
              {this.state.locations.map((location, key) => (
                <option key={key} value={location.id_ubicacion}>
                  {location.descrip}
                </option>
              ))}
            </select>
          </div>
          <div className="input-group col-md-6 mb-5">
            <div className="input-group-prepend">
              <span className="input-group-text">Recibe</span>
            </div>
            <select
              id="recibe"
              className="form-control select2"
              onChange={(e) => this.setState({ recibe: e.target.value })}
              value={this.state.recibe}
            >
              {this.state.locations.map((location, key) => (
                <option key={key} value={location.id_ubicacion}>
                  {location.descrip}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="row">
          <div className="input-group col-md-6 mb-5">
            <div className="input-group-prepend">
              <span className="input-group-text">Cantidad</span>
            </div>
            <input
              id="transferencia"
              type="number"
              className="form-control"
              value={this.state.cantE}
              readOnly
            />
          </div>
          <div className="input-group col-md-6 mb-5">
            <div className="input-group-prepend">
              <span className="input-group-text">Cantidad</span>
            </div>
            <input
              id="recibir"
              type="number"
              className="form-control"
              onChange={this.validate.bind(this)}
              min={0}
            />
          </div>
        </div>
        <ButtonSave
          closeModal={this.props.closeModal}
          save={this.save.bind(this)}
          load={this.state.load}
        />
      </div>
    ) : (
      <div className="text-center">
        <i
          className="fas fa-spinner fa-spin"
          style={{ fontSize: "62px", color: "#3f51b5" }}
        ></i>
        <br /> Cargando Almacenes del Artículo...
      </div>
    );
  }
  componentDidMount() {
    this.load();
    this.loadData();
  }
  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.load !== prevProps.load) {
      this.load();
      this.loadData();
    }
  }
  async load() {
    const user = await this.props.context.loadUser();

    if (user) {
      this.setState({
        user: {
          idUsuario: user.id,
          idPerfil: user.id_perfil,
          nombre: user.name,
          idUbi: user.ubicacion,
        },
      });
    }
  }
  handleChange(e) {
    let envia = e.target.value - 1;
    this.setState({
      envia: this.state.locations[envia].id_ubicacion,
      cantE: this.state.locations[envia].cantidad,
    });
    document.getElementById("recibir").value = 0;
  }
  validate(e) {
    let transferencia = document.getElementById("transferencia").value;

    let cantidad = e.target.value;

    this.setState({ toastTransfer: true });
    if (parseInt(transferencia) - parseInt(cantidad) <= -1) {
      toast.error(
        <span>
          No la cantidad a enviar
          no puede ser mayor a la existente en bodega.
        </span>
      );
      e.target.value = transferencia;
    } else {
      this.setState({ cantR: cantidad });
    }
  }

  async loadData() {
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }
    let data = {
      token: token,
      idArt: this.state.idArt,
    };

    const response = await request.post("/auth/inventory/bringstock ", data);

    if (response && !response.error) {
      if (response.result.stock && !response.empty) {
        this.setState({
          locations: response.result.stock,
          cantE: response.result.stock[0].cantidad,
          loading: true,
        });
      } else {
        this.setState({
          empty: true,
          loading: true,
          message: response.message,
        });
      }
    } else {
      this.setState({
        error: true,
        loading: true,
        message: response.message,
      });
    }
  }
  async handleSubmit() {
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }
    if (
      parseInt(document.getElementById("envia").value) ===
      parseInt(document.getElementById("recibe").value)
    ) {
      toast.error(
        <span>
          No puedes hacer
          transferencias a la misma ubicación, deben ser diferentes!
        </span>
      );
      this.setState({ load: false });
      return;
    }
    let data = {
      idEnvia: parseInt(document.getElementById("envia").value),
      idRecibe: parseInt(document.getElementById("recibe").value),
      cantE: this.state.cantE,
      cantR: this.state.cantR,
      idArt: this.state.idArt,
      idUser: this.state.user.id,
      idUbi: this.state.user.idUbi,
      token: token,
    };
    //console.log("/auth/inventory/transfers", data);

    const response = await request.post("/auth/inventory/transfers", data);
    //console.log("response: ", response);

    if (response && !response.error) {
      if (response.result && !response.empty) {
        this.setState({ load: false });
        this.loadData();
        this.props.reload(response.result[1].original[0]);
        this.props.fload();
        toast.success(
          <span>
            Transferencia del
            artículo, guardada con exito.
          </span>
        );
      } else {
        this.setState({
          empty: true,
          loading: false,
          message: response.message,
          load: false,
        });
        toast.error(
          <span>
            ¡¡¡UPSS!!!, algo salio
            mal.. intentalo de nuevo, si el problema continua contacta a
            sistemas.
          </span>
        );
      }
    } else {
      this.setState({
        error: true,
        loading: false,
        message: response.message,
        load: false,
      });
    }
  }
}

export default Consumer(Transfers);
