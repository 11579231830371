import React, { Component } from "react";
class ButtonSave extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: false,
    };
  }
  render() {
    return (
      <div
        className="modal-footer justify-content-left p-0"
        style={{ zoom: "80%" }}
      >
        <button
          type="button"
          onClick={this.handleSubmit.bind(this)}
          className="btn btn-outline-success btn-flat "
          //readOnly={this.props.block}
          disabled={this.props.load}
        >
          {this.props.load ? (
            <i className="fas fa-spinner fa-spin"></i>
          ) : (
            <i className="far fa-save"></i>
          )}
          &nbsp; Guardar
        </button>

        <button
          type="button"
          onClick={this.handleCancel.bind(this)}
          className="btn btn-outline-danger btn-flat"
          //  readOnly={this.props.block}
          disabled={this.props.load}
        >
          <i className="fas fa-ban"></i> Cerrar
        </button>
      </div>
    );
  }

  handleSubmit() {
    this.props.save();
  }

  handleCancel() {
    this.props.closeModal();
  }
}

export default ButtonSave;
