import React, { Component } from "react";
import { Consumer } from "../../context/index";
import { Link } from "react-router-dom";
import Request from "../../utils/http";
const request = new Request();

class SesionClose extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <Link to="#" className="nav-link" onClick={this.handleLogout.bind(this)}>
        <i className="fas fa-sign-out-alt"></i>
        <p className="color-white">&nbsp;Cerrar Sesión</p>
      </Link>
    );
  }

  async handleLogout() {
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }
    let data = {
      token: token,
    };

    const response = await request.post("/auth/logout", data);

    if (response && !response.error) {
      if (response.result && !response.empty) {
        this.props.context.logout();
        window.location = "/";
      }
    }
  }
}

export default Consumer(SesionClose);
