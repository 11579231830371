/**
 *  @name: private.jsx
 *  @version: 1.0.0
 *  @author: Trejocode - Sergio
 *  @description: Página privada
 */

import React, { useState, useEffect } from "react";
import Layout from "./layout";
import Sidebar from "../components/header/sidebar";
import PurchasesTable from "../components/purchases/purchasesTable";

function Purchases(props) {
  return (
    <>
      <Layout
        title="Lista de Requisiciones de Compra"
        description="Página privada de perfil"
        keywords="Reactjs, SEO Ready, Ejemplo"
      >
        <Sidebar />
        <div className="content-wrapper">
          <div className="card">
            <PurchasesTable />
          </div>
        </div>
      </Layout>
    </>
  );
}

export default Purchases;
