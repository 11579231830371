import React, {useEffect, useState} from "react";
import Request from "../../../utils/http";
import MUIDataTable from "mui-datatables";
import ButtonSave from "../../general/buttonSave";
import moment from "moment";

let idsUsuarios = [];

export default function FormVacation(props){

    const request = new Request();
    //hoocks---
    const [ubicacion, setUbicacion] = useState([]);
    const [datosEmpleado, setDatos] = useState([]);
    const [datosUsers, setUsers] = useState([]);
    const [listaAuxiliares, setListaAuxiliares] = useState([]);


    //nuestro token para todos
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token=token1.id;

    useEffect(() => {
        getUbicacion();
        AuxiliarAgregadoEvento();
    }, []);

    const getUbicacion = async () => {
        let data = {
            token: token
        };
        const response = await request.post("/employee/getUbicacion", data);
        //validar los datos que recibimos
        if (response && !response.error) {
            if (response.result.ubicacion && !response.empty) {
                setUbicacion(response.result.ubicacion);
            } else {
                console.log(response.result.message);
            }
        } else {
            console.log("error en la peticion");
        }
    };

    const getUsersEspecifico = async (id_ubicacion) => {
        let data = {
            token: token,
            id_ubicacion:id_ubicacion
        };
        const response = await request.post("/horasExtras/getUsers", data);
        if (response && !response.error) {
            if (response.result.users && !response.empty) {
                setDatos(response.result.users);
            } else {
                console.log(response.result.message);
            }
        } else {
            console.log("error en la peticion");
        }

    };

    const AuxiliarAgregadoEvento= async() => {
        let data = {
            token: token,
            id_evento:props.datos.id_evento
        };
        const response = await request.post("/horasExtras/AuxiliarAgregadoEvento", data);
        if (response && !response.error) {
            if (response.result.AuxiliarAgregadoEvento && !response.empty) {
                setListaAuxiliares(response.result.AuxiliarAgregadoEvento);
            } else {
                console.log(response.result.message);
            }
        } else {
            console.log("error en la peticion");
        }
    }

    const save = async () => {

        let arrUsers = [];
        let htmlUsers = document.getElementsByClassName('users');
        for (let input of htmlUsers){
            if (input.checked==true){
                let checkUsers=1;

                let id = input.getAttribute('data-id');
                arrUsers.push({id: id,supervisor:checkUsers});
            }else{
                let checkUsers=0;
                let id = input.getAttribute('data-id');
                arrUsers.push({id: id,supervisor:checkUsers});
            }
        }
        if(arrUsers==''){
            alert("Favor de agregar Auxiliares");
        }else{

            //ordemos arreglo dejando como indice
            let users_agregado={};
            let limpio=[];
            for(let propiedad in listaAuxiliares){
                let item=listaAuxiliares[propiedad].id_users
                users_agregado[item]={};
            }
            //VERIFICAMO SI EXISTE LOS AUXILIARES EN EL OBJETO
            for(let id_user in arrUsers){
                if(users_agregado.hasOwnProperty(arrUsers[id_user].id)){
                }else{
                        limpio.push(arrUsers[id_user])
                }
            }

            if(limpio!=''){
                let data = {
                    token: token,
                    id_evento:props.datos.id_evento,
                    id_users:limpio
                };
                const response = await request.post("/horasExtras/saveUsers", data);
        
                if (response && !response.error) {
                    if (response.result.ok && !response.empty) {
                        alert("Auxiliares guardado correctamente");
                        //si las los datos se guardaron bien Vaciamos el array de ids_users
                        idsUsuarios = [];
                        AuxiliarAgregadoEvento();
                      
                    } else {
                      console.log(response.result.message);
                    }
                } else {
                    console.log("error en la peticion");
                }

            }else{
                alert("los auxiliares que intenta agregar ya existen");
            }
        }
        
    }

    const addTempUser = () => {
        let agregar = true;

        let htmlUsers = document.getElementById('nameUsers');
        if (htmlUsers!=null && typeof htmlUsers == 'object'){
            if (htmlUsers.children.length>0){
                let option = htmlUsers.options[htmlUsers.selectedIndex];
                let name = option.text;
                let id_users = option.value;
                id_users = (id_users!="")? parseInt(id_users) : null;
                let item = {id_users:id_users,name:name};
                

                // console.log(id_users)
                if (id_users== undefined || id_users==null) {
                    agregar = false;
                }

                if (idsUsuarios.length>0 && agregar===true){
                    for (let i of idsUsuarios){
                        if (i.id_users == id_users){
                            agregar = false;
                        }
                    }
                }

                if (agregar===true) idsUsuarios.push(item);
            }
        }

        refreshTableUsers();

    };

    function refreshTableUsers() {
        let filtrarEmpleados = datosEmpleado.filter(item=>{
            if(item.id_ubicacion!=""){
                return item.id_ubicacion;
            }
        });
        setDatos(filtrarEmpleados);
    }

    function remover(id_users) {
        if (idsUsuarios.length>0 && id_users>0){
            for (let i in idsUsuarios){
                let item = idsUsuarios[i];
                if (item.id_users == id_users){
                    idsUsuarios.splice(i,1);
                }
            }
        }

        refreshTableUsers();
    }

    const columns = [
        {
         name: "supervisor",
         label: "Tipo de Cargos",
         options: {
          filter: true,
          sort: true,
            customBodyRender: (value, tableMeta, updateValue) => {
                let tipoCargo=value; 
                return <p>{
                    
                    tipoCargo==1?'Supervisor':'Auxiliar General'
                    
                }</p>
            },

         }
        },
        {
         name: "name",
         label: "Nombre",
         options: {
          filter: true,
          sort: false,
         }
        },
        {
            name: "last_name",
            label: "Apellido",
            options: {
             filter: true,
             sort: false,
            }
        }

       ];

       const options = {
        selectableRows: 'none',
        filter: false,
        viewColumns: false,
        responsive: "standard",
        textLabels: {
            body: {
                noMatch: "No se encontraron resultados.",
                toolTip: "Sort"
            },
            pagination: {
                next: "Siguiente",
                previous: "Anterior",
                rowsPerPage: "Número de filas:",
                displayRows: "de"
            },

            toolbar: {
                search: "Buscar",
                downloadCsv: "Descargar Excel",
                print: "Imprimir"
            },

            filter: {
                all: "TODOS",
                title: "FILTROS",
                reset: "LIMPIAR"
            },
            viewColumns: {
                title: "Mostar Columnas",
                titleAria: "Mostrar/Ocultar Columnas de la Tabla"
            },
            selectedRows: {
                text: "Fila(s) Seleccionadas",
                delete: "Eliminar",
                deleteAria: "Eliminar Filar Seleccionadas"
            }

        }

    };
    
   
    return(
        <div className="tab-content">
            <div className="row mt-2">
                <div className="col-md-4">
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Ubicacion</span>
                        </div>
                        <select className="form-control"
                                name="slcUbicacion"
                                id="slcUbicacion"
                                onChange={e => getUsersEspecifico(e.target.value)}
                        >
                            <option value="">Ninguno</option>
                            {ubicacion.map((val, key)=>(
                                <option key={key}
                                        value={val.id_ubicacion}
                                        selected={ubicacion.lugar == val.id_ubicacion ? true : false}
                                >
                                    {val.lugar}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Usuarios</span>
                        </div>
                        <select className="form-control"
                                id="nameUsers"
                                name="nameUsers"
                        >
                            <option value="">Ninguno</option>
                            {datosEmpleado.map((val, key)=>(
                                <option key={key}
                                        value={val.id_employee}
                                        data-id={val.id_employee}
                                        data-name={val.name +' '+ val.last_name}
                                        selected={ubicacion.name == val.id_employee? true : false}
                                >
                                    {val.name} {val.last_name}
                                </option>

                            ))}
                        </select>
                    </div>
                </div>
                <div className="col-md-4">
                    <button
                        className="btn btn-md btn-info"
                        title="Agregar"
                        onClick={e=>addTempUser()}>
                        Agregar Auxiliares
                    </button>
                </div>
            </div>
            <div className="row mt-4 mb-5">
                <div className="col-md-12">

                    <table className="table">
                        <thead>
                        <tr>
                            <th scope="col">Check Como Supervisor</th>
                            <th scope="col">Nombre</th>
                            <th scope="col">Opcion</th>
                        </tr>
                        </thead>
                        <tbody>
                        {idsUsuarios.map((val, key)=>(
                            <tr>
                                <td>
                                    <input type="checkbox" 
                                    className="users"
                                    defaultChecked={val.checked}
                                    data-id={val.id_users}
                                    aria-label="Checkbox for following text input"
                                    
                                    />
                                </td>
                                <td>{val.name}</td>
                                <td><button type="button" className="btn btn-danger"  onClick={e=>remover(val.id_users)}>Eliminar</button></td>
                            </tr>
                        ))
                        }
                        </tbody>
                    </table>

                </div>
            </div>
            <ButtonSave
                save={save}
                closeModal={props.closeModal}
            />
            <div className="row">
                <div className="col-md-12">
                    <MUIDataTable
                    title={listaAuxiliares==''?'No hay Auxiliar Agregado':'Hay Auxiliar Agregado en el Evento'}
                    data={listaAuxiliares}
                    columns={columns}
                    options={options}
                    />
                </div>
            </div>

        </div>
    );

}