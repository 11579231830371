import React, { Component } from "react";
import Icon from "@material-ui/core/Icon";

import Modal from "react-modal";
import Request from "../../utils/http";
const request = new Request();
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "10px",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)"
  }
};

class UserEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nombre: "",
      usuario: "",
      telefono: "",
      correo: "",
      depto: "",
      idPerfil: "",
      ubicacion: "",
      activo: "",
      ubicaciones: [],
      perfiles: [],
      showModal: false
    };
  }
  render() {
    // import("./modal.css");
    return (
      <div>
        <Icon
          className="fas fa-user-edit"
          data-toggle="modal"
          data-target="#exampleModal"
          onClick={this.openModal.bind(this)}
        />
        <Modal
          isOpen={this.state.showModal}
          style={customStyles}
          contentLabel="Example Modal"
          overlayClassName="Overlay"
        >
          <div className="card">
            <div className="row">
              <div className="col">
                <h3>Editar Usuario</h3>
              </div>
              <div className="col justify-end">
                <button
                  className="btn btn-icon btn-3 btn-default"
                  type="button"
                  onClick={this.handleClose.bind(this)}
                >
                  <i className="fas fa-times" />
                </button>
              </div>
            </div>
            <div
              className="card-body"
              style={{ width: 35 + "rem", height: 35 + "rem" }}
            >
              <form onSubmit={this.handleSubmit.bind(this)}>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="">Nombre</label>
                      <input
                        type="text"
                        className="form-control"
                        id=""
                        value={this.state.nombre}
                        onChange={e =>
                          this.setState({ nombre: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="">Usuario</label>
                      <input
                        type="text"
                        className="form-control"
                        id=""
                        value={this.state.usuario}
                        onChange={e =>
                          this.setState({ usuario: e.target.value })
                        }
                      />
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">Teléfono</label>
                          <input
                            type="text"
                            className="form-control"
                            id=""
                            value={this.state.telefono}
                            onChange={e =>
                              this.setState({ telefono: e.target.value })
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-8">
                        <label htmlFor="">Email</label>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            id=""
                            value={this.state.correo}
                            onChange={e =>
                              this.setState({ correo: e.target.value })
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="">Departamento</label>
                          <input
                            type="text"
                            className="form-control"
                            id=""
                            value={this.state.depto}
                            onChange={e =>
                              this.setState({ depto: e.target.value })
                            }
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="">Perfil</label>
                          <select
                            className="form-control"
                            value={this.state.idPerfil}
                            onChange={e =>
                              this.setState({ idPerfil: e.target.value })
                            }
                            name=""
                            id=""
                          >
                            {this.state.perfiles.map((perfil, key) => (
                              <option key={key} value={perfil.id_perfil}>
                                {perfil.nombre_perfil}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="">Ubicacion</label>
                          <select
                            name=""
                            className="form-control"
                            id=""
                            value={this.state.ubicacion}
                            onChange={e =>
                              this.setState({ ubicacion: e.target.value })
                            }
                          >
                            {this.state.ubicaciones.map((ub, key) => (
                              <option key={key} value={ub.id_ubicacion}>
                                {ub.descrip}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="">Activo</label>
                          <select
                            name=""
                            value={this.state.activo}
                            onChange={e =>
                              this.setState({ activo: e.target.value })
                            }
                            className="form-control"
                            id=""
                          >
                            <option value="1">Activo</option>
                            <option value="0">Inactivo</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <button type="submit" className="btn btn-danger">
                    Guardar cambios
                  </button>
                  <button
                    type="button"
                    onClick={this.handleClose.bind(this)}
                    className="btn btn-default"
                  >
                    Cancelar
                  </button>
                </div>
                <div className="white-space-32"></div>
              </form>
            </div>
          </div>
        </Modal>
      </div>
    );
  }

  async handleSubmit(e) {
    e.preventDefault();
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }

    let data = {
      token: token,
      id: this.state.id,
      nombre: this.state.nombre,
      usuario: this.state.usuario,
      telefono: this.state.telefono,
      correo: this.state.correo,
      depto: this.state.depto,
      idPerfil: this.state.idPerfil,
      ubicacion: this.state.ubicacion,
      activo: this.state.activo
    };
    const response = await request.post("/users/user/update", data);

    if (response && !response.error) {
      if (response.result.status === 201 && !response.empty) {
        this.setState({ showModal: false });
      } else {
        this.setState({
          empty: true,
          loading: false,
          message: response.message
        });
      }
    } else {
      this.setState({
        error: true,
        loading: false,
        message: response.message
      });
    }
    this.props.reload();
  }

  openModal() {
    this.setState({
      showModal: true,
      nombre: this.props.datos.nombre,
      usuario: this.props.datos.usuario,
      telefono: this.props.datos.telefono,
      correo: this.props.datos.correo,
      depto: this.props.datos.depto,
      idPerfil: this.props.datos.id_perfil,
      ubicacion: this.props.datos.ubicacion,
      activo: this.props.datos.activo,
      id: this.props.datos.id_usuario
    });
    this.getInfo();
    this.getUbic();
  }
  handleClose() {
    this.setState({ showModal: false });
  }

  async getInfo() {
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }
    let data = {
      token: token
    };

    const response = await request.post("/users/getInfo", data);

    if (response && !response.error) {
      if (response.result.info && !response.empty) {
        this.setState({ perfiles: response.result.info });
      } else {
        this.setState({
          empty: true,
          loading: false,
          message: response.message
        });
      }
    } else {
      this.setState({
        error: true,
        loading: false,
        message: response.message
      });
    }
  }
  async getUbic() {
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }
    let data = {
      token: token
    };

    const response = await request.post("/users/getubicaciones", data);

    if (response && !response.error) {
      if (response.result.ubicaciones && !response.empty) {
        this.setState({ ubicaciones: response.result.ubicaciones });
      } else {
        this.setState({
          empty: true,
          loading: false,
          message: response.message
        });
      }
    } else {
      this.setState({
        error: true,
        loading: false,
        message: response.message
      });
    }
  }
}

export default UserEdit;
