import React, { Component } from "react";
import Request from "../../../utils/http";
import { Consumer } from "../../../context/index";
import ButtonSave from "./buttonSave";
import { toast } from "react-toastify";

let moment = require("moment-timezone");

const request = new Request();

class Purchases extends Component {
  constructor(props) {
    super(props);
    this.state = {
      load: false,
      loading: false,
      category: [],
      providers: [],
      proveedor: "NA",
      codProv: "",
      fechaReg: new Date(),
      fechaComp: new Date(),
      pComp: 0,
      pProd: 0,
      Cat: 0,
      Caract: 0,
      eddit: this.props.eddit,
      idArt: this.props.id,
      startDate: new Date(),
      user: {
        idUsuario: 0,
        idPerfil: 0,
        nombre: "",
      },
    };
  }
  save() {
    this.setState({ load: true });

    this.handleSubmit();
  }

  render() {
    return this.state.loading ? (
      <div className="card-body">
        <div className="row">
          <div className="input-group col-md-6 mb-5">
            <div className="input-group-prepend">
              <span className="input-group-text">Proveedor</span>
            </div>
            <select
              id="proveedor"
              className="form-control select2"
              value={this.state.proveedor}
              onChange={(e) => this.setState({ proveedor: e.target.value })}
              required
            >
              <option defaultValue="NA">Na</option>
              {this.state.providers.map((provider, key) => (
                <option
                  key={key}
                  value={provider.id_prov}
                  defaultValue={provider.id_prov}
                >
                  {provider.razon}
                </option>
              ))}
            </select>
          </div>

          <div className="input-group col-md-6 mb-5">
            <div className="input-group-prepend">
              <span className="input-group-text">codigo proveedor</span>
            </div>
            <input
              id="codigoProveedor"
              type="text"
              value={this.state.codProv}
              className="form-control "
              onChange={(e) => this.setState({ codProv: e.target.value })}
              required
            />
          </div>

          <div className="input-group col-md-6 mb-5">
            <div className="input-group-prepend">
              <span className="input-group-text">Fecha de registro</span>
            </div>

            <input
              id="fr"
              type="date"
              className="form-control select2"
              value={this.state.fechaReg}
              onChange={this.handleChangeFR.bind(this)}
              required
            />
          </div>
          <div className="input-group col-md-6 mb-5">
            <div className="input-group-prepend">
              <span className="input-group-text">Fecha de compra </span>
            </div>
            <input
              type="date"
              id="fc"
              className="form-control select2"
              value={this.state.fechaComp}
              onChange={this.handleChangeFC.bind(this)}
              required
            />
          </div>
        </div>
        <div className="row">
          <div className="input-group col-md-4 mb-5">
            <div className="input-group-prepend">
              <span className="input-group-text">Precio compra $</span>
            </div>
            <input
              id="pc"
              type="number"
              className="form-control select2"
              value={this.state.pComp}
              onChange={(e) => this.setState({ pComp: e.target.value })}
              required
              min="0"
            />
          </div>
          <div className="input-group col-md-4 mb-5">
            <div className="input-group-prepend">
              <span className="input-group-text">Precio produccion $</span>
            </div>
            <input
              id="pp"
              type="number"
              className="form-control"
              value={this.state.pProd}
              onChange={(e) => this.setState({ pProd: e.target.value })}
              required
              min="0"
            />
          </div>
          <div className="input-group col-md-4 mb-5">
            <div className="input-group-prepend">
              <span className="input-group-text">Categoria</span>
            </div>
            <select
              id="cat"
              className="form-control"
              value={this.state.Cat}
              onChange={(e) => this.setState({ Cat: e.target.value })}
              required
            >
              {this.state.category.map((category, key) => (
                <option key={key} value={category.id_subcategoria}>
                  {category.nombre_subcategoria}-{category.nombre_categoria}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="form-group  col-md-12">
          <label>Caracteristicas</label>
          <textarea
            id="caract"
            className="form-control"
            rows="3"
            placeholder="Enter ..."
            value={this.state.Caract}
            onChange={(e) => this.setState({ Caract: e.target.value })}
            required
          ></textarea>
        </div>
        {this.state.user.id_perfil === 1 || this.state.user.id_perfil === 2 ? (
          <ButtonSave
            closeModal={this.props.closeModal}
            save={this.save.bind(this)}
            load={this.state.load}
          />
        ) : null}
      </div>
    ) : (
      <div className="text-center">
        <i
          className="fas fa-spinner fa-spin"
          style={{ fontSize: "62px", color: "#3f51b5" }}
        ></i>
        <br /> Cargando Datos del Artículo...
      </div>
    );
  }
  componentDidMount() {
    this.load();
    if (this.props.eddit === true) {
      this.loadData();
    }
    this.loadInfo();
  }

  async load() {
    const user = await this.props.context.loadUser();

    if (user) {
      this.setState({
        user: user,
      });
    }
  }
  handleChangeFR = (event) => {
    this.setState({ fechaReg: event.target.value });
  };
  handleChangeFC = (event) => {
    this.setState({ fechaComp: event.target.value });
  };

  async loadInfo() {
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }
    let data = {
      token: token,
    };
    const response = await request.post("/auth/inventory/getCategory", data);
    //console.log("🚀 ~ file: purchases.jsx ~ line 231 ~ Purchases ~ loadInfo ~ response", response)

    if (response && !response.error) {
      if (response.result.data && !response.empty) {
        this.setState({ category: response.result.data });
      } else {
        this.setState({
          empty: true,
          loading: false,
          message: response.message,
        });
      }
    } else {
      this.setState({
        error: true,
        loading: false,
        message: response.message,
      });
    }
    this.loadProvider();
  }

  async loadData() {
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }
    let data = {
      idArt: this.state.idArt,
      token: token,
    };
    const response = await request.post("/auth/inventory/getArticle", data);
    // console.log("🚀 ~ file: purchases.jsx ~ line 263 ~ Purchases ~ loadData ~ response", response)

    if (response && !response.error) {
      if (response.result && !response.empty) {
        this.setState({
          proveedor: response.result[0].id_prov,
          codProv: response.result[0].cod_alt,
          fechaReg: moment(response.result[0].f_registro).format("YYYY-MM-DD"),
          fechaComp: moment(response.result[0].f_compra).format("YYYY-MM-DD"),
          pComp: response.result[0].precio_compra,
          pProd: response.result[0].precio_prod,
          Cat: response.result[0].subcategoria,
          Caract: response.result[0].caracteristicas,
          loading: true,
        });
      } else {
        this.setState({
          empty: true,
          loading: true,
          message: response.message,
        });
      }
    } else {
      this.setState({
        error: true,
        loading: true,
        message: response.message,
      });
    }
    this.loadProvider();
  }
  async loadProvider() {
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }
    let data = {
      token: token,
    };
    const response = await request.post("/auth/inventory/getProvider", data);
    // console.log("🚀 ~ file: purchases.jsx ~ line 307 ~ Purchases ~ loadProvider ~ response", response)

    if (response && !response.error) {
      if (response.result.provider && !response.empty) {
        this.setState({ providers: response.result.provider });
      } else {
        this.setState({
          empty: true,
          loading: false,
          message: response.message,
        });
      }
    } else {
      this.setState({
        error: true,
        loading: false,
        message: response.message,
      });
    }
  }
  async handleSubmit(e) {
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }

    let data = {
      idArt: this.state.idArt,
      proveedor:
        this.state.proveedor === null
          ? document.getElementById("proveedor").value
          : this.state.proveedor,
      codProv:
        this.state.codProv === null
          ? document.getElementById("codigoProveedor").value
          : this.state.codProv,
      fechaReg:
        this.state.fechaReg === null
          ? document.getElementById("fr").value
          : this.state.fechaReg,
      fechaComp:
        this.state.fechaComp === null
          ? document.getElementById("fc").value
          : this.state.fechaComp,
      pComp:
        this.state.pComp === null
          ? document.getElementById("pc").value
          : this.state.pComp,
      pProd:
        this.state.pProd === null
          ? document.getElementById("pp").value
          : this.state.pProd,
      Cat:
        this.state.Cat === null
          ? document.getElementById("cat").value
          : this.state.Cat,
      Caract:
        this.state.Caract === null
          ? document.getElementById("caract").value
          : this.state.Caract,
      eddit: this.props.eddit ? 1 : 0,
      idUser: this.state.user.id,

      token: token,
    };

    const response = await request.post("/auth/inventory/saveCost", data);
    //console.log("🚀 ~ file: purchases.jsx ~ line 366 ~ Purchases ~ handleSubmit ~ response", response)

    if (response && !response.error) {
      if (response.result && !response.empty) {
        this.setState({ load: false });
        toast.success(
          <span>
            Costos del artículo,
            guardado con exito.
          </span>
        );
      } else {
        this.setState({
          empty: true,
          loading: false,
          message: response.message,
        });
        toast.error(
          <span>
            ¡¡¡UPSS!!!, algo salio
            mal.. intentalo de nuevo, si el problema continua contacta a
            sistemas.
          </span>
        );
      }
    } else {
      this.setState({
        error: true,
        loading: false,
        message: response.message,
      });
    }
  }
}

export default Consumer(Purchases);
