/**
 *  @name: header.jsx
 *  @version: 1.0.0
 *  @author: TAlexis Duarte
 *  @description: Componente Header
 */

import React, { Component } from "react";

import { Link } from "react-router-dom";
//import SesionClose from "../login/sesionClose";
import Request from "../../utils/http";
import { Consumer } from "../../context/index";
import Tooltip from "@material-ui/core/Tooltip";
const request = new Request();

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menus: [],
      user: {
        idUsuario: 0,
        idPerfil: 0,
        nombre: 0,
      },
    };
  }

  render() {
    return (
      <nav className="main-header navbar navbar-expand navbar-light">
        <ul className="navbar-nav">
          <li className="nav-item">
            <Tooltip title="Menú de Modulo">
              <Link className="nav-link" data-widget="pushmenu" to="#">
                <i className="fas fa-bars"></i>
              </Link>
            </Tooltip>
          </li>
          <li className="nav-item">
            <Tooltip title="Menú Principal">
              <Link to="/inicio" className="nav-link">
                <i className="fas fa-home"></i>
              </Link>
            </Tooltip>
          </li>
          <li className="nav-item">
  
              <Link to="#" className="nav-link">
              {this.props.title}
              </Link>
           
          </li>

          {/* {this.state.menus.map((menu, key) => (
            <li className="nav-item d-none d-sm-inline-block" key={key}>
              <Link to={menu.url} className="nav-link">
                <i className={menu.icono}></i>
                {menu.modulo}
              </Link>
            </li>
          ))} */}
        </ul>

        {/*<ul className="navbar-nav ml-auto">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item dropdown">
              <a className="nav-link" data-toggle="dropdown" href="#">
                <i className="far fa-bell"></i>
                <span className="badge badge-danger navbar-badge">15</span>
              </a>
              <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                <span className="dropdown-item dropdown-header">
                  15 Nuevas requisiones
                </span>
                <div className="dropdown-divider"></div>
                <Link to={"/compras"} className="dropdown-item">
                  <i className="fas fa-file mr-2"></i> (0) Pendientes por
                  autorizar
                </Link>

                <div className="dropdown-divider"></div>
                <a href="#" className="dropdown-item">
                  <i className="fas fa-file mr-2"></i> (0) Autorizadas
                </a>
                <div className="dropdown-divider"></div>
              </div>
            </li>
          </ul>

          {/* <li className="nav-item">
            <SesionClose />
          </li> /}
        </ul>*/}
      </nav>
    );
  }
  componentDidMount() {
    // this.loadMenus();
    this.getUser();
  }
  async getUser() {
    let token = JSON.parse(window.sessionStorage.getItem("decoAdmin"));

    if (token) {
      await request
        .get("/auth/user", { token: token.id })
        .then((response) => {
          if (response.statusCode === 200) {
            let user = {
              id: response.result.id,
              perfil: response.result.id_perfil,
              nombre: decodeURIComponent(escape(response.result.name)),
              depto: response.result.depto,
              ubicacion: response.result.ubicacion,
              foto: response.result.foto,
            };
            this.setState({ user: user });
          } else {
            let user = { id: { id: 0, tipo: 0 } };
            this.setState({ user: user });
          }
        })
        .catch((err) => {
          console.log("LoginBox -> handleSubmit -> err", err);
        });
    }

    // this.getNotifications();
  }
  loadMenus() {
    let menus = window.sessionStorage.getItem("Menus");

    if (menus) {
      this.setState({ menus: JSON.parse(menus) });
    }
  }
  sumarDias(fecha, dias) {
    fecha.setDate(fecha.getDate() + dias);
    return fecha;
  }
  async getNotifications() {
    let date = new Date();

    let fecha = this.sumarDias(date, -30);

    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }

    let data = {
      token: token,
      idPerfil: this.state.user.id_perfil,
      idUsuario: this.state.user.id,
      fecha: fecha,
    };
    let response = await request.post("/purchases/getNotifications", data);
    // console.log("response: ", response);
  }
}

export default Consumer(Header);
