import React, { Component } from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import Modal from "react-modal";
import Fab from "@material-ui/core/Fab";
import Button from "@material-ui/core/Button";
//import validator from "./validatorPurchases";
import { Consumer } from "../../context/index";
import Request from "../../utils/http";
import EditIcon from "@material-ui/icons/Edit";
import UploadPhoto from "./uploadPhoto";
import { toast } from "react-toastify";
const request = new Request();
Modal.setAppElement(document.getElementById("root"));

const customStyles = {
  content: {
    position: "absolute",
    top: "5%",
    left: "10%",
    right: "10%",
    bottom: "5%",
    border: "1px solid rgb(204, 204, 204)",
    background: "rgb(255, 255, 255)",
    overflow: "auto",
    outline: "none",
    padding: "10px",
  },
};
class NewWork extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
      eddit: this.props.eddit,
      saved: 0,
      token: "",
      error: false,
      idUsuario: 0,
      idPerfil: 0,
      nombre: "",

      alto: 0,
      ancho: 0,
      codigo: "",
      costo: 0,
      costo_prod: 0,
      id_trabajo: this.props.id,
      id_ubicacion: 1,
      imagen: "",
      t_trabajo: "",
      unidad: "Pieza (Pz)",
    };
  }
  closeModal() {
    this.openModal();
  }
  reload() {
    this.props.reload();
  }
  saved(id) {
    let cont = this.state.saved + 1;
    this.setState({
      saved: cont,
      idArt: id,
    });
  }
  render() {
    return (
      <>
        {this.props.eddit === false ? (
          <Tooltip title={"Agregar Tipo de Trabajo"}>
            <Fab
              color="primary"
              size="small"
              aria-label="add"
              className="MuiButtonBase-root MuiIconButton-root MUIDataTableToolbar-icon-49"
              style={{ padding: "0px" }}
              onClick={this.openModal.bind(this)}
            >
              <AddIcon className="MuiSvgIcon-root" />
            </Fab>
          </Tooltip>
        ) : (
          <div>
            <button
              className="btn btn-sm btn-info bg-gradient-info"
              title="Editar"
              onClick={this.openModal.bind(this)}
            >
              <EditIcon />
            </button>
          </div>
        )}

        <Modal
          isOpen={this.state.openModal}
          style={customStyles}
          contentLabel="EditarArticulo"
        >
          <div className="modal-header">
            <h4 className="modal-title">
              {this.props.id !== 0
                ? this.state.id_trabajo + " - " + this.state.t_trabajo
                : "Nuevo Tipo de Trabajo"}
            </h4>
            <button
              type="button"
              className="close"
              onClick={this.openModal.bind(this)}
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body card-primary card-outline card-outline-tabs">
            <div className="card-body">
              <div className="tab-content" id="custom-tabs-three-tabContent">
                <div className="row">
                  <div className="col-7">
                    <div className="row">
                      <div className="col-md-4 mt-3">
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text">ID:</span>
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            aria-label=""
                            value={this.state.id_trabajo}
                            readOnly
                          ></input>
                        </div>
                      </div>
                      <div className="col-md-8 mt-3">
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text">CÓDIGO:</span>
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            aria-label=""
                            value={this.state.codigo}
                            name="codigo"
                            id="codigo"
                            onChange={(e) =>
                              this.setState({ codigo: e.target.value })
                            }
                          ></input>
                        </div>
                      </div>
                      <div className="col-md-12 mt-3">
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              DESCRIPCIÓN:
                            </span>
                          </div>
                          <textarea
                            className="form-control"
                            aria-label=""
                            value={this.state.t_trabajo}
                            name="t_trabajo"
                            id="t_trabajo"
                            onChange={(e) =>
                              this.setState({ t_trabajo: e.target.value })
                            }
                          ></textarea>
                        </div>
                      </div>
                      <div className="col-md-6 mt-3">
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text">UBICACIÓN:</span>
                          </div>
                          <select
                            className="form-control"
                            aria-label=""
                            value={this.state.id_ubicacion}
                            name="id_ubicacion"
                            id="id_ubicacion"
                            onChange={(e) =>
                              this.setState({ id_ubicacion: e.target.value })
                            }
                          >
                            <option></option>
                            <option value="1">Cancún</option>
                            <option value="2">Riviera Maya</option>
                            <option value="4">Los Cabos</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6 mt-3">
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text">UNIDAD:</span>
                          </div>
                          <select
                            className="form-control"
                            aria-label=""
                            id="unidad"
                            value={this.state.unidad}
                            onChange={(e) =>
                              this.setState({ unidad: e.target.value })
                            }
                          >
                            <option></option>
                            <option>Metro Cuadrado (M2)</option>
                            <option>Metro Lineal (Mtl)</option>
                            <option>Pieza (Pz)</option>
                            <option>Minuto (Min)</option>
                            <option>Hora (Hr)</option>
                            <option>Otro</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6 mt-3">
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text">ANCHO:</span>
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            aria-label=""
                            value={this.state.ancho}
                            id="ancho"
                            onChange={(e) =>
                              this.setState({ ancho: e.target.value })
                            }
                          ></input>
                          <div className="input-group-append">
                            <span className="input-group-text">Mts.</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 mt-3">
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text">ALTO:</span>
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            aria-label=""
                            value={this.state.alto}
                            id="alto"
                            onChange={(e) =>
                              this.setState({ alto: e.target.value })
                            }
                          ></input>
                          <div className="input-group-append">
                            <span className="input-group-text">Mts.</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-5 mt-3">
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text">COSTO:</span>
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            aria-label=""
                            value={this.state.costo}
                            onChange={(e) =>
                              this.setState({ costo: e.target.value })
                            }
                          ></input>
                        </div>
                      </div>
                      <div className="col-md-7 mt-3">
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              COSTO DE PRODUCCIÓN:
                            </span>
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            aria-label=""
                            value={this.state.costo_prod}
                            onChange={(e) =>
                              this.setState({ costo_prod: e.target.value })
                            }
                          ></input>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-5">
                    <div className="card">
                      <img
                        className="card-img-top"
                        src={`${request.publicURL()}/diseno/${
                          this.state.imagen
                        }`}
                        style={{ maxHeight: "15rem" }}
                        alt="Foto Artículo"
                        onError={(e) => {
                          e.target.src = "./assets/uploads/no-image.jpg";
                        }}
                      />
                      {
                        <UploadPhoto
                          id={this.state.id_trabajo}
                          modulo={7}
                          imgAnt={this.state.imagen}
                          reloadImg={this.reloadImg.bind(this)}
                          reload={(e) => this.reload(e)}
                        />
                      }
                    </div>
                  </div>
                </div>
                <div className="white-space-32"></div>
                <div className="modal-footer justify-content-left ">
                  <button
                    type="button"
                    className="btn btn-outline-success btn-flat "
                    onClick={this.handleSubmit.bind(this)}
                  >
                    <i className="far fa-save"></i>&nbsp; Guardar
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-danger btn-flat"
                    onClick={this.openModal.bind(this)}
                  >
                    <i className="fas fa-ban"></i> Cerrar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  }

  componentDidMount() {
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }
    this.setState({ token: token });
    this.getUser();
  }
  reloadImg() {
    this.getWork();
  }
  reload(data) {
    this.props.reload(data);
  }
  async getWork() {
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }
    let data = {
      token: token,
      id: this.state.id_trabajo,
    };

    const response = await request.post("/auth/design/getWorkInfo", data);
    console.log("response :>> ", response);
    if (response && !response.error) {
      if (response.result && !response.empty) {
        console.log("response :>> ", response.result);
        this.setState({
          alto: response.result[0].alto,
          ancho: response.result[0].ancho,
          codigo: response.result[0].codigo,
          costo: response.result[0].costo,
          costo_prod: response.result[0].costo_prod,
          //id_trabajo: this.props.id,
          id_ubicacion: response.result[0].id_ubicacion,
          imagen: response.result[0].imagen,
          t_trabajo: response.result[0].t_trabajo,
          unidad: response.result[0].unidad,
        });
      } else {
        this.setState({
          empty: true,
          loading: false,
          message: response.message,
        });
      }
    } else {
      this.setState({
        error: true,
        loading: false,
        message: response.message,
      });
    }
  }
  async getUser() {
    const user = await this.props.context.loadUser();

    if (user) {
      this.setState({
        idUsuario: user.id,
        idPerfil: user.id_perfil,
        nombre: user.name,
      });
    }
  }
  openModal() {
    this.setState({
      openModal: !this.state.openModal,
    });
    if (this.props.eddit === true) {
      this.getWork();
    }
  }
  async handleSubmit() {
    let data = {
      token: this.state.token,
      alto: this.state.alto,
      ancho: this.state.ancho,
      codigo: this.state.codigo,
      costo: this.state.costo,
      costo_prod: this.state.costo_prod,
      id_trabajo: this.state.id_trabajo,
      id_ubicacion: this.state.id_ubicacion,
      imagen: this.state.imagen,
      t_trabajo: this.state.t_trabajo,
      unidad: this.state.unidad,
    };
    /*let error = validator(data);
    if (error > 0) {
      alert("Asegurate de llenar correctamente los campos marcados con rojo");
    } else {*/
    const response = await request.post("/auth/design/saveWorkInfo", data);
    console.log("response :>> ", response);
    if (response && !response.error) {
      if (response.result && !response.empty) {
        if (data.id_trabajo == 0)
          this.setState({ id_trabajo: response.result[0].id });
        this.setState({ toast: true });
        this.props.reload();
        toast.success("Datos Guardados con exito!", {
          position: toast.POSITION.TOP_RIGHT,
          theme: "colored",
        });
      } else {
        this.setState({ toast: true });

        toast.error(
          "UPSS!!!, algo salio mal.. intentalo de nuevo, si el problema continua contacta a sistemas.",
          {
            position: toast.POSITION.TOP_RIGHT,
            theme: "colored",
          }
        );
        console.log(response.result);
      }
    } else {
      console.log("error en la peticion");
    }
    //}
  }
}
export default Consumer(NewWork);
