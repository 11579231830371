import React, {useEffect, useState} from "react";
import Request from "../../../utils/http";
import MUIDataTable from "mui-datatables";
import ButtonSave from "../../inventory/tabs/buttonSave";

import { render } from 'react-dom';
import { Multiselect } from 'react-widgets';
import 'react-widgets/dist/css/react-widgets.css';
import DropdownList from 'react-widgets/lib/DropdownList';
import BottonNuevaArea from "./bottonNuevaArea";
const request = new Request();


export default function FormUbicaion(props){

    const [ubicacion, setUbicacionConsulta] = useState([]);
    const [registrarSucursal, setRegistrarSucursal] = useState({
        ubicacion:'',
        sucursal:''
    });
    // const [consultaSucursal, setConsultaSucursal] = useState([]);
    const [sucursal, setSucursal] = useState([]);
    const [areasG, setAreasG] = useState([]);
    const [registrarAreas, setRegistrarAreas]= useState({
        ubicacion:'',
        sucursal:'',
        id_areas: [] 
    });

    //nuestro token para todos
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token = token1.id;

    useEffect(() => {
        getUbicacion();
        getAreasGeneral();
    }, []);

    const getUbicacion = async () => {
        let data = {
          token: token
        };
        const response = await request.post("/employee/getUbicacion", data);
        if (response && !response.error) {
          if (response.result.ubicacion && !response.empty) {
            setUbicacionConsulta(response.result.ubicacion);
          } else {
            console.log(response.result.message);
          }
        } else {
            console.log("error en la peticion");
        }
    }
    let getSucursal = async(id) => {

        setRegistrarAreas({...registrarAreas,ubicacion:id});
        let data = {
          token: token,
          id_ubicacion:id
        };
        const response = await request.post("/employee/getSucursal", data);       
        
        if (response && !response.error) {
            if (response.result.sucursal && !response.empty) {
                setSucursal(response.result.sucursal);
            } else {
              console.log(response.result.message);
            }
        } else {
            console.log("error en la peticion");
        }
        
    }
    const getAreasGeneral = async () => {
        let data = {
          token: token
        }
        const response = await request.post("/employee/getAreasGeneral", data);
    
        if (response && !response.error) {
            if (response.result.areasG && !response.empty) {
                setAreasG(response.result.areasG);
            } else {
              console.log(response.result.message);
            }
          } else {
              console.log("error en la peticion");
          }
    
    }
    let validarNewSucursal=data=>{
        let error = 1;
          if (data.ubicacion === null || data.ubicacion.length === 0 || data.ubicacion === "") {
              document.getElementById("ubicacion").classList.add("is-invalid");
              return error;
            }
          if (data.sucursal === null || data.sucursal.length === 0 || data.sucursal === "") {
            document.getElementById("sucursal").classList.add("is-invalid");
            return error;
          }
        return data;
    }
    const saveSucursal = async () => {
        let datos= validarNewSucursal(registrarSucursal);
        if(datos===1){
            alert("favor de llenar llos campos");
        }else{
            let data = {
                token: token,
                datos: datos
              }
      
              const response = await request.post("/employee/saveSucursal", data);
      
              if (response && !response.error) {
                  if (response.result.ok && !response.empty) {
                      alert("sucursal guardado")
                      getSucursal();
                  } else {
                    console.log(response.result.message);
                  }
                } else {
                  console.log("error en la peticion");
              }
        }

    }



    const  saveAreas = async () => {

        let id_area=(registrarAreas.id_areas);
        let id_ubicacion=registrarAreas.ubicacion;
        let id_sucursal=registrarAreas.sucursal;
        let ids_area=[];

        for(let i in id_area){
            ids_area.push(id_area[i].id_areas);
        }


        if(id_ubicacion=="" || id_sucursal=="" ){
            document.getElementById("ubicacion_area").classList.add("is-invalid");
            document.getElementById("sucursal_area").classList.add("is-invalid");

        }else if(ids_area==""){
            alert("Favor de Ingresar un Departamento")
        }else{
            let data = {
                token: token,
                id_ubicacion:id_ubicacion,
                id_sucursal:id_sucursal,
                ids_area: ids_area
              }
    
            const response = await request.post("/employee/saveSucursal_areas", data);
      
              if (response && !response.error) {
                  if (response.result.ok && !response.empty) {
                      alert("Areas por Sucursal se ha Guardado");
                      props.closeModal();
                  } else {
                    console.log(response.result.message);
                  }
                } else {
                  console.log("error en la peticion");
              }
        }

    }

    const alamcenar=(value)=>{
        setRegistrarAreas({...registrarAreas,id_areas:value});
    }


    return(
        <div className="tab-content">
            <h3 className="text-center mb-3 mt-3">
                <span>Registrar Sucursal</span>  
            </h3>
            <hr></hr>
            <div className="row mt-2">
                <div className="col-md-6">
                    <div className="input-group mb-3 mt-2">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Ubicación</span>
                        </div>                      
                        <select className="form-control" 
                            id="ubicacion"
                            name="ubicacion" 
                            value={registrarSucursal.ubicacion}  
                            onChange={e => setRegistrarSucursal({...registrarSucursal,ubicacion:e.target.value})}
                            >
                            <option></option>
                            {ubicacion.map((ubicacion, key) => (
                                <option key={key} 
                                    value={ubicacion.id_ubicacion}
                                    selected={registrarSucursal.ubicacion == ubicacion.id_ubicacion ? true : false}
                                    >
                                    {ubicacion.lugar}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="input-group mb-3 mt-2">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Nombre Sucursal</span>
                        </div>
                        <input type="text"  className="form-control text-uppercase" 
                            id="sucursal" 
                            name="sucursal"
                            value={registrarSucursal.sucursal} 
                            onChange={e => setRegistrarSucursal({...registrarSucursal,sucursal:e.target.value.toUpperCase()})} 
                         />
                    </div>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-md-12">
                    <ButtonSave  
                        save={saveSucursal}
                        closeModal={props.closeModal}
                        />
                </div> 
            </div>

            <h3 className="text-center mb-3 mt-5">
                <span>Registrar Areas por Sucursal</span>
                
            </h3>
            <hr></hr>
            <div className="row mt-2">
                <div className="col-md-6">
                    <div className="input-group mb-3 mt-2">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Ubicación</span>
                        </div>                      
                        <select className="form-control" 
                            id="ubicacion_area"
                            name="ubicacion" 
                            value={registrarAreas.ubicacion}  
                            onChange={e =>getSucursal(e.target.value)}
                            >
                            <option></option>
                            {ubicacion.map((ubicacion, key) => (
                                <option key={key} 
                                    value={ubicacion.id_ubicacion}
                                    selected={registrarAreas.ubicacion == ubicacion.id_ubicacion ? true : false}
                                    >
                                    {ubicacion.lugar}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>

                <div className="col-md-6">
                    <div className="input-group mb-3 mt-2">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Sucursal</span>
                        </div>                      
                        <select className="form-control" 
                            id="sucursal_area"
                            name="sucursal" 
                            value={sucursal.sucursal}  
                            onChange={e => setRegistrarAreas({...registrarAreas,sucursal:e.target.value})}
                            >
                            <option></option>
                            {sucursal.map((sucursalValue, key) => (
                                <option key={key} 
                                    value={sucursalValue.id_sucursal}
                                    selected={sucursal.sucursal == sucursalValue.id_sucursal ? true : false}
                                    >
                                    {sucursalValue.sucursal}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>     
            </div>

            <BottonNuevaArea recargar={getAreasGeneral}/>

            <div className="row mb-4">
                <div className="col-md-9">
                    <div className="row d-flex mt-2">
                        <div className="col-1">
                            <label htmlFor="">Departamento:</label>
                        </div>
                        <div className="col-11">
                            <Multiselect
                                data={areasG}
                                id={item => item.id_areas}
                                textField={item => item.name}
                                onChange={value => alamcenar(value)} 
                            />
                        </div>
                    </div>
                </div>  
            </div>    
            <div className="row mt-2">
                <div className="col-md-12">
                    <ButtonSave  
                    save={saveAreas}
                    closeModal={props.closeModal}
                    />
                </div> 
            </div>
        </div>
    );
}