import React, { useState } from "react";

import moment from "moment-timezone";

import Layout from "../layout";
import Sidebar from "../../components/header/sidebar";
import MUIDataTable from "mui-datatables";

import Request from "../../utils/http";
import { useEffect } from "react";
const request = new Request();

function EventsHistory() {
  const columns = [
    {
      name: "id_evento",
      label: "ID EVENTO",
      options: {
        filter: true,
        sort: true,
      },
    },

    {
      name: "nombre",
      label: "NOMBRE",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: `lugar`,
      label: "UBICACIÓN",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          if (value) return <p>{value}</p>;
          else return <p>info no disponible</p>;
        },
        filter: true,
        sort: true,
      },
    },
    {
      name: "Fecha_inicio",
      label: "FECHA",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          if (value) return <p>{moment(value).format("DD/MM/YYYY")}</p>;
          else return <p>info no disponible</p>;
        },
        filter: true,
        sort: true,
      },
    },
    {
      name: "id_articulo",
      label: "ID ARTÍCULO",
      options: {
        filter: false,
        sort: true,
        searchable: true,
      },
    },
    {
      name: "cantidad",
      label: "CANTIDAD",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "produccion",
      label: "RENTA Y/O PRODUCCIÓN",
      options: {
        filter: false,
        sort: false,
        searchable: false,
      },
    },
  ];

  const options = {
    selectableRows: "none",
    filter: false,
    viewColumns: false,
    // responsive: true,
    textLabels: {
      body: {
        noMatch:
          " No hay datos para mostrar.",
        toolTip: "Sort",
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Número de filas:",
        displayRows: "de",
      },
      toolbar: {
        search: "Buscar",
        downloadCsv: "Descargar Excel",
        print: "Imprimir",
      },
      filter: {
        all: "TODOS",
        title: "FILTROS",
        reset: "LIMPIAR",
      },
      viewColumns: {
        title: "Mostar Columnas",
        titleAria: "Mostrar/Ocultar Columnas de la Tabla",
      },
      selectedRows: {
        text: "Fila(s) Seleccionadas",
        delete: "Eliminar",
        deleteAria: "Eliminar Filar Seleccionadas",
      },
    },
    onDownload: (buildHead, buildBody, columns, data) => {
      return `${buildHead(columns)}${buildBody(data)}`.trim();
    },
  };

  const [data, setData] = useState([]);
  useEffect(() => {
    getInfo();
  }, []);

  const getInfo = async () => {
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }

    let data = {
      token: token,
    };

    const response = await request.post("/auth/reports/eventsHistory", data);

    if (response && !response.error) {
      if (response.result.historial && !response.empty) {
        setData(response.result.historial);
      } else {
        alert(response.result.message);
      }
    } else {
      console.log("algo salio mal");
    }
  };
  return (
    <Layout
      title="Historial de movimientos"
      description="Página privada de perfil"
      keywords="Reactjs, SEO Ready, Ejemplo"
    >
      <Sidebar />
      <div className="content-wrapper" id="tableBodega">
        <div className="card" style={{ margin: "1rem" }}>
          <div className="card-body">
            <MUIDataTable
              title={"Historial de movimientos del sistema"}
              columns={columns}
              options={options}
              data={data}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default EventsHistory;
