/**
 *  @name: private.jsx
 *  @version: 1.0.0
 *  @author: Anahi Aranda
 *  @description: Página privada
 */

import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { Consumer } from "../../context/index";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import { useIndexedDB } from "react-indexed-db";
import moment from "moment-timezone";
import Ods from "../../components/sales/collection/ods";

import Request from "../../utils/http";
const request = new Request();
const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
});
const customStyles = {
  content: {
    top: "35%",
    left: "57%",
    right: "auto",
    bottom: "10%",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    // overflow: "scroll"
  },
};
function CollectionQuote(props) {
  // ///////////////hooks//////////////////
  const [cotizations, setCotizations] = useState([]);
  const [user, setUser] = useState({
    idUsuario: 0,
    idPerfil: 0,
    nombre: "",
  });
  const [modal, setModal] = useState({
    ods: false,
    openModal: false,
    id: 0,
    nombre: "",
  });
  const [dataCot, setDataCot] = useState({
    id: 0,
    title: "",
    start: "",
    color: "",
    textColor: "white",
    status: "",
    id_status: "",
    id_hotel: "",
    nom_hotel: "",
    id_usuario: "",
    nom_vend: "",
    id_tipo: "",
    tipo: "",
    lugar: "",
  });
  const [load, setLoad] = useState(false);
  const classes = useStyles();
  const eventsDB = useIndexedDB("events");
  const hotelDB = useIndexedDB("hotel");
  const sellerDB = useIndexedDB("sellers");
  useEffect(() => {
    loadUser();
  }, []);

  ////////////funciones///////////////////////
  const getData = async () => {
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }

    let data = {
      token: token,
    };

    const response = await request.post("/auth/sales/collectionQuote", data);
    // console.log(response);

    if (response && !response.error) {
      if (response.result.movement && !response.empty) {
        setCotizations(response.result.movement);
        setLoad(true);
      } else {
        alert(response.result.message);
        setLoad(true);
      }
    } else {
      console.log("algo salio mal");
      setLoad(true);
    }
  };
  const loadUser = async () => {
    const user = await props.context.loadUser();

    if (user) {
      setUser(user);
    }
    getData();
  };
  const changeOdsCot = (info) => {
    let val = false;
    info.target.id == "custom-tabs-three-cot-tab"
      ? (val = false)
      : (val = true);
    setModal({
      ods: val,
      openModal: modal.openModal,
      id: modal.id,
      nombre: modal.nombre,
    });
  };
  const copyCollection = async (id) => {
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }

    let data = {
      token: token,
      idCol: id,
      idUs: user.id,
    };
    console.log(
      "🚀 ~ file: collentionQuote.jsx ~ line 146 ~ copyCollection ~ data",
      data
    );

    const response = await request.post("/auth/sales/copyCollection", data);
    console.log(
      "🚀 ~ file: collentionQuote.jsx ~ line 153 ~ copyCollection ~ response",
      response
    );

    if (response && !response.error) {
      if (response.result && !response.empty) {
        toast.success(
          <span>
            Se copio la colección
            al calendario con la fecha de hoy, id:{" "}
            {response.result[0].id_evento}.
          </span>
        );

        props.changeEvent({
          id: Number(response.result[0].id_evento),
          title:
            "***COPIA***-" + moment().format("YYYY-MM-DD ") + dataCot.title,
          id_usuario: user.id,
          start: moment().format("YYYY-MM-DD"),
        });
        props.setModalColection(false);
        setModal({
          ods: false,
          openModal: false,
          id: 0,
          nombre: "",
        });
      }
    }
  };
  const asignData = (info) => {
    setDataCot(info);
  };
  const toogleModal = (id, nombre) => {
    setModal({
      id: id,
      nombre: nombre,
      ods: false,
      openModal: !modal.openModal,
    });
  };
  const handleEventClick = (e, id, nombre) => {
    toogleModal(id, nombre);
  };
  ///////////////return///////////////////////
  return (
    <>
      <div className="modal-header">
        <h6 className="modal-title">Lista de Machotes para Cotización.</h6>
        <button
          type="button"
          className="close"
          onClick={(e) => props.setModalColection(false)}
          aria-label="Close"
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
      {load ? (
        <div className="private column">
          <div className="white-space-32"></div>
          <div className="justify-center full">
            <div className="card" style={{zoom:"75%"}}>
              <div className="card-body row">
                {cotizations.map((info, key) => (
                  <div className="col-6 col-md-3 col-lg-2" key={key}>
                    {info.permisos.indexOf(user.id) != -1 ? (
                      <Card
                        id={key}
                        className={classes.root}
                        style={{ marginTop: 10 + "px" }}
                      >
                        <CardActionArea
                          onClick={(e) =>
                            handleEventClick(e, info.id_coleccion, info.nombre)
                          }
                        >
                          <CardMedia
                            className={classes.media}
                            image={`./assets/collections/${info.imagen}`}
                            title={info.nombre}
                          />
                          <CardContent>
                            <Typography
                              gutterBottom
                              variant="h6"
                              component="h6"
                            >
                              {info.nombre}
                            </Typography>
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              component="p"
                            >
                              {info.hotel}
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    ) : (
                      ""
                    )}
                    <div className="white-space-16"></div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="white-space-32"></div>
        </div>
      ) : (
        <div className="text-center pt-2">
          <i
            className="fas fa-spinner fa-spin"
            style={{ fontSize: "62px", color: "#3f51b5" }}
          ></i>
          <br /> Cargando Machotes
        </div>
      )}
      <Modal
        isOpen={modal.openModal}
        style={customStyles.content}
        contentLabel="Ver info del evento"
      >
        <div className="modal-header">
          <h6 className="modal-title" style={{ width: 100 + "%" }}>
            Colección: <i>"{modal.id + "-" + modal.nombre}"</i>
          </h6>
          <button
            type="button"
            className="btn btn-primary bg-gradient-primary"
            onClick={(e) => copyCollection(modal.id)}
            title="Copiar"
          >
            <i className="far fa-copy"></i>
          </button>
          <button
            type="button"
            className="close"
            onClick={(e) => toogleModal(e)}
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div className="modal-body card-primary card-outline card-outline-tabs">
          <div className="card-header border-bottom-0">
            <ul
              className="nav nav-tabs"
              id="custom-tabs-three-tab"
              role="tablist"
            >
              <li className="nav-item">
                <a
                  className="nav-link"
                  id="custom-tabs-three-ods-tab"
                  data-toggle="pill"
                  href="#custom-tabs-three-ods"
                  role="tab"
                  aria-controls="custom-tabs-three-ods"
                  aria-selected="false"
                  onClick={(e) => changeOdsCot(e)}
                >
                  ODS
                </a>
              </li>

              <li className="nav-item active">
                <a
                  className="nav-link active"
                  id="custom-tabs-three-cot-tab"
                  data-toggle="pill"
                  href="#custom-tabs-three-ods"
                  role="tab"
                  aria-controls="custom-tabs-three-ods"
                  aria-selected="false"
                  onClick={(e) => changeOdsCot(e)}
                >
                  Cotización
                </a>
              </li>
            </ul>
          </div>
          <div
            className="card-body"
            style={{
              border: "1px solid #dee2e6",
              borderTop: "none",
              overflow: "auto",
              maxHeight: "calc(100vh - 35vh)",
            }}
          >
            <div className="tab-content" id="custom-tabs-three-tabContent">
              <div
                className="tab-pane fade active show"
                id="custom-tabs-three-ods"
                role="tabpanel"
                aria-labelledby="custom-tabs-three-ods-tab"
              >
                <Ods
                  ods={modal.ods}
                  idEvent={modal.id}
                  asignData={(e) => asignData(e)}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default Consumer(CollectionQuote);
