import React, { useState, useEffect } from "react";
// import IconButton from "@material-ui/core/IconButton";
// import Tooltip from "@material-ui/core/Tooltip";

// import Fab from "@material-ui/core/Fab";
import Modal from "react-modal";
import { Consumer } from "../../../context/index";
import MUIDataTable from "mui-datatables";
import Button from "@material-ui/core/Button";
import Request from "../../../utils/http";
import NewArticle from "../../inventory/temporary/newArticle";
import ButtonsAction from "../../inventory/temporary/buttonsActions";

import TextField from "@material-ui/core/TextField";

import Icon from "@material-ui/core/Icon";

const request = new Request();
function NewItem(props) {
  const [showModal, setModal] = useState(false);
  const [productos, setProductos] = useState([]);
  const [band, setBand] = useState(0);
  const [searchText, setsearchText] = useState("");
  const [open, setOpen] = useState(false);
  const [imgEst, setImg] = useState("");
  const [user, setUser] = useState({
    id: 0,
    id_perfil: 0,
    name: "",
    ubicacion: 0,
  });

  let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
  let token;
  if (token1) {
    token = token1.id;
  }

  const openModal = (img) => {
    setOpen(true);
    setImg(img);
  };

  const buscador = (valor) => {
    if (valor) {
      setsearchText(valor);
    } else if (valor.length === 0) {
      setsearchText("");
    }
  };

  const getData = async (band) => {
    setProductos([]);
    let response;
    let data = {
      token: token,
      band: band,
      idPerfil: user.id_perfil,
    };
    if (band === 1) {
      /*let productos = JSON.parse(sessionStorage.getItem("Productos"));
      if (productos) {
        setProductos(productos);
      } else {*/
      response = await request.post("/auth/inventory/getArticles", data);
      // }
    } else {
      /*let productos = JSON.parse(sessionStorage.getItem("ProductosTemp"));
      if (productos) {
        setProductos(productos);
      } else {*/
      response = await request.post("/auth/inventory/getArticles", data);
      //}
    }
    console.log(response);
    if (response && !response.error) {
      if (response.result && !response.empty) {
        setProductos(response.result);

        if (band === 1) {
          window.sessionStorage.setItem(
            "Productos",
            JSON.stringify(response.result)
          );
        } else {
          sessionStorage.removeItem("productost");
          window.sessionStorage.setItem(
            "ProductosTemp",
            JSON.stringify(response.result)
          );
        }
      } else {
        console.log(response);
      }
    } else {
      // console.log(response);
    }
  };

  const agregarDatos = (data, pos) => {
    if (band === 1) {
      let stock = { 1: 0, 4: 0 };
      let info_stock = JSON.parse(data.totalStock);
      //console.log("agregarDatos -> stock", stock);
      if (info_stock !== null && info_stock.length !== 0) {
        info_stock.forEach((dat, key) => {
          if (dat.ubi !== 4) {
            stock[1] = stock[1] + dat.cant;
          } else {
            stock[4] = stock[4] + dat.cant;
          }
        });
      }
      props.idUbicacion !== 4
        ? (data.total = stock[1])
        : (data.total = stock[4]);
    }

    let datos = {
      id_evento: 0,
      nombre: "",
      img_pred: data.img_pred,
      id_art: band === 1 ? data.id_art.toString() : `T-${data.id_art}`,
      nomE: data.nomE,
      nomI: data.nomI,
      dim: data.dim,
      total: 0,
      cantidad: band === 1 ? 1 : 0,
      produccion: band === 1 ? 0 : 1,
      preciouni: 0,
      unidad: data.unidad,
      dias: 1,
      importe: 0,
      indicaciones: "",
      comentarios: "",
      id_subevento: "",
      orden: "",
      locacion_sub: "",
      fecha_montaje: "00-00-0000 00:00",
      fecha_entrega: "00-00-0000 00:00",
      oculto_ods: 0,
      oculto_cot: 0,
      img_char: data.img_pred.substr(0, 4),
      id_subevento_ant: 0,
      tabla: band === 1 ? 0 : 1,
      stock: band === 1 ? data.total : 0,
      cotizados: 0,
      bloqueados: 0,
      idUbicacion: props.idUbicacion,
      subEvents: props.subEvents,
    };
    props.funcion(datos);
  };
  const customStock = (value) => {
    if (band !== 2) {
      let stock = { cun: 0, rm: 0, cab: 0 };
      if (value !== null) {
        value = JSON.parse(value);

        //console.log("TableArticles -> customStock -> value", value)

        for (let i = 0; i < value.length; i++) {
          let el = value[i];
          switch (el.ubi) {
            case 1:
              stock.cun = el.cant;
              break;
            case 2:
            case 3:
              stock.rm = stock.rm + el.cant;
              break;
            case 4:
              stock.cab = el.cant;
              break;
          }
        }
      }
      return (
        <>
          {stock.cun !== 0 ? (
            <div
              className="bg-gradient-info"
              title={`Stock Cancún: ${stock.cun}`}
              style={{
                padding: "0.2rem",
                fontWeight: "bold",
                borderRadius: "0.2rem",
                marginTop: "0.1rem",
              }}
            >
              CUN: {stock.cun}
            </div>
          ) : null}
          {stock.rm !== 0 ? (
            <div
              className="bg-gradient-secondary"
              title={`Stock Riviera Maya: ${stock.rm}`}
              style={{
                padding: "0.2rem",
                fontWeight: "bold",
                borderRadius: "0.2rem",
                marginTop: "0.1rem",
              }}
            >
              RM: {stock.rm}
            </div>
          ) : null}
          {stock.cab !== 0 ? (
            <div
              className="bg-gradient-primary"
              title={`Stock Los Cabos: ${stock.cab}`}
              style={{
                padding: "0.2rem",
                fontWeight: "bold",
                borderRadius: "0.2rem",
                marginTop: "0.1rem",
              }}
            >
              CAB: {stock.cab}
            </div>
          ) : null}
        </>
      );
    } else {
      return <>0</>;
    }
  };
  const columns = [
    {
      name: "id_usuario",
      label: "Acciones",
      options: {
        filter: false,
        sort: false,
        searchable: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          let activo = false;

          for (let index = 0; index < props.boton.length; index++) {
            if (
              props.boton[index].indice === tableMeta.rowIndex &&
              props.boton[index].inv === tableMeta.rowData[7]
            ) {
              activo = true;
            }
          }

          return (
            <>
              {/*(value === user.id ||
                user.id_perfil === 1 ||
                user.id_perfil === 2) &&
              band !== 1 ? (
                <>
                  <ButtonsAction
                    id={tableMeta.rowData[1]}
                    nombre={tableMeta.rowData[3]}
                    data={productos[tableMeta.rowIndex]}
                    reload={(e) => reload(e)}
                  />
                  <br />
                  <br />
                </>
              ) : null*/}
              <Button
                variant="contained"
                color="primary"
                onClick={(e) =>
                  agregarDatos(
                    productos[tableMeta.rowIndex],
                    tableMeta.rowIndex
                  )
                }
                disabled={activo}
              >
                Agregar
              </Button>
            </>
          );
        },
      },
    },
    {
      name: "id_art",
      label: "ID",
      options: {
        filter: true,
        sort: false,
        sortDescFirst: true,
      },
    },
    {
      name: "img_pred",
      label: "IMAGEN",
      options: {
        filter: false,
        sort: false,
        searchable: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          let img = "";
          if (value !== null && value.slice(0, 4) === "http") {
            img = value;
          } else {
            img = `${request.publicURL()}/inventario/${value}`;
          }
          return (
            <div className="text-center">
              <img
                src={
                  value !== null && value.slice(0, 4) === "http"
                    ? value
                    : `${request.publicURL()}/inventario/${value}`
                }
                style={{ maxHeight: 100 + "px" }}
                alt="Imagen Data"
                onError={(e) => {
                  e.target.src = "./assets/uploads/no-image.jpg";
                }}
                onClick={(e) => {
                  openModal(img);
                }}
              />
            </div>
          );
        },
      },
    },
    {
      name: "nomE",
      label: "NOMBRE",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "dim",
      label: "DIM",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "area",
      label: "AREA",
      options: {
        filter: true,
        sort: false,
        display: band === 2 ? false : true,
      },
    },
    {
      name: "totalStock",
      label: "STOCK",
      options: {
        filter: false,
        sort: false,
        display: band === 2 ? false : true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return customStock(value);
        },
      },
    },
    {
      name: "temp",
      label: "INVENTARIO",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          if (value === 0) {
            return <p>Bodega</p>;
          } else {
            return <p>Temporal</p>;
          }
        },
      },
    },
  ];
  const options = {
    selectableRows: "none",
    responsive: "standard",
    filter: false,
    viewColumns: false,
    print: false,
    download: false,
    search: true,
    rowsPerPageOptions: [10, 20, 50, 100, 500],
    sortOrder: {
      name: "ID",
      direction: "desc",
    },
    customToolbar: () => {
      if (band === 2)
        return <NewArticle eddit={false} reload={(e) => reload(e)} />;
    },
    textLabels: {
      body: {
        noMatch: "No se encontraron resultados.",
        toolTip: "Sort",
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Número de filas:",
        displayRows: "de",
      },

      filter: {
        all: "TODOS",
        title: "FILTROS",
        reset: "LIMPIAR",
      },
      viewColumns: {
        title: "Mostar Columnas",
        titleAria: "Mostrar/Ocultar Columnas de la Tabla",
      },
      selectedRows: {
        text: "Fila(s) Seleccionadas",
        delete: "Eliminar",
        deleteAria: "Eliminar Filar Seleccionadas",
      },
    },
    searchText: searchText,

    customSearchRender: (searchText, handleSearch, hideSearch, options) => {
      let valor = "";
      return (
        <div className="row">
          <div className="col-md-8" style={{ paddingRigth: 0 + "px" }}>
            <TextField
              id="standard-basic"
              label="filtrar"
              onChange={(e) => (valor = e.target.value)}
            />
          </div>
          <div
            className="col-md-4"
            style={{ paddingLeft: 0 + "px", marginTop: 15 + "px" }}
          >
            <Button
              className="personal"
              variant="contained"
              color="primary"
              size="small"
              endIcon={<Icon className="fas fa-chevron-right" />}
              onClick={(e) => buscador(valor)}
            >
              Buscar
            </Button>
            <Button
              className="personal"
              variant="contained"
              color="secondary"
              size="small"
              style={{ marginLeft: 5 + "px" }}
              endIcon={<Icon className="fas fa-window-close" />}
              onClick={
                ((e) => buscador(""),
                (e) => (document.getElementById("standard-basic").value = ""))
              }
            >
              Limpiar
            </Button>
          </div>
        </div>
      );
    },
    onDownload: (buildHead, buildBody, columns, data) => {
      return `${buildHead(columns)}${buildBody(data)}`.trim();
    },
  };
  const getinfo = (band) => {
    load();
    setModal(true);
    getData(band);
    setBand(band);
  };
  const load = async () => {
    const user = await props.context.loadUser();

    if (user) {
      setUser(user);
    }
  };
  const customStyles = {
    content: {
      position: "absolute",
      top: "10px",
      left: "10px",
      right: "10px",
      bottom: "10px",
      border: "1px solid rgb(204, 204, 204)",
      background: "rgb(255, 255, 255)",
      overflow: "auto",
      outline: "none",
      padding: "20px",
      zoom: "87%",
    },
  };
  const customStyles2 = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };
  const sendData = () => {
    setModal(false);
  };
  const reload = (e) => {
    setProductos([]);
    sessionStorage.removeItem("ProductosTemp");
    getData(2);
  };
  return (
    <>
      <button
        className="btn btn-sm btn-primary bg-gradient-primary"
        onClick={(e) => getinfo(1)}
      >
        <i className="far fa-list-alt"></i> Bodega
      </button>
      <button
        className="btn btn-sm btn-primary bg-gradient-primary ml-4"
        onClick={(e) => getinfo(2)}
      >
        <i className="fas fa-stopwatch"></i> Temporal
      </button>
      {showModal ? (
        <Modal
          isOpen={showModal}
          contentLabel="EditarArticulo"
          style={customStyles}
        >
          <div className="modal-header">
            <h4 className="modal-title">
              {band === 2
                ? "Agregar Artículos Temporales"
                : "Agregar Artículos de Bodega"}
            </h4>
            <button
              type="button"
              className="close"
              onClick={(e) => sendData()}
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body card-primary">
            <MUIDataTable
              title={
                band === 2
                  ? "Lista Artículos Temporales"
                  : "Lista Artículos de Bodega"
              }
              columns={columns}
              options={options}
              data={productos}
            />
          </div>
        </Modal>
      ) : null}
      {
        /*Modal imagen*/
        open ? (
          <Modal isOpen={open} style={customStyles2} contentLabel="imagen">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                onClick={(e) => setOpen(false)}
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>

            <div className="card-body">
              <div
                className="tab-content"
                id="custom-tabs-three-tabContent"
                style={{ display: "flex" }}
              >
                <div>
                  <img
                    src={imgEst}
                    alt=""
                    style={{
                      width: "auto",
                      maxWidth: 800 + "px",
                      height: "auto",
                      maxHeight: 400 + "px",
                    }}
                  />
                </div>
              </div>
            </div>
          </Modal>
        ) : null
      }
    </>
  );
}
export default Consumer(NewItem);
