import React, { Component } from "react";
import Modal from "react-modal";
import { Consumer } from "../../../context/index";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";
import GeneralData from "./generalData";

Modal.setAppElement(document.getElementById("root"));
const customStyles = {
  content: {
    top: "50%",
    left: "80%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    overflow: "scroll",
  },
};
class NewArticle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
      user: {
        idUsuario: 0,
        idPerfil: 0,
        nombre: "",
      },
      data: {
        id_art: 0,
        tags: "",
        id_unidad: 5,
        Activo: 1,
      },
      eddit: this.props.eddit,
      saved: 0,
    };
  }
  closeModal() {
    this.openModal();
  }
  reload() {
    this.props.reload();
    // this.props.search(this.state.data.Articulo);
  }
  saved() {
    let cont = this.state.saved + 1;
    this.setState({
      saved: cont,
    });
  }
  render() {
    return (
      <>
        <Tooltip title={"Agregar Artículo"}>
          <Fab
            color="primary"
            size="small"
            aria-label="add"
            className="MuiButtonBase-root MuiIconButton-root MUIDataTableToolbar-icon-49"
            style={{ padding: "0px" }}
            onClick={this.openModal.bind(this)}
          >
            <AddIcon className="MuiSvgIcon-root" />
          </Fab>
        </Tooltip>
        {
          <Modal
            isOpen={this.state.openModal}
            style={customStyles.content}
            contentLabel="EditarArticulo"
          >
            <div className="modal-header">
              <h4 className="modal-title">Crear Artículo Nuevo</h4>
              <button
                type="button"
                className="close"
                onClick={this.openModal.bind(this)}
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body card-primary card-outline card-outline-tabs">
              <GeneralData
                idArt={0}
                data={this.state.data}
                user={this.state.user}
                closeModal={this.openModal.bind(this)}
                reload={this.reload.bind(this)}
              />
            </div>
          </Modal>
        }
      </>
    );
  }
  openModal() {
    this.setState({
      openModal: !this.state.openModal,
    });
  }
  componentDidMount() {
    this.load();
  }
  async load() {
    const user = await this.props.context.loadUser();
    let data = this.state.data;
    data.id_usuario = user.id;
    if (user) {
      this.setState({
        user: user,
        data: data,
      });
    }
  }
}
export default Consumer(NewArticle);
