import React, {useEffect, useState} from "react";
import Request from "../../utils/http";
import ButtonSave from "../general/buttonSave";
import moment from "moment";

import { render } from 'react-dom';
import { Multiselect } from 'react-widgets';
import 'react-widgets/dist/css/react-widgets.css';
import DropdownList from 'react-widgets/lib/DropdownList';


import ButtonCategoriaProducto from "./buttonCategoriaProducto";
// import ButtonUnidadMedidad from "./buttonUnidadMedida";
// import validarNuevoProducto from "./validarNewProducto";
import {Consumer} from "../../context/index";

const request = new Request();

function NewFormProducto(props){

    //hooks---
    const [productos, setNewProductos] = useState({
        id_ubicacion:props.context.user.ubicacion,
        id_users:props.context.user.id,
        id_perfil:props.context.user.id_perfil,
        id_producto:'',
        categoria:'',
        name:'',
        codigo:'',
        marca:'',
        color:'',
        dimension:'',
        descripcion:'',
        precio_compra:'',
        precio_almacen:'',
        stock:'',
        tipo_material:'',
        unidad_medida:'',
        avatar:''

    });
    const [categoriasAlmacen, setListarCategorias]=useState([]);
    const [unidadMedida, setListarUnidadMedida]=useState([]);

    //obteneos token
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token=token1.id;
    //al cargar la pagina se ejecuta useefect
    useEffect(() => {
        getCategoriasAlmacen();
        getUnidadMedida();
        // para obtener nuestro valores de editar
        if(props.datos){
            setNewProductos(
                {
                    id_ubicacion:props.datos.id_ubicacion,
                    id_users:props.datos.id_users,
                    id_producto:props.datos.id_producto,
                    categoria:props.datos.id_categoria,
                    name:props.datos.nombre_producto,
                    codigo:props.datos.codigo_producto,
                    unidad_medida:props.datos.id_unidad_medida,
                    marca:props.datos.marca,
                    color:props.datos.color,
                    dimension:props.datos.dimension,
                    descripcion:props.datos.descripcion,
                    descripcion:props.datos.descripcion,
                    stock:props.datos.stock_almacen,
                    precio_compra:props.datos.precio_compra,
                    precio_almacen:props.datos.precio_venta,
                    tipo_material:props.datos.tipo_material,
                    avatar:props.datos.avatar
                }
            )
        }
    }, []);


    // nuestros metodos---
    const save = async () => {

        let perfil=props.context.user.id_perfil;
        let datos =(productos); 
        let id_producto=datos.id_producto;
        let fechaAltual = new Date();
        //cambiar formato de fecha
        let fecha=moment(fechaAltual).format("YYYY-MM-DD");
        let horaActual = (new Date()).getHours()+':'+(new Date()).getMinutes()+':'+(new Date()).getSeconds();     
        let avatar = document.getElementById('avatar').files[0];

        if (datos === 1) {
            alert("favor de llenar todos los campos");
        } else {
            //Nos permite agregar mas datos a JSON STRING
            datos["fecha"]=fecha;
            datos["hora"]=horaActual;
            //FIN
            let formData = new FormData();
            formData.append( "avatar", avatar );
            formData.append( "token", token);
            formData.append( "datos", JSON.stringify(datos));
            const headers = {
                cache: false,
                contentType: false,
                processData: false
            };
            const response = await request.post("/almacen/saveProductosAlmacen", formData,headers);
            
            if (response && !response.error) {
                if (response.result.ok && !response.empty) {
                    console.log(response.result.ok);
                    if(id_producto==='' ||id_producto==null){
                        alert("Productos Guardados con Exito");
                        //obtenemos desde los props para darle parametros al recargar la tabla
                        props.recargar(datos.id_users,datos.id_perfil,datos.id_ubicacion);
                        setNewProductos({
                            categoria:'',
                            name:'',
                            codigo:'',
                            marca:'',
                            color:'',
                            dimension:'',
                            descripcion:'',
                            precio_compra:'',
                            precio_almacen:'',
                            stock:'',
                            tipo_material:'',
                            unidad_medida:'',
                            avatar:'' 
                        });
                        
                    }else{
                        alert("Productos Actualizado Con Exito");
                        props.recargar(datos.id_users,perfil,datos.id_ubicacion);
                        props.closeModal();
                    }

                } else {
                console.log(response.result.message);
                }
            } else {
                console.log("error en la peticion");
            }
        }
    }

    const getCategoriasAlmacen = async () => {
        let data = {
            token: token
        };
        const response = await request.post("/almacen/getCategoriasAlmacen", data);
        if (response && !response.error) {
            if (response.result.categoriasAlmacen && !response.empty) {
              setListarCategorias(response.result.categoriasAlmacen);
            }else {
                console.log(response.result.message);
            }
        } else {
            console.log("error en la peticion");
        }
    }
    const obtenerDatosCategoria = async(value)=>{   
        setNewProductos({ ...productos, categoria:value.id_categoria});

    }

    const getUnidadMedida = async () => {
        let data = {
            token: token
        };
        const response = await request.post("/almacen/getUnidadMedida", data);
        if (response && !response.error) {
            if (response.result.unidadMedida && !response.empty) {
              setListarUnidadMedida(response.result.unidadMedida);
            }else {
                console.log(response.result.message);
            }
        } else {
            console.log("error en la peticion");
        }
    }
    const obtenerUnidadMedida = async(value)=>{   
        setNewProductos({ ...productos, unidad_medida:value.id_unidad_medida});  
    }
    
    const subirFoto=(event)=>{

        let imagen=event.target.files[0];
        if(imagen["type"]!=="image/jpeg" && imagen["type"]!=="image/png"){
            alert("La imagen debe estar en formato JPG O PNG !");
            event.target.value = '';
            return;
 
        }else{
            let datosArchivo = new FileReader;
            datosArchivo.readAsDataURL(imagen);
            
            datosArchivo.onload=(event)=>{
              let rutaArchivo= event.target.result;

              document.getElementById("resultado").src =rutaArchivo;
            };

            setNewProductos({...productos,avatar:imagen.name});
        }
        
    }



    return(
        <div>
            <ButtonCategoriaProducto recargar={getCategoriasAlmacen}/>
            <div className="row mt-2">
                <div className="col-12 col-md-4">
                    <div className="row">
                        <div className="col-sm-12 mb-5">
                            <div className="conte_name">
                                <div className="select_name">
                                    <span className="input-group-text border_name">Categoria:</span>
                                </div>
                                <div className="select_name1 text-uppercase">
                                    {
                                        props.datos==null?
                                        <DropdownList filter
                                            data={categoriasAlmacen}
                                            id="categoria"
                                            allowCreate="onFilter"
                                            onChange={value =>obtenerDatosCategoria(value)}
                                            textField="nombre"
                                        />
                                        :
                                        <DropdownList filter
                                            data={categoriasAlmacen}
                                            id="categoria"
                                            allowCreate="onFilter"
                                            onChange={value =>obtenerDatosCategoria(value)}
                                            textField="nombre"
                                            defaultValue={props.datos.nombreCategoria}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Nombre:</span>
                        </div>
                        <input type="text" className="form-control text-uppercase"
                            id="name" 
                            name="name" 
                            value={productos.name} 
                            onChange={e => setNewProductos({...productos,name:e.target.value.toUpperCase()})} 
                        />
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Marca:</span>
                        </div>
                        <input type="text" className="form-control text-uppercase"
                            id="codigo" 
                            name="codigo"
                            value={productos.codigo} 
                            onChange={e => setNewProductos({...productos,codigo:e.target.value})} 
                        />
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12 col-md-4">
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Modelo:</span>
                        </div>
                        <input type="text"  className="form-control text-uppercase"
                            id="marca" 
                            name="marca"
                            value={productos.marca} 
                            onChange={e => setNewProductos({...productos,marca:e.target.value.toUpperCase()})} 
                        />
                    </div>
                </div>
                <div className="col-12 col-md-4">
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Color:</span>
                        </div>
                        <input type="text"  className="form-control text-uppercase"
                            id="color" 
                            name="color"
                            value={productos.color} 
                            onChange={e => setNewProductos({...productos,color:e.target.value.toUpperCase()})} 
                        />
                    </div>
                </div>
                <div className="col-12 col-md-4">
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Numero Serie:</span>
                        </div>
                        <input type="text"  className="form-control text-uppercase"
                            id="color" 
                            name="color"
                            value={productos.color} 
                            onChange={e => setNewProductos({...productos,color:e.target.value.toUpperCase()})} 
                        />
                    </div>
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-md-4">
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Dimension:</span>
                        </div>
                        <input type="text"  className="form-control text-uppercase"
                            id="dimension" 
                            name="dimension"
                            value={productos.dimension} 
                            onChange={e => setNewProductos({...productos,dimension:e.target.value.toUpperCase()})} 
                        />
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Descripcion</span>
                        </div>
                            <textarea className="form-control text-uppercase" aria-label="With textarea" 
                                    id="descripcion"
                                    name="descripcion"
                                    value={productos.descripcion} 
                                    onChange={e => setNewProductos({...productos,descripcion:e.target.value.toUpperCase()})} 
                                >
                            </textarea>
                    </div>
                </div>

                <div className="col-md-4">
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Stock:</span>
                        </div>
                        <input type="number"  className="form-control text-uppercase"
                            id="stock" 
                            name="stock"
                            value={productos.stock} 
                            onChange={e => setNewProductos({...productos,stock:e.target.value})} 
                        />
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-4">
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Precio Compra:</span>
                        </div>
                        <input type="number"  className="form-control text-uppercase"
                            id="precio_compra"
                            name="precio_compra"
                            value={productos.precio_compra} 
                            onChange={e => setNewProductos({...productos,precio_compra:e.target.value})} 
                        />
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Folio:</span>
                        </div>
                        <input type="text"  className="form-control text-uppercase"
                            id="tipo_material" 
                            name="tipo_material"
                            value={productos.tipo_material} 
                            onChange={e => setNewProductos({...productos,tipo_material:e.target.value.toUpperCase()})} 
                        />
                    </div>
                </div>
            </div>
            
            <div className="row mb-2 d-flex flex-column">
                <div className="col-md-3 mb-1">
                    <div className="caja-imagen">
                        <img  id="resultado" 
                            src={
                                productos.avatar ===null
                                ? "./assets/uploads/no-image.jpg"
                                : `./assets/uploads/productosAlmacen/${productos.avatar}`
                            }
                            alt=""
                        /> 
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="input-file">
                        <div className="input-group-text name_imagen">
                            <span>Subir Imagen: </span>
                        </div>
                        <input type="file" 
                            id="avatar" 
                            name="avatar"
                            onChange={e => subirFoto(e)}
                        />
                    </div>
                </div>
            </div>

            <ButtonSave  
                save={save} 
                closeModal={props.closeModal}
            />
        </div>
    );
}

export default  Consumer(NewFormProducto);