import React, {useEffect, useState} from "react";
import Request from "../../../utils/http";
import MUIDataTable from "mui-datatables";
import moment from "moment";
import { Consumer } from "../../../context/index";
import ButtonSave from "../../general/buttonEliminarEmpleado";

const request = new Request();

function FormElimnarEmpleado(props){

    const [bajaEmpleado, setBajaEmpleado] = useState({
        id_ubicacion:props.context.user.ubicacion,
        id_users:props.context.user.id,
        id_perfil:props.context.user.id_perfil,
        motivo:''
    });
    //nuestro token para todos
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token = token1.id;

    let validarBajaEmpleado=data=>{
        let error = 1;
        if (data.motivo.length === 0 || data.motivo === "") {
            document.getElementById("motivo").classList.add("is-invalid");
          return error;
        }
        return data;
    }
    const eliminarEmpleado = async () => {
        //validamos campos
        let datos=validarBajaEmpleado(bajaEmpleado);
        if(datos==1){
            alert("favor de llenar el campo");
        }else{
            if(window.confirm('Esta seguro de Elimiar?')){
                let fechaAltual = new Date();
                let data = {
                  token: token,
                  datos: datos,
                  id:props.datos.id,
                  fecha: moment(fechaAltual).format("YYYY-MM-DD")
                }
                const response = await request.post("/employee/deleteEmployee", data);
                if (response && !response.error) {
                    if (response.result.ok && !response.empty) {
                        props.recargar(datos.id_users,datos.id_perfil,datos.id_ubicacion);
                        alert("Empleado eliminado");
                        props.closeModal();
                    }
                }
            }      
        }

    }

    return(
        <div className="tab-content">
            <p>
                <span className="font-weight-bold">Nombre Empleado:</span>
                {props.datos.name+' '+props.datos.last_name}&nbsp;&nbsp;&nbsp;&nbsp;
                <span className="font-weight-bold">Ubicación:</span>
                {props.datos.lugar}</p>
           
            <div className="row mt-2 mb-3">
                <div className="col-md-12">
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Motivos de Baja</span>
                        </div>
                        <textarea 
                            className="form-control" aria-label="With textarea"
                            id="motivo"
                            name="motivo"
                            onChange={e => setBajaEmpleado({...bajaEmpleado,motivo:e.target.value})}
                        >
                        </textarea>
                    </div>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-md-12">
                    <ButtonSave  
                    save={eliminarEmpleado}
                    closeModal={props.closeModal}
                    />
                </div> 
            </div>
        </div>
    );
}

export default Consumer(FormElimnarEmpleado);