import React, { useState, useEffect } from "react";
import { Consumer } from "../../context/index";
import Modal from "react-modal";
import Actions from "./tabs/actions";
import Ods from "./tabs/ods";
import Attachment from "./tabs/attachment";
import ModalPrice from "../desing/modalPriceDesign";
import AttachmentDesign from "../desing/attachment";
import NotifyOds from "./tabs/notifyOds";

Modal.setAppElement("#root");

function ModalOds(props) {
  const [user, setUser] = useState({
    idUsuario: 0,
    idPerfil: 0,
    nombre: "",
    ubicacion: 0,
  });
  const [ods, setOds] = useState(props.id_modulo !== 9 ? true : false);
  const [numAdj, setNumAdj] = useState(0);
  const [numAdjD, setNumAdjD] = useState(0);
  const [showAttach, setAttach] = useState(false);

  const customStyles = {
    content: {
      position: "absolute",
      top: "10px",
      left: "50px",
      right: "50px",
      bottom: "10px",
      border: "1px solid rgb(204, 204, 204)",
      background: "rgb(255, 255, 255)",
      overflow: "auto",
      outline: "none",
      padding: "10px",
    },
  };

  useEffect(() => {
    if (props.infoEvent.id_event !== 0) {
      loadUser();
    }
  }, [props.infoEvent.id_event]);

  const loadUser = async () => {
    const user = await props.context.loadUser();

    if (user) {
      setUser(user);
    }
  };

  const changeOdsCot = (info) => {
    let val = true;
    info.target.id === "custom-tabs-three-cot-tab"
      ? (val = false)
      : (val = true);
    setOds(val);
  };

  const newAttachFile = (val) => {
    if (val === 0) setAttach(false);
    else setAttach(true);
    //setAttach(!showAttach);
  };

  return (
    <Modal
      isOpen={props.modalOds}
      style={customStyles}
      contentLabel="Ver info del evento"
    >
      <div className="modal-header p-1">
        <div className="col-12 row">
          <div className="col-12 col-sm-6 col-lg-8 col-xl-9 text-left">
            <h6 className="modal-title" style={{ width: 100 + "%" }}>
              Evento:{" "}
              <i>"{props.infoEvent.id_event + "-" + props.infoEvent.title}"</i>
            </h6>
          </div>
          <div className="col-12 col-sm-6 col-lg-4 col-xl-3 text-right">
            <Actions
              idUser={props.infoEvent.idUser}
              idEvent={props.infoEvent.id_event}
              title={props.infoEvent.title}
              idModulo={props.id_modulo}
              changeEvent={(e) => props.changeEvent(e)}
              newAttachFile={() => newAttachFile()}
              setModalEddit={(e) => props.setModalEddit(e)}
              setRecargaOds={(e) => props.setRecargaOds(e)}
              deleteCalendarEvent={(e) => props.deleteCalendarEvent(e)}
              recargaOds={props.recargaOds}
            />
          </div>
        </div>

        <button
          type="button"
          className="close"
          onClick={() => props.setModalOds(false)}
          aria-label="Close"
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div className="modal-body card-primary card-outline card-outline-tabs">
        <div className="card-header border-bottom-0">
          <ul
            className="nav nav-tabs"
            id="custom-tabs-three-tab"
            role="tablist"
          >
            {props.id_modulo !== 9 ? (
              <li className="nav-item active">
                <a
                  className="nav-link active"
                  id="custom-tabs-three-ods-tab"
                  data-toggle="pill"
                  href="#custom-tabs-three-ods"
                  role="tab"
                  aria-controls="custom-tabs-three-ods"
                  aria-selected="false"
                  onClick={(e) => changeOdsCot(e)}
                >
                  ODS
                </a>
              </li>
            ) : null}
            {/*props.id_modulo == 1 ? (
              <li className="nav-item">
                <a
                  className="nav-link"
                  id="custom-tabs-three-home-tab"
                  data-toggle="pill"
                  href="#custom-tabs-three-home"
                  role="tab"
                  aria-controls="custom-tabs-three-home"
                  aria-selected="false"
                >
                  Seguimiento Entrada-Salida
                </a>
              </li>
            ) : null*/}
            {(props.id_modulo == 2 &&
              (user.id == props.infoEvent.idUser ||
                user.id_perfil == 1 ||
                user.id_perfil == 2)) ||
            (props.id_modulo == 9 &&
              (user.id == props.infoEvent.idUser || user.id_perfil != 3)) ? (
              <li className="nav-item">
                <a
                  className={
                    props.id_modulo !== 9 ? "nav-link" : "nav-link active"
                  }
                  id="custom-tabs-three-cot-tab"
                  data-toggle="pill"
                  href="#custom-tabs-three-ods"
                  role="tab"
                  aria-controls="custom-tabs-three-ods"
                  aria-selected="false"
                  onClick={(e) => changeOdsCot(e)}
                >
                  Cotización
                </a>
              </li>
            ) : null}
            <li className="nav-item" id="attachment">
              <a
                className="nav-link"
                id="custom-tabs-three-adj-tab"
                data-toggle="pill"
                href="#custom-tabs-three-adj"
                role="tab"
                aria-controls="custom-tabs-three-adj"
                aria-selected="false"
              >
                Adjuntos
                <span className="badge badge-primary right ml-2">
                  {numAdj + numAdjD}
                </span>
              </a>
            </li>
            {props.id_modulo == 2 &&
            (user.id == props.infoEvent.idUser || user.id_perfil != 3) ? (
              <>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="custom-tabs-three-price-tab"
                    data-toggle="pill"
                    href="#custom-tabs-three-price"
                    role="tab"
                    aria-controls="custom-tabs-three-price"
                    aria-selected="false"
                  >
                    Costos Diseño
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="custom-tabs-three-notify-tab"
                    data-toggle="pill"
                    href="#custom-tabs-three-notify"
                    role="tab"
                    aria-controls="custom-tabs-three-notify"
                    aria-selected="false"
                  >
                    Notificar
                  </a>
                </li>
              </>
            ) : null}
            {props.id_modulo == 7 ? (
              <li className="nav-item" id="pricedesing">
                <a
                  className="nav-link"
                  id="custom-tabs-three-price-tab"
                  data-toggle="pill"
                  href="#custom-tabs-three-price"
                  role="tab"
                  aria-controls="custom-tabs-three-price"
                  aria-selected="false"
                >
                  Cotización Diseño
                </a>
              </li>
            ) : null}
          </ul>
        </div>
        <div
          className="card-body"
          style={{
            border: "1px solid #dee2e6",
            borderTop: "none",
            overflow: "auto",
            maxHeight: "calc(100vh - 28vh)",
          }}
        >
          <div className="tab-content" id="custom-tabs-three-tabContent">
            <div
              className="tab-pane fade active show"
              id="custom-tabs-three-ods"
              role="tabpanel"
              aria-labelledby="custom-tabs-three-ods-tab"
            >
              <Ods
                ods={ods}
                recargaOds={props.recargaOds}
                idEvent={props.infoEvent.id_event}
                idModulo={props.id_modulo}
                setRecargaOds={(e) => props.setRecargaOds(e)}
              />
              <pricesView></pricesView>
            </div>
            <div
              className="tab-pane fade"
              id="custom-tabs-three-adj"
              role="tabpanel"
              aria-labelledby="custom-tabs-three-adj-tab"
            >
              <Attachment
                idEvent={props.infoEvent.id_event}
                attach={showAttach}
                countAdj={(e) => setNumAdj(e)}
                newAttachFile={(e) => newAttachFile(e)}
              />
              {props.id_modulo != 7 &&
              (user.id == props.infoEvent.idUser || user.id_perfil != 3) ? (
                <AttachmentDesign
                  idEvent={props.infoEvent.id_event}
                  attach={false}
                  id_modulo={props.id_modulo}
                  countAdj={(e) => setNumAdjD(e)}
                />
              ) : (
                <AttachmentDesign
                  idEvent={props.infoEvent.id_event}
                  attach={true}
                  id_modulo={props.id_modulo}
                  countAdj={(e) => setNumAdjD(e)}
                />
              )}
            </div>
            <div
              className="tab-pane fade"
              id="custom-tabs-three-price"
              role="tabpanel"
              aria-labelledby="custom-tabs-three-price-tab"
            >
              {props.id_modulo == 2 &&
              (user.id == props.infoEvent.idUser || user.id_perfil != 3) ? (
                <ModalPrice idEvent={props.infoEvent.id_event} eddit={false} />
              ) : (
                <ModalPrice idEvent={props.infoEvent.id_event} eddit={true} />
              )}
            </div>
            {props.id_modulo == 2 &&
            (user.id == props.infoEvent.idUser || user.id_perfil != 3) ? (
              <div
                className="tab-pane fade"
                id="custom-tabs-three-notify"
                role="tabpanel"
                aria-labelledby="custom-tabs-three-notify-tab"
              >
                <NotifyOds
                  idEvent={props.infoEvent.id_event}
                  closeModal={() => props.setModalOds(false)}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </Modal>
  );
}
export default Consumer(ModalOds);
