import React, { Component } from "react";
import MUIDataTable from "mui-datatables";
import Request from "../../..//utils/http";
const request = new Request();
class History extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: false,
    };
  }
  render() {
    const columns = [
      // {
      //   name: "activo",
      //   label: "Activo",
      //   options: {
      //     filter: true,
      //     sort: true,
      //     customBodyRender: (value, tableMeta, updateValue) => {
      //       return <div>{value === 0 ? <p>Inactivo</p> : <p>Activo</p>}</div>;
      //     }
      //   }
      // },

      {
        name: "id_evento",
        label: "ID",
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        name: "nom_Evento",
        label: "Evento",
        options: {
          filter: true,
          sort: true,
        },
      },

      {
        name: "cantidad",
        label: "Cantidad",
        options: {
          filter: false,
          sort: false,
          searchable: false,
        },
      },
      {
        name: "produccion",
        label: "Produccion",
        options: {
          filter: true,
          sort: true,
          searchable: false,
        },
      },
      {
        name: "total",
        label: "Total",
        options: {
          filter: false,
          sort: false,
          searchable: false,
        },
      },
      {
        name: "dias",
        label: "Dias",
        options: {
          filter: false,
          sort: false,
          searchable: false,
        },
      },
      {
        name: "Fecha_inicio",
        label: "Fecha",
        options: {
          filter: false,
          sort: false,
        },
      },
    ];

    const options = {
      selectableRows: "none",
      filter: false,
      viewColumns: false,
      textLabels: {
        body: {
          noMatch: "No se encontraron resultados.",
          toolTip: "Sort",
        },
        pagination: {
          next: "Siguiente",
          previous: "Anterior",
          rowsPerPage: "Número de filas:",
          displayRows: "de",
        },
        toolbar: {
          search: "Buscar",
          downloadCsv: "Descargar Excel",
          print: "Imprimir",
        },
        filter: {
          all: "TODOS",
          title: "FILTROS",
          reset: "LIMPIAR",
        },
        viewColumns: {
          title: "Mostar Columnas",
          titleAria: "Mostrar/Ocultar Columnas de la Tabla",
        },
        selectedRows: {
          text: "Fila(s) Seleccionadas",
          delete: "Eliminar",
          deleteAria: "Eliminar Filar Seleccionadas",
        },
      },
    };
    return this.state.loading ? (
      <MUIDataTable
        title={"Historial de eventos en los que ha estado el artículo"}
        columns={columns}
        options={options}
        data={this.state.data}
      />
    ) : (
      <div className="text-center">
        <i
          className="fas fa-spinner fa-spin"
          style={{ fontSize: "62px", color: "#3f51b5" }}
        ></i>
        <br /> Cargando Historial del Artículo...
      </div>
    );
  }

  componentDidMount() {
    this.loadInfo();
  }
  async loadInfo() {
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }
    let data = {
      idArt: this.props.id,
      token: token,
    };
    // console.log("data: ", data);
    const response = await request.post("/auth/inventory/getHistory", data);

    if (response && !response.error) {
      if (response.result.historial && !response.empty) {
        this.setState({
          data: response.result.historial,
          loading: true,
        });
      } else {
        this.setState({
          empty: true,
          loading: true,
          message: response.message,
        });
      }
    } else {
      this.setState({
        error: true,
        loading: true,
        message: response.message,
      });
    }
  }
}

export default History;
