import React, {useEffect, useState} from "react";
import Request from "../../../utils/http";
import Select from "react-select";
import ButtonSave from "../../inventory/tabs/buttonSave";

import { render } from 'react-dom';
import { Multiselect } from 'react-widgets';
import 'react-widgets/dist/css/react-widgets.css';
import DropdownList from 'react-widgets/lib/DropdownList';

const request = new Request();

const customStyles = {
    content: {
        width: "60px"
    }
};

export default function NewFormPermiso(props){

    //set de valores
    const [permiso, setPermiso] = useState({
        name:'',
        date:'',
        tipo_permiso:'',
        time:'',
        date_i:'',
        date_f:'',
        description:''
    });
    const [employee, setEmployee] = useState([]);
    const [datosEmpleado, setDatosEmpleado] = useState([]);

 
    const [prueba, setPrueva]= useState({
        nombre:''
    });
  
    //obteneos token
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
        token = token1.id;
    }
    useEffect(() => {
        getEmpleadosGeneral();
    }, []);

    const getEmpleadosGeneral = async () => {
        let data = {
            token: token
        };
        const response = await request.post("/employee/getEmpleadosGeneral", data);

        
        if (response && !response.error) {
            if (response.result.employeeGeneral && !response.empty) {
                setEmployee(response.result.employeeGeneral);
            } else {
                console.log(response.result.message);
            }
        } else {
            console.log("error en la peticion");
        }
    }
    

    const obtenerDatos = async(value)=>{   

        setPermiso({ ...permiso, name:value.id});

       const datos = employee.find(item=>{
            if(item.id==value.id){
                setDatosEmpleado(item);
            }
        });
        
        
    }
    
    const save = async () => {
        let datos =(permiso); 
        
        if (datos === 1) {
            alert("Asegurate de llenar los campos");
        } else {
            let data = {
                token: token,
                datos: datos
        };
    
        const response = await request.post("/permiso/savePermiso", data);

            if (response && !response.error) {
                if (response.result.ok && !response.empty) {
                    alert("permiso guardado correctamente");
                    props.loadTable(true);
                } else {
                  console.log(response.result.message);
                }
              } else {
                console.log("error en la peticion");
            }

        } 

    }

    
    return(
        <div className="tab-content">
            <div className="row mt-2">
                <div className="col-12 col-md-6">
                    <div className="row">
                        <div className="col-sm-12 mb-5">
                            <div className="conte_name">
                                <div className="select_name">
                                    <span className="input-group-text border_name">Nombre</span>
                                </div>
                                <div className="select_name1">
                                    <DropdownList filter
                                        data={employee}
                                        id={item => item.id}
                                        // textField={item => item.name+'  '+item.apellido}
                                        defaultValue={employee[0]}
                                        allowCreate="onFilter"
                                        onChange={value =>obtenerDatos(value)}
                                        textField="full_name"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-md-6">
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Fecha de Solicitud</span>
                        </div>
                        <input type="date" className="form-control" name="date"   
                         value={permiso.date} 
                         onChange={e => setPermiso({...permiso,date:e.target.value})}                           
                            
                        />
                    </div>
                </div>
            </div>

            <div className="row mt-2">
                <div className="col-md-6">
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text">ubicacion</span>
                        </div>
                        <input type="text"  name="ubicacion" className="form-control" 
                            value={datosEmpleado.lugar === null? '':datosEmpleado.lugar}
                            disabled
                        />

                    </div>
                </div>
                <div className="col-md-6">
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Sucursal</span>
                        </div>
                        <input type="text"  name="ubicacion" className="form-control"
                            value={datosEmpleado.sucursal === null? '':datosEmpleado.sucursal}
                            disabled
                        />
                    </div>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-md-6">
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Departamento</span>
                        </div>
                        <input type="text"  name="ubicacion" className="form-control"
                            value={datosEmpleado.nombre_area === null? '':datosEmpleado.nombre_area}
                            disabled
                        />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Puesto</span>
                        </div>
                        <input type="text"  name="ubicacion" className="form-control"
                            value={datosEmpleado.puesto === null? '':datosEmpleado.puesto}
                            disabled
                        />
                    </div>
                </div>
            </div>

            <div className="row mt-2">
                <div className="col-md-6">
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Tipo de Permiso</span>
                        </div>
                        <select className="custom-select" id="inputGroupSelect01" name="tipo_permiso"
                            value={permiso.tipo_permiso} 
                            onChange={e => setPermiso({...permiso,tipo_permiso:e.target.value})}    
                        >
                            <option></option>
                            <option value="1">sin Sueldo</option>
                            <option value="2">Con Sueldo</option>
                        </select>
                    </div>
                </div> 
                <div className="col-md-6">
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Hora </span>
                        </div>
                        <input type="time" name="time" className="form-control" 
                         value={permiso.time} 
                         onChange={e => setPermiso({...permiso,time:e.target.value})} 
                        />
                    </div>
                </div> 
            </div>
            <h3 className="mb-3 mt-3"><span>Periodo de Tiempo Solicitado </span></h3>
            <hr/>

            <div className="row mt-4">
                <div className="col-md-6">
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Fecha Inicial</span>
                        </div>
                        <input type="date" className="form-control" name="date_i"                             
                            aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default"
                            value={permiso.date_i} 
                            onChange={e => setPermiso({...permiso,date_i:e.target.value})} 
                        />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Fecha Termino</span>
                        </div>
                        <input type="date" className="form-control" name="date_f"                             
                            aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default"
                            value={permiso.date_f} 
                            onChange={e => setPermiso({...permiso,date_f:e.target.value})} 
                        />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Motivo</span>
                        </div>
                        <textarea className="form-control" aria-label="With textarea" name="description"
                            value={permiso.description} 
                            onChange={e => setPermiso({...permiso,description:e.target.value})} 
                        ></textarea>
                    </div>
                </div>
            </div>
            <ButtonSave  
                save={save} 
                closeModal={props.closeModal}
            />
        </div>
    );
}