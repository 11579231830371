/**
 *  @name: header.jsx
 *  @version: 1.0.0
 *  @author: TAlexis Duarte
 *  @description: Componente Header
 */

import React, { useState, useEffect } from "react";
import Request from "../../utils/http";
import { Link } from "react-router-dom";
import { Consumer } from "../../context/index";

const request = new Request();
function SubModules(props) {
  const [user, setUser] = useState({
    idUsuario: 0,
    idPerfil: 0,
    nombre: "",
    idUbicacion: 0,
  });
  const [subModulos, setSubModulos] = useState([]);
  const [subModulosLoad, setSubModulosLoad] = useState(true);
  const [activeMenu, setActiveMenu] = useState("");
  const [activeSubMenu, setActiveSubMenu] = useState("");
  const [viewMenu, setViewMenu] = useState("none");

  useEffect(() => {
    // let menu = window.location.pathname;
    // if (menu === props.menu.url) {
    //   setActiveMenu("menu-open");
    //   setViewMenu("block");
    // }
    getSubMenus();
  }, []);
  useEffect(()=>{
    let menu = window.location.pathname;
    if(subModulos.length!==0){
      subModulos.forEach(sub => {
        if(sub.subMenu===menu){
          setActiveMenu("menu-open");
          setActiveSubMenu("active");
          setViewMenu("block");
          document.getElementById(sub.subMenu).classList.add('active');
        }
      });
    }
  },[subModulos])

  const displayChange = () => {
    if (viewMenu === "none") {
      setActiveMenu("menu-open");
      setViewMenu("block");
    } else {
      setActiveMenu("");
      setViewMenu("none");
    }
  };
  const getSubMenus = async () => {
    let idMenu = props.menu.id_menu;
    let submod = JSON.parse(sessionStorage.getItem(`SubMenu${idMenu}`));

    if (!submod || submod.length === 0) {
      let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
      let token;
      if (token1) {
        token = token1.id;
      }
      let data = {
        idMenu: idMenu,
        token: token,
      };

      const response = await request.post("/auth/menu/submodules", data);

      if (response && !response.error) {
        if (response.result.subModulos && !response.empty) {
          setSubModulos(response.result.subModulos);
          window.sessionStorage.setItem(
            "SubMenu" + idMenu,
            JSON.stringify(response.result.subModulos)
          );
          setSubModulosLoad(false);
        } else {
        }
      } else {
      }
    } else if (submod.length !== 0) {
      setSubModulos(submod);
      setSubModulosLoad(false);
    } else {
      setSubModulosLoad(false);
    }
  };

  return (
    <li className={`nav-item ${activeMenu}`}>
      <a
        className={`nav-link ${activeSubMenu}`}
        title={props.menu.modulo}
        onClick={() => displayChange()}
        style={{ cursor: "pointer" }}
      >
        <i className={`nav-icon ${props.menu.icono}`}></i>
        <p className="color-white">
          {props.menu.modulo}
          {!subModulosLoad ? (
            subModulos.length !== 0 ? (
              <i className="right fas fa-angle-left"></i>
            ) : null
          ) : (
            <i className="right fas fa-spinner fa-spin"></i>
          )}
        </p>
      </a>
      {subModulos.length !== 0 ? (
        <ul
          className="nav nav-treeview"
          id={`subModulo_${props.menu.modulo}`}
          style={{ display: viewMenu }}
        >
          {subModulos.map((sub, key) => (
            <li className="nav-item" key={key}>
              <Link
                to={sub.subMenu}
                id={sub.subMenu}
                className={`nav-link`}
                title={sub.titulo}
              >
                <i className={`nav-icon ${sub.icono}`}></i>
                <p className="color-white">&nbsp;{sub.titulo}</p>
              </Link>
            </li>
          ))}
        </ul>
      ) : null}
    </li>
  );
}

export default SubModules;
