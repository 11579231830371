import React, {useEffect, useState} from "react";
import MUIDataTable from "mui-datatables";
import DeleteIcon from '@material-ui/icons/Delete';
import Request from "../../utils/http";
import moment from "moment";
import ModalNewProductoAlmacen from "./modalNewProducto";
// import ButtonEditarProducto from "./buttonEditarProducto";
import {Consumer} from "../../context/index";

const request = new Request();

function ListarProductos(props){

  const [productosAlmacen, setListarProductos]=useState([]);
  // Nuestro token
  let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
  let token=token1.id;
  
  useEffect(() => {
    getUser();
  }, []);



 //realizamos una busqueda si hay status
  let respuestaStatus='';
  const datos = productosAlmacen.find(item=>{
    respuestaStatus=item.status
  });
  //fin

const getUser = async () => {
  const user = await props.context.loadUser();
  //nos permite enviar datos quien inicio sesion
    let id=user.id;
    let perfil=user.id_perfil;
    let ubicacion=user.ubicacion;
    if(id!=null && perfil!=null &&ubicacion!=null){
      getProductosAlmacen(id,perfil,ubicacion);
    }
};

const getProductosAlmacen = async (id,perfil,ubicacion) => {

      let data = {
        token: token,
        id:id,
        perfil:perfil,
        ubicacion:ubicacion
    };
 
    const response = await request.post("/almacen/getProductosAlmacen", data);
    //resultado de la peticion
    if (response && !response.error) {
        if (response.result.productoAlmacen && !response.empty) {
          setListarProductos(response.result.productoAlmacen);
        }else {
            console.log(response.result.message);
        }
    } else {
        console.log("error en la peticion");
    }

}

const eliminar= async(datosId)=>{     

  let data = {
      id: datosId,
      token: token
  };
  
  const response = await request.post("/almacen/deleteProductoAlamcen", data);  
  //resultado de nuestra peticion     
  if (response && !response.error) {
      if (response.result.ok) {
          alert("Producto Eliminado");
          refresTable(datosId);
          
      } else {
          console.log(response.result.message);
      }
  } else {
      console.log("error en la peticion");
  }
}

function refresTable(id_producto) {
  //obtenemos dos datos en el hooks
  let new_productos = Object.assign([],productosAlmacen);
  if (new_productos.length>0 || Object.keys(new_productos).length>0){
      for(let i in new_productos){
          let productos = new_productos[i];
          if (productos.id_producto == id_producto){
            //eliminamos producto que existe en el array
              new_productos.splice(i, 1);
              break;
          }
      }
      setListarProductos([]);
      setListarProductos(new_productos);
  }
}

  const columns = [
    {
      name: "avatar",
      label: "Foto",
        options: {
          filter: true,
          sort: false,
            customBodyRender: (value, tableMeta, updateValue) => {
              let foto=productosAlmacen[tableMeta.rowIndex].avatar;   
              return <img  id="foto" src={foto=='' ? `./assets/uploads/empleados/no-image.jpg`:`./assets/uploads/productosAlmacen/${foto}`}  
              alt=""/>
          }
        }
     },
     {
        name: "codigo_producto",
        label: "CodigoProducto",
        options: {
          filter: true,
          sort: false,
        }
     },
    {
      name: "nombre_producto",
      label: "NombreProducto",
      options: {
          filter: true,
          sort: false,
      }
     },
     {
        name: "marca",
        label: "Marca",
        options: {
          filter: true,
          sort: false,
        }
     },
     {
        name: "medida",
        label: "UnidadMedida",
        options: {
          filter: true,
          sort: false,
        }
     },
     {
       name: "dimension",
        label: "Dimension",
        options: {
          filter: true,
          sort: false,
        }
     },
    {
      name: "precio_compra",
      label: "Precio",
      options: {
        filter: true,
        sort: false,
      }
    },

    {
      name: "stock_almacen",
      label: "stock",
      options: {
        filter: true,
        sort: false,
      }
    },

    {
     name: "state",
     label: "Aciones",
     options: {
      filter: true,
      sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          // console.log(employee[tableMeta.rowIndex].id_vacations);
          return (
              <div>
                  <div className="btn-group">
                      <button
                          className="btn btn-sm btn-danger"
                          title="Eliminar"
                          onClick={()=>{if(window.confirm('Esta seguro de Elimiar?')){eliminar(productosAlmacen[tableMeta.rowIndex].id_producto)}}} 
                          >
                          <DeleteIcon />
                      </button>
                  </div>
                  {/* <ButtonEditarProducto datos={productosAlmacen[tableMeta.rowIndex]} updateProductos={getProductosAlmacen}></ButtonEditarProducto> */}
              </div>
          )
        }
     }
    }
   ];
   

//parte de administrador
const columnsAdmin = [
  {
   name: "avatar",
   label: "Foto",
     options: {
       filter: true,
       sort: false,
         customBodyRender: (value, tableMeta, updateValue) => {
           let foto=productosAlmacen[tableMeta.rowIndex].avatar;   
           return <img  id="foto" src={foto=='' ? `./assets/uploads/empleados/no-image.jpg`:`./assets/uploads/productosAlmacen/${foto}`}  
           alt=""/>
       }
     }
  },
  {
     name: "codigo_producto",
     label: "CodigoProducto",
     options: {
       filter: true,
       sort: false,
     }
  },
  {
    name: "lugar",
    label: "Ubicacion",
    options: {
      filter: true,
      sort: false,
    }
 },
 {
   name: "nombre_producto",
   label: "NombreProducto",
   options: {
       filter: true,
       sort: false,
   }
  },
  {
     name: "marca",
     label: "Marca",
     options: {
       filter: true,
       sort: false,
     }
  },
  {
     name: "medida",
     label: "UnidadMedida",
     options: {
       filter: true,
       sort: false,
     }
  },
  {
    name: "dimension",
     label: "Dimension",
     options: {
       filter: true,
       sort: false,
     }
  },
 {
   name: "precio_compra",
   label: "Precio",
   options: {
     filter: true,
     sort: false,
   }
 },

 {
   name: "stock_almacen",
   label: "stock",
   options: {
     filter: true,
     sort: false,
   }
 },

 {
  name: "state",
  label: "Aciones",
  options: {
   filter: true,
   sort: true,
     customBodyRender: (value, tableMeta, updateValue) => {
       // console.log(employee[tableMeta.rowIndex].id_vacations);
       return (
           <div>
               <div className="btn-group">
                   <button
                       className="btn btn-sm btn-danger"
                       title="Eliminar"
                       onClick={()=>{if(window.confirm('Esta seguro de Elimiar?')){eliminar(productosAlmacen[tableMeta.rowIndex].id_producto)}}} 
                       >
                       <DeleteIcon />
                   </button>
               </div>
               {/* <ButtonEditarProducto datos={productosAlmacen[tableMeta.rowIndex]} updateProductos={getProductosAlmacen}></ButtonEditarProducto> */}
           </div>
       )
     }
  }
 }
];


   const options = {
    selectableRows: "none",
    filter: false,
    viewColumns: false,
    textLabels: {
      body: {
        noMatch: "No se encontraron resultados.",
        toolTip: "Sort"
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Número de filas:",
        displayRows: "de"
      },

      toolbar: {
        search: "Buscar",
        downloadCsv: "Descargar Excel",
        print: "Imprimir"
      },

      filter: {
        all: "TODOS",
        title: "FILTROS",
        reset: "LIMPIAR"
      },
      viewColumns: {
        title: "Mostar Columnas",
        titleAria: "Mostrar/Ocultar Columnas de la Tabla"
      },
      selectedRows: {
        text: "Fila(s) Seleccionadas",
        delete: "Eliminar",
        deleteAria: "Eliminar Filar Seleccionadas"
      }
    },
    customToolbar: () => {
      return <ModalNewProductoAlmacen recargar={getProductosAlmacen}/>;
    }
    
  };

    return ( 
      <MUIDataTable
        title={"Productos de Almacen"}
        data={productosAlmacen}
        columns={respuestaStatus==1?columnsAdmin:columns}
        options={options}
    />

    )

}
export default Consumer(ListarProductos);

