import React from "react";
import Button from '@material-ui/core/Button';

function filterProvider(){
  
    return(
        <div>
            <div className="white-space-16"></div>
            <div className="filter-provider">
                <div className="caja-filtro">
                    <div className="filtro input-group mb-3">
                        <div className="input-group-append">
                            <label className="input-group-text">Filtro</label>
                        </div>
                        <select className="custom-select">
                            <option selected>Choose...</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                        </select>
                    </div>
                    <div className="filtro input-group mb-3">
                        <select className="custom-select">
                            <option selected>Choose...</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                        </select>
                        <div className="input-group-append">
                            <button className="btn btn-md btn-info bg-gradient-info mr-1 ml-1"><i className="fas fa-search"></i></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default filterProvider;
