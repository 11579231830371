import React, { useEffect, useState } from "react";
import Request from "../../../utils/http";
import MUIDataTable from "mui-datatables";
import DeleteIcon from '@material-ui/icons/Delete';
import moment from "moment";

const request = new Request();



export default function DetalleEmpleado(props) {

    console.log(props.datos);
    let fechaNacimiento=moment(props.datos.date_birth).format("DD-MM-YYYY"); 
    let fechaIngreso=moment(props.datos.date_hire).format("DD-MM-YYYY"); 

    return (
        <div className="tab-content">
            <div className="row">
                <div className="col-md-3 d-flex justify-content-center">
                    <div className="foto_detalles">
                        <p className="font-weight-bold">Foto de Perfil</p>
                        <img id="foto" src={props.datos.avatar == '' ? `./assets/uploads/empleados/no-image.jpg` : `./assets/uploads/empleados/${props.datos.avatar}`} />
                    </div>
                </div>
                <div className="col-md-8">
                    <div className="row">
                        <div className="col-md-6">
                            <ul className="mt-3 details_em">
                                <h2>Datos del empleado</h2>
                                <li>
                                    <span className="font-weight-bold ">Numero de Empleado:</span>
                                    {props.datos.id}
                                </li>
                                <li>
                                    <span className="font-weight-bold ">Nombre de Empleado:</span>
                                    {props.datos.name + ' ' + props.datos.last_name}
                                </li>
                                <li>
                                    <span className="font-weight-bold ">Sexo:</span>
                                    {props.datos.sex == 1 ? 'Masculino' : 'Femenino'}
                                </li>
                                <li>
                                    <span className="font-weight-bold ">Estado Civil:</span>
                                    {props.datos.civil_status}
                                </li>
                                <li>
                                    <span className="font-weight-bold ">Fecha Nacimiento:</span>
                                    {fechaNacimiento}
                                </li>
                                <li>
                                    <span className="font-weight-bold ">Edad:</span>
                                    {props.datos.years}
                                </li>
                                <li>
                                    <span className="font-weight-bold ">Fecha Ingreso:</span>
                                    {fechaIngreso}
                                </li>
                                <li>
                                    <span className="font-weight-bold ">Direccion:</span>
                                    {props.datos.address}
                                </li>
                                <li>
                                    <span className="font-weight-bold ">Ubicacion:</span>
                                    {props.datos.lugar}
                                </li>
                                <li>
                                    <span className="font-weight-bold ">Sucursal:</span>
                                    {props.datos.sucursal}
                                </li>
                                <li>
                                    <span className="font-weight-bold ">Departamento:</span>
                                    {props.datos.nombre_area}
                                </li>
                                <li>
                                    <span className="font-weight-bold ">Puesto:</span>
                                    {props.datos.puesto}
                                </li>
                                <li>
                                    <span className="font-weight-bold ">Nivel de Estudio:</span>
                                    {props.datos.level}
                                </li>
                                <li>
                                    <span className="font-weight-bold ">Tipo de Contrato:</span>
                                    {props.datos.contract_type==1?'Indeterminado':'Determinado'}
                                </li>
                                <li>
                                    <span className="font-weight-bold ">Salario Diario $</span>
                                    {props.datos.salary_daily}
                                </li>
                                <li>
                                    <span className="font-weight-bold ">Salario Mensual $</span>
                                    {props.datos.salary_mensual}
                                </li>
                                <li>
                                    <span className="font-weight-bold ">Horas Extras $</span>
                                    {props.datos.hours_ex}
                                </li>
                                <li>
                                    <span className="font-weight-bold ">NSS:</span>
                                    {props.datos.nss}
                                </li>
                                <li>
                                    <span className="font-weight-bold ">RFC:</span>
                                    <span className="text-uppercase">{props.datos.rfc}</span>
                                    
                                </li>
                                <li>
                                    <span className="font-weight-bold ">CURP:</span>
                                    <span className="text-uppercase">{props.datos.curp}</span>
                                    
                                </li>
                                <li>
                                    <span className="font-weight-bold ">Tipo de Sangre:</span>
                                    {props.datos.tipo_sangre}
                                </li>
                                <li>
                                    <span className="font-weight-bold ">Correo:</span>
                                    {props.datos.email}
                                </li>
                                <li>
                                    <span className="font-weight-bold ">Celular de Empresa:</span>
                                    {props.datos.cel_empresa}
                                </li>
                                <li>
                                    <span className="font-weight-bold ">Celular Personal:</span>
                                    {props.datos.cel_personal}
                                </li>
                                <li>
                                    <span className="font-weight-bold ">Numero Lockers:</span>
                                    {props.datos.num_lockers}
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-6">
                            <ul className="mt-3 details_em">
                                <h2 className="mt-2">Datos de Referencia</h2>
                                <li>
                                    <span className="font-weight-bold ">Nombre:</span>
                                    {props.datos.nombre_contacto + '  ' + props.datos.apellido_contacto}
                                </li>
                                <li>
                                    <span className="font-weight-bold ">sexo:</span>
                                    {props.datos.sexo_contacto==1?'MASCULINO':'FEMENINO'}
                                </li>
                                <li>
                                    <span className="font-weight-bold ">Numero Celular:</span>
                                    {props.datos.numTelefono}
                                </li>
                                <li>
                                    <span className="font-weight-bold ">Numero Telefono:</span>
                                    {props.datos.numTelefono2}
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-6">
                            <ul className="mt-3 details_em">
                                <h2 className="mt-2">Datos del Banco</h2>
                                <li>
                                    <span className="font-weight-bold ">Nombre Banco:</span>
                                    {props.datos.banco}
                                </li>
                                <li>
                                    <span className="font-weight-bold ">Numero de Cuenta:</span>
                                    {props.datos.num_cuenta}
                                </li>
                                <li>
                                    <span className="font-weight-bold ">Clabe Interbancaria:</span>
                                    {props.datos.clabe_banco}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}