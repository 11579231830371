/**
 *  @name: private.jsx
 *  @version: 1.0.0
 *  @author: Trejocode - Sergio
 *  @description: Página privada
 */

import React, { useState, useEffect } from "react";
import { Consumer } from "../../context/index";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import ButtonActions from "./buttonsActions";
import Request from "../../utils/http";
import ModalEddit from "./modalEdit";
const request = new Request();

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    //overflow: "scroll",
  },
};

function SupList(props) {
  const [user, setUser] = useState({
    idUsuario: 0,
    idPerfil: 0,
    nombre: "",
    idUbicacion: 0,
  });
  const [sup, setSup] = useState([]);
  const [supId, setSupId] = useState({ id: 0, nom: "Nuevo" });
  const [load, setLoad] = useState(true);
  const [ModalEdit, setModalEdit] = useState(false);
  const [nombre, setNombre] = useState("");
  const [location, setLocation] = useState([]);
  const [locationList, setLocationList] = useState([]);

  useEffect(() => {
    loadUser();
    getSupervisores();
    getLocations();
  }, []);

  const loadUser = async () => {
    const user = await props.context.loadUser();

    if (user) {
      setUser(user);
    }
  };
  const getSupervisores = async () => {
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }

    let data = {
      token: token,
    };

    const response = await request.post(
      "/auth/operations/getSupervisores",
      data
    );
    console.log(response);
    if (response && !response.error) {
      if (response.result.sup && !response.empty) {
        setSup(response.result.sup);
        setLoad(false);
      } else {
        alert(response.result.message);
      }
    } else {
      console.log("algo salio mal");
    }
  };
  const getLocations = async () => {
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }
    let data = {
      token: token,
    };

    const response = await request.post("/auth/sales/getubicaciones", data);

    if (response && !response.error) {
      if (response.result && !response.empty) {
        setLocationList(response.result.ubicaciones);
      } else {
        // this.setState({
        //   empty: true,
        //   loading: false,
        //   message: response.message
        // });
      }
    } else {
      // this.setState({
      //   error: true,
      //   loading: false,
      //   message: response.message
      // });
    }
  };
  const handleChangeFilter = async (event, list) => {
    let state = {
      nombre: nombre,
      location: location,
    };
    switch (event.target.name) {
      case "nombre":
        setNombre(event.target.value);
        state.nombre = event.target.value;
        break;
      case "ubicacion":
        state.location = event.target.value;
        setLocation(event.target.value);
        break;
      default:
        setNombre(state.nombre);
        setLocation(state.location);
        break;
    }
  };
  const clearSearch = () => {
    setLoad(true);
    setNombre("");
    setLocation([]);
    getSupervisores();
  };
  const getSearchEvents = async () => {
    setLoad(true);
    let token1 = JSON.parse(sessionStorage.getItem("decoAdmin"));
    let token;
    if (token1) {
      token = token1.id;
    }

    let data = {
      token: token,
      nombre: nombre,
      location: location,
    };

    const response = await request.post(
      "/auth/operations/getSearchSupervisores",
      data
    );

    console.log(response);
    if (response && !response.error) {
      if (response.result.sup && !response.empty) {
        setSup(response.result.sup);
        setLoad(false);
      } else {
        alert(response.result.message);
      }
    } else {
      console.log("algo salio mal");
    }
  };
  const openModal = (e) => {
    setModalEdit(!ModalEdit);
    setSupId(e);
  };
  const reload = async (e) => {
    getSupervisores();
    setSupId(e);
  };
  return (
    <div className="content-wrapper" id="tableDaily">
      <div className="card" style={{ margin: "1rem" }}>
        <div className="card-header">
          <div
            className="card-title row card-title"
            style={{ width: "100%" }}
          >
            <div className="col-1 text-right">
            <button
                className="btn btn-info btn-sm bg-gradient-info button-filter"
                onClick={() => openModal({ id: 0, nom: "Nuevo Supervisor" })}
              >
                <i className="fas fa-plus"></i>
              </button>
            </div>
            <div className="col-2 text-center">
              <TextField
                label="Nombre"
                name="nombre"
                value={nombre}
                onChange={(e) => handleChangeFilter(e)}
              />
            </div>
            <div className="col-2 text-center">
              <FormControl>
                <InputLabel htmlFor="type">Ubicación</InputLabel>
                <Select
                  multiple
                  value={location}
                  input={<Input id="ubicacion" />}
                  renderValue={(selected) => selected.join(", ")}
                  name="ubicacion"
                  onChange={(e) => handleChangeFilter(e)}
                >
                  {locationList.map((info, key) => (
                    <MenuItem key={key} value={info.lugar}>
                      <Checkbox checked={location.indexOf(info.lugar) > -1} />
                      <ListItemText
                        primary={info.lugar}
                        style={{ textTransform: "capitalize" }}
                      />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="col-2 text-left">
              <button
                className="btn btn-primary btn-sm button-filter"
                onClick={() => getSearchEvents()}
              >
                <i className="fas fa-search"></i>
              </button>
              &nbsp;
              <button
                className="btn btn-secondary btn-sm button-filter"
                onClick={() => clearSearch()}
              >
                <i className="fas fa-times"></i>
              </button>      
            </div>
          </div>
        </div>
        <div className="card-body">
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">ACCIÓN</TableCell>
                  <TableCell align="center">STATUS</TableCell>
                  <TableCell align="center">ID</TableCell>
                  <TableCell align="center">NOMBRE</TableCell>
                  <TableCell align="center">TELÉFONO</TableCell>
                  <TableCell align="center">UBICACIÓN</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!load ? (
                  <>
                    {sup.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell align="center">
                          <ButtonActions
                            id={row.id_supervisor}
                            nom={row.nombre}
                            openModal={(e) => openModal(e)}
                            idPerfil={user.id_perfil}
                            reload={() => getSearchEvents()}
                          />
                        </TableCell>
                        <TableCell align="center">
                          {row.activo === 0 ? (
                            <>
                              <i
                                className="fas fa-eye-slash"
                                style={{ color: "#b71c1c" }}
                                title="Inactivo"
                              ></i>
                              &nbsp;Inactivo
                            </>
                          ) : (
                            <>
                              <i
                                className="fas fa-eye"
                                style={{ color: "#4caf50" }}
                                title="Activo"
                              ></i>
                              &nbsp;Activo
                            </>
                          )}
                        </TableCell>
                        <TableCell align="center">
                          {row.id_supervisor}
                        </TableCell>
                        <TableCell align="center">{row.nombre}</TableCell>
                        <TableCell align="center">{row.tel}</TableCell>
                        <TableCell align="center">{row.lugar}</TableCell>
                      </TableRow>
                    ))}
                  </>
                ) : (
                  <TableRow>
                    <TableCell colSpan={12} align="center">
                      <i
                        className="fas fa-spinner fa-spin"
                        style={{ fontSize: "62px", color: "#3f51b5" }}
                      ></i>
                      <br /> Buscando Datos...
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
      <ModalEddit
        openModal={ModalEdit}
        sup={supId}
        setOpenModal={(e) => openModal(e)}
        location={locationList}
        reload={(e) => reload(e)}
      />
    </div>
  );
}
export default Consumer(SupList);
