/**
 *  @name: private.jsx
 *  @version: 1.0.0
 *  @author: Trejocode - Sergio
 *  @description: Página privada
 */

import React from "react";
import Layout from "../layout";
import Sidebar from "../../components/header/sidebar";
import WarehouseList from "../../components/inventory/warehouseList";

const Warehouse = () => (
  <Layout
    title="Catálogo de Almacenes"
    description="Página privada de perfil"
    keywords="Reactjs, SEO Ready, Ejemplo"
  >
    <Sidebar/>
    <div className="content-wrapper" id="tableBodega">
        <div className="card" style={{ margin: "1rem" }}>
          <div className="card-body" style={{maxWidth:'35rem'}}>
            <WarehouseList/>
          </div>
        </div>
      </div>
  </Layout>
);

export default Warehouse;
